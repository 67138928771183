import React, { useState, useEffect } from 'react'
import { Modal, Spin, Table } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import style from './metricsModal.module.css'

// Utility function to flatten nested objects and arrays
function flattenObject(ob, prefix = '') {
  let result = {}
  // eslint-disable-next-line
  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null && Object.keys(ob[i]).length > 0) {
      // eslint-disable-next-line
      const flatObject = flattenObject(ob[i], prefix + i + '_')
      result = { ...result, ...flatObject }
    } else {
      result[prefix + i] = ob[i] ?? 'N/A' // Handle undefined, null, or empty values
    }
  }
  return result
}

// Utility function to format date strings like 'YYYY-MM-DD-YYYY-MM-DD' to 'DD Month YYYY to DD Month YYYY'
function formatDate(dateStr) {
  const dates = dateStr.split('-')
  if (dates.length === 6) {
    const startDate = new Date(`${dates[0]}-${dates[1]}-${dates[2]}`)
    const endDate = new Date(`${dates[3]}-${dates[4]}-${dates[5]}`)
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    return `${startDate.toLocaleDateString('en-GB', options)} - ${endDate.toLocaleDateString(
      'en-GB',
      options
    )}`
  }
  return dateStr
}

const KeywordsMetricsModal = ({ modalVisibility, handleCancel, isLoading }) => {
  const [isModalVisible, setIsModalVisible] = useState(modalVisibility)
  const [tableData, setTableData] = useState([])
  const dataFromStore = useSelector(
    state => state?.extraInsights?.extraInsightsData?.extra_insights_data?.keywords_data
  )
  useEffect(() => {
    // eslint-disable-next-line
    setTableData(dataFromStore ? dataFromStore : [])
    // eslint-disable-next-line
  }, [dataFromStore])
  useEffect(() => {
    setIsModalVisible(modalVisibility)
  }, [modalVisibility])

  function formatString(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  const keywrodsMetrics = tableData[0]?.keywords_metrics || {}
  const dataArray = Object.keys(keywrodsMetrics)

  const additionalMetrics = tableData[1]?.keywords_segments || {}
  const dataArray2 = Object.keys(additionalMetrics)

  // Flatten and process the data for the first dataSource
  const dataSource = dataArray.map((key, index) => {
    const flattenedMetrics = flattenObject(keywrodsMetrics[key])
    const row = { key: `metric-${index}`, title: key }
    Object.keys(flattenedMetrics).forEach(metricKey => {
      const value = flattenedMetrics[metricKey]
      row[metricKey] =
        // eslint-disable-next-line
        typeof value === 'number'
          ? value.toFixed(2)
          : // eslint-disable-next-line
          value === true
          ? 'True'
          : // eslint-disable-next-line
          value === false
          ? 'False'
          : // eslint-disable-next-line
          typeof value === 'string' && /\d{4}-\d{2}-\d{2}-\d{4}-\d{2}-\d{2}/.test(value)
          ? formatDate(value)
          : typeof value === 'string'
          ? value
          : '-'
    })
    return row
  })

  // Flatten and process the data for the second dataSource
  const dataSource2 = dataArray2?.map((key, index) => {
    const flattenedMetrics = flattenObject(additionalMetrics[key])
    const row = { key: `resource-${index + 100}`, title: key }
    Object.keys(flattenedMetrics).forEach(metricKey => {
      const value = flattenedMetrics[metricKey]
      row[metricKey] =
        // eslint-disable-next-line
        typeof value === 'number'
          ? value.toFixed(2)
          : // eslint-disable-next-line
          value === true
          ? 'True'
          : // eslint-disable-next-line
          value === false
          ? 'False'
          : // eslint-disable-next-line
          typeof value === 'string' && /\d{4}-\d{2}-\d{2}-\d{4}-\d{2}-\d{2}/.test(value)
          ? formatDate(value)
          : typeof value === 'string'
          ? value
          : '-'
    })
    return row
  })

  // Combine the data sources
  const combinedDataSource = [...dataSource, ...dataSource2]

  // Extract all unique metric keys to dynamically create columns
  const allMetricKeys = Array.from(
    new Set([
      ...dataArray.flatMap(key => Object.keys(flattenObject(keywrodsMetrics[key]))),
      ...dataArray2.flatMap(key => Object.keys(flattenObject(additionalMetrics[key]))),
    ])
  )

  // Ensure all cells have a value, and set empty cells to '-'
  const filledDataSource = combinedDataSource.map(row => {
    const filledRow = { ...row }
    allMetricKeys.forEach(key => {
      if (!filledRow[key]) {
        filledRow[key] = '-'
      }
    })
    return filledRow
  })

  const columns = [
    {
      title: <span style={{ fontWeight: 'bold', fontSize: 15 }}>Name</span>,
      dataIndex: 'title',
      key: 'title',
      fixed: true,
      width: 400,
      render: text => (
        <span style={{ fontWeight: 'bold', fontSize: 14 }}>{formatString(text)}</span>
      ),
      ellipsis: true,
    },
    ...allMetricKeys.map(metricKey => ({
      title: (
        <span style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>
          {formatString(metricKey)}
        </span>
      ),
      dataIndex: metricKey,
      key: metricKey,
      width: 250,
    })),
  ]

  return (
    <Modal
      width="100%"
      title={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              color: '#f26234',
              fontWeight: 'bold',
              fontSize: 24,
            }}
          >
            Extra Metrics - Keywords
          </div>
        </div>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      bodyStyle={{ overflowX: 'auto', overflowY: 'auto', padding: 0 }}
      footer={null}
    >
      <div className={style.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
          aria-label="Close"
        >
          <CloseOutlined className={style.closeButton} />
        </button>
      </div>
      <div id="metrics-table">
        {isLoading ? (
          <div style={{ height: 120 }}>
            <div style={{ textAlign: 'center', marginTop: 50 }}>
              <Spin />
            </div>
          </div>
        ) : (
          <Table
            dataSource={filledDataSource}
            columns={columns}
            pagination={false}
            bordered
            scroll={{ x: 'max-content', y: 580 }}
          />
        )}
      </div>
    </Modal>
  )
}

export default KeywordsMetricsModal
