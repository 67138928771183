// import { LS_KEY } from '../pages/login/login-route'
import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchReportDashboard = async (reportId, customerId) => {
  const { url, method } = apiRoutes.reportDashboard
  try {
    const res = await api({
      url: reportId ? `${url}?unique_id=${reportId}&customer_id=${customerId}` : '',
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
