import React, { useState } from 'react'

import { LEARNING_CENTER } from '@utils/constants'
import LearningCenterFeature from './learning-center-feature'

const LearningCenter = () => {
  const [temp, setTemp] = useState()
  return (
    <div
      className="learning-center"
      style={{
        marginTop: '32px',
        marginLeft: '52px',
        marginRight: '52px',
      }}
    >
      <section id={LEARNING_CENTER}>
        <LearningCenterFeature />
      </section>
    </div>
  )
}

export default LearningCenter
