import React, { useEffect } from 'react'
import { Card, Col, Row, Spin, Space } from 'antd'

import { useTranslation } from 'react-i18next'
import GoogleLogin from 'react-google-login'
import useFetch from 'use-http'
import { useHistory } from 'react-router-dom'
import { baseURL, RequestService } from '@services/requests'

import SignInWithGoogleButton from '@components/SignInWithGoogleButton'
import User from '@services/user'
import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import axios from 'axios'
import Footer from '../../components/Footer'
import styles from './login.module.scss'
import { useUtmParamsStore } from '../../hooks/use-utm-params-store'
// {
//   "detail": "Given token not valid for any token type",
//   "code": "token_not_valid",
//   "messages": [
//       {
//           "token_class": "AccessToken",
//           "token_type": "access",
//           "message": "Token is invalid or expired"
//       }
//   ]
// }

const googleClientId = process.env.REACT_APP_GOOGLE_PROJECT_CLIENT_ID

const LoginPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0)
  }, [])
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }

  const history = useHistory()
  const { post, response: loginResponse, loading } = useFetch(baseURL)

  const { t } = useTranslation()

  const onFailure = e => console.info(e)

  const handlLoginSuccess = async payload => {
    const access = await post('auth/login/', { code: payload.code })

    if (loginResponse.ok) {
      User.set({ ...access })
      localStorage.setItem('profilePic', access?.user?.profile?.profile_picture)
      history.push('/select-account')
      localStorage.setItem('loginThroughWebLink', JSON.stringify(true))
      // localStorage.setItem('showPopover', JSON.stringify(true))
    }
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <LogoAdsGrader />
        <Text className={styles.title} size={40} color="white">
          {t('login.title')}
        </Text>

        <Row className={styles.row}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            <Card className={styles.loginCard}>
              <Space direction="vertical" size={16}>
                {loading ? (
                  <Spin> </Spin>
                ) : (
                  <>
                    <Text color="darkerGray">{t('login.description.part1')}</Text>

                    <Text size={12} color="primary">
                      {t('login.description.part2')}
                      <strong> {t('login.description.part3')}</strong>
                    </Text>

                    <GoogleLogin
                      clientId={googleClientId}
                      render={({ onClick, disabled }) => (
                        <SignInWithGoogleButton onClick={onClick} disabled={disabled} />
                      )}
                      buttonText="Login with Google"
                      onSuccess={handlLoginSuccess}
                      onFailure={onFailure}
                      scope="profile email https://www.googleapis.com/auth/adwords"
                      accessType="offline"
                      responseType="code"
                      prompt="select_account"
                    />
                  </>
                )}
              </Space>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  )
}

export default LoginPage
