import React, { useState } from 'react'
import { Card, Col, Row } from 'antd'

import Notification from '@images/Learning/Notification.png'
import Share from '@images/Learning/Share.png'
import Text from '@components/Text/text'

const LearningCard = ({ image, title, tag, comments, shares }) => {
  const [temp, setTemp] = useState()
  return (
    <div>
      <div id="LearningCenterLearningCard">
        <Card style={{ height: 320 }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={image}
              height={160}
              alt="Neil"
              style={{
                maxWidth: '-moz-available',
                objectFit: 'contain',
                width: '-webkit-fill-available',
                marginLeft: 24,
                marginRight: 24,
              }}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              marginLeft: 24,
              display: 'inline-flex',

              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F1F3F4',
              borderRadius: '4px',
              width: 'auto',
              height: '30px',
            }}
          >
            <Text size={14} style={{ color: '#8F8F8F', padding: '10px', fontWeight: 600 }}>
              {tag}
            </Text>
          </div>
          <div style={{ height: '48px' }}>
            <Text
              size={16}
              isSemiBold
              style={{
                marginTop: 16,
                maxWidth: 258,
                marginLeft: 24,
                color: '#202020',
                lineHeight: '150%',
                minHeight: '48px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 2,
              }}
            >
              {title}
            </Text>
          </div>
        </Card>
      </div>
      {/* <div>
        <div
          style={{
            display: 'flex',
            maxHeight: '50px',
            padding: '16px 24px',
            border: '1px solid #E8EBEC',
            alignItems: 'center',
            // gap: '12px',
          }}
        >
          <img
            style={{ marginLeft: 16 }}
            src={Notification}
            width={18}
            height={18}
            alt="notification"
          />
          <span style={{ color: '#A3B0B3', textDecoration: 'none', marginLeft: 8 }}>
            {comments}
          </span>
          <img style={{ marginLeft: 16 }} src={Share} width={18} height={18} alt="share" />{' '}
          <span style={{ color: '#A3B0B3', textDecoration: 'none', marginLeft: 8 }}>{shares}k</span>
        </div>
      </div> */}
    </div>
  )
}

export default LearningCard
