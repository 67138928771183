/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment'
import { AG_AC_TOKEN, LS_KEY } from './constants'

export const clearLocalStorage = () => {
  const keys = [
    LS_KEY,
    AG_AC_TOKEN,
    'user',
    'role',
    'copied_report_unique_id',
    'errorCustomer',
    'isCustomers',
    'grader_impression_share',
    'copied_report_customer_id',
    'showPopover',
    'showQuestionnaire',
    'leadForm',
    'customerslength',
    'url',
    'showForm',
    'term',
    'content',
    'source',
    '?medium',
    'campaign',
    '_gl',
    'isSearchFeature',
    'profilePic',
    // 'share_Baord_Link',
  ]
  keys.forEach(key => localStorage.removeItem(key))
}

export const updateLocalStorage = data => {
  if (data?.access_token) {
    localStorage.setItem(AG_AC_TOKEN, data.access_token)
    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        access_token: data.access_token,
        user: data.user,
        refresh_token: data.refresh_token,
        isGoogleUser: data.isGoogleUser,
      })
    )
  } else {
    const userData = JSON.parse(localStorage.getItem(LS_KEY))
    localStorage.setItem(LS_KEY, JSON.stringify({ ...userData, user: data }))
  }
}

export const getParentCustomerId = payload => {
  try {
    const id = payload.split('/')[1]
    return id
  } catch (e) {
    return payload
  }
}

export const formatDate = (dateString, format = 'MMM DD, YYYY') => {
  if (!dateString) return dateString
  try {
    const date = moment(dateString).format(format)

    return date
  } catch (e) {
    return dateString
  }
}

export const scrollToElement = element => {
  try {
    if (!element) {
      console.error('Element not found in the document.')
      return
    }
    element.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    console.error('Error occurred while scrolling:', error)
  }
}

export const sortByCreatedAt = (array, sort = 'desc') => {
  if (!array?.length) return array
  const sorted = array.sort((a, b) => {
    if (sort === 'asc') {
      return moment(a.created_at).diff(moment(b.created_at))
    }
    return moment(b.created_at).diff(moment(a.created_at))
  })
  return sorted
}
