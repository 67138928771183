import React, { useState } from 'react'
import { Text } from '@components'
import { Card, Dropdown, Menu, Button, Input, Spin, message, Modal, Tooltip } from 'antd'
import verticalDivider from '@images/AdsPreview/verticalDivider.png'
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DownloadOutlined,
  MinusOutlined,
  PushpinOutlined,
} from '@ant-design/icons'
import { RequestService, bucketBaseURL } from '@services/requests'
import activeIcon from '@images/AdsPreview/activeIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  API_DATA_BOARDS_LIST,
  FETCH_SPECTFIC_BOARD_DATA,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
  SAVED_DESCRIPTION,
} from '@utils/constants'
import dayjs from 'dayjs'
import GoogleIcon from '@images/DiscoverAds/SearchBar/Meta Icons.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import SavedIcon from '@images/DiscoverAds/AdsCard/adSavedIcon.png'
import AddIcon from '@images/DiscoverAds/ButtonIcons/add.png'
import AddIconOrange from '@images/DiscoverAds/ButtonIcons/addIconOrange.svg'
import ShareIcon from '@images/DiscoverAds/ButtonIcons/share.svg'
import ShareIconOrange from '@images/DiscoverAds/ButtonIcons/shareOrange.svg'
import MoreOptionsIcon from '@images/DiscoverAds/ButtonIcons/More Option.png'
import FollowIcon from '@images/DiscoverAds/MoreOptions/followBrandIcon.png'
import CopyLinkIcon from '@images/DiscoverAds/MoreOptions/copyIcon.png'
import PinIcon from '@images/DiscoverAds/MoreOptions/pinIcon.png'
import { addBoard } from '../../../store/actions'
import AdsModal from '../AdsModal'
import styles from './adsCard.module.css'

const AdsCard = ({
  savedAdsFlowRoute,
  savedAdsFlow,
  adsData,
  onShowModal,
  onPrevious,
  onNext,
  selectedData,
  isFirst,
  isLast,
  isSavedInSavedAds,
  isAdPinned,
  boardDetailRoute,
}) => {
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  const [ThumbnailLodaer, setThumbnailLoader] = useState(false)
  const [descriptionLoader, setDescriptionLoader] = useState(false)
  const boardDetailData = useSelector(state => state?.discoverAds?.boardDetailData)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [test, setTest] = useState(apiDataBoardsList)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const hasMore = useSelector(state => state?.discoverAds?.hasMore)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPinModalOpen, setIsPinModalOpen] = useState(false)
  const [isSaved, setIsSaved] = useState(adsData?.status)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isUnSaveLoading, setIsUnSaveLoading] = useState(false)
  const savedAds = useSelector(state => state?.discoverAds?.savedAds)
  const dispatch = useDispatch()
  const showModal = () => {
    setIsModalOpen(true)
    onShowModal()
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handlePinOk = () => {
    // Logic to pin the ad to a board
    setIsPinModalOpen(false)
  }

  const handlePinCancel = () => {
    setIsPinModalOpen(false)
  }

  const handleSearch = e => {
    setSearchTerm(e.target.value)
  }
  const fetchSpecificBoardsList = async () => {
    const singleBoardID = localStorage.getItem('board_detail_id')
    try {
      const response = await RequestService.get(`/create/board-ad-detail/${singleBoardID}/`)
      dispatch({ type: FETCH_SPECTFIC_BOARD_DATA, payload: response?.data?.boards[0]?.ads })
      dispatch({
        type: FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
        payload: response?.data?.boards[0],
      })
    } catch (error) {
      // console.log('🚀 ~ fetchSpecificBoardsList ~ error:', error)
    }
  }
  const getSavedAds = async () => {
    // setLoading(true)
    try {
      const response = await RequestService.get('/save/ads/')
      const ads = response?.data?.meta_ads || []

      dispatch({ type: SAVED_PAGE_ALL_DATA, payload: ads })
      dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: ads })

      const tagsSet = new Set()
      ads.forEach(item => {
        if (item.tags && Array.isArray(item.tags)) {
          item.tags.forEach(tag => {
            tagsSet.add(tag)
          })
        }
      })
      dispatch({ type: SAVED_PAGE_UNIQUE_TAGS, payload: [...tagsSet] })
      setIsUnSaveLoading(false)
      message.success('Ad unsaved successfully')
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
      setIsUnSaveLoading(false)
    }
  }
  const handleUnsaveAds = async () => {
    setIsUnSaveLoading(true)

    try {
      // eslint-disable-next-line
      await RequestService.delete(`/un-save/assigned-ads/?ad_id=${adsData?._id}`)

      getSavedAds()
    } catch (error) {
      setIsUnSaveLoading(false)
    }
  }
  const handleSaveAds = async () => {
    setIsSaveLoading(true)
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      is_active: true,
    }
    try {
      await RequestService.post('/save/ads/', data)
      await RequestService.post('/ads-preview/competitors-prod/', data)
      setIsSaveLoading(false)
      setIsSaved(true)
    } catch (error) {
      setIsSaveLoading(false)
      setIsSaved(false)
      console.error('Error creating board:', error)
    }
  }
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setTest(response?.data)
    } catch (error) {
      // console.log('🚀 ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }
  const handlePinToBoard = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
        is_pinned: true,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Pinned to board')
        setIsPinModalOpen(false)
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }
  const handlePinToBoardFromDetailPage = async (value, id) => {
    const trimmedValue = value?.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      board: id,
      is_pinned: true,
    }
    try {
      await RequestService.post('/create/pinned-ads/', data)
      hanldeDiscoverAdsBoard()
      fetchSpecificBoardsList()
      message.success('Pinned to board')
      setIsPinModalOpen(false)
    } catch (error) {
      console.error('Error pinning ad to board:', error)
    }
  }

  const handleSelectOrCreate = async (value, id) => {
    const trimmedValue = value.trim().toLowerCase()
    const exists = test.some(board => board.name.toLowerCase() === trimmedValue)

    if (!exists) {
      const data = {
        name: value,
      }
      try {
        const response = await RequestService.post('/create/boards/', data)
        dispatch(addBoard(response))
        const datas = {
          // eslint-disable-next-line
          ad_id: adsData?._id,
          board: response.data.data.id,
        }
        try {
          await RequestService.post('/create/pinned-ads/', datas)
          hanldeDiscoverAdsBoard()
          message.success('Added to baord')
        } catch (error) {
          console.error('Error pinning ad to board:', error)
        }
        hanldeDiscoverAdsBoard()
      } catch (error) {
        console.error('Error creating board:', error)
      }
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        board: id,
      }
      try {
        await RequestService.post('/create/pinned-ads/', data)
        hanldeDiscoverAdsBoard()
        message.success('Added to baord')
      } catch (error) {
        console.error('Error pinning ad to board:', error)
      }
    }
  }

  const filteredItems = test.filter(board =>
    board?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const [isCopied, setIsCopied] = useState(false)

  const handleCopyToClipboard = async () => {
    if (adsData?.description) {
      const textToCopy = adsData?.description
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setIsCopied(true)
          message.success('Text copied to clipboard!!')
          setTimeout(() => setIsCopied(false), 2000)
        })
        .catch(() => {
          message.error('Failed to copy Link')
        })
    } else {
      setDescriptionLoader(true)
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })

        const textToCopy = response?.data?.description

        if (textToCopy) {
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              setIsCopied(true)
              message.success('Text copied to clipboard!!')
              setTimeout(() => setIsCopied(false), 2000)
            })
            .catch(() => {
              message.error('Failed to copy text')
              setDescriptionLoader(false)
            })
        } else {
          message.error('No script available')
        }
        setDescriptionLoader(false)
      } catch (error) {
        setDescriptionLoader(false)
      }
    }
  }
  const handleUnPinFromBoard = async (boardId, adId) => {
    try {
      const data = {
        board_id: boardId,
        ad_id: adId,
        is_pinned: false,
      }
      await RequestService.post('/un-save/pinned-ads/', data)
      fetchSpecificBoardsList()
      message.success('Unpinned from board')
    } catch (error) {
      message.error('Error while unpinning ad')
    }
  }
  const handleRemoveFromBoard = async (boardId, adId) => {
    try {
      await RequestService.delete(`/create/delete-ads/?ad_id=${adId}&board_id=${boardId}`)
      fetchSpecificBoardsList()
      message.success('Removed from board')
    } catch (error) {
      message.error('Error while removing ad')
    }
  }
  const saveOptionMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      <Menu.Item key="search" disabled>
        <Input
          placeholder="Search or create board"
          value={searchTerm}
          onChange={handleSearch}
          // eslint-disable-next-line
          onPressEnter={() => (handleSelectOrCreate(searchTerm), setSearchTerm(''))}
        />
      </Menu.Item>

      {filteredItems.map((board, index) => {
        // Check if the current ad is pinned in the board's pinned_ads array
        // eslint-disable-next-line
        const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

        return (
          <React.Fragment key={board?.id}>
            {isPinned ? (
              <Menu.Item key={board?.id} disabled>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <span style={{ color: '#FF5E29' }}># </span>
                    {board.name}
                  </div>
                  <CheckOutlined style={{ color: '#f16434' }} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={board?.id} onClick={() => handleSelectOrCreate(board.name, board.id)}>
                <span style={{ color: '#FF5E29' }}># </span>
                {board.name}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      })}
    </Menu>
  )

  const pinnedOptionsMenu = (
    <Menu style={{ maxHeight: 200, overflowY: 'auto' }}>
      {filteredItems.map((board, index) => {
        // eslint-disable-next-line
        const isPinned = board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)

        return (
          <React.Fragment key={board?.id}>
            {isPinned ? (
              <Menu.Item key={board?.id} disabled>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div>
                    <span style={{ color: '#FF5E29' }}># </span>
                    {board.name}
                  </div>
                  <CheckOutlined style={{ color: '#f16434' }} />
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key={board?.id} onClick={() => handlePinToBoard(board?.name, board?.id)}>
                <span style={{ color: '#FF5E29' }}># </span>
                {board.name}
              </Menu.Item>
            )}
          </React.Fragment>
        )
      })}
    </Menu>
  )

  const moreOptionsMenu = (
    <Menu>
      <Menu.Item key="1" disabled>
        <div style={{ display: 'flex', gap: 8, opacity: 1 }}>
          <img src={FollowIcon} alt="follow-icon" />
          Follow Brand
        </div>
        <div style={{ opacity: 0.7 }}>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Follow specific brands to track their ads.
          </Text>
        </div>
      </Menu.Item>

      <Menu.Item key="2" onClick={handleCopyToClipboard}>
        <div style={{ display: 'flex', gap: 8 }}>
          <img src={CopyLinkIcon} alt="copy-icon" />
          {isCopied ? 'Link Copied' : 'Copy Link'}
        </div>
        <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
          {isCopied ? '' : ' Copy the link to share this.'}
        </Text>
      </Menu.Item>
      {isAdPinned && boardDetailRoute === 'true' ? (
        <Menu.Item
          key="3"
          // eslint-disable-next-line
          onClick={() => handleUnPinFromBoard(boardDetailData?.board_id, adsData?._id)}
        >
          <div style={{ display: 'flex', gap: 8 }}>
            {/* <img src={PinIcon} alt="pin-icon" /> */}
            <PushpinOutlined style={{ width: 24, height: 24, fontSize: 24 }} />
            Unpin From Board
          </div>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Unpin this ad from Board.
          </Text>
        </Menu.Item>
      ) : (
        <>
          {!isAdPinned && boardDetailRoute === 'true' ? (
            <>
              <Menu.Item
                key="3"
                onClick={() =>
                  handlePinToBoardFromDetailPage(boardDetailData?.board_name, boardDetailData?.id)
                }
              >
                <div style={{ display: 'flex', gap: 8 }}>
                  <img src={PinIcon} alt="pin-icon" />
                  Pin to Board
                </div>
                <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                  Pin this ad to a board to organize ads.
                </Text>
              </Menu.Item>
            </>
          ) : (
            <Menu.Item key="3" onClick={() => setIsPinModalOpen(true)}>
              <div style={{ display: 'flex', gap: 8 }}>
                <img src={PinIcon} alt="pin-icon" />
                Pin to Board
              </div>
              <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
                Pin this ad to a board to organize ads.
              </Text>
            </Menu.Item>
          )}
        </>
      )}
      {boardDetailRoute === 'true' && (
        <Menu.Item
          key="4"
          // eslint-disable-next-line
          onClick={() => handleRemoveFromBoard(boardDetailData?.board_id, adsData?._id)}
        >
          <div style={{ display: 'flex', gap: 14 }}>
            {/* <img src={PinIcon} alt="pin-icon" /> */}
            <MinusOutlined />
            Remove from Board
          </div>
          <Text style={{ marginLeft: 30, fontSize: 12, color: '#8F8F8F' }}>
            Remove this ad from board.
          </Text>
        </Menu.Item>
      )}
    </Menu>
  )
  const handleDownloadThumbnailForMeta = async () => {
    setThumbnailLoader(true)
    if (adsData?.description) {
      const adText = adsData?.description || 'No description available'
      const textBlob = new Blob([adText], { type: 'text/plain' })
      const textUrl = URL.createObjectURL(textBlob)
      const textLink = document.createElement('a')
      textLink.href = textUrl
      textLink.download = 'description.txt' // File name for the text file
      document.body.appendChild(textLink)
      textLink.click()
      document.body.removeChild(textLink)
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
        const adText = response?.data?.description
        const textBlob = new Blob([adText], { type: 'text/plain' })
        const textUrl = URL.createObjectURL(textBlob)
        const textLink = document.createElement('a')
        textLink.href = textUrl
        textLink.download = 'description.txt' // File name for the text file
        document.body.appendChild(textLink)
        textLink.click()
        document.body.removeChild(textLink)
      } catch (error) {
        setDescriptionLoader(false)
      }
    }

    if (adsData?.ads_grader_image_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData.ads_grader_image_url}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
    } else if (!adsData?.ads_grader_image_url && adsData?.image_url) {
      const imageUrl = adsData.image_url
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbmail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbmail downloaded successfully')
    } else if (adsData?.ads_grader_video_url) {
      const imageUrl = `https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`
      const response = await fetch(imageUrl, { cache: 'no-store' })
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', 'thumbnail.png') // Specify image file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      message.success('Thumbnail downloaded successfully')
    } else {
      message.info('No media URL available to download')
    }
    setThumbnailLoader(false)
  }
  const handleDownloadThumbnailForGoogle = async () => {
    setThumbnailLoader(true)
    if (adsData?.description) {
      const adText = adsData?.description || 'No description available'
      const textBlob = new Blob([adText], { type: 'text/plain' })
      const textUrl = URL.createObjectURL(textBlob)
      const textLink = document.createElement('a')
      textLink.href = textUrl
      textLink.download = 'description.txt' // File name for the text file
      document.body.appendChild(textLink)
      textLink.click()
      document.body.removeChild(textLink)
    } else {
      const data = {
        // eslint-disable-next-line
        ad_id: adsData?._id,
        image_url: adsData?.image_url,
      }
      try {
        const response = await RequestService.post('/ads-preview/extract-ads/', data)
        dispatch({ type: SAVED_DESCRIPTION, payload: response?.data })
        const adText = response?.data?.description
        const textBlob = new Blob([adText], { type: 'text/plain' })
        const textUrl = URL.createObjectURL(textBlob)
        const textLink = document.createElement('a')
        textLink.href = textUrl
        textLink.download = 'description.txt' // File name for the text file
        document.body.appendChild(textLink)
        textLink.click()
        document.body.removeChild(textLink)
      } catch (error) {
        setThumbnailLoader(false)
      }
    }
    try {
      if (adsData?.image_url && !adsData?.video_url) {
        const imageUrl = adsData.image_url
        const response = await fetch(imageUrl, { mode: 'cors' })
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', 'thumbmail.png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        message.success('Thumbmail downloaded successfully')
      } else if (adsData?.video_url) {
        const imageUrl = adsData?.image_url
        try {
          // Show message when the video download starts
          const response = await RequestService.get(
            `/ads-preview/download-ads/?video_url=${imageUrl}`,
            {
              responseType: 'blob',
            }
          )
          // Create a blob URL and trigger download
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png' }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'thumbnail.png') // Give it a filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)

          // Show success message when the download is complete
          message.success('Thumbnail downloaded successfully')
        } catch (error) {
          // Show error message if something goes wrong
          message.error('Failed to download Tthumbnail')
        }
      } else {
        message.info('No media URL available to download')
      }
    } catch (error) {
      message.error(`Failed to download media: ${error.message}`)
    }
    setThumbnailLoader(false)
  }
  return (
    <>
      <Card className={styles.card}>
        <div className={styles.profileContainer}>
          {/* eslint-disable-next-line */}
          <div className={styles.profile} onClick={() => showModal(adsData._id)}>
            {localDate === adsDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <div>
                    <img
                      src={adsData?.additional_info?.page_profile_picture_url}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <div>
                    <img
                      src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                      alt="profile"
                      width={48}
                      height={48}
                      style={{ borderRadius: '48px' }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            )}

            <div>
              <Text isBold size={16}>
                {adsData?.additional_info?.page_name}
              </Text>
              <div style={{ display: 'flex', gap: 8 }}>
                {
                  // eslint-disable-next-line
                  adsData?.origin?.provider === 'meta' ? (
                    <div style={{ marginTop: -5 }}>
                      <img src={MetaIcon} alt="meta-icon" width="100%" height={16} />
                    </div>
                  ) : adsData?.origin?.provider === 'google' ? (
                    <div style={{ marginTop: -5 }}>
                      <img src={GoogleIcon} alt="google-icon" width={18} height={16} />
                    </div>
                  ) : (
                    ''
                  )
                }{' '}
                <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                {adsData?.additional_info?.ad_creation_time ? (
                  <>
                    <Text size={14} style={{ color: '#8F8F8F' }}>
                      Published Date:
                    </Text>
                    <Text size={14} style={{ color: '#8F8F8F' }}>
                      {dayjs(adsData?.additional_info?.ad_creation_time).format('MMM D, YYYY')}
                    </Text>
                  </>
                ) : (
                  ''
                )}
                <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                {
                  // eslint-disable-next-line
                  adsData?.additional_info?.is_active === 'false' ||
                  adsData?.additional_info?.is_active === false ? (
                    ''
                  ) : adsData?.additional_info?.is_active === 'true' ||
                    adsData?.additional_info?.is_active === true ? (
                    <img src={activeIcon} className={styles.profileIcons} alt="active-icon-pic" />
                  ) : (
                    ''
                  )
                }
                <Text size={14} style={{ color: '#8F8F8F' }}>
                  {
                    // eslint-disable-next-line
                    adsData?.additional_info?.is_active === 'true' ||
                    adsData?.additional_info?.is_active === true
                      ? 'Currently Active'
                      : adsData?.additional_info?.is_active === 'false' ||
                        adsData?.additional_info?.is_active === false
                      ? 'Ended'
                      : ''
                  }
                </Text>
                {isAdPinned ? (
                  <>
                    <img src={verticalDivider} height={12} style={{ marginTop: 5 }} alt="divider" />
                    <PushpinOutlined
                      color="#f16434"
                      style={{ color: '#f16434', fontSize: '15px' }}
                    />{' '}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {/* Dropdown */}
          <div id="ads-card-more-options">
            <Dropdown
              overlay={moreOptionsMenu}
              getPopupContainer={() => document.getElementById('ads-card-more-options')}
              trigger={['click']} // Ensures the dropdown is triggered on click
            >
              <button
                type="submit"
                style={{ border: 'none', padding: 0, background: 'none', display: 'inline-flex' }}
              >
                <img
                  src={MoreOptionsIcon}
                  width={20}
                  height={20}
                  alt="more-options"
                  style={{ cursor: 'pointer' }} // Ensures the cursor is a pointer
                />
              </button>
            </Dropdown>
          </div>
        </div>
        {localDate === adsDate ? (
          <>
            {
              // eslint-disable-next-line
              savedAdsFlow === 'true' ? (
                <>
                  {adsData?.origin?.provider === 'meta' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <video
                              style={{ width: '100%', height: '300px' }}
                              src={adsData?.video_url}
                              onPlay={e => e.preventDefault()} // Prevents playing
                            >
                              <track
                                kind="captions"
                                srcLang="en"
                                src="captions_en.vtt"
                                label="English captions"
                              />
                              Your browser does not support the video tag.
                            </video>

                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                  {adsData?.origin?.provider === 'google' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url && !adsData?.video_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.image_url && adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={adsData.image_url ?? noPreviewImage}
                              className={styles.cardImage}
                              alt="card-img"
                            />
                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <video
                          style={{ width: '100%', height: '300px' }}
                          src={adsData?.video_url}
                          onPlay={e => e.preventDefault()} // Prevents playing
                        >
                          <track
                            kind="captions"
                            srcLang="en"
                            src="captions_en.vtt"
                            label="English captions"
                          />
                          Your browser does not support the video tag.
                        </video>
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                    C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                    C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        ) : (
          <>
            {
              // eslint-disable-next-line
              savedAdsFlow === 'true' ? (
                <>
                  {adsData?.origin?.provider === 'meta' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.ads_grader_image_url ? (
                          <img
                            src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.ads_grader_video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`}
                              className={styles.cardImage}
                              alt="card-img"
                            />

                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                  {adsData?.origin?.provider === 'google' && savedAdsFlow === 'true' ? (
                    // eslint-disable-next-line
                    <div
                      className={styles.cardImageContainer}
                      // eslint-disable-next-line
                      onClick={() => showModal(adsData._id)}
                    >
                      {
                        // eslint-disable-next-line
                        adsData?.image_url && !adsData?.video_url ? (
                          <img
                            src={adsData.image_url ?? noPreviewImage}
                            className={styles.cardImage}
                            alt="card-img"
                          />
                        ) : adsData?.image_url && adsData?.video_url ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={adsData.image_url ?? noPreviewImage}
                              className={styles.cardImage}
                              alt="card-img"
                            />
                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={noPreviewImage}
                            className={styles.cardImage}
                            alt="noPreviewImage"
                          />
                        )
                      }
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : // eslint-disable-next-line
              selectedChannel === 'Meta' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.ads_grader_image_url ? (
                      <img
                        src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.ads_grader_image_url}`}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.ads_grader_video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_meta_thumbnail}`}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : selectedChannel === 'Google' ? (
                // eslint-disable-next-line
                <div className={styles.cardImageContainer} onClick={() => showModal(adsData._id)}>
                  {
                    // eslint-disable-next-line
                    adsData?.image_url && !adsData?.video_url ? (
                      <img
                        src={adsData.image_url ?? noPreviewImage}
                        className={styles.cardImage}
                        alt="card-img"
                      />
                    ) : adsData?.image_url && adsData?.video_url ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          src={adsData.image_url ?? noPreviewImage}
                          className={styles.cardImage}
                          alt="card-img"
                        />
                        <svg
                          height="20%"
                          version="1.1"
                          viewBox="0 0 68 48"
                          width="20%"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fill: 'white',
                            opacity: '0.8',
                            cursor: 'pointer',
                          }}
                        >
                          <path
                            className="ytp-large-play-button-bg"
                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 
                  C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 
                  C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                            fill="#f00"
                          />
                          <path d="M 45,24 27,14 27,34" fill="#fff" />
                        </svg>
                      </div>
                    ) : (
                      <img src={noPreviewImage} className={styles.cardImage} alt="noPreviewImage" />
                    )
                  }
                </div>
              ) : (
                ''
              )
            }
          </>
        )}

        {adsData?.title && (
          <Text
            style={{
              marginTop: 12,
              color: '#202020',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              WebkitLineClamp: 1,
            }}
          >
            {adsData?.title[0]}
          </Text>
        )}

        <div className={styles.buttonContainer} id="area">
          {savedAdsFlowRoute === 'true' ? (
            <>
              {isUnSaveLoading ? (
                <div style={{ marginLeft: '65px' }}>
                  <Spin />
                </div>
              ) : (
                <button
                  type="button"
                  className={styles.saveButton} // Change style based on save status
                  onClick={handleUnsaveAds}
                >
                  <Text className={styles.savedText} size={14}>
                    Unsave
                  </Text>
                </button>
              )}
            </>
          ) : (
            <>
              {' '}
              {isSaveLoading && !isSavedInSavedAds && !isSaved ? (
                <div style={{ marginLeft: '65px' }}>
                  <Spin />
                </div>
              ) : (
                <button
                  type="button"
                  className={`${styles.saveButton} ${
                    isSaved || isSavedInSavedAds ? styles.saved : ''
                  }`} // Change style based on save status
                  onClick={handleSaveAds}
                >
                  {isSaved || isSavedInSavedAds ? (
                    <div>
                      <img style={{ marginTop: -7 }} src={SavedIcon} alt="save-icon" />
                      <span style={{ paddingLeft: 6 }}>Ad Saved</span>
                    </div>
                  ) : (
                    <Text className={styles.savedText} size={14}>
                      Save
                    </Text>
                  )}
                </button>
              )}
            </>
          )}

          <div className={styles.iconButtonContainer} id="adsCardTooltip">
            {descriptionLoader ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <Tooltip
                title="Copy script"
                color="rgb(32, 32, 32)"
                getPopupContainer={() => document.getElementById('adsCardTooltip')}
                placement="top"
              >
                {/* eslint-disable-next-line */}
                <button
                  className={styles.addButton}
                  type="button"
                  onClick={handleCopyToClipboard}
                  id="first"
                >
                  <>
                    <CopyOutlined
                      style={{ color: '#939395', marginTop: '6px' }}
                      className={styles.copyIcon}
                    />
                    <CopyOutlined style={{ marginTop: '6px' }} className={styles.copyIconOrange} />
                  </>
                </button>
              </Tooltip>
            )}
            {ThumbnailLodaer ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <Tooltip
                title="Download Thumbnail"
                color="rgb(32, 32, 32)"
                getPopupContainer={() => document.getElementById('adsCardTooltip')}
                placement="top"
              >
                <button
                  className={styles.addButton}
                  type="button"
                  onClick={
                    adsData?.origin?.provider === 'meta'
                      ? handleDownloadThumbnailForMeta
                      : handleDownloadThumbnailForGoogle
                  }
                  id="first"
                >
                  <DownloadOutlined
                    style={{ color: '#939395', marginTop: '6px' }}
                    className={styles.copyIcon}
                  />
                  <DownloadOutlined
                    style={{ marginTop: '6px' }}
                    className={styles.copyIconOrange}
                  />
                  {/* <img src={CopyIcon} alt="copy-icon" className={styles.copyIcon} />
                <img
                  src={CopyIconOrange}
                  alt="copy-icon-orange"
                  className={styles.copyIconOrange}
                /> */}
                </button>
              </Tooltip>
            )}

            {/* <Tooltip
              title="Copy ad link"
              color="rgb(32, 32, 32)"
              getPopupContainer={() => document.getElementById('adsCardTooltip')}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={handleCopyToClipboard}
                id="first"
              >
                <img src={CopyIcon} alt="copy-icon" className={styles.copyIcon} />
                <img
                  src={CopyIconOrange}
                  alt="copy-icon-orange"
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip> */}
            <Tooltip
              title="View ad"
              color="rgb(32, 32, 32)"
              getPopupContainer={() => document.getElementById('adsCardTooltip')}
              placement="top"
            >
              <button
                className={styles.addButton}
                type="button"
                onClick={() => {
                  window.open(adsData?.ad_snapshot_url, '_blank')
                }}
              >
                <img src={ShareIcon} alt="share-icon" width={20} className={styles.copyIcon} />
                <img
                  src={ShareIconOrange}
                  alt="share-icon"
                  width={20}
                  className={styles.copyIconOrange}
                />
              </button>
            </Tooltip>
            <Dropdown
              onClick={hanldeDiscoverAdsBoard}
              overlay={saveOptionMenu}
              getPopupContainer={() => document.getElementById('adsCardTooltip')}
              trigger={['click']}
              overlayStyle={{ width: 'max-content', maxWidth: '240px', zIndex: 1 }}
            >
              <Tooltip
                title="Add to board"
                color="rgb(32, 32, 32)"
                getPopupContainer={() => document.getElementById('adsCardTooltip')}
                placement="top"
              >
                <button className={styles.addButton} type="button">
                  <img src={AddIcon} alt="add-icon" className={styles.addIcon} />
                  <img
                    src={AddIconOrange}
                    alt="hovered-add-icon"
                    className={`${styles.addIcon} ${styles.hoveredIcon}`}
                  />
                </button>
              </Tooltip>
            </Dropdown>
          </div>
        </div>
      </Card>

      {isModalOpen && selectedData && (
        <AdsModal
          adsData={selectedData}
          modalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          onPrevious={onPrevious}
          onNext={onNext}
          isFirst={isFirst}
          isLast={isLast}
          boardsList={test}
        />
      )}
      {/* <PinToBoard
        adsData={adsData}
        isPinModalOpen={isPinModalOpen}
        handleOk={handlePinOk}
        handleCancel={handlePinCancel}
        boardsList={test}
      /> */}
      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Pin ad to Board</span>
            <CloseOutlined onClick={() => setIsPinModalOpen(false)} style={{ cursor: 'pointer' }} />
          </div>
        }
        visible={isPinModalOpen}
        onCancel={() => setIsPinModalOpen(false)}
        footer={null}
      >
        <Dropdown
          overlay={pinnedOptionsMenu}
          // getPopupContainer={() => document.getElementById('area')}
          trigger={['click']}
          overlayStyle={{ width: 'max-content', maxWidth: '240px' }}
        >
          <Input
            placeholder="Search or create board"
            value={searchTerm}
            onChange={handleSearch}
            onClick={() => setIsDropdownVisible(prev => !prev)}
            onPressEnter={() => {
              handleSelectOrCreate(searchTerm)
              setSearchTerm('')
              setIsDropdownVisible(false)
            }}
          />
        </Dropdown>
      </Modal>
    </>
  )
}

export default AdsCard
