/* eslint-disable no-underscore-dangle */
import axios from 'axios'

import User from './user'
import { sessionStorage } from './session-storage'

export const baseURL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
  baseURL,
})

const axiosWithAuth = axios.create({
  baseURL,
})

axiosWithAuth.interceptors.request.use(
  config => {
    const user = User.get()

    if (user && user.access_token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${user.access_token}`
    }
    return config
  },
  error => Promise.reject(error)
)

axiosWithAuth.interceptors.response.use(
  res => res,
  async err => {
    if (err.response) {
      // Access Token was expired
      const _retry = sessionStorage.get(err.config.url)

      if (err.response.status === 401 && !_retry) {
        sessionStorage.set(err.config.url, 'retryDone')

        try {
          const user = User.get()

          if (!user && !user.refresh_token) {
            return new Error('User has no refresh token')
          }

          const rs = await axiosWithAuth.post('/auth/token/refresh/', {
            refresh: user.refresh_token,
          })

          const { access } = rs.data
          User.set({ ...user, access_token: access })

          return axiosWithAuth(err.config)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)
const bucketBaseURL = 'production'
const boardShareBaseURL = 'https://adsgrader.com'
const RequestService = axiosWithAuth

export { RequestService, axiosInstance, bucketBaseURL, boardShareBaseURL }
