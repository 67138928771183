import { forIn } from 'lodash'

/* eslint-disable import/prefer-default-export */
export const formatMCC = text => {
  if (!text) return '-'

  return `${text.substring(0, 3)}-${text.substring(3, 6)}-${text.substring(6, 10)}`
}

export const delay = (timeout = 1000) =>
  new Promise(resolve => {
    setTimeout(() => resolve(), timeout)
  })

export const convertToNumber = number => (number / 10000).toFixed(2)

export const getCTR = (clicks, impressions) => {
  if (clicks && impressions) {
    const percentage = (clicks / impressions) * 100

    return percentage.toFixed(2)
  }
  return 0
}

export const getPercentage = number => (number ? `${(number * 100).toFixed(2)} %` : 0)

export const doubleDecimals = number => {
  if (!number) return 0

  return Number(number.toFixed(2))
}

export const formatCurrency = (number, options = {}) =>
  typeof number === 'number' && !JSON.parse(localStorage.getItem('copied_report_customer_id'))
    ? new Intl.NumberFormat(options.language || 'en-US', {
        style: 'currency',
        currency: options.currency || 'USD',
      }).format(number)
    : new Intl.NumberFormat(options.language || 'en-US').format(number)

export const getBenchmarkProp = (prop, benchmarks) => {
  if (!benchmarks && !benchmarks.length) return '-'
  return benchmarks.find(benchmark => benchmark.name === prop)?.value || '-'
}

export const isTrue = value => {
  if (!value) return false
  return value === 'True'
}

export const checkingFeedback = (feedback, textPositve, textNeutral, textNegative) => {
  if (feedback >= 80) {
    return textPositve
  }
  if (feedback < 80 && feedback >= 60) {
    return textNeutral
  }
  return textNegative
}

export const getLineatLine = (initialValue, incrementValue, daysLength) => {
  const array = [initialValue]

  for (let i = 0; i < daysLength - 1; i += 1) {
    array.push(array[array.length - 1] + incrementValue)
  }
  return array
}
