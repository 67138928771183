import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import styles from './progressBar.module.scss'
import Text from '../Text/text'

const ProgressBar = ({ percentageNumber }) => {
  const percentageColor = percentage => {
    if (percentage <= 40) {
      return 'percentage1'
    }
    if (percentage < 65) {
      return 'percentage2'
    }
    return 'percentage3'
  }
  return (
    <div className={styles.container}>
      <Text color={percentageColor(percentageNumber)} weight="700" size={12}>
        {Number(percentageNumber) < 0 ? 0 : percentageNumber?.toFixed(0)}%
      </Text>
      <div className={styles.progressBar}>
        <div
          className={classNames([[styles[`progress-${percentageColor(percentageNumber)}`]]])}
          style={{
            height: 8,
            width: Number(percentageNumber) < 0 ? 0 : percentageNumber,
            borderRadius: 16,
          }}
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  percentageNumber: PropTypes.number,
}

ProgressBar.defaultProps = {
  percentageNumber: 0,
}

export default ProgressBar
