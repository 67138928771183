import React from 'react'
import { Modal as AntdMOdal } from 'antd'
import styles from './modal.module.css'

const Modal = ({
  children,
  open,
  handleCancel,
  heading = 'Password Reset Email Sent',
  description = 'If your email address is in our database, we’ve sent you a link to reset your password.',
  ...rest
}) => (
  <AntdMOdal open={open} footer={false} onCancel={handleCancel} {...rest}>
    <div className={styles.modal}>
      <h2 className={styles.modalHeading}>{heading}</h2>
      <div className={styles.divider} />
      <p className={styles.description}>{description}</p>
      {children}
    </div>
  </AntdMOdal>
)

export default Modal
