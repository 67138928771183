import React, { useState } from 'react'
import { Card, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import GoogleLogin from 'react-google-login'

import { Text } from '@components'

import styles from './styles.module.css'

const googleClientId = process.env.REACT_APP_GOOGLE_PROJECT_CLIENT_ID
const CodePage = () => {
  const [code, setCode] = useState('')
  const { t } = useTranslation()

  const onFailure = e => console.info(e)

  const handlLoginSuccess = async payload => {
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-alert
    setCode(payload.code)
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <Text className={styles.title} size={40}>
          Code:
        </Text>

        <input type="text" className={styles.input} value={code} />

        <br />
        <br />
        <GoogleLogin
          clientId={googleClientId}
          render={renderProps => (
            <Button type="primary" onClick={renderProps.onClick}>
              {t('login.siginWithGoogle')}
            </Button>
          )}
          buttonText="Login with Google"
          onSuccess={handlLoginSuccess}
          onFailure={onFailure}
          scope="profile email https://www.googleapis.com/auth/adwords"
          accessType="offline"
          responseType="code"
          prompt="select_account"
        />
      </div>
    </div>
  )
}

export default CodePage
