import React, { useState } from 'react'
import npOutshine from '@images/Learning/np-outshine.png'
import { Text } from '@components'
import { RequestService } from '@services/requests'
import { useSelector } from 'react-redux'

const Outshine = () => {
  const [temp, setTemp] = useState()
  const modalMessage = useSelector(state => state?.learningCenterModalMessage?.message)
  const handleOpenConsulting = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      // Base URL with common parameters
      let url =
        'https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting'
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `&cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
        url += `&message=${encodeURIComponent(modalMessage)}`
      }
      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log(':rocket: ~ getBenchmarks ~ e:', e)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        height: 140,
        justifyContent: 'center',
        marginTop: 32.5,
        border: '1px solid #E8EBEC',
        background: '#fff',
      }}
    >
      <div>
        <img
          src={npOutshine}
          width={116.548}
          height={125}
          style={{ marginTop: 13.5 }}
          alt="Neil Patel"
        />
      </div>
      <Text
        size={24}
        // isBold
        style={{
          fontWeight: 500,
          marginTop: '46.5px',
          marginBottom: '47.5px',
          marginLeft: '40px',
          lineHeight: '120%',
          letterSpacing: '0.3px',
          maxWidth: 199,
        }}
      >
        <span style={{ color: '#26282C' }}>Ready to outshine your </span>{' '}
        <span style={{ color: '#FF5E29' }}>competition?</span>
      </Text>
      <div
        style={{
          marginTop: '52.5px',
          marginLeft: '40px',
          maxWidth: 332,
          height: 35,
          // letterSpacing: '0.1px',
          color: '#202020',
          fontSize: 16,
          lineHeight: '150%',
        }}
      >
        Join us, and let&apos;s revolutionize your advertising strategy for unparalleled success in
        the market!
      </div>
      <div style={{ marginTop: '60px', marginLeft: '40px' }}>
        <button
          style={{
            display: 'inline-flex',
            height: 32,
            // width: 204,
            fontSize: 12,
            fontWeight: 700,
            padding: '12px',
            // justifyContent: 'center',
            letterSpacing: '0.5px',
            alignItems: 'center',
            background: '#F16434',
            border: '1px solid #F16434',
            borderRadius: '2px',
            textTransform: 'uppercase',
            color: '#ffffff',
            cursor: 'pointer',
          }}
          type="submit"
          onClick={handleOpenConsulting}
        >
          Get Our Consulting Services
        </button>
      </div>
    </div>
  )
}

export default Outshine
