import React, { memo } from 'react'
import styles from './input.module.css'

const InputField = ({ label, inputProps, labelProps, endAdornment, isError, isSuccess }) => (
  <div className={styles.inputWrapper}>
    {label && (
      <p className={styles.label} {...labelProps}>
        {label}
      </p>
    )}
    <div className={styles.inputContainer}>
      <input
        className={`${styles.input} ${isError && styles.errorBorder}`}
        type="text"
        placeholder="Enter Password"
        {...inputProps}
      />
      {!!endAdornment && (!!isError || !!isSuccess) && (
        <div
          className={`${styles.endAdornment} ${isError && styles.isError} ${
            isSuccess && styles.isSuccess
          }`}
        >
          {endAdornment}
        </div>
      )}
    </div>
  </div>
)

export default memo(InputField)
