import { LS_KEY } from '@utils/constants'

const getLocalAccessToken = () => {
  try {
    const accessToken = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
    return accessToken
  } catch (e) {
    return null
  }
}
const getLocalRefreshToken = () => {
  try {
    const refreshToken = JSON.parse(localStorage.getItem(LS_KEY))?.refresh_token
    return refreshToken
  } catch (e) {
    return null
  }
}
const updateAccessToken = token => {
  try {
    const preData = JSON.parse(localStorage.getItem(LS_KEY)) ?? {}
    preData.access_token = token
    localStorage.setItem(LS_KEY, JSON.stringify(preData))
  } catch (e) {
    throw new Error()
  }
}

const updateRefreshToken = token => {
  try {
    const preData = JSON.parse(localStorage.getItem(LS_KEY)) ?? {}
    preData.refresh_token = token
    localStorage.setItem(LS_KEY, JSON.stringify(preData))
  } catch (e) {
    throw new Error()
  }
}

const checkRefreshTokenValidity = token => {
  const tokenParts = token?.split('.')
  if (tokenParts?.length !== 3) {
    return false
  }

  const encodedPayload = tokenParts[1]
  let payload
  try {
    payload = JSON.parse(window.atob(encodedPayload))
  } catch (error) {
    return false
  }

  const expirationTime = payload.exp * 1000
  if (Date.now() >= expirationTime) {
    return false
  }

  return true
}

const tokenService = {
  getLocalAccessToken,
  getLocalRefreshToken,
  updateAccessToken,
  updateRefreshToken,
  checkRefreshTokenValidity,
}
export default tokenService
