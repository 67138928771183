import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Checkbox, Avatar } from 'antd'

import { Text } from '@components'
import CustomSlider from '@components/CustomSlider'

import { ADS_BEDGET_COST_CACULATOR } from '@utils/constants'
import ProfileImg from '../../images/neil-face.png'
import calStyle from './caluculator.module.css'

import styles from '../dashboard/dashboard.module.scss'

const Calculator = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const { t } = useTranslation()
  // check box
  const onChange = e => {
    // console.log(`checked = ${e.target.checked}`)
  }

  return (
    <div>
      <section id={ADS_BEDGET_COST_CACULATOR} className={styles.containe}>
        <Row>
          <Col span={14} style={{ padding: '25px', paddingBottom: '0px' }}>
            <div className={styles.threeStepsTitle} style={{ padding: '10px' }}>
              <Text
                isTitle
                size={32}
                weight={500}
                style={{
                  margin: '20px',
                  fontSize: '24px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                <strong>Budget & ROAS Calculator</strong>
              </Text>
            </div>
            {/* <div
              style={{
                display: 'flex',
                margin: '30px',
                marginBottom: '0px',
                justifyContent: 'space-between',
              }}
            >
              <Checkbox onChange={onChange} className={calStyle.CheckboxCustom}>
                Fill with my ad account data
              </Checkbox>
              <Checkbox onChange={onChange} className={calStyle.CheckboxCustom}>
                Use my industry benchmark numbers
              </Checkbox>
            </div> */}
          </Col>
          {/* when uncomment this section remove paddingBottom: '0px' */}
          {/* <Col
            span={10}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '25px',
              paddingLeft: '10px',
            }}
          >
            <div className={calStyle.container}>
              <Avatar className={calStyle.image} src={ProfileImg} />
              <Text size={16} color="black" className={calStyle.quoteContent}>
                We can load data from your ad account to make the calculator more accurate. Or you
                can use your industry benchmark numbers.
              </Text>
            </div>
          </Col> */}
        </Row>

        <div className={styles.calculator} style={{ padding: '30px', paddingTop: '0px' }}>
          {/* when uncomment upper  section remove ppaddingTop: '0px' */}

          <CustomSlider />
        </div>
      </section>
    </div>
  )
}
export default Calculator
