import React, { useState } from 'react'
import { bucketBaseURL } from '@services/requests'
import styles from './categoryButon.module.css'

const CategoryButton = ({ categryData, onClick, clearState }) => {
  const [clickedNames, setClickedNames] = useState([])

  const handleClick = () => {
    const pageName = categryData?.additional_info?.page_name

    if (pageName && !clickedNames.includes(pageName)) {
      onClick(pageName)
      setClickedNames(prevNames => [...prevNames, pageName])
    } else {
      // console.log('Name already clicked or does not exist:', pageName)
    }
  }
  const categoryDate = new Date(categryData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <button
          onClick={handleClick}
          type="button"
          className={styles.button}
          style={{ display: 'flex' }}
        >
          {categoryDate === localDate ? (
            <>
              {categryData?.additional_info?.page_profile_picture_url ? (
                <img
                  src={categryData?.additional_info?.page_profile_picture_url}
                  alt="category-logo"
                  width={24}
                  height={24}
                  style={{ borderRadius: '24px' }}
                />
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {categryData?.additional_info?.ads_grader_page_profile_picture_url ? (
                <img
                  src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${categryData?.additional_info?.ads_grader_page_profile_picture_url}`}
                  alt="category-logo"
                  width={24}
                  height={24}
                  style={{ borderRadius: '24px' }}
                />
              ) : (
                ''
              )}
            </>
          )}

          <span
            style={{
              paddingLeft: 8,
              whiteSpace: 'nowrap',
            }}
          >
            {categryData?.additional_info?.page_name}
          </span>
        </button>
      </div>
    </div>
  )
}

export default CategoryButton
