// import { LS_KEY } from '../pages/login/login-route'
import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchQuestionnaireQuestions = async () => {
  const { url, method } = apiRoutes.getQuestions
  try {
    const res = await api({
      url,
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
