import React, { useState } from 'react'
import supportIcon from '@images/Learning/support-icon.png'
import { Text } from '@components'
import LearningModal from '../LearningModal'

const Support = () => {
  const [temp, setTemp] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <div
      style={{
        display: 'flex',
        height: 140,
        background: '#E9F0FC',
        border: '1px solid #A0C2F9',
        justifyContent: 'center',
        marginTop: 32,
      }}
    >
      <div>
        <img
          src={supportIcon}
          width={166.721}
          height={122}
          style={{ marginTop: '16px' }}
          alt="Neil Patel"
        />
      </div>
      <div style={{ marginTop: 45, marginLeft: 31.28 }}>
        <Text
          size={24}
          isBold
          style={{
            height: 20,
            // width: 360,
            lineHeight: '120%',
            color: '#26282C',
            fontStyle: 'normal',
          }}
        >
          Our Support Team is here to help
        </Text>
        <Text
          size={14}
          isBold
          style={{
            marginTop: 16,
            // lineHeight: '171.429%',
            fontStyle: 'normal',
            color: '#202020',
          }}
        >
          Contact us to get your issue resolved as soon as possible.
        </Text>
      </div>
      <div style={{ marginTop: '52px', marginLeft: '24px' }}>
        <button
          style={{
            display: 'inline-flex',
            height: 32,
            // width: 132,
            fontSize: 12,
            fontWeight: 700,
            background: '#4285F4',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px',
            border: '1px solid #4285F4',
            borderRadius: '2px',
            color: '#ffffff',
            cursor: 'pointer',
          }}
          type="submit"
          onClick={showModal}
        >
          CONTACT SUPPORT
        </button>
        {isModalOpen ? <LearningModal modalOpen={isModalOpen} handleCancel={handleCancel} /> : ''}
      </div>
    </div>
  )
}

export default Support
