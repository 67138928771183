/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Text } from '@components'
import LenguegeSelect from '@components/LenguegeSelect'
import styles from '../Navbar/navbar.module.css'

const MobileView = () => {
  const [temp, setTemp] = useState()
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.mobileTopText} id="ok">
        <div className={styles.navbarContainer}>
          <div className={styles.logoSection}>
            <div className={styles.logoSection2}>
              <div className={styles.logo}>
                <h3 className={styles.logoText}>Ads Grader</h3>
                <p className={`${styles.bold} ${styles.logoHelperText}`}>
                  by <span className={styles.NP}>NP</span> digital
                </p>
              </div>
              <div className={styles.spanLine} />
              <span>
                <LenguegeSelect color="black" />
              </span>
            </div>
          </div>
        </div>
        <div className={styles.mobileText}>
          <Text
            isBold
            size={20}
            style={{ textAlign: 'center', top: '50%', position: 'absolute', padding: '0px 25px' }}
          >
            {t('mobileView')}
          </Text>
        </div>
      </div>
    </>
  )
}

export default MobileView
