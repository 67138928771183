import {
  ADS_CHANNEL,
  ADS_END_DATE,
  ADS_FORMAT,
  ADS_LOCATION,
  ADS_SEARCH_COMPANY,
  ADS_SEARCH_KEYWORD,
  ADS_START_DATE,
  ADS_DATA,
  ADS_PAGINATION_DATA,
  ADS_LOADER,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  ADS_PAGINATION,
  ADS_MODAL_PAGINATION,
  SPINNER,
  EMPTY_ADS_PREVIEW_DATA,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  MORE_DATA_LOADER,
  META_ADS_PAGINATION_DATA,
} from '@utils/constants'

const initialState = {
  metaAdsNewData: [],
  searchCompany: '',
  searchKeyword: '',
  startDate: '',
  endDate: '',
  location: '',
  channels: 'Google',
  formats: '',
  adsPreviewData: [],
  adsPaginationData: [],
  loading: false,
  homeState: true,
  adsNoData: false,
  adsPagination: '',
  adsModalPagination: '',
  spinner: false,
  cleanedDataCount: '',
  hasMore: false,
  moreDataLoader: false,
}
const adsPreviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADS_SEARCH_COMPANY:
      return { ...state, searchCompany: action.payload }
    case ADS_SEARCH_KEYWORD:
      return { ...state, searchKeyword: action.payload }
    case ADS_START_DATE:
      return { ...state, startDate: action.payload }
    case ADS_END_DATE:
      return { ...state, endDate: action.payload }
    case ADS_LOCATION:
      return { ...state, location: action.payload }
    case ADS_CHANNEL:
      return { ...state, channels: action.payload }
    case ADS_FORMAT:
      return { ...state, formats: action.payload }
    case ADS_DATA:
      return { ...state, adsPreviewData: [...state.adsPreviewData, ...action.payload] }
    case ADS_PAGINATION_DATA:
      return { ...state, adsPreviewData: [...state.adsPreviewData, ...action.payload] }
    case EMPTY_ADS_PREVIEW_DATA:
      return { ...state, adsPreviewData: [], adsPaginationData: [] }
    case ADS_LOADER:
      return { ...state, loading: action.payload }
    case MORE_DATA_LOADER:
      return { ...state, moreDataLoader: action.payload }
    case ADS_HOME_STATE:
      return { ...state, homeState: action.payload }
    case ADS_NO_DATA:
      return { ...state, adsNoData: action.payload }
    case ADS_PAGINATION:
      return { ...state, adsPagination: action.payload }
    case ADS_MODAL_PAGINATION:
      return { ...state, adsModalPagination: action.payload }
    case SPINNER:
      return { ...state, spinner: action.payload }
    case CLEANED_DATA_COUNT:
      return { ...state, cleanedDataCount: action.payload }
    case HAS_MORE:
      return { ...state, hasMore: action.payload }
    case META_ADS_PAGINATION_DATA:
      return { ...state, metaAdsNewData: [action.payload] }

    default:
      return state
  }
}
export default adsPreviewReducer
