import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { notify } from '@utils/notify'

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { t } = useTranslation()
  if (!isAuthenticated) {
    notify(t('notification.privateRoute'), 'warning')
    return <Redirect to="/login" />
  }
  return Component
}

export default PrivateRoute
