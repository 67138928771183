import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { passwordRules } from '@utils/validation'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { updateLocalStorage } from '@utils/helperFunctions'
import { notify } from '@utils/notify'
import { RequestService } from '@services/requests'
import Modal from '../../components/Modal'
import { apiRoutes } from '../../config/apiRoutes'
import api from '../../services/api'
import Authentication from '../../components/Authentication'
import { updateUserData } from '../../store/actions'
import styles from './register.module.css'

const validationSchema = yup.object().shape({
  first_name: yup.string().required().min(3).label('First Name'),
  last_name: yup.string().required().min(3).label('Last Name'),
  email: yup.string().email('Email is invalid!').required().label('Email'),
  password: yup.string().min(8).required().label('Password'),
  receive_emails: yup.boolean().oneOf([true], 'Pleaase check the email notification checkbox.'),
  terms_and_conditions: yup
    .boolean()
    .oneOf([true], 'Please check the terms and conditons checkbox.'),
})

const Register = props => {
  // eslint-disable-next-line
  const invitedUser = props?.location?.state?.invitedUser
  const alreadyLoggedIn = localStorage.getItem('adsGraderAccessToken')

  const [isLoading, setIsLoading] = useState(false)
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    // eslint-disable-next-line
    if (!invitedUser && !alreadyLoggedIn) {
      history.push('/login')
    } else if (!invitedUser && alreadyLoggedIn) {
      history.push('/dashboard')
    }
    // eslint-disable-next-line
  }, [invitedUser, alreadyLoggedIn])
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      terms_and_conditions: false,
      receive_emails: false,
    },
    validationSchema,
    onSubmit: async values => {
      const { url, method } = apiRoutes.register
      const updatedValues = {
        email: values.email,
        password1: values.password,
        password2: values.password,
        is_email_notification: values.receive_emails,
        first_name: values.first_name,
        last_name: values.last_name,
      }
      setIsLoading(true)
      try {
        const res = await api({
          url,
          method,
          data: updatedValues,
        })
        if (res.status === 201) {
          setIsLoading(false)
          if (res.data.access_token) {
            const userData = { ...res.data }
            updateLocalStorage(userData)
            dispatch(updateUserData(userData))
            history.push('/dashboard')
          } else {
            setShowModal(true)
          }
        }
      } catch (e) {
        setIsLoading(false)
        if (e?.response?.data?.email?.length) {
          notify(e.response.data.email[0], 'error')
        }
      }
    },
  })

  useEffect(() => {
    if (formik?.values?.email) {
      formik.setFieldTouched('email')
    }
    if (formik?.values?.password) {
      formik.setFieldTouched('password')
    }
    if (formik?.values?.first_name) {
      formik.setFieldTouched('first_name')
    }
    if (formik?.values?.last_name) {
      formik.setFieldTouched('last_name')
    }

    // eslint-disable-next-line
  }, [
    formik?.values?.email,
    formik?.values?.first_name,
    formik?.values?.last_name,
    formik?.values?.password,
  ])

  const handleCancel = () => {
    setShowModal(false)
    history.push('/')
  }
  const handleGoogleLoginSuccess = async payload => {
    setGoogleUserLoading(true)
    fetchGoogleUserInfo(payload.code)
      .then(res => {
        const userData = { ...res.data }
        userData.isGoogleUser = true
        setGoogleUserLoading(false)
        if (res.status === 200) {
          notify(t('notification.singupSuccess'), 'success')
          updateLocalStorage(userData)
          dispatch(updateUserData(userData))
          history.push('/dashboard')
        } else {
          console.log('Error while log in with google', res)
          if (res.status >= 400) {
            notify(t('notification.somethingWentWrong'), 'error')
          }
        }
      })

      .catch(e => {
        setGoogleUserLoading(false)
        // console.log('google register error~: ', e)
      })
  }
  return (
    <div className={styles.registerContainer}>
      {googleUserLoading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <>
          <Authentication
            componentType="register"
            formik={formik}
            isLoading={isLoading}
            buttonText="Register"
            handleGoogleLogin={handleGoogleLoginSuccess}
          />
          <Modal
            open={showModal}
            heading="Verification Email Sent Successfully"
            description="Please verify your email to use our services without any delay."
            buttonText="Back to Login"
            onCancel={handleCancel}
          />
        </>
      )}
    </div>
  )
}

export default Register
