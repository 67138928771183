import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { LS_KEY, UPDATE_LEARNING_SELECTED_VALUE } from '@utils/constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Navbar from '@components/LearningCenter/Navbar'
import LearningCard from '@components/LearningCenter/LearningCard'
import TrainingsCards from '@components/LearningCenter/TrainingsCards'
import Outshine from '@components/LearningCenter/Outshine'
import Support from '@components/LearningCenter/Support'
import { Text } from '@components'
import { RequestService } from '@services/requests'
import { Col, Row, Spin } from 'antd'
import noPreviewImage from '@images/Learning/noPreviewImage.webp'
import styles from './knowledgeAndGuides.module.css'
import SearchFeature from '../search-feature'

const KnowledgeAndGuides = ({ catagory, handleLearningRedirect, tags }) => {
  const dispatch = useDispatch()
  const [getTag, setGetTag] = useState(tags)
  const history = useHistory()
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isKnowledgeGuidesOpen, setIsKnowledgeGuidesOpen] = useState(true)
  const [isSearchValue, setIsSearchValue] = useState(false)
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user.access_token
  const baseURL = process.env.REACT_APP_API_URL
  const handleCatagoryApi = async value => {
    setIsLoading(true)
    try {
      const response = await RequestService.get(
        `learning-center/learning-resources/?category=${value}&tag=`
      )

      setIsLoading(false)
      setData(response.data)
    } catch (error) {
      console.error('Error in API call:', error)
      setIsLoading(false)
    }
  }

  const handleBothApis = async (cataogrey, level) => {
    setIsLoading(true)
    try {
      let categoryValue

      if (cataogrey === 'Knowledge and Guides') {
        categoryValue = 1
      } else if (cataogrey === 'Video Insights') {
        categoryValue = 2
      } else {
        categoryValue = 3
      }

      const response = await RequestService.get(
        `/learning-center/learning-resources/?category=${categoryValue}&tag=${level}`
      )

      setData(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error in API call:', error)
      setIsLoading(false)
    }
  }

  const handleSelectChange = value => {
    dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: value })
    setGetTag(value)
  }

  const currentPath = window.location.pathname
  useEffect(() => {
    if (currentPath === '/dashboard/learning-center/search-feature') {
      setIsSearchValue(true)
      setIsKnowledgeGuidesOpen(false)
    }
  }, [currentPath])
  useEffect(() => {
    if (getTag && catagory.length > 0) {
      handleBothApis(catagory, getTag)
    } else if (catagory.length > 0 && getTag === '') {
      if (catagory === 'Knowledge and Guides') {
        handleCatagoryApi(1)
      } else if (catagory === 'Video Insights') {
        handleCatagoryApi(2)
      } else if (catagory === 'Trainings and Downloadables') {
        handleCatagoryApi(3)
      }
    }
    // eslint-disable-next-line
  }, [catagory, getTag])
  return (
    <>
      {
        // eslint-disable-next-line
        isKnowledgeGuidesOpen ? (
          <div>
            <div style={{ display: 'flex', marginBottom: 20 }}>
              <Text
                size={14}
                onClick={handleLearningRedirect}
                cursor
                className={styles.learningTop}
              >
                Learning Center
              </Text>
              <div className={styles.slash}> /</div>
              <Text className={styles.catagory}>{catagory}</Text>
            </div>
            <Navbar
              fromExploreMoreFlow="true"
              title={catagory}
              onSelectChange={handleSelectChange}
              tag={getTag}
            />
            {/* eslint-disable-next-line */}
            <>
              {isLoading ? (
                <Spin style={{ textAlign: 'center' }} className={styles.loadingContainer} />
              ) : (
                <>
                  {/* eslint-disable-next-line */}
                  {catagory === 'Knowledge and Guides' ? (
                    <div>
                      <Row>
                        {data
                          ?.filter(singleData => singleData.category === 'Knowledge and Guides')
                          .slice(0, 8)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LearningCard
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                  comments="15"
                                  shares="20"
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                      <Outshine />
                      <Row>
                        {data
                          ?.filter(singleData => singleData.category === 'Knowledge and Guides')
                          .slice(8)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LearningCard
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                  comments="15"
                                  shares="20"
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                      <Support />
                    </div>
                  ) : // eslint-disable-next-line
                  catagory === 'Video Insights' ? (
                    <div>
                      <Row>
                        {data
                          ?.filter(singleData => singleData.category === 'Video Insights')
                          .slice(0, 8)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LearningCard
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                  comments="15"
                                  shares="20"
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                      <Outshine />
                      <Row>
                        {data
                          ?.filter(singleData => singleData.category === 'Video Insights')
                          .slice(8)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <LearningCard
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                  comments="15"
                                  shares="20"
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                      <Support />
                    </div>
                  ) : (
                    <div>
                      <Row>
                        {data
                          ?.filter(
                            singleData => singleData.category === 'Trainings and Downloadables'
                          )
                          .slice(0, 4)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <TrainingsCards
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                      <Outshine />
                      <Row>
                        {data
                          ?.filter(
                            singleData => singleData.category === 'Trainings and Downloadables'
                          )
                          .slice(5)
                          .map((filteredData, id) => (
                            <Col span={6} key={filteredData.id} style={{ marginTop: '32px' }}>
                              <a
                                href={filteredData.link}
                                aria-label={filteredData.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <TrainingsCards
                                  image={
                                    filteredData.preview_image
                                      ? filteredData?.preview_image
                                      : noPreviewImage
                                  }
                                  title={filteredData.title}
                                  tag={filteredData.tag}
                                />
                              </a>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        ) : // eslint-disable-next-line
        isSearchValue ? (
          <SearchFeature
            catagory={catagory}
            tags={getTag}
            handleLearningRedirect={handleLearningRedirect}
          />
        ) : (
          ''
        )
      }
    </>
  )
}
export default KnowledgeAndGuides
