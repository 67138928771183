import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const generatePassswordForGoogleUser = async (data, token) => {
  const { url, method } = apiRoutes.generatePassword
  try {
    const res = await api({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
