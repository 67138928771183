import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styles from './ctabanner.module.scss'

const CTABanner = ({ text, buttonText, bgColor, onCtaClick }) => (
  <div className={styles.containerDiv} style={{ background: bgColor }}>
    <div className={styles.textDiv}>{text}</div>
    <div className={styles.buttonDiv}>
      <Button className={styles.button} onClick={onCtaClick} type="primary">
        {buttonText}
      </Button>
    </div>
  </div>
)

CTABanner.propTypes = {
  text: PropTypes.node,
  buttonText: PropTypes.string,
  bgColor: PropTypes.string,
  onCtaClick: PropTypes.func,
}

CTABanner.defaultProps = {
  text: '',
  buttonText: '',
  bgColor: '#feefeb',
  onCtaClick: () => {},
}

export default CTABanner
