import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import { RequestService } from '@services/requests' // Assuming you have a service for API requests
import { Button, Input, Modal, Select, message } from 'antd'
import { INVITED_BOARDS_USERS } from '@utils/constants'
import { useTranslation } from 'react-i18next'
import {
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { notify } from '@utils/notify'
import { useDispatch, useSelector } from 'react-redux'
import styles from './invitedUsersModal.module.css'

const InvitedUsersModal = ({ modalOpen, handleOk, handleCancel, handleBack, shareBoardData }) => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invitedBoardsUsers = useSelector(state => state?.discoverAds?.invitedBoardsUsers)
  const { Option } = Select
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false) // New state to handle form submission
  const closeModal = () => {}
  const validateEmail = value => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  }

  const handleEmailChange = e => {
    // eslint-disable-next-line
    const value = e.target.value
    setEmail(value)
    setIsEmailValid(validateEmail(value))
  }

  const handleRoleChange = value => {
    setRole(value)
  }

  const invitedUsersBoard = async () => {
    try {
      const response = await RequestService.get(
        `/create/boards-invitee/?board_id=${shareBoardData.board_id}`
      )
      dispatch({ type: INVITED_BOARDS_USERS, payload: response?.data })
    } catch (error) {
      // console.log('🚀 ~ useEffect ~ error:', error)
    }
  }

  const handleAddMember = async () => {
    setHasAttemptedSubmit(true) // Mark that the user attempted submission
    if (hasAttemptedSubmit && isEmailValid) {
      const data = {
        // eslint-disable-next-line
        email: email,
        // eslint-disable-next-line
        board_id: shareBoardData.board_id,
        // eslint-disable-next-line
        role: role,
      }
      try {
        const response = await RequestService.post('/create/boards-invite/', data)
        if (response.status === 200 || response.status === 201) {
          message.success(`Invitation sent to ${email}`)
          setEmail('') // Reset email input
          setRole(null) // Reset role
          setHasAttemptedSubmit(false) // Reset after successful submission
          invitedUsersBoard()
        } else {
          message.error('Failed to send the invitation.')
        }
      } catch (error) {
        message.error('Error occurred while sending the invitation.')
      }
    }
  }

  // Disable the add button until both email and role are entered
  const isAddDisabled = !email || !role

  useEffect(() => {
    invitedUsersBoard()
    // eslint-disable-next-line
  }, [])
  const handleDelete = async (memberId, UserEmail) => {
    try {
      setDeleteLoading(true)

      const res = await RequestService.delete(
        `/create/boards-invitee-delete/${memberId}/?board_id=${shareBoardData?.board_id}&email=${UserEmail}`
      )
      setDeleteLoading(false)
      invitedUsersBoard()
      message.success('User removed successfully')
    } catch (error) {
      setDeleteLoading(false)
      message.error('Something went wrong!')
    }
  }

  const confirm = (memberId, UserEmail) => {
    Modal.confirm({
      title: t('removeUserHeading'),
      icon: <ExclamationCircleOutlined />,
      // content: 'Are you sure, You want to remove the selected user?',
      content: t('removeUser'),
      okText: t('delete'),
      cancelText: t('cancel'),
      okButtonProps: { size: 'small' },
      cancelButtonProps: { size: 'small' },
      confirmLoading: deleteLoading,
      onOk: () => handleDelete(memberId, UserEmail),
      onCancel: closeModal,
    })
  }
  const handleDeleteIconClick = (userId, UserEmail) => {
    confirm(userId, UserEmail)
  }
  return (
    <Modal
      open={modalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      borderRadius="12px 12px 0px 0px"
      width={600}
      style={{ top: '180px' }}
    >
      <div style={{ justifyContent: 'end', display: 'flex' }}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            fontWeight: 'bold',
            fontSize: '18px',
            cursor: 'pointer',
          }}
          onClick={handleCancel}
          aria-label="Close"
        >
          <CloseOutlined />
        </button>
      </div>
      <div style={{ display: 'flex' }}>
        <Text onClick={handleBack}>
          <LeftOutlined style={{ color: '#f16434', cursor: 'pointer' }} />
        </Text>
        <Text size={16} isBold className={styles.invitedMembers}>
          {invitedBoardsUsers?.length} Invited Members
        </Text>
      </div>
      <div style={{ marginTop: '24px', display: 'flex' }}>
        <Input.Group compact>
          <Input
            placeholder="Enter email to invite"
            value={email}
            onChange={handleEmailChange}
            style={{ width: 'calc(100% - 100px)', height: '48px' }}
          />
          <Select
            style={{ width: 100, height: '48px' }}
            placeholder="Select role"
            value={role}
            onChange={handleRoleChange}
          >
            <Option value="2">Admin</Option>
            <Option value="10">Editor</Option>
            <Option value="9">Viewer</Option>
          </Select>
        </Input.Group>
        <Button
          className={styles.sendEmail}
          disabled={isAddDisabled}
          type="primary"
          onClick={!isAddDisabled ? handleAddMember : null}
        >
          <UserAddOutlined style={{ color: isAddDisabled ? '#gray' : 'white' }} /> Add
        </Button>
      </div>

      {/* Display error message only after an add attempt */}
      {hasAttemptedSubmit && !isEmailValid && (
        <Text style={{ color: 'red' }}>Please enter a valid email address</Text>
      )}

      <div style={{ marginTop: '24px', position: 'relative' }}>
        {invitedBoardsUsers.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0px',
              fontWeight: 'bold',
              borderBottom: '2px solid #f0f0f0',
              position: 'sticky',
              top: '0',
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <div style={{ width: '20%' }}>Name</div>
            <div style={{ width: '20%', textAlign: 'left' }}>Email</div>
            <div style={{ width: '15%', textAlign: 'center' }}>Role</div>
            <div style={{ width: '25%', textAlign: 'right' }}>Invitation Status</div>
            <div style={{ width: '10%', textAlign: 'center' }} />
            {/* No header for the delete icon */}
          </div>
        )}
        <div style={{ maxHeight: '400px', overflowY: 'auto', marginTop: '15px' }}>
          {invitedBoardsUsers.map((member, index) => {
            let backgroundColor
            switch (member.role_name) {
              case 'Admin':
                backgroundColor = '#FFB9A6'
                break
              case 'Viewer':
                backgroundColor = '#FEEFEB'
                break
              case 'Editor':
                backgroundColor = '#D9D9D9'
                break
              default:
                backgroundColor = '#f5f5f5'
            }

            return (
              <div
                // eslint-disable-next-line
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '12px',
                  borderBottom: '1px solid #f0f0f0',
                  paddingBottom: '8px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', width: '10%' }}>
                  <div
                    style={{
                      // eslint-disable-next-line
                      backgroundColor: backgroundColor,
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      marginRight: '16px',
                      color: member.role_name === 'Viewer' ? '#FFB9A6' : '#FFF',
                      fontWeight: 'bold',
                    }}
                  >
                    {member?.user?.first_name?.charAt(0).toUpperCase() || (
                      <UserOutlined style={{ color: 'white' }} />
                    )}
                  </div>
                  <Text>{member.name}</Text>
                </div>
                <div style={{ width: '30%', textAlign: 'center' }}>
                  <Text>{member.email}</Text>
                </div>
                <div style={{ width: '20%', textAlign: 'center' }}>
                  <Text>{member.role_name}</Text>
                </div>
                <div style={{ width: '20%', textAlign: 'center' }}>
                  <Text>{member.accepted ? 'Accepted' : 'Pending'}</Text>
                </div>
                <div style={{ width: '10%', textAlign: 'center' }}>
                  {/* Dustbin Icon for deleting the user */}
                  <DeleteOutlined
                    onClick={() => handleDeleteIconClick(member?.id, member?.email)}
                    type="error"
                    className="deleteIcon"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default InvitedUsersModal
