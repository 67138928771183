import { LEARNING_CENTER_MESSAGE_FROM_MODAL } from '@utils/constants'
import { message } from 'antd'

const initialState = {
  message: '',
}

export const learningCenterMessageFromModal = (state = initialState, action) => {
  switch (action.type) {
    case LEARNING_CENTER_MESSAGE_FROM_MODAL:
      return {
        ...state,
        message: action.payload,
      }
    default:
      return state
  }
}
