/* eslint-disable */

import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchInvitedUsersList = async cutomerId => {
  let url = apiRoutes.invitedUsersList.url
  const { method } = apiRoutes.invitedUsersList
  if (cutomerId) {
    url += `?customer_id=${cutomerId}`
  }
  try {
    const res = await api({
      url,
      method,
    })
    return res
  } catch (e) {
    return e.response
  }
}
