// RectangleComponent.js

import React from 'react'
import PropTypes from 'prop-types'
import styles from './rectangle.module.css'

const RectangleComponent = ({
  minWidth,
  marginLeft,
  marginRight,
  width,
  height,
  backgroundColor,
  borderRadius,
  border,
  marginTop,
  children,
}) => {
  const style = {
    minWidth,
    marginLeft,
    marginRight,
    borderRadius,
    width,
    height,
    backgroundColor,
    border,
    marginTop,
  }

  return (
    <div style={style} className={styles.rectangle}>
      {children}
    </div>
  )
}

// RectangleComponent.propTypes = {
//   width: PropTypes.string,
//   height: PropTypes.string,
//   color: PropTypes.string,
//   backgroundColor: PropTypes.string,
//   border: PropTypes.string,
//   marginTop: PropTypes.string,
//   children: PropTypes.node.isRequired,
// }

export default RectangleComponent
