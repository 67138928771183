/* eslint-disable import/named */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import ImprovementCard from '@components/ImprovementCard'
import { Row, Col, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text, SmallerPerformanceCard, CTABanner } from '@components'
import KeywordsMetricsModal from '@components/ReportPageTemporary/KeywordsMetricsModal'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInsights } from '@services/fetchInsights'
import { EXTRA_INSIGHTS_CLICK, EXTRA_INSIGHTS_DATA } from '@utils/constants'
import { notify } from '@utils/notify'
import styles from './styles.module.scss'

export const PanelSection = ({
  extraInsightsTitle,
  title,
  description,
  meansDescription,
  ctaTitle,
  ctaButtonText,
  score,
  children,
  onCtaClick,
  isMeansDescription,
  isCTA,
  isBlur,
  titleOptional,
  AlertArrow,
  formattedImprovement,
  viewQtd,
  viewQtdCost,
  viewQtdWasted,
  viewQtdAdRelevance,
  viewQtdQualityScore,
  viewQtdCtr,
  viewQtdKeyword,
  viewQtdImpression,
  viewQtdGoogleAds,
  handleImprovementMoreCost,
  handleImprovementMoreWasted,
  handleImprovementMoreAdRelevance,
  handleImprovementMoreQualityScore,
  handleImprovementMoreCtr,
  handleImprovementMoreKeyword,
  handleImprovementMoreImpression,
  handleImprovementMoreGoogleAds,
  firstPageCpc,
  firstPositionCpc,
  reportId,
  adGroupCriterianStatus,
  adGroupAdStatus,
}) => {
  const { t } = useTranslation()
  const extraInsightsButton = useSelector(state => state?.extraInsights?.extraInsightsButton)
  const dispatch = useDispatch()
  const [extraInsights, setExtraInsights] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    if (extraInsightsButton) {
      setIsLoading(true)
      dispatch({ type: EXTRA_INSIGHTS_DATA, payload: [] })
      fetchInsights(reportId)
        .then(res => {
          dispatch({ type: EXTRA_INSIGHTS_DATA, payload: res?.data })
          setIsLoading(false)
          if (res?.data?.customer_id) {
            dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: false })
          }
        })
        .catch(error => {
          notify(error, 'error')
          setIsLoading(false)
        })
    }
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const openCtaUrl = useCallback(() => {
    window.open('https://advanced.npdigital.com/consulting-ads/', '_blank')
  }, [])
  const StatusEnum = {
    0: 'ENABLED',
    1: 'PAUSED',
    2: 'REMOVED',
    3: 'UNKNOWN',
    4: 'UNSPECIFIED',
  }
  function getStatusString(statusCode) {
    return StatusEnum[statusCode] || 'UNSPECIFIED'
  }
  return (
    <div className={styles.collapsePadder}>
      {isBlur && (
        <div className={styles.boxShadown}>
          <div className={styles.modalContent}>
            <Text color="primary" isTitle isBold size={24} className={styles.text}>
              {t('report.painelSection.title')}
            </Text>
            <br />
            <Text color="black" size={18}>
              {t('report.painelSection.subtitle')}
            </Text>
            <br />
            <br />
            <Button className={styles.button} onClick={openCtaUrl} type="primary">
              {t('report.painelSection.button')}
            </Button>
          </div>
        </div>
      )}
      <Row gutter={24} justify="center">
        <Col xs={24} sm={24} lg={18}>
          {extraInsightsTitle ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text size={32}>{title}</Text>
              <button
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                  // minWidth: '180%',
                }}
                onClick={showModal}
                type="submit"
              >
                Extra Insights
              </button>
            </div>
          ) : (
            <Text size={32}>{title}</Text>
          )}
          {isModalVisible && (
            <KeywordsMetricsModal
              modalVisibility={isModalVisible}
              isLoading={isLoading}
              handleOk={handleOk}
              handleCancel={handleCancel}
              tableData={extraInsights}
            />
          )}
          <Text size={24}>{description}</Text>
          <hr className={styles.hrMargin} />
          {children}
          {isMeansDescription && (
            <>
              <hr className={styles.hrMargin} />
              <Text size={18} isBold>
                {t('whatMeans')}
              </Text>
              <Text size={16}>{meansDescription}</Text>
            </>
          )}
        </Col>

        <Col xs={24} sm={24} lg={6}>
          <div className={styles.scoreCard}>
            <SmallerPerformanceCard
              titleText={`${titleOptional || title} ${t('report.grade')}`}
              // titleText={`${titleOptional || title} ${t('report.grade')}`}
              percentageNumber={score}
            />
          </div>
          {title === t('home.primarySection.qualityScore') && (
            <div>
              <Row>
                <Col span={24}>
                  <div className={styles.wrapperInfo_adPerformance}>
                    <div className={styles.infos_adPerformance}>
                      <Text style={{ fontSize: 11 }} isTitle>
                        First Position
                      </Text>
                      <Text style={{ fontSize: 11 }}>(CPC BID)</Text>
                      <Text size={23} isTitle>
                        R${firstPositionCpc?.toFixed(2) ?? 0.0}
                      </Text>
                    </div>
                    <span className={styles.divider} />
                    <div className={styles.infos_adPerformance}>
                      <Text style={{ fontSize: 11 }} isTitle>
                        First Page
                      </Text>
                      <Text style={{ fontSize: 11 }}>(CPC BID)</Text>
                      <Text size={23} isTitle>
                        R${firstPageCpc?.toFixed(2) ?? 0.0}
                      </Text>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Text size={11} style={{ textAlign: 'center' }} isTitle>
                Ad group criterion status
              </Text>
              <Text size={23} style={{ textAlign: 'center', marginTop: '4px' }}>
                {getStatusString(adGroupCriterianStatus)}
              </Text> */}
            </div>
          )}
          {/* {title === t('report.tabs.adRelevance.title2') && (
            <div style={{ textAlign: 'center', marginTop: '25px' }}>
              <Text size={11} isTitle>
                Ad group ad status
              </Text>
              <Text size={23} style={{ marginTop: '4px' }}>
                {getStatusString(adGroupAdStatus)}
              </Text>
            </div>
          )} */}
        </Col>
        <Row gutter={[30, 30]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ paddingTop: 64 }}>
              <Space direction="vertical" wrap={4}>
                <Text isTitle size={16}>
                  <span
                    style={{
                      textTransform: 'uppercase',
                      display: 'flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <img src={AlertArrow} alt="icon alert" />
                    {t('report.improvement.title')}
                  </span>
                </Text>
                {formattedImprovement?.length > 0 ? (
                  <Text size={16}>{t('report.improvement.subtitle')}</Text>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '20px',
                    }}
                  >
                    <Text size={16}>{t('report.improvement.noOpportunity')}</Text>
                  </div>
                )}
              </Space>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {formattedImprovement &&
              formattedImprovement?.length > 0 &&
              formattedImprovement?.map((col, index) => (
                <div
                  // key={`row-improvement-${index + 1}`}
                  className={classNames([
                    styles.improvementCol,
                    // eslint-disable-next-line
                    title === t('report.tabs.costEvolution.title')
                      ? index <= viewQtdCost
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.spend.title')
                      ? index <= viewQtdWasted
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.adRelevance.title2')
                      ? index <= viewQtdAdRelevance
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.qualityScore.title')
                      ? index <= viewQtdQualityScore
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.ctr.title')
                      ? index <= viewQtdCtr
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.keywordsMatchType.title')
                      ? index <= viewQtdKeyword
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.impShare.title')
                      ? index <= viewQtdImpression
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : // eslint-disable-next-line
                      title === t('report.tabs.googleAdsBest.title')
                      ? index <= viewQtdGoogleAds
                        ? styles.showImprovementCol
                        : styles.hiddenImprovementCol
                      : '',
                  ])}
                >
                  {col?.map((item, unique) => (
                    <ImprovementCard
                      priority={item.priority}
                      key={`col-improvement-${unique + 1}`}
                      title={t(`report.recommendation.${item?.type_id}.category`)}
                      description={t(`report.recommendation.${item?.type_id}.message`)}
                      url={item?.url}
                      impact={item?.uplift}
                    />
                  ))}
                </div>
              ))}
            {formattedImprovement.length > 1 && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 8 }}>
                <Button
                  className={styles.btnGhostDisableStyle}
                  onClick={
                    // eslint-disable-next-line
                    title === t('report.tabs.costEvolution.title')
                      ? handleImprovementMoreCost
                      : // eslint-disable-next-line
                      title === t('report.tabs.spend.title')
                      ? handleImprovementMoreWasted
                      : // eslint-disable-next-line
                      title === t('report.tabs.adRelevance.title2')
                      ? handleImprovementMoreAdRelevance
                      : // eslint-disable-next-line
                      title === t('report.tabs.qualityScore.title')
                      ? handleImprovementMoreQualityScore
                      : // eslint-disable-next-line
                      title === t('report.tabs.ctr.title')
                      ? // eslint-disable-next-line
                        handleImprovementMoreCtr
                      : // eslint-disable-next-line
                      title === t('report.tabs.keywordsMatchType.title')
                      ? handleImprovementMoreKeyword
                      : // eslint-disable-next-line
                      title === t('report.tabs.impShare.title')
                      ? handleImprovementMoreImpression
                      : // eslint-disable-next-line
                      title === t('report.tabs.googleAdsBest.title')
                      ? handleImprovementMoreGoogleAds
                      : ''
                  }
                  size="small"
                  disabled={
                    // eslint-disable-next-line
                    title === t('report.tabs.costEvolution.title')
                      ? viewQtdCost === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.spend.title')
                      ? viewQtdWasted === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.adRelevance.title2')
                      ? viewQtdAdRelevance === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.qualityScore.title')
                      ? viewQtdQualityScore === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.ctr.title')
                      ? // eslint-disable-next-line
                        viewQtdCtr === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.keywordsMatchType.title')
                      ? viewQtdKeyword === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.impShare.title')
                      ? viewQtdImpression === formattedImprovement.length - 1
                      : // eslint-disable-next-line
                      title === t('report.tabs.googleAdsBest.title')
                      ? viewQtdGoogleAds === formattedImprovement.length - 1
                      : ''
                  }
                >
                  {t('report.improvement.viewMoreText')}
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {/* {isCTA && ( */}
        <Col span={24}>
          <div className={styles.hrMargin}>
            <CTABanner
              text={
                <Text color="black" size={18}>
                  {ctaTitle}
                </Text>
              }
              buttonText={ctaButtonText}
              onCtaClick={onCtaClick}
            />
          </div>
        </Col>
        {/* )} */}
      </Row>
    </div>
  )
}

PanelSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleOptional: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  meansDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ctaTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ctaButtonText: PropTypes.string,
  score: PropTypes.number,
  onCtaClick: PropTypes.func,
  isMeansDescription: PropTypes.bool,
  isCTA: PropTypes.bool,
  isBlur: PropTypes.bool,
  // eslint-disable-next-line
  formattedImprovement: PropTypes.array,
}

PanelSection.defaultProps = {
  meansDescription: '',
  ctaTitle: '',
  ctaButtonText: '',
  score: 0,
  onCtaClick: () => {},
  isMeansDescription: true,
  isCTA: true,
  isBlur: false,
  titleOptional: false,
  formattedImprovement: [],
}
