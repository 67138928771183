/* eslint-disable import/no-unresolved */
import { getLineatLine } from 'utils'

export const costEvoChartConfig = (report, chartLegendAvarege, chartLegendTrendline, months) => {
  const dateCostEvolution = []
  const seriesCostEvolution = []
  const dateFormatting = []

  if (
    report.cost_evolution !== null &&
    report.cost_evolution?.chart !== undefined &&
    report.cost_evolution?.chart !== null
  ) {
    report.cost_evolution.chart.sort((a, b) => new Date(a.date) - new Date(b.date))

    report.cost_evolution.chart.forEach(item => {
      dateCostEvolution.push(item.date)
      seriesCostEvolution.push(item.cost_per_conversion)
    })

    dateCostEvolution.forEach(item => {
      const date = new Date(item)
      dateFormatting.push(`${months[date.getMonth()]} ${date.getDate()}`)
    })

    const graficConfig = {
      series: [
        {
          name: chartLegendAvarege,
          type: 'column',
          data: seriesCostEvolution,
        },
        {
          name: chartLegendTrendline,
          type: 'line',
          data: getLineatLine(
            report.cost_evolution.trendline.initial_value,
            report.cost_evolution.trendline.increment_per_day,
            seriesCostEvolution.length
          ),
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [1, 4],
        },

        dataLabels: {
          enabled: false,
          enabledOnSeries: [0],
        },
        tooltip: {
          followCursor: true,
        },
        legend: {
          position: 'bottom',
          offsetY: 0,
          offsetX: 16,
          floating: true,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        labels: dateFormatting,
        grid: {
          padding: {
            bottom: 48,
          },
        },
        xaxis: {
          type: 'category',
          floating: true,
          position: 'bottom',
          labels: {
            show: false,
            rotate: -90,
            rotateAlways: true,
          },
        },
        yaxis: [
          {
            labels: {
              show: false,
              formatter: val => `R$ ${val.toFixed(2)}`,
            },
          },
        ],
      },
    }
    return graficConfig
  }

  return false
}
