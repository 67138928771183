import { UPDATE_GENERATE_REPORT } from '@utils/constants'

const initialState = {
  generateReport: false,
}

export const generateReport = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GENERATE_REPORT:
      return action.payload
    default:
      return state
  }
}
