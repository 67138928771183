import { UPDATE_CUSTOMER } from '@utils/constants'

const initialState = null

export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
