import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Spin } from 'antd'
import api from '@services/api'
import { passwordRules } from '@utils/validation'
import Modal from '@components/Modal'
import { apiRoutes } from '@config/apiRoutes'

import styles from './passwordReset.module.css'

const PasswordReset = () => {
  const [isLoading, setIsLoading] = useState()
  const { uid, token } = useParams()
  const [showModal, setShowModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      new_password1: '',
      new_password2: '',
      uid: '',
      token: '',
    },
    validationSchema: Yup.object().shape({
      new_password1: Yup.string().required().label('New Password'),
      new_password2: Yup.string().required().label('Confirm Password'),
    }),
    onSubmit: async values => {
      const { url, method } = apiRoutes.passwordResetConfirm
      setIsLoading(true)
      try {
        const response = await api({
          url: `${url + uid}/${token}/`,
          method: 'POST',
          data: values,
        })
        if (response.status === 200) {
          setIsLoading(false)
          setShowModal(true)
        }
      } catch (e) {
        setIsLoading(false)
      }
    },
  })

  useEffect(() => {
    if (uid || token) {
      formik.setFieldValue('uid', uid)
      formik.setFieldValue('token', token)
    }
    // eslint-disable-next-line
  }, [uid, token])

  const { getFieldProps } = formik
  const handleCancel = () => {
    setShowModal(false)
  }
  return (
    <div className={styles.passwordResetWrapper}>
      <h2 className={styles.bold}>Ads Grader</h2>
      <p className={styles.bold}>
        by <span className={styles.NP}>NP</span> digital
      </p>
      <div className={styles.loginFormCard}>
        <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>New Password</p>
            <input
              className={styles.input}
              type="password"
              placeholder="Enter new password"
              {...getFieldProps('new_password1')}
            />
            {formik.touched.new_password1 && Boolean(formik.errors.new_password1) && (
              <p className={styles.error}>{formik.errors.new_password1}</p>
            )}
          </div>
          <div className={styles.inputWrapper}>
            <p className={styles.label}>Confirm New Password</p>
            <input
              className={styles.input}
              type="password"
              placeholder="Enter confirm password"
              {...getFieldProps('new_password2')}
            />
            {formik.touched.new_password2 && Boolean(formik.errors.new_password2) && (
              <p className={styles.error}>{formik.errors.new_password2}</p>
            )}
          </div>

          <button className={styles.submitButton} type="submit">
            {isLoading ? <Spin /> : 'Update Password'}
          </button>
        </form>
      </div>
      <Modal
        open={showModal}
        heading="Password Reset Successfull"
        description=""
        onCancel={handleCancel}
      >
        <Button size="small" type="primary" className={styles.submitButton}>
          <Link to="/login">Back to Login</Link>
        </Button>
      </Modal>
    </div>
  )
}

export default PasswordReset
