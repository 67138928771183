import React, { useEffect, useState } from 'react'
import { Button, Modal, Progress, Select as AntDSelect, Checkbox, Spin } from 'antd'
import { useFormik } from 'formik'
import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Text from '@components/Text/text'
import { apiRoutes } from '@config/apiRoutes'
import styles from './popover.module.css'
import api from '../../services/api'

const { Option } = AntDSelect

const Questionnaire = ({ data, wholeData, trueOnly }) => {
  const [modalOpen, setmodalOpen] = useState(true)
  const [cardNumber, setCardNumber] = useState(0)
  const [multipleSelect, setMultipleSelect] = useState(false)
  const [isAllApply, setIsAllApply] = useState(false)

  const [isMoveToNext, setIsMoveToNext] = useState(false)

  const [filledData, setFilledData] = useState({})

  const { t } = useTranslation()

  useEffect(() => {
    data?.forEach(item => {
      if (item.text.includes('all that apply')) {
        setIsAllApply(item.answer_choices.find(choice => choice.text === 'Google Ads').id)
      }
    })
    // eslint-disable-next-line
  }, [])
  const previousData = {
    responses: trueOnly?.map(item => ({
      question_id: item.id,
      selected_choices: item.selected_choices,
      text: item.text,
      hubspot_question: item.hubspot_question,
      questionnaire: item.questionnaire,
      is_answered: item.is_answered,
    })),
  }

  const initialValues = {
    responses: data?.map(item => ({
      question_id: '',
      selected_choices: item.text.includes('all that apply')
        ? [item.answer_choices.find(choice => choice.text === 'Google Ads').id]
        : [''],
      text: '',
      hubspot_question: '',
      questionnaire: '',
      is_answered: false,
    })),
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      if (cardNumber < data?.length - 1) {
        const dataResponse = { responses: [] }
        setFilledData(dataResponse)
        // eslint-disable-next-line
        const dataForSubmitted = values?.responses?.filter(item => {
          return item?.question_id !== ''
        })
        dataResponse.responses = [...previousData.responses, ...dataForSubmitted]
        // setFilledData(dataResponse)
        setCardNumber(cardNumber + 1)
      } else if (cardNumber === data.length - 1) {
        localStorage.setItem('showPopover', false)
        setmodalOpen(false)
        const dataResponse = { responses: [] }
        // eslint-disable-next-line
        const dataForSubmitted = values?.responses?.filter(item => {
          return item?.question_id !== ''
        })
        // eslint-disable-next-line
        dataResponse.send_to_hubspot = JSON.parse(localStorage.getItem('isCustomers'))
        dataResponse.responses = [...previousData.responses, ...dataForSubmitted]
        try {
          const res = await api({
            url: apiRoutes.questionsResponse.url,
            method: apiRoutes.questionsResponse.method,
            data: {
              ...dataResponse,
              page_uri: `${window?.location?.origin}/dashboard`,
              page_name: 'Dashboard',
            },
          })
        } catch (e) {
          // console.log('login error', e?.response)
        }
      }
    },
  })

  const closeQuestionnaire = async () => {
    setmodalOpen(false)
    localStorage.setItem('showPopover', false)
    // eslint-disable-next-line
    filledData.send_to_hubspot = JSON.parse(localStorage.getItem('isCustomers'))
    try {
      const res = await api({
        url: apiRoutes.skipQuestions.url,
        method: apiRoutes.skipQuestions.method,
        data: {
          ...filledData,
          page_uri: `${window?.location?.origin}/dashboard`,
          page_name: 'Dashboard',
        },
      })
    } catch (e) {
      // console.log('login error', e?.response)
    }
  }

  const filteredData = data[cardNumber]
  const formikSelectedOption = formik.values.responses[cardNumber]
  const firstSelectedChoice = !multipleSelect && formikSelectedOption.selected_choices[0]

  useEffect(() => {
    if (filteredData?.text.includes('all that apply')) {
      setMultipleSelect(true)
    } else {
      setMultipleSelect(false)
    }
    // eslint-disable-next-line
  }, [cardNumber])

  const handleCheckboxChange = itemId => {
    const updatedResponses = formik.values.responses[cardNumber]

    const itemIndex = updatedResponses.selected_choices.indexOf(itemId)

    if (itemIndex === -1) {
      updatedResponses.selected_choices.push(itemId)
      updatedResponses.question_id = filteredData.id
    } else if (itemId !== isAllApply) {
      updatedResponses.selected_choices.splice(itemIndex, 1)
    }
    formik.setFieldValue(`responses[${cardNumber}]`, updatedResponses)
  }
  const GoBack = () => {
    // eslint-disable-next-line
    for (let i = 0; i < 2; i++) {
      if (formik.values.responses[cardNumber - i]?.selected_choices[0] !== 35) {
        formik.setFieldValue(`responses[${cardNumber - i}]`, {
          question_id: '',
          selected_choices: [''],
          text: '',
          hubspot_question: '',
          questionnaire: '',
          is_answered: false,
        })
      }
    }
    setCardNumber(cardNumber - 1)
  }
  const titleContent = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: cardNumber > 0 ? 'space-between' : 'flex-end',
        padding: '0px 0px',
      }}
    >
      {cardNumber > 0 &&
        (isMoveToNext ? (
          <Spin style={{ color: 'yellow' }} className={styles.ant_spin_dot_item} />
        ) : (
          // eslint-disable-next-line
          <div onClick={GoBack} className={styles.goback_button_top_div}>
            <LeftOutlined style={{ color: '#a3b0b3' }} />
            <Text className={styles.goback_button_top}>{t('questionnaire.goBack')}</Text>
          </div>
        ))}
      <Button
        onClick={closeQuestionnaire}
        className={styles.cross_button_top}
        style={{ border: 'none', padding: '0px', color: 'black' }}
      >
        <CloseOutlined />
      </Button>
    </div>
  )

  const NextCard = value => {
    formik.setFieldValue(`responses[${cardNumber}]`, {
      question_id: filteredData?.id,
      selected_choices: [value],
      text: filteredData.text,
      hubspot_question: filteredData.hubspot_question,
      questionnaire: filteredData.questionnaire,
      is_answered: filteredData.is_answered,
    })
    setIsMoveToNext(true)
    setTimeout(() => {
      formik.handleSubmit()
      setIsMoveToNext(false)
    }, 1000)
  }
  return (
    <>
      <Modal
        title={titleContent}
        centered
        closeIcon={null}
        closable={false}
        maskClosable={false}
        open={modalOpen}
        // onOk={() => setmodalOpen(false)}
        onCancel={closeQuestionnaire}
        okText="Submit"
        cancelText="Close"
        footer={null}
        width={multipleSelect ? 'auto' : '30%'}
      >
        {/* <div style={{ margin: !cardNumber <= 0 ? '30px 26px 35px 26px' : '30px 26px 42px 26px' }}> */}
        <div style={{ margin: multipleSelect ? '0px 26px 32px 26px' : '0px 26px 2px 26px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 0 1.5rem 0',
            }}
          >
            <Text size={24} className={styles.welcome_heading}>
              {t('questionnaire.heading')}
            </Text>
            <Text isSemibold style={{ color: '#A3B0B3' }}>
              {t('questionnaire.subHeading')}
            </Text>
            <Progress
              percent={(100 / data?.length) * (cardNumber + 1)}
              strokeWidth={2}
              showInfo={false}
              strokeColor="#F16434"
              style={{ maxWidth: '160px', marginTop: '24px' }}
            />
          </div>
          <div>
            <div style={{ padding: '0 0 22px 0px' }}>
              <Text className={styles.modal_heading} style={{ marginBottom: '1.4rem' }}>
                {t(filteredData?.text)}
              </Text>
              {/* <Text style={{ marginBottom: '1.5rem' }}>
              {filteredData?.text.split('(Select all that apply)').map((part, index, array) => (
                <span key={part}>
                  {part}
                  {index < array.length - 1 ? (
                    <span style={{ color: 'gray' }}>(Select all that apply)</span>
                  ) : null}
                </span>
              ))}
            </Text> */}
              {multipleSelect ? (
                <div className={styles.radioWrapper}>
                  {filteredData?.answer_choices?.map((item, index) => (
                    <div key={item.id} className={styles.radioItem}>
                      {/* eslint-disable-next-line */}
                      <label>
                        <Checkbox
                          checked={formik.values.responses[cardNumber]?.selected_choices?.includes(
                            item.id
                          )}
                          value={item.id}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                        {t(item.text)}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <AntDSelect
                  name="selectedOption"
                  style={{ width: '100%' }}
                  // onChange={value => {
                  //   formik.setFieldValue(`responses[${cardNumber}]`, {
                  //     question_id: filteredData?.id,
                  //     selected_choices: [value],
                  //   })
                  //   formik.handleSubmit()
                  // }}
                  onChange={value => NextCard(value)}
                  onBlur={formik.handleBlur}
                  value={firstSelectedChoice}
                >
                  <Option value="">
                    <Text className={styles.placeholder_please_select}>
                      {t('questionnaire.select')}
                    </Text>
                  </Option>
                  {filteredData?.answer_choices?.map(item => (
                    <Option value={item.id} key={item.id}>
                      {t(item.text)}
                    </Option>
                  ))}
                </AntDSelect>
              )}
            </div>

            <div className={styles.btn_div}>
              {/* <Button
              type="primary"
              size="small"
              onClick={formik.handleSubmit}
              style={{
                backgroundColor:
                  formik.values.responses[cardNumber]?.selected_choices[0] === '' && '#A3B0B3',
              }}
            >
              {cardNumber < data?.length - 1 ? 'Continue' : 'Finish'}
            </Button> */}
              {/* <Button
              className={styles.skipButton}
              // style={{ border: 'none', color: 'black' }}
              onClick={closeQuestionnaire}
            >
              Skip
            </Button> */}
              {multipleSelect && (
                <Button
                  type="primary"
                  size="small"
                  onClick={formik.handleSubmit}
                  className={styles.continue_button}
                >
                  {t('questionnaire.continue')}
                </Button>
              )}
              {/* {!cardNumber <= 0 &&
                (isMoveToNext ? (
                  <Spin />
                ) : (
                  <div className={styles.goback_button_div}>
                    <button type="button" onClick={GoBack} className={styles.goback_button}>
                      Go Back
                    </button>
                  </div>
                ))} */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Questionnaire
