import { DownOutlined } from '@ant-design/icons'
import { Avatar, Collapse, List } from 'antd'
import Text from '@components/Text/text'
import React from 'react'
import { useSelector } from 'react-redux'
import styles from './relatedQueries.module.css'

const RelatedQueries = ({ adsData }) => {
  const loading = useSelector(state => state?.discoverAds?.relatedQueriesLoader)
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const data = useSelector(state => state?.discoverAds?.relatedQueries)
  const { Panel } = Collapse
  return (
    <>
      {selectedChannel === 'Google' || adsData?.origin?.provider === 'google' ? (
        <div id="ModalCollapseSetting" style={{ marginTop: '32px', background: 'white' }}>
          <Collapse
            expandIcon={({ isActive }) => (
              <div className={styles.headingIcon}>
                <DownOutlined rotate={isActive ? 180 : 360} />{' '}
              </div>
            )}
            expandIconPosition="right"
            defaultActiveKey={['3']}
            ghost
          >
            <Panel
              header={
                <div className={styles.tableHeader}>
                  <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                    Organic Results
                  </Text>
                </div>
              }
              key="3"
            >
              <div id="scrollableDiv2">
                <List
                  itemLayout="horizontal"
                  loading={loading}
                  dataSource={data.organic_results}
                  style={{ marginLeft: 10 }}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item.favicon} />}
                        title={
                          <a href={item.link} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        }
                      />
                    </List.Item>
                  )}
                  locale={{
                    emptyText: (
                      <div style={{ marginTop: 16 }}>
                        <svg
                          className="ant-empty-img-simple"
                          width="64"
                          height="41"
                          viewBox="0 0 64 41"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                            <ellipse
                              className="ant-empty-img-simple-ellipse"
                              cx="32"
                              cy="33"
                              rx="32"
                              ry="7"
                            />
                            <g className="ant-empty-img-simple-g" fillRule="nonzero">
                              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                              <path
                                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                className="ant-empty-img-simple-path"
                              />
                            </g>
                          </g>
                        </svg>
                        <p>No Results Available</p>
                      </div>
                    ),
                  }}
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      ) : (
        ''
      )}
      {/* Start of Organic Results Collapse */}

      {/* End of Organic Results Collapse */}
      <div id="ModalCollapseSetting" style={{ marginTop: '24px', background: 'white' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className={styles.headingIcon}>
              <DownOutlined rotate={isActive ? 180 : 360} />{' '}
            </div>
          )}
          expandIconPosition="right"
          defaultActiveKey={['2']}
          ghost
        >
          <Panel
            header={
              <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                Related Queries
              </Text>
            }
            key="2"
          >
            <List
              itemLayout="horizontal"
              loading={loading}
              dataSource={data?.related_questions}
              style={{ padding: '10px' }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta title={item.question} description={item.snippet} />
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <div style={{ marginTop: 16 }}>
                    <svg
                      className="ant-empty-img-simple"
                      width="64"
                      height="41"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                        <ellipse
                          className="ant-empty-img-simple-ellipse"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        />
                        <g className="ant-empty-img-simple-g" fillRule="nonzero">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            className="ant-empty-img-simple-path"
                          />
                        </g>
                      </g>
                    </svg>
                    <p>No Results Available</p>
                  </div>
                ),
              }}
            />
          </Panel>
        </Collapse>
      </div>
    </>
  )
}

export default RelatedQueries
