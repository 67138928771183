import React, { useEffect } from 'react'
import Text from '@components/Text/text'
import { RequestService } from '@services/requests'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { SHARED_BOARDS } from '@utils/constants'
import { useDispatch } from 'react-redux'

const SharedBoards = () => {
  const dispatch = useDispatch()
  const currentURL = window.location.href
  const urlParts = currentURL.split('/')
  const lastPart = currentURL.split('/').pop()
  const publicPart = urlParts[5]
  const history = useHistory()
  localStorage.setItem('public_status', publicPart)
  if (lastPart !== null) {
    localStorage.setItem('share_Baord_Link', lastPart)
  }
  useEffect(() => {
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessToken = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    const inviteFromString = localStorage.getItem('inviteFrom')
    const inviteFrom = JSON?.parse(inviteFromString)
    const isBoardPrivate = inviteFrom?.isBoardPrivate
    const storeAndFetchData = async () => {
      if (lastPart) {
        if (publicPart === 'public') {
          try {
            const response = await RequestService.get(
              `/create/board-ads/${lastPart}/?role=${publicPart}`
            )
            const updatedBoards = response.data.boards?.map(board => ({
              ...board,
              is_shared: true,
            }))
            dispatch({ type: SHARED_BOARDS, payload: updatedBoards })
            history.push('/dashboard/boards')
          } catch (error) {
            history.push('/login')
          }
        } else {
          try {
            const response = await RequestService.get(`/create/board-ads/${lastPart}/`)
            const updatedBoards = response.data.boards?.map(board => ({
              ...board,
              is_shared: true,
            }))
            dispatch({ type: SHARED_BOARDS, payload: updatedBoards })
            history.push('/dashboard/boards')
          } catch (error) {
            history.push('/login')
          }
        }
      }
    }
    if (userEmail === inviteFormEmail && isBoardPrivate) {
      storeAndFetchData()
    } else if (userEmail !== inviteFormEmail && isBoardPrivate) {
      // storeAndFetchData()
    } else {
      storeAndFetchData()
    }

    // eslint-disable-next-line
  }, [lastPart, history])

  return <></>
}

export default SharedBoards
