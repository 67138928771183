import React, { useState } from 'react'
import infoIcon from '@images/Learning/info.png'
import rightArrow from '@images/Learning/right-arrow.png'
import Text from '@components/Text/text'
import { Tooltip } from 'antd'

const TopBar = ({ title, handleExploreMore, data, toolTipText }) => {
  const [temp, setTemp] = useState()
  const TooltipRangeContent = () => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '.5rem' }}>
      {/* eslint-disable-next-line */}
      <Text style={{ color: 'white', fontSize: '12px', fontWeight: '400' }}>{toolTipText}</Text>
    </div>
  )
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        background: '#fff',
        height: '68px',
        // marginTop: 28.5,
        padding: '0 24px',
        color: '#202020',
        border: '1px solid #E8EBEC',
      }}
    >
      <div style={{ display: 'flex', gap: '6px', marginBottom: 27 }}>
        <Text isBold size={18} style={{ marginTop: 20 }}>
          {title}
        </Text>
        <div>
          <div style={{ position: 'relative' }} id="area">
            <Tooltip
              placement="bottom"
              color="rgb(32, 32, 32)"
              getPopupContainer={() => document.getElementById('area')}
              title={
                <div style={{ padding: '0px' }}>
                  <TooltipRangeContent />
                </div>
              }
            >
              <img src={infoIcon} style={{ marginTop: 28, cursor: 'pointer' }} alt="info-icon" />
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <button
          type="submit"
          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
          onClick={handleExploreMore}
        >
          <div style={{ display: 'flex', gap: '6px', marginBottom: 27 }}>
            <Text isBold cursor size={14} style={{ marginTop: 20 }}>
              Explore more
            </Text>

            <div>
              <img
                src={rightArrow}
                width={12}
                height={12}
                style={{ marginTop: 24 }}
                alt="right-arrow"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default TopBar
