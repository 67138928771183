import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@components'
import { Card, Divider } from 'antd'
import { CLEANED_DATA_COUNT } from '@utils/constants'
import googleIcon from '@images/AdsPreview/googleIcon.png'
import ArrowRightOrange from '@images/AdsPreview/ArrowRightOrange.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { RequestService } from '@services/requests'
import copyImg from '../../../images/AdsPreview/copy.png'
import styles from './dataCard.module.css'
import AdsModal from '../AdsModal'

const GooleAdsCard = () => {
  const selectedChannel = useSelector(state => state?.adsPreview?.channels)
  const [modalData, setModalData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const cleanedDataCount = useSelector(state => state?.adsPreview?.cleanedDataCount)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const adsDatas = useSelector(state => state?.adsPreview?.adsPreviewData)
  const [filteredData, setFilteredData] = useState(adsDatas)
  const filterAndSaveData = () => {
    const cleanedData = adsDatas.filter(data => {
      if (data.format === 'image' || data.format === 'text') {
        return data.image
      } // eslint-disable-next-line
      else {
        return data.video_link
      }
    })
    const uniqueImagesOrIframes = new Set(cleanedData.map(data => data.image || data.iframe_url))
    const uniqueData = Array.from(uniqueImagesOrIframes).map(item =>
      cleanedData.find(data => data.image === item || data.iframe_url === item)
    )
    setFilteredData(uniqueData)
    if (cleanedData.length < 100 && adsFormats !== 'Videos' && cleanedDataCount < 100) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    } else if (adsFormats === 'Videos' && cleanedDataCount < 20) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    }
  }

  useEffect(() => {
    if (adsDatas.length > 0) {
      setFilteredData(adsDatas)
      // eslint-disable-next-line
    }
  }, [adsDatas])
  useEffect(() => {
    if (adsDatas[0]?.advertiser) {
      filterAndSaveData()
    }
    // eslint-disable-next-line
  }, [adsDatas])
  const showModal = data => {
    setModalData(data)
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const fetchRenderHTMLData = (key, uniqueId, Id) => {
    // eslint-disable-next-line
    let result = typeof key === 'string' ? key : JSON.stringify(key)

    const parser = new DOMParser()
    const doc = parser.parseFromString(result, 'text/html')

    const scrollbarStyle = `
        <style>
            ::-webkit-scrollbar {
                width: 5px;
                border-radius: 5px;
            }
            ::-webkit-scrollbar-track {
                background-color: #EBECEE;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #a3b0b3;
                border-radius: 5px;
                width: 5px;
            }
        </style>
    `

    const updatedHTML = doc.documentElement.outerHTML.replace('</head>', `${scrollbarStyle}</head>`)

    const iframe = document.createElement('iframe')
    iframe.style.cssText = 'width: 100%; height: 600px; border: none; overflow-x: hidden;'

    iframe.onload = () => {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
      const wordsCount = iframeDocument.querySelector('._7jyr')

      if (wordsCount) {
        const textLength = wordsCount.innerText?.length || 0
        iframe.scrolling = textLength < 39 ? 'no' : 'yes'
        iframe.style.overflowY = textLength > 39 ? 'auto' : 'hidden'
      }

      const button = document.createElement('button')
      button.textContent = '...'
      button.style.cssText = `
            border: none;
            font-size: 25px;
            background: white;
            position: absolute;
            cursor: pointer;
            width: 45px;
            height: 50px;
            top: 18px;
            right: 10px;
            z-index: 1000;
        `

      const dropdown = document.createElement('div')
      dropdown.style.cssText = `
            position: fixed;
            top: 60px;
            right: 10px;
            width: 231px;
            border: 1px solid #ccc;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            display: none;
            z-index: 1001;
            border-radius: 4px;
        `
      dropdown.innerHTML = `
            <div id="copyLink" style="padding: 10px; cursor: pointer;">
                <table>
                    <tr>
                        <td style="vertical-align: top;">
                            <img src="${copyImg}" style="width: 20px; height: 22px; margin-top: 7px;" alt="Link Icon">
                        </td>
                        <td style="vertical-align: top; padding-left: 3px;">
                            <div style="font-size: 15px; font-family: Roboto;">Copy ad link</div>
                            <div style="font-size: 12px; font-family: Roboto; margin-top: 2px; padding-left: 2px;">Get an Ad Library URL for this ad</div>
                        </td>
                    </tr>
                </table>
            </div>
        `

      button.addEventListener('click', () => {
        dropdown.style.display = dropdown.style.display === 'none' ? 'block' : 'none'
      })

      dropdown.querySelector('#copyLink').addEventListener('click', () => {
        navigator.clipboard
          .writeText(`https://www.facebook.com/ads/library/?id=${Id}`)
          // eslint-disable-next-line
          .then(() => (dropdown.style.display = 'none'))
          .catch(err => console.error('Failed to copy: ', err))
      })

      iframe.addEventListener('mouseleave', () => {
        dropdown.style.display = 'none'
      })

      iframeDocument.body.appendChild(button)
      iframeDocument.body.appendChild(dropdown)
    }

    iframe.srcdoc = updatedHTML
    document.getElementById(uniqueId).appendChild(iframe)
  }

  const [lastIndex, setLastIndex] = useState(0)
  useEffect(() => {
    if (selectedChannel === 'Meta' && adsDatas.length > lastIndex) {
      const newAds = adsDatas.slice(lastIndex)
      newAds.forEach((adData, i) => {
        if (adData?.html_content) {
          const uniqueId = `ad-preview-${lastIndex + i}`
          fetchRenderHTMLData(adData.html_content, uniqueId, adData.id)
        }
      })
      setLastIndex(lastIndex + newAds.length)
      if (adsDatas.length < 3) {
        dispatch({ type: CLEANED_DATA_COUNT, payload: adsDatas.length })
      }
    }
    // dispatch({ type: FINAL_DATA_COUNT_FOR_PAGINATION, payload: adsDatas.length })

    // eslint-disable-next-line
  }, [adsDatas])

  return (
    <>
      {
        // eslint-disable-next-line
        filteredData[0]?.ad_snapshot_url ? (
          <Masonry columnsCount={3} gutter="20px">
            {adsDatas.map((_, index) => (
              // eslint-disable-next-line
              <div style={{ background: 'transparent' }} id={`ad-preview-${index}`} key={index} />
            ))}
          </Masonry>
        ) : filteredData[0]?.ad_creative_id ? (
          <>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
              <Masonry columnsCount={3} gutter="20px">
                {filteredData?.map(data => (
                  <>
                    {data.format === 'text' || data.format === 'image' ? (
                      <Text onClick={() => showModal(data)} style={{ cursor: 'pointer' }}>
                        <Card className={styles.card} key={data?.ad_creative_id}>
                          <div className={styles.profile}>
                            <Text
                              isBold
                              size={16}
                              style={{
                                fontWeight: 400,
                                width: 'auto',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {data?.advertiser}
                            </Text>
                            <img
                              src={googleIcon}
                              className={styles.profileIcons}
                              alt="profile-pic"
                            />
                          </div>
                          <div className={styles.cardImageContainer}>
                            <img
                              src={data?.image ? data.image : noPreviewImage}
                              className={styles.cardImage}
                              alt={data?.advertiser}
                            />
                          </div>
                          <Divider style={{ marginTop: 24 }} />
                          <div className={styles.shareContainer}>
                            <div className={styles.shareTextContainer}>
                              <Text
                                size={14}
                                isBold
                                style={{ color: '#202020' }}
                                onClick={() => showModal(data)}
                              >
                                Extra Insights
                              </Text>
                              <img
                                src={ArrowRightOrange}
                                height={12}
                                width={12}
                                style={{ marginTop: 5 }}
                                alt="card-img"
                              />
                            </div>
                          </div>
                        </Card>
                      </Text>
                    ) : (
                      <Text onClick={() => showModal(data)} style={{ cursor: 'pointer' }}>
                        <Card className={styles.card} key={data?.ad_creative_id}>
                          <div className={styles.profile}>
                            <Text
                              isBold
                              size={16}
                              style={{
                                fontWeight: 400,
                                width: 'auto',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {data?.advertiser}
                            </Text>
                            <img
                              src={googleIcon}
                              className={styles.profileIcons}
                              alt="profile-pic"
                            />
                          </div>

                          <div
                            className={styles.cardImageContainer}
                            style={{ position: 'relative' }}
                          >
                            <img
                              // src={`https://www.youtube.com/embed/${data.video_link}`}
                              style={{
                                width: '-webkit-fill-available',
                                maxWidth: '-moz-available',
                              }}
                              src={data.iframe_url}
                              alt="Thumbnail"
                            />
                            <svg
                              height="20%"
                              version="1.1"
                              viewBox="0 0 68 48"
                              width="20%"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fill: 'white',
                                opacity: '0.8',
                                cursor: 'pointer',
                              }}
                            >
                              <path
                                className="ytp-large-play-button-bg"
                                d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                fill="#f00"
                              />
                              <path d="M 45,24 27,14 27,34" fill="#fff" />
                            </svg>
                          </div>
                          <Divider style={{ marginTop: 24 }} />
                          <div className={styles.shareContainer}>
                            <div className={styles.shareTextContainer}>
                              <Text
                                size={14}
                                isBold
                                style={{ color: '#202020' }}
                                onClick={() => showModal(data)}
                              >
                                Extra Insights
                              </Text>
                              <img
                                src={ArrowRightOrange}
                                height={12}
                                width={12}
                                style={{ marginTop: 5 }}
                                alt="card-img"
                              />
                            </div>
                          </div>
                        </Card>
                      </Text>
                    )}
                  </>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </>
        ) : (
          ''
        )
      }

      {/* Open Modal Conditionally */}
      {isModalOpen && modalData && (
        <AdsModal
          adsData={modalData}
          modalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
export default GooleAdsCard
