import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const prefix = process.env.NODE_ENV === 'development' ? 'DEV_' : ''
const redirectStoreName = `${prefix}ads_grader_redirect_to`

export function useRedirectStore({ autoRedirect }) {
  const history = useHistory()
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    const storedRedirect = localStorage.getItem(redirectStoreName)

    if (storedRedirect) {
      setRedirect(storedRedirect)
    }
  }, [])

  useEffect(() => {
    if (redirect && autoRedirect) {
      localStorage.clear(redirectStoreName)

      history.push(redirect)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, autoRedirect])

  const handleSetRedirect = next => {
    localStorage.setItem(redirectStoreName, next)
  }

  return handleSetRedirect
}
