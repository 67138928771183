const LS_KEY = 'np_ads_grader:session'

class User {
  static get() {
    return JSON.parse(window.localStorage.getItem(LS_KEY)) || null
  }

  static set(user) {
    window.localStorage.setItem(LS_KEY, JSON.stringify(user))
  }

  static remove() {
    window.localStorage.removeItem(LS_KEY)
  }
}

export default User
