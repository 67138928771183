import React from 'react'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import TermsFooter from '../../components/Footer/TermsFooter'
import styles from './policies.module.scss'

const TermsPage = () => (
  <div className={styles.pageWrapper}>
    <div className={styles.policiesHeader}>
      <Link to="/">
        <LogoAdsGrader notTranslate />
      </Link>
      <Text className={styles.title} size={48} color="white">
        Terms of Service
      </Text>
      <Text size={22} color="white">
        Last Updated: November 24, 2021
      </Text>
    </div>

    <Row className={styles.row}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 18, offset: 3 }}
        xl={{ span: 8, offset: 8 }}
      >
        <article className={styles.article} role="main">
          <div>
            <p>
              The following terms and conditions govern all use of the AdsGrader.com website and all
              content, services, features, activities, and products available at or through the
              website, including, but not limited to, Neil Patel Digital Advanced Consulting,
              Ubersuggest, Neil Patel Digital Events, mobile sites, and applications (collectively,
              the “Services”). The Services are owned and operated by Neil Patel Digital, LLC.
              (“Neil Patel Digital”, “us” or “we”). Please carefully review these Terms of Service
              which, together with Neil Patel Digital’s{' '}
              <a href="https://adsgrader.com/privacy/">Privacy Policy</a> and Copyright or{' '}
              <a href="https://neilpatel.com/copyright/">
                Intellectual Property Infringement Notification Policy
              </a>
              , which policies are incorporated by reference herein in their entirety, constitute a
              legally binding agreement between you and Neil Patel Digital that conditions your use
              of the Services (this “Agreement”). By using or accessing the Services, you agree to
              be bound by the terms and conditions of this Agreement. If you do not agree to all the
              terms and conditions of this Agreement, then you may not access or use the Services.
              The Services are available only to individuals who are at least 16 years old.&nbsp;
            </p>
            <h2>1. Content on the Services</h2>
            <p>
              <strong>1.1. In General.</strong> Any opinions expressed by the contributors, authors,
              and moderators who post Content (as defined below) to Neil Patel Digital are the
              personal opinions of the authors, not of Neil Patel Digital, whether or not the
              authors are employees or contractors of Neil Patel Digital. The Content (as defined
              below) is provided for informational and entertainment purposes only and is not meant
              to be an endorsement or representation by Neil Patel Digital or any other party.
            </p>
            <p>
              <strong>1.2. Ownership.</strong> The Content on the Services is intended for your
              personal, non-commercial use only. Commercial use of any of the Content or Services is
              strictly prohibited. The Services and all materials published and/or distributed on or
              through the Services (including, but not limited to, news articles, photographs,
              images, illustrations, audio clips, and video clips) (collectively, the “Content”) are
              protected by copyright pursuant to U.S. and international copyright laws, and are
              owned or controlled by Neil Patel Digital or the party credited as the provider of the
              Content. Except as set forth in Sections 1.3 and 5.4 of this Agreement, you may not
              modify, publish, transmit, adapt, participate in the transfer or sale of, reproduce,
              create new works from, distribute, perform, display, or in any way exploit any of the
              Content in whole or in part, without the express written consent of Neil Patel Digital
              or the copyright owner identified in the copyright notice in the Content. You agree
              not to change or delete any proprietary notices that appear in the Content. You may
              not use any Neil Patel Digital logo or proprietary graphic or trademark without Neil
              Patel Digital’s express written permission. You shall abide by all additional
              copyright notices, information, or restrictions contained in any Content accessed
              through the Services. As between the parties, title, ownership rights, and
              intellectual property rights in the Content and Services, and any copies or portions
              thereof, shall remain in Neil Patel Digital and/or its Content providers or licensors.
              Neil Patel Digital reserves any rights not expressly granted in this Agreement.
            </p>
            <p>
              <strong>1.3. Limited License.</strong> Subject to your strict compliance with this
              Agreement, Neil Patel Digital grants you a limited, revocable, non-transferable,
              non-assignable and non-exclusive license to access, download (temporary storage only),
              display, view, use, play and/or print the Content (excluding, for purposes of this
              Section 1.3, Content accessed through Neil Patel Digital Advanced Consulting and
              further excluding source and object code in raw form or otherwise, other than as made
              available to access and use via a standard web browser to enable display) on a
              personal computer, mobile phone or other wireless device, or other Internet-enabled
              device, for your personal non-commercial use only, subject to the restrictions set
              forth in this Agreement. The foregoing limited license (A) does not give you any
              ownership of, or any other intellectual property interest in, any Content, and (B) may
              be immediately suspended or terminated for any reason, at Neil Patel Digital’s sole
              discretion, and without advance notice or liability.
            </p>
            <h2>2. User-Generated Content</h2>
            <p>
              <strong>2.1. Prohibited Actions.</strong> You are solely responsible for your
              communications on and your use of the Services. You agree not to do any of the
              following: (A) post or transmit any libelous, defamatory, indecent, obscene,
              fraudulent, deceptive, abusive, or pornographic message, data, image, content, or
              program; (B) post or transmit any message, data, image, Content, or program that would
              violate any property rights of others; (C) use the Services to threaten, harass, or
              otherwise violate the legal rights (including rights of privacy and publicity) of
              others; (D) upload or download files that contain software, marks, logos, data,
              images, Content, or other material protected by intellectual property laws, rights of
              privacy or publicity, or any other applicable law, unless you own or control the
              rights to such files or material, or have received all necessary consents; (E) upload
              files that contain a virus or corrupted data; (F) falsify the source or origin of
              software or other material contained in a file that you upload to the Services; (G)
              falsely purport to be an employee or agent of Neil Patel Digital; (H) act, in your use
              of the Services, in a manner that is contrary to applicable law or regulation; or (I)
              circumvent, disable, or otherwise interfere with security-related features of the
              Services or features that prevent or restrict use or copying of any Content or enforce
              limitations on use of the Services or the Content. Neil Patel Digital does not
              represent or endorse any materials you post on your sites or products. Neil Patel
              Digital does not review, cannot review, and is under no obligation to review the
              material that is using the Services provided.
            </p>
            <p>
              <strong>
                2.2. Neil Patel Digital’s Exclusive Right to Manage the User Submissions.
              </strong>{' '}
              You acknowledge that any materials and other information (including, without
              limitation, ideas contained therein for new or improved products or Services) you
              post, upload, or submit to the Services, including but not limited to comments, forum
              messages, reviews, text, video, audio, photographs, computer code, and applications
              (each, a “User Submission”) may be edited, removed, deleted, modified, published,
              transmitted, and displayed by Neil Patel Digital in its sole discretion and without
              your permission, and you waive any rights you may have (including any moral rights) in
              preventing the material from being altered or changed in a manner not agreeable to
              you. You expressly agree that we may remove, disable, or restrict access to or the
              availability of any User Submissions from the Services at any time, for any reason, or
              for no reason at all. Neil Patel Digital reserves the right to treat User Submissions
              as Content stored at the direction of users for which Neil Patel Digital will not
              exercise control except to block or remove Content that comes to Neil Patel Digital’s
              attention and is offensive, obscene, lewd, lascivious, filthy, violent, harassing,
              threatening, abusive, illegal, libelous, defamatory, fraudulent, deceptive,
              misleading, or otherwise objectionable to Neil Patel Digital, or to enforce the rights
              of third parties, or the Content restrictions set forth below in this Agreement when
              notice of their violation comes to Neil Patel Digital’s attention. However, Neil Patel
              Digital shall not be responsible for controlling or editing any Content, and Neil
              Patel Digital has no contractual obligation to remove inappropriate or unlawful
              Content. Under no circumstances will we be held liable for removing, disabling, or
              restricting access to or the availability of Content.
            </p>
            <p>
              <strong>2.3. License to Neil Patel Digital of Your User Submissions.</strong> You
              hereby grant to Neil Patel Digital, and you agree to grant to Neil Patel Digital, a
              perpetual, royalty-free, non-exclusive, irrevocable, unrestricted, unconditional,
              unlimited, worldwide, and cost-free license to use, copy, record, disclose, sell,
              re-sell, sublicense, reproduce, distribute, redistribute, modify, adapt, publish,
              edit, translate, transmit, create derivative works of, broadcast, publicly perform,
              display, or otherwise exploit in any manner whatsoever, all or any portion of your
              User Submissions (and derivative works thereof), for any purpose whatsoever in all
              formats, on or through any media, software, formula, or technology whether by any
              means and in any media now known or hereafter developed and to sublicense such rights
              through multiple tiers of sublicenses, and to advertise, market and promote the same.
              In order to further effect the rights and license that you grant to Neil Patel Digital
              to your User Submissions, you also hereby grant to Neil Patel Digital, and agree to
              grant to Neil Patel Digital, the unconditional, perpetual, irrevocable right to use
              and exploit your name, persona, image, photograph, and likeness that you provide in
              connection with any User Submission, without any obligation or remuneration to you.
              Except as prohibited by law, you hereby waive, and you agree to waive, any moral
              rights (including attribution and integrity) that you may have in any User
              Submissions, even if your User Submissions are altered or changed in a manner not
              agreeable to you. You agree that you shall have no recourse against Neil Patel Digital
              for any alleged or actual infringement or misappropriation of any proprietary right in
              your User Submissions. You further acknowledge and agree that no compensation will be
              paid with respect to the use of your User Submissions or any of the rights granted in
              this Section 2.3.
            </p>
            <p>
              <strong>2.4. Representations and Warranties Related to Your User Submissions.</strong>{' '}
              Each time you submit a User Submission, you represent and warrant that, as to that
              User Submission, (A) you are the sole author and owner of the intellectual property
              and other rights to the User Submission, or you have a lawful right to submit the User
              Submission and grant Neil Patel Digital the rights to it that you are granting by this
              Agreement, all without any Neil Patel Digital obligation to obtain consent of any
              third party and without creating any obligation or liability of Neil Patel Digital;
              (B) the User Submission is accurate; (C) the User Submission does not and, as to Neil
              Patel Digital’s permitted uses and exploitation set forth in this Agreement, will not
              infringe any intellectual property or other right of any third party; (D) the User
              Submission will not violate this Agreement or cause injury or harm to any person; and
              (E) the User Submission complies with all applicable laws and regulations.
            </p>
            <p>
              <strong>2.5. Responsibility of Users.</strong> You are entirely responsible for the
              content of, and any harm resulting from, your User Submissions. Neil Patel Digital
              does not assume any responsibility or liability for any User Submissions posted on the
              Services or any website linked to the Services, and makes no express or implied
              warranty or guarantee about the accuracy, copyright compliance, legality, or any other
              aspect of the User Submissions.
            </p>
            <p>
              <strong>2.6. Non-Confidentiality of Your User Submissions.</strong> The Services are
              available to the public, and your User Submissions, along with information concerning
              your identity that you provide to Neil Patel Digital (including your name, image,
              employer, and location), may be publicly available. Do not post information you
              consider confidential to the Services. In addition, you agree that Neil Patel Digital
              may reveal your identity and whatever information we know about you to any law
              enforcement agent or official in the event of legal action or a legal request arising
              from any User Submission made by you.
            </p>
            <p>
              <strong>2.7. Unsolicited Email, Spamming &amp; Spoofing.</strong> You may not use the
              Services to transmit unsolicited email. You may not send unsolicited email to the
              Services or to anyone whose email address includes a domain name used on the Services.
              You may not use any domain name on our Services as a pseudonymous return email address
              for any communications which you transmit from another location or through another
              service. You may not pretend to be someone else – or spoof their identity – when using
              the Services.
            </p>
            <h2>3. Links to Third-Party Websites</h2>
            <p>
              The Services may contain links to websites operated by third parties (“Third-Party
              Websites”). Access to Third-Party Websites is at your own risk, and Neil Patel Digital
              is not responsible for the accuracy, availability or reliability of any information,
              goods, data, opinions, advice or statements made available on Third-Party Websites.
              These links may also lead to Third-Party Websites containing information that some
              people may find inappropriate or offensive. The inclusion of any links to Third-Party
              Websites on Neil Patel Digital does not imply an endorsement or recommendation by Neil
              Patel Digital. Neil Patel Digital is not responsible for any form of transmission
              received from any link nor is Neil Patel Digital responsible if any of these links are
              not working appropriately. Third-Party Websites are not under the control of Neil
              Patel Digital and Neil Patel Digital makes no representations or warranties of any
              kind regarding such Third-party Websites. Neil Patel Digital shall have no liability
              for any damage or loss caused or alleged to be caused by or in connection with use of
              or reliance on any Third-Party Website.You are responsible for viewing and abiding by
              any privacy statements and terms of use posted in connection with Third-Party
              Websites, and these Third-Party Websites are not governed by this Agreement.&nbsp;
            </p>
            <h2>4. Copyright or Intellectual Property Infringement Notification</h2>
            <p>
              If you believe in good faith that material or Content available on Neil Patel Digital
              infringes a copyright or other intellectual property right that you own or for which
              you are a beneficial owner or exclusive licensee, you are encouraged to notify Neil
              Patel Digital in accordance with Neil Patel Digital’s Copyright or Intellectual
              Property Infringement Notification Policy, currently accessible at{' '}
              <a href="https://neilpatel.com/copyright/">https://neilpatel.com/copyright/</a>. It is
              our policy to terminate, in appropriate circumstances, the access rights of repeat
              infringers.
            </p>
            <h2>
              5. Additional Terms Applicable to Users of Neil Patel Digital Advanced Consulting
            </h2>
            <p>
              <strong>5 .1. In General.</strong> Neil Patel Digital offers the opportunity to sign
              up to obtain enhanced Content and features through Neil Patel Digital Advanced
              Consulting. By using or accessing any products, Content, features, Services or events
              offered through Neil Patel Digital Advanced Consulting, you agree to be bound by the
              terms and conditions of this Section 5, which are specific to use of Neil Patel
              Digital Advanced Consulting and shall not apply to the use of any of the Services
              other than Neil Patel Digital Advanced Consulting.
            </p>
            <p>
              <strong>5.2. Neil Patel Digital Advanced Consulting Account.</strong> In order to
              access certain features of Neil Patel Digital Advanced Consulting, you will be
              required to create an online account (the “Premium Account”). You agree to: (A) take
              reasonable measures to maintain the security of your user identification, password and
              other information relating to your Premium Account; (B) refrain from sharing your
              Premium Account password or from allowing access to your Premium Account by any third
              party; (C) be solely responsible for the security, confidentiality, legality and
              integrity of all messages and Content that you receive, transmit or store using Neil
              Patel Digital Advanced Consulting and the Premium Account; (D) be responsible for all
              charges resulting from use of your Premium Account, including unauthorized use prior
              to your notifying Neil Patel Digital in writing of such use and taking steps to
              prevent its further occurrence, including by changing your password; (E) comply with
              the terms and conditions set forth herein; and (F) comply with all applicable U.S. and
              international laws, statutes, ordinances, rules, regulations, contracts and applicable
              licenses regarding your use of Neil Patel Digital Advanced Consulting.
            </p>
            <p>
              <strong>5.3. Fees; Payment Terms; Account Cancellation.</strong>
            </p>
            <ol>
              <li>
                Neil Patel Digital charges a subscription fee (“Fees”) for the use of Neil Patel
                Digital Advanced Consulting. By registering for a Premium Account, you agree to pay
                Neil Patel Digital the Fees applicable to the account level chosen. Unless otherwise
                stated, all Fees are in U.S. Dollars. Neil Patel Digital expressly reserves the
                right to change the Fees at any time, upon 30-days written notice to you at your
                registered email address. The Fees do not include any payment for telecommunications
                and computer hardware, software, telecommunications access charges, lines or
                connections or other equipment and services required to access and use Neil Patel
                Digital Advanced Consulting, which shall be your sole responsibility. All Fees will
                be billed to your credit card at the current international currency conversion rate.
                You are responsible for and shall pay Neil Patel Digital all currency conversion
                charges, sales, use, value-added, personal property or other tax, duty or levy of
                any kind, including interest and penalties thereon (collectively, “Taxes”) for the
                use of Neil Patel Digital Advanced Consulting, whether imposed now or hereinafter by
                any governmental entity. For any upgrade or downgrade in plan level that you elect,
                your credit card will automatically be charged the new rate on your next billing
                cycle.
              </li>
              <li>
                All Fees are paid in advance and are refundable for 30 days of the initial purchase
                unless otherwise stated by a contract between Neil Patel Digital and the customer.
                After the 30-day period, there will be no refunds or credits for partial months of
                service, upgrades/downgrades, or for months when you did not use Neil Patel Digital
                Advanced Consulting. We reserve the right to deactivate your access to Neil Patel
                Digital Advanced Consulting if you fail to pay applicable fees. You must provide
                current, complete, and accurate billing information. You must promptly update all
                billing information to keep your account current, complete, and accurate (such as by
                furnishing a new billing address, credit card number or expiration date), and you
                must promptly notify Neil Patel Digital if your credit card is canceled (including
                if you lose your card or it is stolen), or if you become aware of a potential breach
                of security (such as an unauthorized disclosure or use of your name or password).
                You authorize us to obtain updated or replacement expiration dates for your credit
                card in the event that the credit card you provided us expires. We reserve the right
                to charge any renewal card issued to you as a replacement. You agree to promptly pay
                Neil Patel Digital in the event of any refusal of your credit card issuer to pay any
                amount to Neil Patel Digital for any reason. You agree to pay all costs of
                collection, including attorney---s fees and costs, on any outstanding balance. In
                the event you fail to pay any amount when due, Neil Patel Digital may immediately
                suspend or terminate this Agreement and your access to Neil Patel Digital Advanced
                Consulting.
              </li>
              <li>
                Your subscription will renew automatically unless we terminate it or you terminate
                your subscription by providing written or electronic notice through one of the
                following methods:
                <br />
                <br />
                Email: <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>
                <br />
                <br />
                Mail:
                <br />
                <br />
                Neil Patel Digital, LLC
                <br />
                ATTN: Termination
                <br />
                750 B ST STE 1400
                <br />
                SAN DIEGO CA 92101-8190
                <br />
                <br />
                You must cancel your subscription before it renews in order to avoid billing of
                subscription fees for the renewal term to your credit card.
              </li>
            </ol>
            <p>
              <strong>5.4. Limited License to Neil Patel Digital Advanced Consulting Users.</strong>{' '}
              Subject to your strict compliance with this Agreement, Neil Patel Digital grants you a
              limited, revocable, non-transferable, non-assignable and non-exclusive license to use
              the Content available on Neil Patel Digital Advanced Consulting (the “Neil Patel
              Digital Advanced Consulting Content”) as described below. You may:
            </p>
            <ul>
              <li>
                View the Neil Patel Digital Advanced Consulting Content while accessing Neil Patel
                Digital Advanced Consulting.
              </li>
              <li>
                Download or print one copy of any Neil Patel Digital Advanced Consulting Content for
                personal use.
              </li>
              <li>
                Incorporate, on a non-systematic and non-routine basis, a small excerpt of the Neil
                Patel Digital Advanced Consulting Content (e.g., a few lines of text, a paragraph, a
                specific graphic, chart or graph) within a report or presentation that is
                distributed to an audience that is internal to the company for which you are
                employed (“your company”), as long as you attribute Neil Patel Digital Advanced
                Consulting as the source.
              </li>
              <li>
                Briefly summarize, on a non-systematic and non-routine basis, Neil Patel Digital
                Advanced Consulting Content in your own words, and distribute the summary to an
                audience that is internal to your company in connection with a specific project, as
                long as you attribute Neil Patel Digital Advanced Consulting as the source.
              </li>
              <li>
                Distribute, in digital or hard copy, Neil Patel Digital Advanced Consulting Content
                for which you have purchase reprint rights, to audiences inside or outside your
                company.
              </li>
              <li>
                If your Premium Account was obtained under an enterprise license made by your
                company, distribute Neil Patel Digital Advanced Consulting Content in accordance
                with the terms of that license.
              </li>
            </ul>
            <p>
              Except as expressly provided in this Section 5.4, you may not post or otherwise
              distribute Neil Patel Digital Advanced Consulting Content (e.g. via hard copy, email,
              and/or posting on intranet, company bulletin boards or other information storage &amp;
              retrieval systems). The foregoing limited license (A) does not give you any ownership
              of, or any other intellectual property interest in, any Neil Patel Digital Advanced
              Consulting Content, and (B) may be immediately suspended or terminated for any reason,
              in Neil Patel Digital’s sole discretion, and without advance notice or liability.
            </p>
            <h2>6. Additional Terms of Use Applicable to Users of Ubersuggest</h2>
            <p>
              <strong>6 .1. In General.</strong> Neil Patel Digital offers the opportunity to sign
              up to obtain enhanced marketing tools through Ubersuggest. By using or accessing any
              products, Content, features, Services or events offered through Ubersuggest, you agree
              to be bound by the terms and conditions of this Section 6 which are specific to use of
              Ubersuggest and shall not apply to the use of any of the Services other than
              Ubersuggest.
            </p>
            <p>
              <strong>6.2. Ubersuggest Account.</strong> In order to access certain features of
              Ubersuggest, you will be required to create an online account (the “Ubersuggest
              Account”). You may use the Services and apply it to multiple websites but sharing or
              reselling your Ubersuggest account is not permitted. You agree to: (A) take reasonable
              measures to maintain the security of your user identification, password, and other
              information relating to your Ubersuggest Account; (B) refrain from sharing your
              Ubersuggest Account password or from allowing access to your Ubersuggest Account by
              any third party; (C) be solely responsible for the security, confidentiality,
              legality, and integrity of all messages and Content that you receive, transmit or
              store using Ubersuggest and the Ubersuggest Account.; (D) be responsible for all
              charges resulting from use of your Ubersuggest Account, including unauthorized use
              prior to your notifying Neil Patel Digital in writing of such use and taking steps to
              prevent its further occurrence, including by changing your password; (E) comply with
              the terms and conditions set forth herein; and (F) comply with all applicable U.S. and
              international laws, statutes, ordinances, rules, regulations, contracts and applicable
              licenses regarding your use of Ubersuggest. If we discover a violation of these rules,
              we reserve the right to immediately and indefinitely suspend your Ubersuggest Account
              without notice.&nbsp;
            </p>
            <p>
              <strong>6.3. Backup of Data.</strong>&nbsp;There is no warranty included with this
              Service. You shall be solely responsible for daily backup and other protection of your
              data against loss, damage or destruction and shall backup your individual computers
              before any Services are rendered in order to protect against any loss of data.
              Ubersuggest will have no obligation or liability for lost data as a result of your
              non-compliance with this section.
            </p>
            <p>
              <strong>6.4. Fees; Payment Terms; Account Cancellation.</strong>
            </p>
            <ol>
              <li>
                Neil Patel Digital charges a fee (“Fees”) for the use of Ubersuggest. By registering
                for an Ubersuggest Account, you agree to pay Neil Patel Digital the Fees applicable
                to the account level chosen. Unless otherwise stated, all Fees are in U.S. Dollars.
                Neil Patel Digital expressly reserves the right to change the Fees at any time, upon
                30-day written notice to your registered email address. The Fees do not include any
                payment for telecommunications and computer hardware, software, telecommunications
                access charges, lines or connections or other equipment and services required to
                access and use Ubersuggest, which shall be your sole responsibility. All Fees will
                be billed to your credit card at the current international currency conversion rate.
                You are responsible for and shall pay Neil Patel Digital all currency conversion
                charges, sales, use, value-added, personal property or other tax, duty or levy of
                any kind, including interest and penalties thereon (collectively, “Taxes”) for the
                use of Ubersuggest, whether imposed now or hereinafter by any governmental entity.
              </li>
              <li>
                All Fees are paid in advance. For monthly subscriptions, you will receive a one-time
                7-day free trial. After the free trial, Fees are non-refundable. For annual and
                lifetime subscriptions, you have a 30-day, no questions asked, money back guarantee,
                unless otherwise stated by a contract between Neil Patel Digital and the customer.
                After the 7-day or 30-day period above, there will be no refunds or credits for
                partial months of service, upgrades/downgrades, or for months when you did not use
                Ubersuggest. We reserve the right to deactivate your access to Ubersuggest if you
                fail to pay applicable Fees. You must provide current, complete and accurate billing
                information. You must promptly update all billing information to keep your account
                current, complete and accurate (such as by furnishing a new billing address, card
                number or expiration date), and you must promptly notify Neil Patel Digital if your
                credit card is canceled (including if you lose your card or it is stolen), or if you
                become aware of a potential breach of security (such as an unauthorized disclosure
                or use of your name or password). You’ll replace the information for any card that
                expires with information for a valid one. If your card is automatically replaced
                with a new card by a payment processor, you acknowledge and agree that we’re
                authorized to deduct any charges on your account against the new card. You agree to
                promptly pay Neil Patel Digital in the event of any refusal of your credit card
                issuer to pay any amount to Neil Patel Digital for any reason. You agree to pay all
                costs of collection, including attorney’s fees and costs, on any outstanding
                balance. In the event you fail to pay any amount when due, Neil Patel Digital may
                immediately suspend or terminate this Agreement and your access to Ubersuggest.
              </li>
              <li>
                Ubersuggest subscriptions have a defined amount of limits available per month.
                Exceeding the limits of your subscription will require an upgrade. These limits are
                subject to change and may vary depending upon your usage. If we detect unusual or
                abusive use of Services, your account may be suspended or terminated.
              </li>
              <li>
                If your account becomes inactive and is otherwise no longer valid, (A) Neil Patel
                Digital will no longer be obligated to provide you the Services, (B) your account
                will no longer be accessible by you, (C) you will immediately stop receiving
                Services, and (D) all licenses and other rights granted to you under the Agreement
                will immediately cease. Neil Patel Digital will not be liable to you or any third
                party for termination of this Agreement or any termination or suspension of your use
                of the Services.
                <br />
                <br />
                YOU ACKNOWLEDGE AND AGREE THAT YOUR CONTENT MAY BE DELETED FROM YOUR ACCOUNT UPON
                YOUR ACCOUNT BECOMING INACTIVE FOR SIXTY (60) DAYS. THIS CONTENT CANNOT BE RECOVERED
                ONCE DELETED. IF YOUR LICENSE IS NOT ACTIVE, NEIL PATEL DIGITAL WILL NOT STORE
                CONTENT ON YOUR BEHALF.
                <br />
              </li>
              <li>
                Neil Patel Digital reserves the right to cancel your Ubersuggest Account without
                refund or proration if you violate any of the terms of this Agreement, try to scrape
                or resell data, share your login with a third party, or otherwise abuse your use of
                your Ubersuggest Account, the Content, or Services.
              </li>
              <li>
                Your subscription will renew automatically unless we terminate it or you terminate
                your subscription by providing written or electronic notice through one of the
                following methods:
                <br />
                <br />
                Email: <a href="mailto:admin@ubersuggest.com">admin@ubersuggest.com</a>
                <br />
                <br />
                Mail:
                <br />
                <br />
                Neil Patel Digital, LLC
                <br />
                ATTN: Termination
                <br />
                750 B Suite 1400
                <br />
                San Diego, CA, 92101&nbsp;
                <br />
                <br />
                You must cancel your subscription before it renews in order to avoid billing of
                subscription fees for the renewal term to your credit card.
              </li>
            </ol>
            <p>
              <strong>6.5. Subscription</strong>
            </p>
            <ol>
              <li>
                Your Ubersuggest Account will be available for the term applicable to the account
                level purchased.
              </li>
              <li>
                Lifetime Subscription. If you purchase a lifetime subscription, your Ubersuggest
                Account will be available for a “lifetime” basis. “Lifetime” shall be defined as for
                as long as neilpatel.com/ubersuggest exists, at the discretion of Neil Patel
                Digital. Lifetime subscriptions are not transferable to any other entity, person,
                email address (even another email address owned by you), for any reason, in any way,
                or under any circumstance whatsoever. Please subscribe with an email address that
                you will not lose access to.
              </li>
              <li>
                Neil Patel Digital reserves the right to change the products, Content, features,
                Services and events offered through Ubersuggest at Neil Patel Digital’s sole and
                absolute discretion in order to improve functionality, address security issues, or
                for any reason (or no reason at all) as Neil Patel Digital deems appropriate.
              </li>
            </ol>
            <p>
              <strong>6.6. Limited License to Ubersuggest Users.</strong> Subject to your strict
              compliance with this Agreement, Neil Patel Digital grants you a limited, revocable,
              non-transferable, non-assignable and non-exclusive license to use the Content
              available on Ubersuggest (the “Ubersuggest Content”) as described below. You may:
            </p>
            <ul>
              <li>View the Ubersuggest Content while accessing Ubersuggest.</li>
              <li>Download or print one copy of any Ubersuggest Content for personal use.</li>
              <li>
                Incorporate, on a non-systematic and non-routine basis, a small excerpt of the
                Ubersuggest Content (e.g., a few lines of text, a paragraph, a specific graphic,
                chart or graph) within a report or presentation that is distributed to an audience
                that is internal to the company for which you are employed (“your company”), as long
                as you attribute Ubersuggest as the source.
              </li>
              <li>
                Briefly summarize, on a non-systematic and non-routine basis, Ubersuggest Content in
                your own words, and distribute the summary to an audience that is internal to your
                company in connection with a specific project, as long as you attribute Ubersuggest
                as the source.
              </li>
              <li>
                Distribute, in digital or hard copy Ubersuggest Content for which you have purchased
                reprint rights, to audiences inside or outside your company.
              </li>
            </ul>
            <p>
              If your Ubersuggest Account was obtained under an enterprise license made by your
              company, distribute Ubersuggest Content in accordance with the terms of that
              license.Except as expressly provided in this Section 6.6, you may not post or
              otherwise distribute Ubersuggest Content (e.g. via hard copy, email, and/or posting on
              intranet, company bulletin boards, or other information storage &amp; retrieval
              systems). The foregoing limited license (A) does not give you any ownership of or any
              other intellectual property interest in any Ubersuggest Content, and (B) may be
              immediately suspended or terminated for any reason, in Ubersuggest’s sole discretion,
              and without advance notice or liability.
            </p>
            <p>
              <strong>6.7. Ubersuggest Javascript &amp; WordPress Plugin Services.</strong> Because
              the number and variety of javascript and plugins available are vast and wide, we do
              not guarantee that our plugin(s) will function with all third-party plugins, themes,
              or browsers of any kind. We do not assume responsibility and will not be held
              responsible for any conflicts or compatibility issues that may occur due to
              third-party software. We assume no responsibility for any data loss as a result of
              installing or using our plugins. Should conflicts occur with third-party software, we
              may provide support at our discretion. We will provide instruction and/or supporting
              documentation regarding setup, configuration, and technical troubleshooting as it
              pertains to our products. We do not provide support for third-party integrations and
              may ask that you disable these add-ons to better assist you.
            </p>
            <p>
              <strong>6.8. Ubersuggest Javascript &amp; WordPress Plugin Updates.</strong> Plugin
              updates, including any automatic updates, will be granted as long as the Ubersuggest
              account is valid, active, and the plugin remains installed.&nbsp;
            </p>
            <p>
              6.9. URL Data available on this site was provided by use of the{' '}
              <a href="https://moz.com/help/links-api" target="_blank" rel="noopener noreferrer">
                Moz Links API
              </a>
              , a proprietary API service we subscribe to from{' '}
              <a href="https://moz.com/" target="_blank" rel="noopener noreferrer">
                SEOmoz, Inc
              </a>
              . (“Moz”). Metrics provided by the Domain Authority®, Page Authority®, and Spam Score®
              are proprietary to Moz and are based on Moz’s proprietary Data. Moz, Data Domain
              Authority®, Page Authority®, and Spam Score® are trademarks of Moz.
            </p>
            <h2>7. Operation of the Services</h2>
            <p>
              Neil Patel Digital reserves complete and sole discretion with respect to the operation
              of the Services. Neil Patel Digital may, among other things: (A) make available to
              third parties information relating to the Services and their users, subject to the
              Privacy Policy; and (B) withdraw, suspend, or discontinue any functionality or feature
              of the Services. You acknowledge and agree that from time to time the Services may be
              inaccessible or inoperable for any reason, including, without limitation: (i)
              equipment malfunctions; (ii) periodic maintenance procedures or repairs which Neil
              Patel Digital may undertake from time to time; or (iii) causes beyond the control of
              Neil Patel Digital or which are not reasonably foreseeable by Neil Patel Digital.
            </p>
            <h2>8. DISCLAIMER OF WARRANTIES</h2>
            <p>
              THE SERVICES ARE PROVIDED BY NEIL PATEL DIGITAL ON AN “AS IS” BASIS. NEIL PATEL
              DIGITAL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
              THE OPERATION OF THE SERVICES OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS
              INCLUDED ON THE SERVICES, INCLUDING THE ACCURACY (EITHER WHEN POSTED OR AS A RESULT OF
              THE PASSAGE OF TIME) OF ANY CONTENT ON THE SERVICES. TO THE FULL EXTENT PERMISSIBLE BY
              APPLICABLE LAW, NEIL PATEL DIGITAL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, TITLE, NON-INFRINGEMENT, AND THAT ACCESS TO OR USE OF THE SERVICES WILL BE
              SECURE, ACCESSIBLE CONTINUOUSLY, UNINTERRUPTED OR ERROR-FREE. NEIL PATEL DIGITAL MAKES
              NO REPRESENTATIONS, WARRANTIES OR GUARANTEES AGAINST HUMAN AND MACHINE ERRORS,
              OMISSIONS, DELAYS, LOSSES (INCLUDING LOSS OF DATA), OR THAT FILES AVAILABLE FOR
              DOWNLOAD FROM THE SERVICES WILL BE FREE OF INFECTION BY VIRUSES, WORMS, TROJAN HORSES
              OR OTHER CODE THAT MANIFEST CONTAMINATING OR DESTRUCTIVE PROPERTIES. THIS DISCLAIMER
              OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT. IF YOU ARE DISSATISFIED
              WITH ANY PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE
              REMEDY IS TO DISCONTINUE USING THE SERVICES AND, WHERE APPLICABLE, TERMINATE YOUR
              PREMIUM ACCOUNT. TO THE EXTENT THAT YOU MIGHT OTHERWISE BELIEVE THAT ANY WARRANTIES,
              GUARANTEES OR REPRESENTATIONS HAVE BEEN MADE TO YOU, YOU HEREBY AGREE THAT SUCH
              STATEMENTS, WHETHER MADE ORALLY OR IN WRITING, ARE TO BE CONSTRUED AS MERELY
              NONBINDING EXPRESSIONS OF POLICY RATHER THAN AFFIRMATIVE REPRESENTATIONS, OBLIGATIONS,
              GUARANTEES OR WARRANTIES. IN THE EVENT OF ANY CONFLICT BETWEEN THIS SECTION 8 AND
              OTHER TERMS OR PROVISIONS OF THIS AGREEMENT, THIS SECTION SHALL BE CONSTRUED TO TAKE
              PRECEDENCE.
            </p>
            <h2>9. LIMITATION OF LIABILITY</h2>
            <p>
              IN NO EVENT SHALL NEIL PATEL DIGITAL, ITS AFFILIATES, VENDORS, SUBCONTRACTORS,
              OFFICERS, DIRECTORS, EMPLOYEES, OR ITS AGENTS BE LIABLE UNDER ANY THEORY OF LAW FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT
              NOT LIMITED TO, LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION OR DATA OR
              COSTS OF REPLACEMENT GOODS, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, OR
              THE USE OR INABILITY TO USE THE SERVICES, OR RESULTING FROM USE OF OR RELIANCE ON THE
              CONTENT, EVEN IF NEIL PATEL DIGITAL MAY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. NEIL PATEL DIGITAL, ITS AFFILIATES, VENDORS, SUBCONTRACTORS, OFFICERS,
              DIRECTORS, EMPLOYEES, AND ITS AGENTS’ ENTIRE LIABILITY UNDER THIS AGREEMENT, IF ANY,
              FOR ANY CLAIMS FOR DAMAGES RELATED TO THIS AGREEMENT WHICH ARE MADE AGAINST THEM,
              WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE, SHALL BE LIMITED TO THE
              AMOUNT OF CHARGES PAID BY YOU RELATIVE TO THE PERIOD OF OCCURRENCE OF THE EVENTS WHICH
              ARE THE BASIS OF THE CLAIMS, BUT SHALL UNDER NO CIRCUMSTANCES EXCEED THE AMOUNT EQUAL
              TO THE PAYMENTS MADE BY YOU OVER THE PRIOR TWELVE MONTHS.
            </p>
            <p>
              The Services are controlled and offered by Neil Patel Digital from its facilities in
              the United States of America. Neil Patel Digital makes no representations that the
              Services are appropriate or available for use in other locations. Those who access or
              use the Services from other jurisdictions do so at their own volition and are
              responsible for compliance with local law.
            </p>
            <h2>10. INDEMNIFICATION</h2>
            <p>
              YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS NEIL PATEL DIGITAL, ITS OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, AND SUPPLIERS FROM AND AGAINST ALL LOSSES, EXPENSES,
              DAMAGES AND COSTS, INCLUDING REASONABLE ATTORNEY’S FEES, RESULTING FROM ANY VIOLATION
              OF THIS AGREEMENT BY YOU OR ANY USER SUBMISSION POSTED OR SUBMITTED BY YOU (INCLUDING
              BUT NOT LIMITED TO INFRINGEMENT OF THIRD PARTIES’ WORLDWIDE INTELLECTUAL PROPERTY
              RIGHTS OR NEGLIGENT OR WRONGFUL CONDUCT).
            </p>
            <h2>11. WAIVER AND RELEASE</h2>
            <p>
              YOU AGREE THAT NEITHER NEIL PATEL DIGITAL NOR ITS OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, LICENSORS OR SUPPLIERS SHALL HAVE ANY LIABILITY TO YOU UNDER ANY THEORY OF
              LIABILITY OR INDEMNITY IN CONNECTION WITH YOUR USE OF THE NEIL PATEL DIGITAL SERVICES.
              YOU SPECIFICALLY ACKNOWLEDGE THAT NEIL PATEL DIGITAL SHALL NOT BE LIABLE, DIRECTLY OR
              INDIRECTLY, FOR ANY USER SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT
              OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
              ENTIRELY WITH YOU. YOU HEREBY RELEASE AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY
              HAVE AGAINST NEIL PATEL DIGITAL, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
              LICENSORS, OR SUPPLIERS (INCLUDING BUT NOT LIMITED TO CLAIMS BASED UPON THE NEGLIGENCE
              OF NEIL PATEL DIGITAL, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS OR
              SUPPLIERS) FOR LOSSES OR DAMAGES YOU SUSTAIN IN CONNECTION WITH YOUR USE OF THE
              SERVICES.
            </p>
            <h2>12. Modifications / Termination</h2>
            <p>
              12.1. In General. Our employees are not authorized to vary the terms of this
              Agreement. This Agreement may be modified only (A) by obtaining our written consent in
              a notarized agreement signed by an officer of Neil Patel Digital; or (B) as set forth
              below in Section 13.2.
            </p>
            <p>
              12.2. Periodic Revisions. You agree that we may modify the terms of this Agreement
              from time to time, and that your right to access the Services is conditioned on an
              ongoing basis with your compliance with the then-current version of this Agreement.
              Any modifications made will be effective on the date published on the website. We will
              notify you of any material revisions or modifications to this Agreement by: (1)
              posting a notice on the AdsGrader.com website for thirty (30) days following any
              revisions or modifications to this Agreement and/or by posting a notice on
              AdsGrader.com the first time that you visit AdsGrader.com following such revisions or
              modifications; or (2) through a direct communication to you by email, if you have
              provided an email address to us. You will be deemed to have agreed to the new terms
              and conditions if you continue to access the Services after having been notified of
              such revisions or modifications by email or, if you have not been notified by email,
              after the passage of thirty (30) days from the time the revised terms and conditions
              are first posted on AdsGrader.com (whichever is sooner). If you do not provide us with
              a valid email address to send you information concerning revisions or modifications to
              this Agreement, then you agree that you will not receive notice by email. If you
              change email accounts, it will be your responsibility to notify us of a new valid
              email address in order to receive email notice. We assume no responsibility for your
              failure to actually receive notice. It is your responsibility to review this page for
              possible modifications. If you do not agree with the revisions, you may choose to
              discontinue use of the Services.
            </p>
            <h2>13. Arbitration Clause</h2>
            <p>
              <strong>13.1 Individual Arbitration as Sole Remedy for Dispute Resolution.</strong>
            </p>
            <p>
              1. Any dispute, claim or controversy arising out of or relating to this Agreement or
              the breach, termination, enforcement, interpretation or validity thereof, including
              the determination of the scope or applicability of this agreement to arbitrate, shall
              be determined by final and binding individual (not class, representative, nor
              collective) arbitration in San Diego, California before one arbitrator. The language
              to be used in the arbitral proceedings will be English. The arbitration shall be
              administered by the Office of Judicial Arbitration and Mediation Service (“JAMS”)
              pursuant to its Comprehensive Arbitration Rules and Procedures or pursuant to JAMS’
              Streamlined Arbitration Rules and Procedures. JAMS’s rules governing the arbitration
              may be obtained from JAMS’s website, which currently is{' '}
              <a href="http://www.jamsadr.com/" target="_blank" rel="noopener noreferrer">
                www.jamsadr.com
              </a>
              . Judgment on the Award may be entered in any court having jurisdiction. This clause
              shall not preclude parties from seeking provisional remedies in aid of arbitration
              from a court of appropriate jurisdiction.
            </p>
            <p>
              2. If you are not a resident of the United States, then any dispute, controversy or
              claim arising out of or relating to this Agreement, including the formation,
              interpretation, breach or termination thereof, including whether the claims asserted
              are arbitrable, will be referred to and finally determined by final and binding
              individual arbitration in accordance with the JAMS International Arbitration Rules.
              The Tribunal will consist of one arbitrator. The place of arbitration will be San
              Diego, California. The language to be used in the arbitral proceedings will be
              English. Judgment upon the award rendered by the arbitrator may be entered in any
              court having jurisdiction thereof.
            </p>
            <p>
              <strong>13.2 Negotiation in Advance of Arbitration</strong>
            </p>
            <ol>
              <li>
                The parties shall attempt in good faith to resolve any dispute arising out of or
                relating to this Agreement promptly by negotiation between persons who have
                authority to settle the controversy and who are at a higher level of management than
                the persons with direct responsibility for administration of this Agreement. Any
                party may give the other party written notice of any dispute not resolved in the
                normal course of business. Within 15 days after delivery of the notice, the
                receiving party shall submit to the other a written response. The notice and
                response shall include with reasonable particularity (a) a statement of each party’s
                position and a summary of arguments supporting that position, and (b) the name and
                title of the executive who will represent that party and of any other person who
                will accompany the executive. Within 30 days after delivery of the notice, the
                executives of both parties shall meet at a mutually acceptable time and place.
              </li>
              <li>
                Unless otherwise agreed in writing by the negotiating parties, the above-described
                negotiation shall end at the close of the first meeting of persons described above
                (“First Meeting”). Such closure shall not preclude continuing or later negotiations,
                if desired. All offers, promises, conduct and statements, whether oral or written,
                made in the course of the negotiation by any of the parties, their agents,
                employees, experts and attorneys are confidential, privileged and inadmissible for
                any purpose, including impeachment, in arbitration or other proceeding involving the
                parties, provided that evidence that is otherwise admissible or discoverable shall
                not be rendered inadmissible or non-discoverable as a result of its use in the
                negotiation.
              </li>
              <li>
                At no time prior to the First Meeting shall either side initiate an arbitration or
                litigation related to this Agreement except to pursue a provisional remedy that is
                authorized by law or by JAMS Rules or by agreement of the parties. However, this
                limitation is inapplicable to a party if the other party refuses to comply with the
                requirements of Paragraph 1 above.
              </li>
            </ol>
            <p>
              <strong>13.3 Requirements for Modification or Revocation.</strong> This agreement to
              arbitrate shall survive the termination of Neil Patel Digital’s relationship with you.
              It can only be revoked or modified by a writing executed by Neil Patel Digital and you
              that specifically states an intent to revoke or modify this agreement to arbitrate.
            </p>
            <p>
              <strong>13.4 Claims Covered by Arbitration Clause.</strong> Neil Patel Digital and you
              mutually consent to the resolution by final and binding arbitration of all claims or
              controversies (collectively, the “
              <span style={{ textDecoration: 'underline' }}>Claims</span>”) that Neil Patel Digital
              may have against you or you may have against Neil Patel Digital or against its past,
              present, or future predecessors, successors, assigns, affiliates, parent and
              subsidiary companies, and joint ventures, and their respective past, present, or
              future officers, directors, employees, stockholders, representatives, managers,
              members, partners, agents, advisors, insurers, and indemnities (collectively referred
              to as the “<span>Neil Patel Digital Parties</span>”), relating to, resulting from, or
              in any way arising out of your relationship with Neil Patel Digital. The Claims
              include, but are not limited to claims for penalties, fines, claims for breach of any
              contract (express or implied); tort claims (including, but not limited to, those
              relating to reputation); claims for violation of trade secret, proprietary, or
              confidential information laws; claims for unfair business practices; and claims for
              violation of any public policy, federal, state, international, or other governmental
              law, statute, regulation, or ordinance.
            </p>
            <p>
              <strong>13.5 Required Notice of Claims and Statute of Limitations.</strong> Neil Patel
              Digital may initiate arbitration by serving or mailing a written notice to you at the
              last known address. You may initiate arbitration by serving or mailing a written
              notice to Neil Patel Digital. The written notice must specify with reasonable
              particularity the claims asserted against the other party. Notice of any claim sought
              to be arbitrated must be served within the limitations period established by
              applicable federal or state law. After demand for arbitration has been made by serving
              written notice, the party demanding arbitration shall file a demand for arbitration
              with the Office of Judicial Arbitration and Mediation Service (“JAMS”) located in San
              Diego, California.
            </p>
            <p>
              <strong>13.6 Selection of Arbitrator.</strong> Within 30 days after the commencement
              of arbitration, Neil Patel Digital shall select one person from the JAMS panel to act
              as arbitrator. The arbitrator shall serve as a neutral, independent and impartial
              arbitrator.
            </p>
            <p>
              <strong>13.7 Confidentiality.</strong> The parties shall maintain the confidential
              nature of the arbitration proceeding and the award, if any, including the hearing,
              except as may be necessary to prepare for or conduct the arbitration hearing on the
              merits, or except as may be necessary in connection with a court application for a
              preliminary remedy, a judicial challenge to an award or its enforcement, or unless
              otherwise required by law or judicial decision.
            </p>
            <p>
              <strong>13.8 Dispositive Motions.</strong> In any arbitration arising out of or
              related to this Agreement:
            </p>
            <ol>
              <li>
                Any party wishing to make a dispositive motion shall first submit a brief letter
                (not exceeding five pages) explaining why the motion has merit and why it would
                speed the proceeding and make it more cost-effective. The other side shall have a
                brief period within which to respond.
              </li>
              <li>
                Based on the letters, the arbitrator will decide whether to proceed with more
                comprehensive briefing and argument on the proposed motion.
              </li>
              <li>
                If the arbitrator decides to go forward with the motion, he/she will place page
                limits on the briefs and set an accelerated schedule for the disposition of the
                motion.
              </li>
              <li>
                Under ordinary circumstances, the pendency of such a motion will not serve to stay
                any aspect of the arbitration or adjourn any pending deadlines.
              </li>
            </ol>
            <p>
              <strong>13.9 Document Requests.</strong> In any arbitration arising out of or related
              to this Agreement, requests for documents:
            </p>
            <ol>
              <li>
                Shall be limited to documents which are directly relevant to significant issues in
                the case or to the case’s outcome;
              </li>
              <li>
                Shall be restricted in terms of time frame, subject matter and persons or entities
                to which the requests pertain; and
              </li>
              <li>
                Shall not include broad phraseology such as “all documents directly or indirectly
                related to.”
              </li>
            </ol>
            <p>
              <strong>13.10 E-Discovery.</strong> In any arbitration arising out of or related to
              this Agreement:
            </p>
            <ol>
              <li>
                There shall be production of electronic documents only from sources used in the
                ordinary course of business. Absent a showing of compelling need, no such documents
                are required to be produced from backup servers, tapes or other media.
              </li>
              <li>
                Absent a showing of compelling need, the production of electronic documents shall
                normally be made on the basis of generally available technology in a searchable
                format which is usable by the party receiving the e-documents and convenient and
                economical for the producing party. Absent a showing of compelling need, the parties
                need not produce metadata, with the exception of header fields for email
                correspondence.
              </li>
              <li>
                The description of custodians from whom electronic documents may be collected shall
                be narrowly tailored to include only those individuals whose electronic documents
                may reasonably be expected to contain evidence that is material to the dispute.
              </li>
              <li>
                Where the costs and burdens of e-discovery are disproportionate to the nature of the
                dispute or to the amount in controversy, or to the relevance of the materials
                requested, the arbitrator will either deny such requests or order disclosure on
                condition that the requesting party advance the reasonable cost of production to the
                other side, subject to the allocation of costs in the final award
                <em>.&nbsp;</em>
              </li>
            </ol>
            <p>
              <strong>13.11 Interrogatories and Requests to Admit.</strong>&nbsp;In any arbitration
              arising out of or related to this Agreement, there shall be no interrogatories or
              requests to admit.
            </p>
            <p>
              <strong>13.12 Depositions.</strong>&nbsp;In any arbitration arising out of or related
              to this Agreement, each side may take three (3) discovery depositions. Each side’s
              depositions are to consume no more than a total of fifteen (15) hours. The total
              period for the taking of depositions shall not exceed six (6) weeks.
            </p>
            <p>
              <strong>13.13 Governing Law and Arbitrator Authority.</strong>
            </p>
            <ol>
              <li>
                Except as otherwise provided herein, this Agreement and the rights of the Parties
                hereunder shall be governed by and construed in accordance with the laws of the
                state of California, exclusive of conflict or choice of law rules.
              </li>
              <li>
                The Parties acknowledge that this Agreement evidences a transaction involving
                interstate or international commerce. Notwithstanding the provision in the preceding
                paragraph with respect to applicable substantive law, any arbitration conducted
                pursuant to the terms of this Agreement shall be governed by the Federal Arbitration
                Act (<em>9 U.S.C. §§ 1-16)</em>. The arbitrator shall apply the substantive laws
                (and the law of remedies, if applicable) of the state of California, federal law, or
                international law, as applicable to the claim(s) asserted.
              </li>
              <li>
                The arbitrator shall have exclusive authority to resolve any dispute relating to the
                interpretation, applicability, enforceability, or formation of this Agreement,
                including, but not limited to, any claim that all or any part of this Agreement is
                void or voidable and issues of arbitrability of any matter herein.
              </li>
              <li>
                The arbitrator is not empowered to award punitive or exemplary damages, except where
                permitted by statute, and the parties waive any right to recover any such damages.
              </li>
            </ol>
            <p>
              <strong>13.14 Arbitration Decision.</strong> The arbitrator’s decision shall be final
              and binding and shall not be subject to appeal. The arbitrator shall issue a written
              arbitration decision revealing the essential findings and conclusions upon which the
              decision and/or award is based. Any decision or award made by the arbitrator shall be
              enforceable by a court of competent jurisdiction. The parties irrevocably stipulate to
              the confidential nature of the arbitral award and proceedings and further stipulate
              that the award and any papers of the proceedings shall only be filed with a court of
              competent jurisdiction under confidential seal and only to enforce an arbitral award
              or decision.
            </p>
            <p>
              <strong>
                13.15 Application for Emergency Injunctive and/or Other Equitable Relief.
              </strong>{' '}
              JAMS Comprehensive Rules shall apply for the appointment of an Emergency Arbitrator to
              address and decide a request for emergency relief.
            </p>
            <p>
              <strong>13.16 WAIVER OF REPRESENTATIVE/CLASS ACTION PROCEEDINGS.</strong> BY
              REGISTERING TO RECEIVE ANY PRODUCTS OR SERVICES FROM US OR PROVIDING YOUR INFORMATION
              TO US IN, YOU KNOWINGLY AND VOLUNTARILY AGREE TO BRING ANY CLAIMS (LAWSUITS) AGAINST
              NEIL PATEL DIGITAL IN YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF, CLASS MEMBER,
              GROUP, OR REPRESENTATIVE IN ANY PURPORTED REPRESENTATIVE, COLLECTIVE, OR CLASS ACTION.
              YOU AGREE TO WAIVE ANY RIGHT TO PARTICIPATE IN ANY REPRESENTATIVE, COLLECTIVE, OR
              CLASS ACTION PROCEEDING (A LAWSUIT WHERE YOU SUE AS A GROUP RATHER THAN BEING THE ONLY
              PLAINTIFF) RELATED TO ANY CLAIMS GOVERNED BY THIS PRIVACY POLICY. YOU FURTHER AGREE
              THAT THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE INDIVIDUAL’S CLAIMS, AND MAY NOT
              OTHERWISE PRESIDE OVER ANY FORM OF REPRESENTATIVE, COLLECTIVE, OR CLASS ACTION
              PROCEEDING, INCLUDING, BUT NOT LIMITED TO, ANY REPRESENTATIVE ACTION UNDER CALIFORNIA
              BUSINESS AND PROFESSIONS CODE SECTIONS 17200{' '}
              <strong>
                <em>ET SEQ.</em>
              </strong>
            </p>
            <p>
              <strong>13.17 WAIVER OF JURY TRIAL/EXCLUSIVE REMEDY.</strong> THERE IS NO JUDGE OR
              JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION REWARD IS LIMITED.&nbsp; BY
              AGREEING TO ARBITRATION, NEIL PATEL DIGITAL AND YOU ARE AGREEING TO WAIVE ANY
              CONSTITUTIONAL RIGHT TO A JURY OR COURT TRIAL OF COVERED CLAIMS. NEIL PATEL DIGITAL
              AND YOU FURTHER AGREE THAT IF FOR ANY REASON ANY SUCH DISPUTE OR CLAIM PROCEEDS IN
              COURT RATHER THAN ARBITRATION, THE PARTIES WAIVE ANY CONSTITUTIONAL&nbsp; RIGHT TO A
              JURY OR COURT TRIAL<strong>.</strong>
            </p>
            <p>
              <strong>13.18 Arbitration Fees and Costs.</strong> Each party shall advance its own
              costs and expenses in any such arbitration and one-half (1/2) of the arbitrator’s fees
              and costs, however, the arbitrator shall award attorneys’ fees and costs to Neil Patel
              Digital if Neil Patel Digital is the prevailing party. Any decision or award of such
              arbitration proceeding shall be confidential and may be made public only with the
              prior written consent of both you and Neil Patel Digital.
            </p>
            <p>
              <strong>13.19 Severability (Arbitration Clause).</strong> Neil Patel Digital and you
              agree and acknowledge that if any section, subsection, sentence, clause, or phrase
              contained in this Arbitration Clause is found to be invalid, unenforceable, or
              otherwise inoperative, such decision shall not affect the validity of the remaining
              portion(s) of this Arbitration Clause.
            </p>
            <p>
              13.20. Small Claims Matters Are Excluded from Arbitration Requirement. Notwithstanding
              the foregoing, either of us may bring a qualifying claim in small claims court.
            </p>
            <h2>14. Force Majeure</h2>
            <p>
              Neither Party is liable for any default, delay, or failure in the performance of any
              of its obligations under these Terms of Services (other than failure to make payments
              when due) caused directly or indirectly by circumstances beyond such Party’s
              reasonable control, including, without limitation, fire, flood, acts of God, labor
              disputes, acts of war or terrorism, pandemic, interruptions of transportation or
              communications, supply shortages or the failure of any third party to perform any
              commitment relative to the production or delivery of any equipment or material
              required for such Party to perform its obligations hereunder.
            </p>
            <p>
              Initially, the force majeure event or unforeseeable circumstance shall have the effect
              of suspending performance hereof.
            </p>
            <p>
              If the force majeure event or unforeseeable circumstance lasts for more than three (3)
              months and unless the Parties agree otherwise, these Terms of Service shall be
              terminated automatically, without the need to seek a court ruling, serve formal notice
              or provide prior notice. In such event, the Parties shall not be held liable for the
              total or partial non-performance of their obligations hereunder.
            </p>
            <h2>15. Severability</h2>
            <p>
              If any provision of this Agreement shall be deemed invalid, illegal, or unenforceable
              , this Agreement shall be construed as though such invalid, illegal, or unenforceable
              provision was not included. The remainder of this Agreement shall nevertheless remain
              in full force and effect.
            </p>
            <h2>16. Notices</h2>
            <p>
              Neil Patel Digital may deliver notice to you under this Agreement by means of
              electronic mail, a general or specific notice on the Services, a communication to your
              Neil Patel Digital account or by written communication delivered by first class U.S.
              mail to your address on record. You may give notice to Neil Patel Digital at any time
              via electronic mail to the following address:{' '}
              <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>.
            </p>
            <h2>17. Miscellaneous</h2>
            <p>
              Neil Patel Digital may terminate or suspend access to the Services immediately,
              without prior notice or liability, if you breach this Agreement or for any other
              reason. The failure of either Party to exercise in any respect any right provided for
              herein shall not be deemed a waiver of any further rights hereunder. This Agreement is
              not assignable, transferable or sub-licensable by you except with Neil Patel Digital’s
              prior written consent. No agency, partnership, joint venture, or employment is created
              as a result of this Agreement and you do not have any authority of any kind to bind
              Neil Patel Digital in any respect whatsoever. The headings of the sections contained
              in this Agreement are for convenience only and shall not be deemed to control of
              affect the meaning or construction of any provision of this Agreement.
            </p>
            <h2>18. Complete Agreement</h2>
            <p>
              This Agreement, which incorporates Neil Patel Digital’s Privacy Policy and Copyright
              or Intellectual Property Infringement Notification Policy, constitutes the entire
              agreement between you and us relating to your access to and use of the Services and
              supersedes any prior or contemporaneous written or oral agreements, communications or
              other understandings relating to the subject matter hereof (with the sole exception of
              any separate license agreements for Neil Patel Digital Advanced Consulting or Neil
              Patel Digital Advanced Consulting Content to which you are a party or beneficiary,
              which shall remain in full force and effect and which shall supplement the terms of
              this Agreement). This Agreement shall not be modified, either expressly or by
              implication, except as set forth above in Section 12. The provisions of this Agreement
              supersede all contemporaneous oral agreements and all prior oral and written
              communications and understandings of the Parties with respect to the subject matter of
              this Agreement.
            </p>
          </div>
        </article>
      </Col>
    </Row>

    <TermsFooter />
  </div>
)

export default TermsPage
