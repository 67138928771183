import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'
import styles from './quote.module.scss'
import Text from '../Text/text'
import ProfileImg from '../../images/neil-face.png'

const Quote = ({ children }) => (
  <div className={styles.container}>
    <Avatar className={styles.image} src={ProfileImg} />
    <div className={styles.textDiv}>{children}</div>
  </div>
)

Quote.propTypes = {
  children: PropTypes.node,
}

Quote.defaultProps = {
  children: (
    <Text size={16} color="black">
      We were able to analyze <strong>9 active search campaigns</strong>, with an average number of
      groups per campaign of <strong>4.3</strong>. Our ad analytics intelligence formulated{' '}
      <strong>39 `&quot;Active Ad Groups`&quot;</strong> considerations and we were able to evaluate
      all of its <strong>911 keywords.</strong> We found that you have an
      <strong style={{ color: '#F26234' }}> 89% chance of improving your ad performance.</strong>
    </Text>
  ),
}

export default Quote
