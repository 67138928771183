import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@components'
import styles from './styles.module.scss'

const CardInfo = ({ children }) => (
  <div className={styles.container}>
    <Text size={20} color="primary" isBold>
      {children}
    </Text>
  </div>
)

CardInfo.propTypes = {
  children: PropTypes.string.isRequired,
}

export default CardInfo
