import React from 'react'
import { Tooltip } from 'antd'

import './tooltip.css'

const overlayStyle = {
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: 'green',
  borderRadius: '4px',
  height: 'fit-content',
  width: 'fit-content',
  display: 'flex',
  margin: 'auto',
  alignItems: 'center',
}

const CustomTooltip = ({ text, children }) => (
  <Tooltip
    title={text}
    color="#F16434"
    overlayInnerStyle={overlayStyle}
    overlayClassName="customTooltip"
  >
    {children}
  </Tooltip>
)

export default CustomTooltip
