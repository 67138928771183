import React, { useEffect, useState } from 'react'

import { Select, Input } from 'antd'
// import Search from 'antd/lib/transfer/search'
import { Text } from '@components'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_LEARNING_SEARCH_STRING,
  UPDATE_LEARNING_SELECTED_VALUE,
  UPDATE_ACTIVE_BUTTON_STATE,
} from '@utils/constants'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SearchIcon from '@images/Learning/Search.png'

const Navbar = ({
  fromExploreMoreFlow,
  title,
  onSelectChange,
  handleLearningRedirect,
  isSearchDisabled,
  // searchValue,
  handleSearchInputChangess,
}) => {
  // const [selectedValue, setSelectedValue] = useState('Ad Level: Select Level')
  const [searchValue, setSearchValue] = useState('')
  const [isSearchFeature, setIsSearchFeature] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const searchString = useSelector(state => state?.learningCenter?.searchString)
  const selectedValue = useSelector(state => state?.learningCenter?.selectedValue)
  const currentPath = window.location.pathname
  const onSearch = () => {
    dispatch({ type: UPDATE_LEARNING_SEARCH_STRING, payload: searchValue })
    dispatch({ type: UPDATE_ACTIVE_BUTTON_STATE, payload: true })
    if (searchValue) {
      history.push('/dashboard/learning-center/search-feature')
    }
  }
  useEffect(() => {
    if (searchString.length === 0) {
      setSearchValue('')
    }
    // eslint-disable-next-line
  }, [searchString])

  useEffect(() => {
    if (searchString) {
      setSearchValue(searchString)
    }
  }, [searchString])

  useEffect(() => {
    if (currentPath === '/dashboard/learning-center/search-feature') {
      setIsSearchFeature(true)
    }
  }, [currentPath])

  const handleSearchInputChange = event => {
    dispatch({ type: UPDATE_ACTIVE_BUTTON_STATE, payload: false })
    // dispatch({ type: UPDATE_LEARNING_SEARCH_STRING, payload: event.target.value })
    setSearchValue(event.target.value)
    // onSearchChange(searchValue)
    // handleSearchValue(event.target.value)
    // handleSearchInputChangess(event.target.value)
  }
  const handleSelectChange = value => {
    // setSelectedValue(value)
    dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: value })
    onSelectChange(dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: value }))
    onSelectChange(value)
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Text isbold style={{ color: '#F16434', marginTop: '8px', fontSize: '22px' }}>
        {title === 'Learning Center' ? (
          <Text
            size={22}
            onClick={handleLearningRedirect}
            cursor
            isbold
            style={{ color: '#F16434', marginTop: '8px' }}
          >
            <strong>{title}</strong>
          </Text>
        ) : (
          <strong>{title}</strong>
        )}
      </Text>

      <div style={{ display: 'flex' }}>
        {!fromExploreMoreFlow ? (
          <div style={{ width: '292px' }}>
            {!isSearchDisabled ? (
              <div style={{ display: 'flex' }}>
                <Input
                  allowClear
                  type="text"
                  style={{ height: '48px' }}
                  value={searchValue}
                  onChange={e => handleSearchInputChange(e)}
                  onPressEnter={onSearch}
                />
                <button
                  type="submit"
                  style={{
                    width: '106px',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '1.12px',
                    background: '#F16434',
                    color: '#FFF',
                    height: '48px',
                    border: '1px solid #F16434',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                  }}
                  onClick={onSearch}
                >
                  Search
                </button>
              </div>
            ) : (
              // <Input
              //   placeholder="Search"
              //   size="large"
              //   // allowClear
              //   onPressEnter={onSearch}
              //   onChange={e => handleSearchInputChange(e)}
              //   value={searchString}
              //   prefix={
              //     // <SearchOutlined style={{ width: 24, height: 24, color: '#8F8F8F' }} />
              //     <img src={SearchIcon} height={24} width={24} alt="search-icon" />
              //   }
              //   style={{
              //     width: 292,
              //     height: 48,
              //     background: '#fff',
              //     fontSize: 14,
              //     color: '#939395',
              //   }}
              // />
              <Input disabled />
            )}
          </div>
        ) : (
          ''
        )}
        {currentPath === '/dashboard/learning-center' ? (
          <div style={{ position: 'relative' }} id="area">
            <Select
              defaultValue="Ad Level: All"
              style={{
                fontSize: 14,
                fontWeight: 400,
                width: '202px',
                height: 44,
                marginLeft: '16px',
                borderRadius: '2px',
                color: '#202020',
              }}
              dropdownClassName="learning-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              onChange={handleSelectChange}
              options={[
                {
                  value: '',
                  label: 'All',
                },
                {
                  value: '1',
                  label: 'Beginner',
                },
                {
                  value: '2',
                  label: 'Intermediate',
                },
                {
                  value: '3',
                  label: 'Advanced',
                },
              ]}
            />
          </div>
        ) : (
          <div style={{ position: 'relative' }} id="area">
            <Select
              defaultValue={selectedValue.length === 0 ? 'Ad Level: All' : selectedValue}
              style={{
                fontSize: 14,
                fontWeight: 400,
                width: '202px',
                height: 44,
                marginLeft: '16px',
                borderRadius: '2px',
                color: '#202020',
              }}
              dropdownClassName="learning-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              onChange={handleSelectChange}
              options={[
                {
                  value: '',
                  label: 'All',
                },
                {
                  value: '1',
                  label: 'Beginner',
                },
                {
                  value: '2',
                  label: 'Intermediate',
                },
                {
                  value: '3',
                  label: 'Advanced',
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
