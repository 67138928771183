import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Select as AntDSelect } from 'antd'
import { updateSelectedCustomer } from '../../store/actions'
import styles from './select.module.css'

const { Option } = AntDSelect

const Select = ({
  options,
  onSelect,
  getOptionLabel,
  passCustomKey,
  defaultValue,
  startAdornment,
  ...props
}) => {
  const dispatch = useDispatch()
  const handleChange = (value, key) => {
    if (onSelect) onSelect(value)
    const customer = options.find(obj => obj.id === value)
    dispatch(updateSelectedCustomer(customer))
  }

  return (
    <AntDSelect
      // eslint-disable-next-line
      defaultValue={defaultValue ? defaultValue : { value: '', label: 'Please Select' }}
      style={{
        width: 100,
      }}
      onChange={handleChange}
      {...props}
    >
      {options?.map((option, index) => (
        <Option
          key={passCustomKey ? passCustomKey(option, index) : option?.value}
          className={styles.option}
          value={option.id}
        >
          {startAdornment && <div className={styles.startAdornment}>{startAdornment(option)}</div>}

          {
            // eslint-disable-next-line
            getOptionLabel
              ? getOptionLabel(option)
              : option.descriptive_name
              ? option.descriptive_name
              : option.id
          }
        </Option>
      ))}
    </AntDSelect>
  )
}

export default Select
