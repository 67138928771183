/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Popover, Space, Spin, Tooltip } from 'antd'
import { EXTRA_INSIGHTS_CLICK, LS_KEY } from 'utils/constants'
import tokenService from 'services/tokenService'
import { clearLocalStorage } from 'utils/helperFunctions'
import { notify } from 'utils/notify'
import LenguegeSelect from 'components/LenguegeSelect'
import UnderlineTextButton from 'components/UnderlineTextButton'
import CustomersSelect from '@components/Select/customersSelect'
import ReportHeader from 'components/Report/ReportHeader'
import { RequestService } from 'services/requests'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { report } from 'pages/report-demo/demo-data'
import { fetchRoleInfo } from '@services/fetchRoleInfo'
import Text from '@components/Text/text'
import {
  logoutUser,
  updateGenerateReport,
  updateReport,
  updateReportDashboard,
  updateRole,
  updateUserData,
  addFilteredChartReport,
  paginatedReports,
  paginatedNext,
} from '../../store/actions'
import DropdownMenu from './DropdownMenu'
import { fetchReports } from '../../services/fetchReports'
import { sortByCreatedAt } from '../../utils/helperFunctions'
import exportPdf from '../../images/exportPdf.svg'

import styles from './navbar.module.css'

const unAuthenticatedRoute = pathname => {
  const routes = ['start', 'select-account', 'report-generating']
  return routes.some(route => pathname.includes(route))
}

const generateRoles = ['Admin', 'Account Manager', 'Owner']

const Navbar = () => {
  const customer = useSelector(state => state.customer)
  const errorInCustomer = useSelector(state => state.errorCustomer)
  const isCurrentReport = useSelector(state => state?.currentReportReducer?.currentReport)
  const [graphReports, setGraphReports] = useState([])

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [customers, setCustomers] = useState([])
  const [selectedUserId, setSelectedUserId] = useState(customer?.id ?? '')
  const [currentSection, setCurrentSection] = useState(null)
  const [reports, setReports] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentReport, setCurrentReport] = useState(null)
  const [showGenerateButton, setShowGenerateButton] = useState(false)
  const [selectedTab, setSelectedTab] = useState('')
  const [loadError, setLoadError] = useState(false)
  const [generateReportLoader, setGenerateReportLoader] = useState(false)
  const [isExportPdf, setIsExportPdf] = useState(false)

  const user = useSelector(state => state?.user)
  const profilePicFromLocal = localStorage.getItem('profilePic')
  const profilePicFromStore = user?.user?.profile?.profile_picture
  const dispatch = useDispatch()
  const localStorageData = JSON.parse(localStorage.getItem(LS_KEY))
  const token = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const sampleReport = useSelector(state => state?.sampleReport?.sampleReport)

  useEffect(() => {
    if (localStorageData && Object.keys(user).length <= 1) {
      dispatch(updateUserData(localStorageData))
    }
    // verify refresh token validity
    const refreshToken = tokenService.getLocalRefreshToken()
    if (refreshToken) {
      const isTokenValid = tokenService.checkRefreshTokenValidity(refreshToken)
      if (!isTokenValid) {
        clearLocalStorage()
        notify(t('notification.tokenExpired'), 'error')
        history.push('/')
      }
    }
    // eslint-disable-next-line
  }, [location?.pathname, customers])
  useEffect(() => {
    const accessToken = user.acccess_token ?? localStorageData?.access_token
    if (accessToken) {
      setIsAuthenticated(true)
    } else if (!accessToken) {
      setIsAuthenticated(false)
      clearLocalStorage()
      // history.push('/')
    }
    // eslint-disable-next-line
  }, [user, localStorageData])
  // API call for graphs
  const hanldeGraphsApi = async id => {
    try {
      const response = await RequestService.get(`/graph-reports/?customer_id=${id}`)
      const sortedData = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )
      setGraphReports(sortedData)
      dispatch(paginatedReports(sortedData))
    } catch (e) {
      // console.log('error', e)
    }
  }
  useEffect(() => {
    if (token && customer?.id) {
      hanldeGraphsApi(customer.id)
    }
    // eslint-disable-next-line
  }, [token, history, customer])
  // End of API call for graphs
  useEffect(() => {
    if (token && customer?.id) {
      setIsLoading(true)
      setReports([])
      setCurrentReport(null)
      setCurrentSection(null)
      fetchReports(customer?.id)
        .then(res => {
          setShowGenerateButton(false)
          setIsLoading(false)
          if (res.status === 200) {
            const sortedData = sortByCreatedAt(res?.data?.results)
            setReports(sortedData)
            dispatch(paginatedNext(res?.data?.next))
            setSelectedTab('')
            setLoadError(false)
            if (sortedData?.length) {
              fetchRoleInfo(customer?.id ? customer?.id : selectedUserId).then(r => {
                if (generateRoles.includes(r?.data?.role?.name) || !r?.data?.is_invited_user) {
                  setShowGenerateButton(true)
                }
                // localStorage.setItem('role', JSON.stringify(r?.data?.role?.name))
                dispatch(
                  updateRole(
                    r?.data?.role?.name ? !generateRoles.includes(r?.data?.role?.name) : false
                  )
                )
              })
            }
          } else if (res.status >= 500) {
            setSelectedTab('')
            setLoadError(true)
            // notify(`Your Ad's account does not have enough data.`, 'error')
            notify(t('notification.unableToLoadData'), 'error')
          } else {
            // console.log('reports error~: ', res)
          }
        })
        .catch(error => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [token, history, customer])

  useEffect(() => {
    if (reports?.length) {
      setSelectedTab(reports[0]?.id)
      setCurrentReport(reports[0])
      dispatch(updateReport(reports[0]))
    }
    // eslint-disable-next-line
  }, [reports])
  useEffect(() => {
    if (currentReport) {
      dispatch(updateReport(currentReport))
      dispatch(updateReportDashboard(currentReport?.unique_id))
    }
    // eslint-disable-next-line
  }, [currentReport])

  const handleSelectUser = customerId => {
    history.push('/')
    setSelectedUserId(customerId)
  }
  const errorCustomer = JSON.parse(localStorage.getItem('errorCustomer'))

  const handleMenuClick = async () => {
    dispatch(logoutUser())
    setCustomers([])
    history.push('/')
    localStorage.removeItem('customer')
    localStorage.removeItem('report-generating')
    localStorage.removeItem('selectAccount')
    localStorage.removeItem('loginThroughWebLink')
    localStorage.removeItem('inviteFrom')
    localStorage.removeItem('errorCustomer')
    localStorage.removeItem('url')
    localStorage.removeItem('customerslength')
    localStorage.removeItem('isCustomers')
    localStorage.removeItem('showPopover')
    localStorage.removeItem('showQuestionnaire')
    localStorage.removeItem('leadForm')
    localStorage.removeItem('showForm')
    localStorage.removeItem('term')
    localStorage.removeItem('content')
    localStorage.removeItem('source')
    localStorage.removeItem('?medium')
    localStorage.removeItem('campaign')
    localStorage.removeItem('_gl')
    localStorage.removeItem('ads_grader_saved_utms')
    localStorage.removeItem('isSearchFeature')
    localStorage.removeItem('profilePic')
    localStorage.removeItem('lead_save')
    localStorage.removeItem('share_Baord_Link')
    localStorage.removeItem('public_status')
    localStorage.removeItem('board_detail_id')
  }

  const handleOpenConsulting = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      // Base URL with common parameters
      let url =
        'https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting'
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `&cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
      }

      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log('🚀 ~ getBenchmarks ~ e:', e)
    }
  }
  useEffect(() => {
    if (sampleReport) {
      setCurrentReport(report?.data)
    }
  }, [sampleReport])
  const generatePDF = () => {
    function convertElementByIdToPdf(elementId) {
      const htmlNode = document.getElementById(elementId)
      if (!htmlNode) {
        console.error(`Element with ID "${elementId}" not found.`)
        return
      }
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        window.scrollTo(0, 0)
        html2canvas(htmlNode, {
          scale: 1,
          allowTaint: true,
          useCORS: true,
          logging: false,
          height: htmlNode.scrollHeight,
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          windowWidth: document.documentElement.offsetWidth,
          windowHeight: htmlNode.scrollHeight,
        })
          .then(canvas => {
            // eslint-disable-next-line
            let pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height])
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            let headerContent = ''

            // headerContent += pdf
            //   .setFontSize(24)
            //   .setFont('Helvetica-Black')
            //   .text('Ads Grader', pdfWidth / 25, 40)
            // headerContent += pdf.setFontSize(12).text('by', pdfWidth / 15, 63)
            // const text = 'NP'
            // const fontSize = 14
            // const textWidth = (pdf.getStringUnitWidth(text) * fontSize) / pdf.internal.scaleFactor

            // headerContent += pdf
            //   .setFontSize(fontSize)
            //   .setFont('Helvetica-Bold')
            //   .setTextColor(255, 94, 41)
            //   .text(text, pdfWidth / 13, 63)

            // pdf
            //   .setLineWidth(2) // Border width
            //   .setDrawColor(255, 94, 41) // Border color
            //   .rect(pdfWidth / 13.2, 60 - 12, textWidth + 4, 20) // Adjust dimensions as needed
            //   .stroke() // Draw the border

            // headerContent += pdf
            //   .setFontSize(16)
            //   .setTextColor('#26282c')
            //   .text('digital', pdfWidth / 11, 63)

            // Use the function to create a clickable link in the PDF
            headerContent += pdf.textWithLink(
              'adsgrader.com',
              pdfWidth - 120,
              36,
              {
                url: 'https://adsgrader.com',
              },
              null,
              'left'
            )
            const marginLeft = 20
            const header = document.getElementById('header')
            pdf.html(header, {
              x: marginLeft,
              y: 20,
              callback: () => {
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 100, pdfWidth, pdfHeight - 80)
                const blobUrl = pdf.output('bloburl')
                setIsExportPdf(false)
                notify(t('notification.exportPdf'), 'success')
                resolve(blobUrl)
              },
            })
          })
          .catch(error => {
            reject(error)
            setIsExportPdf(false)
          })
      })
    }
    setIsExportPdf(true)
    const elementId = sampleReport ? 'report_sample' : 'report'
    convertElementByIdToPdf(elementId)
      .then(blobUrl => {
        const link = document.createElement('a')
        link.href = blobUrl
        const dateTime = moment(currentReport.created_at).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        const accountID = selectedUserId
        const accountName = currentReport?.customer_descriptive_name
          ? currentReport?.customer_descriptive_name
          : 'Sample-Report'
        link.download = `AdsGrader-${accountName}-${accountID}-${dateTime}.pdf`
        link.click()
      })
      .catch(error => {
        console.error('Error converting HTML element to PDF:', error)
        setIsExportPdf(false)
      })
  }

  // ...........................................................................new report generating ..................................................
  // eslint-disable-next-line consistent-return
  const retrieveReport = async () => {
    dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: true })
    setGenerateReportLoader(true)
    setIsExportPdf(true)
    dispatch(updateGenerateReport(true))
    try {
      const response = await RequestService.post('/reports/', {
        customer_id: String(customer.id),
        parent_customer_id: customer.resource_name.split('/')[1],
      })
      setCurrentReport(response?.data ? response?.data : report?.data)
      setReports([response?.data, ...reports])
      setGenerateReportLoader(false)
      setIsExportPdf(false)
      // setSelectedUserId(response?.customer_id)
      dispatch(updateGenerateReport(false))
      notify(t('notification.reportGenerated'), 'success')
      history.push('/')
    } catch (e) {
      // eslint-disable-next-line no-console
      notify(t('notification.noEnoughReportData'), 'error')
      setGenerateReportLoader(false)
      setIsExportPdf(false)
      dispatch(updateGenerateReport(false))
    }
  }
  const handleRedirect = () => {
    history.push('/')
  }
  // ...........................................................................new report generating ..................................................
  const [weeklyGroups, setWeeklyGroups] = useState([])
  const groupReportsByWeek = () => {
    // Sort reports by created_at date
    const sortedReports = [...graphReports].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    )

    // Initialize variables
    const groups = []
    const currentGroupStartDate = new Date(sortedReports[0].created_at)

    // Helper function to get the end of the week (Sunday) for a given start date
    const getWeekEndDate = startDate => {
      const endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + 7)
      return endDate
    }

    // Group reports by week
    sortedReports.forEach(reporties => {
      const reportDate = new Date(reporties.created_at)

      // If the report date is beyond the current week's end date, move to the next week
      while (reportDate > getWeekEndDate(currentGroupStartDate)) {
        currentGroupStartDate.setDate(currentGroupStartDate.getDate() + 7)
      }

      // Find the group for the current week or create a new one
      let currentGroup = groups.find(
        group => group.week_start === currentGroupStartDate.toISOString()
      )

      if (!currentGroup) {
        currentGroup = {
          week_start: currentGroupStartDate.toISOString(),
          week_end: getWeekEndDate(currentGroupStartDate).toISOString(),
          reports: [],
        }
        groups.push(currentGroup)
      }

      // Add the report to the appropriate group
      currentGroup.reports.push(reporties)
    })

    return groups
  }

  useEffect(() => {
    if (graphReports.length > 0) {
      setWeeklyGroups([])
      const groupedReports = groupReportsByWeek()
      setWeeklyGroups(groupedReports)
    }
    // eslint-disable-next-line
  }, [graphReports])
  // eslint-disable-next-line
  const getLatestReportFromGroups = () => {
    return weeklyGroups.map(group => {
      // Find the report with the maximum created_at timestamp within the group
      // eslint-disable-next-line
      const latestReport = group.reports.reduce((maxReport, currentReporties) => {
        return currentReporties.created_at > maxReport.created_at ? currentReporties : maxReport
      }, group.reports[0]) // Start with the first report in the group

      return { latestReport }
    })
  }
  useEffect(() => {
    if (weeklyGroups.length > 0) {
      const latestReports = getLatestReportFromGroups()
      // eslint-disable-next-line
      const sortedLatestReports = latestReports.sort((a, b) => {
        return new Date(b.latestReport.created_at) - new Date(a.latestReport.created_at)
      })
      const topEightReports = sortedLatestReports.slice(0, 8)

      dispatch(addFilteredChartReport(topEightReports))
      // console.log('🚀 ~ sortedLatestReports ~ sortedLatestReports:', sortedLatestReports)
    }
    // eslint-disable-next-line
  }, [weeklyGroups])
  return (
    <>
      <div className={styles.navbarContainer}>
        <div className={styles.logoSection}>
          <div className={styles.logoSection2}>
            <div className={styles.logo} id="header">
              {
                // eslint-disable-next-line
                !localStorageData?.access_token ? (
                  <Link to="/">
                    <h3 className={styles.logoText}>Ads Grader</h3>
                  </Link>
                ) : // eslint-disable-next-line
                window.location.href.includes('/select-sample-account') ? (
                  <Link to="/select-sample-account">
                    <h3 className={styles.logoText}>Ads Grader</h3>
                  </Link>
                ) : window.location.href.includes('/lead-form') ? (
                  <Link to="/lead-form">
                    <h3 className={styles.logoText}>Ads Grader</h3>
                  </Link>
                ) : (
                  <Link to="/dashboard">
                    <h3 className={styles.logoText}>Ads Grader</h3>
                  </Link>
                )
              }
              <p className={`${styles.bold} ${styles.logoHelperText}`}>
                by <span className={styles.NP}>NP</span> digital
              </p>
            </div>
            <div className={styles.spanLine} />

            <span>
              <LenguegeSelect color="black" />
            </span>
          </div>
          <div className={styles.barSection}>
            {isAuthenticated &&
              !unAuthenticatedRoute(location?.pathname) &&
              location.pathname.startsWith('/dashboard') && (
                <CustomersSelect
                  selectedUserId={selectedUserId}
                  setSelectedUserId={setSelectedUserId}
                  handleSelectUser={handleSelectUser}
                  rightMenu
                  setCurrentSection={setCurrentSection}
                  setReports={setReports}
                />
              )}
            {isAuthenticated &&
              !unAuthenticatedRoute(location?.pathname) &&
              // !isLoading &&
              location.pathname.startsWith('/dashboard') &&
              !isCurrentReport &&
              (reports && currentReport ? (
                !isLoading &&
                !sampleReport &&
                location.pathname.startsWith('/dashboard') && (
                  <ReportHeader
                    reports={reports}
                    setCurrentReport={setCurrentReport}
                    currentReport={currentReport}
                    isNavbar="true"
                  />
                )
              ) : (
                <div style={{ minWidth: '50px' }}>
                  <Spin />
                </div>
              ))}
            <div className={styles.generate_button}>
              {generateReportLoader ? (
                <Spin />
              ) : (
                isAuthenticated &&
                !unAuthenticatedRoute(location?.pathname) &&
                location.pathname.startsWith('/dashboard') &&
                JSON.parse(localStorage.getItem('customerslength')) &&
                JSON.parse(localStorage.getItem('isCustomers')) && (
                  <Button onClick={retrieveReport} className={styles.generate_report_button}>
                    {t('generateNewReport')}
                  </Button>
                )
              )}
              <div>
                {isAuthenticated &&
                  !unAuthenticatedRoute(location?.pathname) &&
                  !isLoading &&
                  location.pathname.startsWith('/dashboard') &&
                  !sampleReport && (
                    <div className={styles.exportpdf_button}>
                      {isExportPdf ? (
                        <Spin />
                      ) : (
                        <Tooltip title="Export to PDF" trigger="hover" color="#333333">
                          {/* eslint-disable-next-line */}
                          <img
                            src={exportPdf}
                            alt="export pdf logo"
                            onClick={generatePDF}
                            height="100%"
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
              </div>
              <div>
                {isAuthenticated &&
                  !unAuthenticatedRoute(location?.pathname) &&
                  location.pathname.startsWith('/dashboard') &&
                  sampleReport && (
                    <div className={`${styles.exportpdf_button} ${styles.exportpdf_button_sample}`}>
                      {isExportPdf ? (
                        <Spin />
                      ) : (
                        <Tooltip title="Export to PDF" trigger="hover" color="#333333">
                          {/* eslint-disable-next-line */}
                          <img
                            src={exportPdf}
                            alt="export pdf logo"
                            onClick={generatePDF}
                            height="100%"
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        {isAuthenticated && !unAuthenticatedRoute(location?.pathname) && (
          <div className={styles.buttonSection}>
            <button
              className={styles.buttonSectionButton}
              type="button"
              onClick={() => window.open('https://adsgrader.feedbear.com/', '_blank')}
            >
              {t('featureSuggestButton')}
            </button>
            {location.pathname.startsWith('/dashboard') && (
              // eslint-disable-next-line
              // <a
              //   href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-top-consulting&utm_term=tool-consulting"
              //   target="_blank"
              //   rel="noreferer"
              // >
              <button
                className={styles.buttonSectionButton}
                type="button"
                onClick={handleOpenConsulting}
              >
                {t('consultingButtonText')}
              </button>
              // </a>
            )}
            {location?.pathname !== '/report-dashboard' &&
              location?.pathname !== '/select-sample-account' &&
              location?.pathname !== '/lead-form' &&
              isAuthenticated && (
                <div className={styles.DropdownMenuContainer}>
                  <DropdownMenu setCustomers={setCustomers}>
                    {profilePicFromStore ? (
                      <div className={styles.avatar}>
                        <img
                          src={profilePicFromLocal ?? profilePicFromStore}
                          width="100%"
                          height="100%"
                          alt={<UserOutlined />}
                        />
                      </div>
                    ) : (
                      <Avatar size={30} icon={<UserOutlined />} />
                    )}
                  </DropdownMenu>
                </div>
              )}
          </div>
        )}
        {!isAuthenticated && !unAuthenticatedRoute(location?.pathname) && (
          <div className={styles.authButtons}>
            {/* <Button
            type="primary"
            size="small"
            className={styles.buttonSmall}
            onClick={() => history.push('/register')}
          >
            Register
          </Button> */}
            {location?.pathname !== '/login' && location?.pathname !== '/login/' && (
              <Button
                type="primary"
                size="small"
                className={styles.buttonSmall}
                onClick={() => history.push('/login')}
              >
                {t('loginText')}
              </Button>
            )}
          </div>
        )}
        {/* start of this code will be un commented in staging */}
        {/* .....................log out ..................... */}
        {isAuthenticated && unAuthenticatedRoute(location?.pathname) && (
          <div className={styles.authButtons}>
            {/* <Button
            type="primary"
            size="small"
            className={styles.buttonSmall}
            onClick={() => history.push('/register')}
          >
            Register
          </Button> */}

            {location?.pathname === '/select-account' && (
              <Button
                type="primary"
                size="small"
                className={styles.buttonSmall}
                onClick={handleMenuClick}
              >
                {t('logoutText')}
              </Button>
            )}
          </div>
        )}
        {/* ENd of of this code will be un commented in staging  */}

        {/* start of  This code is temporary ..only to send in master for  production.. */}
        {/* {isAuthenticated &&
        unAuthenticatedRoute(location?.pathname) &&
        errorCustomer &&
        errorInCustomer && (
          <div className={styles.authButtons}> */}
        {/* <Button
            type="primary"
            size="small"
            className={styles.buttonSmall}
            onClick={() => history.push('/register')}
          >
            Register
          </Button> */}
        {/* {location?.pathname === '/select-account' && (errorCustomer || errorInCustomer) && (
              <Button
                type="primary"
                size="small"
                className={styles.buttonSmall}
                onClick={handleMenuClick}
              >
                {t('logoutText')}
              </Button>
            )}
          </div>
        )} */}
        {/* End of This code is temporary ..only to send in master for  production.. */}

        {location?.pathname === '/report-dashboard' && (
          <div className={styles.authButtons}>
            <Button
              type="primary"
              size="small"
              className={styles.buttonSmall}
              onClick={handleMenuClick}
            >
              {t('logoutText')}
            </Button>
          </div>
        )}
        {window.location.href.includes('/select-sample-account') && (
          <div className={styles.authButtons}>
            <Button
              type="primary"
              size="small"
              className={styles.buttonSmall}
              onClick={handleMenuClick}
            >
              {t('logoutText')}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default Navbar
