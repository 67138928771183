import React, { useState } from 'react'
import { Collapse } from 'antd'
import styles from './SideDrawerCollapse.module.css'

const { Panel } = Collapse

const SideDrawerCollapse = ({ panelHeader, pannelItem, handlePanelClick, icon }) => {
  const [selectedIndex, setSelectedIndex] = useState(null)

  return (
    <Collapse
      accordion
      expandIconPosition="end"
      bordered={false}
      defaultActiveKey={['1']}
      style={{ marginTop: '-9px' }}
    >
      <Panel
        header={
          <div style={{ display: 'flex' }}>
            {icon ? (
              <img
                src={icon}
                alt="icone conversion"
                style={{ width: '25px', height: '25px', marginRight: '5px' }}
              />
            ) : (
              ''
            )}

            <div className={styles.pannelHeader}>{panelHeader}</div>
          </div>
        }
        key="1"
      >
        <div
          className={`${styles.reportsAccordion} customScrollbar`}
          style={{
            height: '50%',
            overflow: 'auto',
            paddingBottom: '20px',
          }}
        >
          {pannelItem.map((buttonValue, index) => (
            <button
              key={buttonValue}
              type="button"
              className={`${styles.sectionName} ${selectedIndex === index ? styles.active : ''}`}
              onClick={() => {
                setSelectedIndex(index)
                handlePanelClick(buttonValue)
              }}
            >
              {buttonValue}
            </button>
          ))}
        </div>
      </Panel>
    </Collapse>
  )
}

export default SideDrawerCollapse
