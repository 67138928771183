/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-console */
/* eslint-disable import/named */
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Card, Col, Row, Select, Form, Button, Avatar, Space, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { RequestService } from '@services/requests'
import { SecretService } from '@services/secret'
import { useRollbar } from '@rollbar/react'
import User from '@services/user'
import { Text } from '@components'
import iconAlert from '@images/alert-icon.svg'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import { notify } from '@utils/notify'
import { updateErrorCustomer, updateSelectedCustomer } from '../../store/actions'
import Footer from '../../components/Footer'
import { formatMCC } from '../../utils'

import styles from './select-account.module.scss'

// const getCustomerInfo = (customerId, customers) =>
//   customers.find(customer => {
//     const parsedCustomerId = customer.resource_name.split('/')[1]

//     return parsedCustomerId === customerId
//   })

const SelectAccountPage = () => {
  const shareBoardLink = localStorage.getItem('share_Baord_Link')
  const history = useHistory()
  const [selectedCustomer, setSelectedCustomer] = useState()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = User.get()

  const handleButtonTryAgain = () => {
    history.push('/')
  }

  const [reportLoading, setReportLoading] = useState(false)
  const [isEventExcute, setIsEventExcute] = useState(false)

  const [profile, setProfile] = useState({ error: false, loading: true, data: {} })
  const [customers, setCustomers] = useState({
    error: false,
    loading: true,
    errorMessage: '',
    data: [],
  })

  const getCustomers = async () => {
    try {
      const { data: customersData } = await RequestService.get('/customers/')
      if (customersData.length > 0) {
        setCustomers({ error: false, loading: false, data: customersData })
      } else {
        setCustomers({
          data: [
            {
              id: 123456789,
              level: 1,
              descriptive_name: 'Sample Report',
            },
          ],
        })
        localStorage.setItem('errorCustomer', JSON.stringify(true))
        dispatch(updateErrorCustomer(true))
        notify(t('notification.customerDataError'), 'error')
      }
    } catch (e) {
      setCustomers({
        // error: false,
        // loading: false,
        // errorMessage: e.response.data.messages[0],

        data: [
          {
            id: 123456789,
            level: 1,
            descriptive_name: 'Sample Report',
          },
        ],
      })
      localStorage.setItem('errorCustomer', JSON.stringify(true))
      dispatch(updateErrorCustomer(true))
      notify(t('notification.customerDataError'), 'error')
      // eslint-disable-next-line no-console
      console.error(e.message)
    }
  }

  const getProfile = async () => {
    try {
      const { data: profileData } = await RequestService.get('/profile/')
      localStorage.setItem('lead_form', profileData?.is_lead_save)
      setProfile({ error: false, loading: false, data: profileData })
    } catch (e) {
      setProfile({ error: true, loading: false, data: {} })
      // eslint-disable-next-line no-console
      console.error(e.message)
    }
  }
  const rollbar = useRollbar()
  const getCountry = useCallback(async () => {
    try {
      const { data } = await axios.get('https://mute-voice-f028.m-haziq-grayphite.workers.dev/')
      localStorage.setItem('region', data?.country)
    } catch (e) {
      // eslint-disable-next-line no-console
      rollbar.error('IPINFO error in Select Account Page', e)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    getCustomers()
    getProfile()
    getCountry()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line consistent-return
  const retrieveReport = async cb => {
    if (!selectedCustomer.id) return console.error('Tried to get report without customer id')

    setReportLoading(true)

    try {
      const { data } = await RequestService.post('/reports/', {
        customer_id: String(selectedCustomer.id),
        parent_customer_id: selectedCustomer.resource_name.split('/')[1],
      })

      setReportLoading(false)
      cb(data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      notify(t('notification.noEnoughReportData'), 'error')
      setReportLoading(false)
    }
  }

  const handleGenerateReport = () => {
    const leadTest = JSON.parse(localStorage.getItem('lead_form'))
    if (JSON.parse(localStorage.getItem('errorCustomer')) && !shareBoardLink) {
      history.push('/dashboard')
    } else if (JSON.parse(localStorage.getItem('errorCustomer')) && shareBoardLink) {
      history.push('/dashboard/boards')
    } else {
      User.set({ ...user, ...profile })
      dispatch(updateSelectedCustomer(selectedCustomer))
      localStorage.setItem('customer', JSON.stringify(selectedCustomer))

      retrieveReport(report => {
        // const customerInfo = getCustomerInfo(selectedCustomer.id, customers.data)

        const reportPayload = {
          customerId: String(selectedCustomer.id),
          parentId: selectedCustomer.resource_name.split('/')[1],
          uuid: report.unique_id,
          currency: selectedCustomer.currency_code,
          customerName: selectedCustomer.descriptive_name,
        }
        const reportToken = SecretService.encode(reportPayload)
        if (!leadTest) {
          history.push(`/report-generating/${reportToken}`, { report })
          localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        } else {
          if (!shareBoardLink) {
            history.push('/dashboard')
          } else if (shareBoardLink) {
            history.push('/dashboard/boards')
          }

          localStorage.setItem('selectAccount', JSON.stringify(reportToken))
        }
      })
    }
  }

  if (!customers.loading && customers.error && window.ga && !isEventExcute) {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())

    gtag('config', 'G-NSF7N516W4')

    gtag('event', 'erro', {
      screen_name: 'select-account',
      label_event: `erro-to-load-customers: ${customers.errorMessage[0]}`,
    })
    setIsEventExcute(true)
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <div className={styles.logo}>
          <LogoAdsGrader />
        </div>

        {!profile.loading && profile.error && (
          <Text size={24} color="white">
            {t('selectAccount.profileError')}
          </Text>
        )}

        {profile.loading && !profile.error && <Spin />}

        {/* {!profile.loading && !profile.error && (
          <>
            <Space>
              {profile.data.social_accounts && (
                <Avatar src={profile.data.social_accounts[0].extra_data.picture}>
                  {profile.data.first_name[0]}
                </Avatar>
              )}
              <Text size={24} color="white">
                {profile.data.first_name}
              </Text>
            </Space>
            <Text size={12} color="white">
              {profile.data.email}
            </Text>
            <Text className={styles.title} size={40} color="white">
              {customers.loading ? t('loading') : t('selectAccount.title')}
            </Text>
          </>
        )} */}

        <Row className={styles.row}>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xl={{ span: 8, offset: 8 }}
          >
            <Card className={styles.loginCard} loading={customers.loading}>
              {!customers.loading && customers.error && (
                <>
                  <Text size={16}>{t('selectAccount.customerError')}</Text>
                  <Button
                    type="primary"
                    className={styles.tryAgainButton}
                    onClick={handleButtonTryAgain}
                  >
                    {t('selectAccount.buttonError')}
                  </Button>
                </>
              )}

              {!customers.loading && !customers.error && (
                <>
                  <Form.Item>
                    <Select
                      onChange={e => {
                        setSelectedCustomer(JSON.parse(e))
                      }}
                      placeholder={t('selectAccount.selectPlaceholder')}
                    >
                      {customers.data.map(customer => {
                        if (customer.manager === true) return null

                        const parsedCustomerId = String(customer.id)

                        return (
                          <Select.Option
                            value={JSON.stringify(customer)}
                            key={customer.resource_name}
                          >
                            <>
                              {customer.descriptive_name ? (
                                <>
                                  <Text>{customer.descriptive_name}</Text>
                                  <Text color="darkerGray" size={12}>
                                    {formatMCC(parsedCustomerId)}
                                  </Text>
                                </>
                              ) : (
                                <Text>{formatMCC(parsedCustomerId)}</Text>
                              )}
                            </>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Button
                    onClick={handleGenerateReport}
                    loading={reportLoading}
                    disabled={!selectedCustomer}
                    type="primary"
                    block
                  >
                    {t('selectAccount.button')}
                  </Button>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      {!customers.loading && customers.error && (
        <Row className={styles.row}>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <div className={styles.cardAlert}>
              <img src={iconAlert} width={48} alt="icon alert" />
              <Text size={16}>{t('selectAccount.errorCustomer')}</Text>
            </div>
          </Col>
        </Row>
      )}

      <Footer />
    </div>
  )
}

export default SelectAccountPage
