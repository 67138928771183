import { Card, Col, Divider, Row, Select, Spin } from 'antd'
import {
  LS_KEY,
  LEAD_DATA,
  UPDATE_LEARNING_SEARCH_STRING,
  LEARNING_CENTER_API_DATA,
  UPDATE_LEARNING_SELECTED_VALUE,
} from '@utils/constants'
import Search from 'antd/lib/transfer/search'
import Text from '@components/Text/text'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import knowledgeIcon from '@images/Learning/knowledge-icon.png'
import videoIcon from '@images/Learning/video-icon.png'
import trainingsIcon from '@images/Learning/trainings-icon.png'
import noPreviewImage from '@images/Learning/noPreviewImage.webp'
import npTip from '@images/Learning/np-tip.png'
import knowledgeImage1 from '@images/Learning/knowledge-image.png'
import Share from '@images/Learning/Share.png'
import Navbar from '@components/LearningCenter/Navbar'
import LearningCard from '@components/LearningCenter/LearningCard'
import TopBar from '@components/LearningCenter/TopBar'
import TrainingsCards from '@components/LearningCenter/TrainingsCards'
import Outshine from '@components/LearningCenter/Outshine'
import Support from '@components/LearningCenter/Support'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { RequestService } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import infoIcon from '@images/Learning/info.png'
import rightArrow from '@images/Learning/right-arrow.png'
import Notification from '@images/Learning/Notification.png'
import KnowledgeAndGuides from '../knowledge-and-guides'
import TrainingsAndDownloadables from '../trainings-and-downloadables'
import VideoInsights from '../video-insights'

import { getLearningCenterData } from '../../../store/actions'
import style from './general.module.css'
import SearchFeature from '../search-feature'

const LearningCenterFeature = () => {
  const [searchedValueFromNav, setSearchedValueFromNav] = useState()
  const [check, setCheck] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const [temp, setTemp] = useState()
  const [activeTab, setActiveTab] = useState('Knowledge and Guides')
  const [searchValue, setSearchValue] = useState()
  const dispatch = useDispatch()
  const searchString = useSelector(state => state?.learningCenter?.searchString)
  const selectedValue = useSelector(state => state?.learningCenter?.selectedValue)

  const currentPath = window.location.pathname
  const handleSearchValue = value => {
    setSearchedValueFromNav(value)
  }

  const [isKnowledgeGuidesOpen, setIsKnowledgeGuidesOpen] = useState(false)
  const [isLearningCenterOpen, setIsLearningCenterOpen] = useState(true)
  const [isVideoInsightsOpen, setIsVideoInsightsOpen] = useState(false)
  const [isTrainingsDownloadsOpen, setIsTrainingsDownloadsOpen] = useState(false)
  const [isSearchValue, setIsSearchValue] = useState(false)

  useEffect(() => {
    if (currentPath === '/dashboard/learning-center') {
      setIsLearningCenterOpen(true)
      setIsKnowledgeGuidesOpen(false)
      setIsVideoInsightsOpen(false)
      setIsTrainingsDownloadsOpen(false)
    } else if (currentPath === '/dashboard/learning-center/search-feature') {
      setIsSearchValue(true)
      setIsLearningCenterOpen(false)
    }
  }, [currentPath])
  const handleLeadApi = async () => {
    try {
      const response = await RequestService.get('/accounts/user-lead/')

      dispatch({ type: LEAD_DATA, payload: response.data })
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    handleLeadApi()
    // eslint-disable-next-line
  }, [])
  const handleKnowledge = () => {
    setIsKnowledgeGuidesOpen(true)
    setIsLearningCenterOpen(false)
    setIsVideoInsightsOpen(false)
    setIsTrainingsDownloadsOpen(false)
    setIsSearchValue(false)
    history.push('/dashboard/learning-center/explore-more')
  }
  const handleVideoInsights = () => {
    setIsVideoInsightsOpen(true)
    setIsKnowledgeGuidesOpen(false)
    setIsLearningCenterOpen(false)
    setIsTrainingsDownloadsOpen(false)
    setIsSearchValue(false)
    history.push('/dashboard/learning-center/explore-more')
  }
  const handleTrainings = () => {
    setIsTrainingsDownloadsOpen(true)
    setIsVideoInsightsOpen(false)
    setIsKnowledgeGuidesOpen(false)
    setIsLearningCenterOpen(false)
    setIsSearchValue(false)
    history.push('/dashboard/learning-center/explore-more')
  }
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user.access_token
  const baseURL = process.env.REACT_APP_API_URL
  const handleAPI = async () => {
    setIsLoading(true)
    try {
      const response = await RequestService.get(
        '/learning-center/learning-resources/?category=&tag='
      )

      setTemp(response.data)
      setIsLoading(false)
      dispatch({ type: LEARNING_CENTER_API_DATA, payload: response.data })
    } catch (error) {
      // Handle errors here
      console.error('Error in API call:', error)
      setIsLoading(false)
    }
  }

  const handleSelectLevelApi = async level => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${baseURL}/learning-center/learning-resources/?category=&tag=${level}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      setTemp(response.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error in API call:', error)
      setIsLoading(false)
    }
  }

  const [getTag, setGetTag] = useState()
  const handleSelectChange = value => {
    dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: value })
    setGetTag(value)
    if (value === '') {
      handleAPI()
    } else if (value) {
      // handleSelectLevelApi(value)
    }
  }
  useEffect(() => {
    if (selectedValue === '') {
      handleAPI()
    } else if (selectedValue) {
      // handleSelectLevelApi(selectedValue)
    }
    // eslint-disable-next-line
  }, [selectedValue])
  useEffect(() => {
    if (currentPath === '/dashboard/learning-center') {
      dispatch({ type: UPDATE_LEARNING_SEARCH_STRING, payload: '' })
      dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: '' })
    }
    // eslint-disable-next-line
  }, [currentPath, searchString])
  const handleLearningRedirect = () => {
    setIsLearningCenterOpen(true)
    history.push('/dashboard/learning-center')
  }

  const handleGetTag = tag => {
    setGetTag(selectedValue)
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      {isLearningCenterOpen ? (
        <div>
          <Navbar
            title="Learning Center"
            searchValue="Knowledge"
            onSelectChange={handleSelectChange}
            // onSearchChange={handleSearch}
            handleSearchValue={handleSearchValue}
            searchedValueFromNav={searchedValueFromNav}
            handleGetTag={handleGetTag}
            tag={getTag}
          />
          <Row style={{ marginTop: 40 }}>
            <Col span={8}>
              <Card
                style={{
                  height: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleTrainings}
              >
                <img src={trainingsIcon} height={56} alt="trainings icon" />
                <Text size={16} isBold style={{ marginTop: '24px' }}>
                  Trainings & Downloadables
                </Text>
                <Text size={14} style={{ marginTop: 12, fontWeight: 400, color: '#8F8F8F' }}>
                  Explore our training resources and downloadable content, unlocking proven
                  strategies from our team. Learn effective techniques to optimize your ads account,
                  achieving significant conversions and clicks.
                </Text>
              </Card>
            </Col>

            <Col span={8}>
              <Card
                style={{
                  height: '100%',
                  cursor: 'pointer',
                }}
                onClick={handleVideoInsights}
              >
                <img src={videoIcon} height={56} alt="video icon" />
                <Text size={16} isBold style={{ marginTop: '24px' }}>
                  Video Insights
                </Text>
                <Text size={14} style={{ marginTop: 12, fontWeight: 400, color: '#8F8F8F' }}>
                  Watch video insights from Neil Patel and team, guiding you on maximizing your ads
                  account. Gain valuable strategies to boost your ROI, reduce wasted spend, and make
                  the most of your campaigns.
                </Text>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                style={{
                  cursor: 'pointer',
                  height: '100%',
                }}
                onClick={handleKnowledge}
              >
                <img src={knowledgeIcon} height={56} alt="knowledge icon" />
                <Text size={16} isBold style={{ marginTop: '24px', color: '#202020' }}>
                  Knowledge and Guides
                </Text>
                <Text
                  size={14}
                  style={{
                    marginTop: 12,
                    fontWeight: 400,
                    color: '#8F8F8F',
                  }}
                >
                  Explore our latest and relevant blog articles to discover practical tips and
                  tricks for optimizing your ads account. Learn how to increase conversions, boost
                  click-through rates, and improve product sales effortlessly.
                </Text>
              </Card>
            </Col>
          </Row>

          {/* <div
            style={{
              border: '1px solid  #E8EBEC',
              marginTop: '32px',
              background: '#F9FBFF',
            }}
          >
            <div
              style={{
                display: 'flex',
                margin: '24px',
                padding: 24,
              }}
            >
              <img src={npTip} alt="np-tip" />
              <Text
                isBold
                size={24}
                style={{
                  color: '#F16434',
                  marginTop: 30,
                  marginLeft: 24,
                  minWidth: 216,
                }}
              >
                TIP OF THE DAY
              </Text>
              <Text
                size={14}
                style={{
                  marginLeft: 24,
                  marginTop: 25,
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                Great job managing your Google Ads with a good number of negative keywords! This
                shows youve minimized wasted spend, but keep an eye out for potential improvements
                and analyze campaign performance regularly to optimize your ad spend.
              </Text>
            </div>
          </div> */}
          <Outshine />

          {isLoading ? (
            <Spin style={{ textAlign: 'center' }} className={style.loadingContainer} />
          ) : (
            <>
              <div id="training" style={{ marginTop: 32 }}>
                <TopBar
                  id="training"
                  title="Trainings & Downloadables"
                  handleExploreMore={handleTrainings}
                  data={temp}
                  // toolTipText="Explore our training resources and downloadable content, unlocking proven strategies from our team. Learn effective techniques to optimize your ads account, achieving significant conversions and clicks."
                  toolTipText="Want to improve your knowledge? Here are some ebooks, guides, templates, and more to help you grow your traffic."
                />

                <Row>
                  {temp
                    ?.filter(data => data.category === 'Trainings and Downloadables')
                    .slice(0, 4)
                    .map(data => (
                      <Col span={6} key={data.id}>
                        <a
                          href={data.link}
                          aria-label={data.title}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TrainingsCards
                            image={data.preview_image ? data?.preview_image : noPreviewImage}
                            title={data.title}
                            tag={data.tag}
                          />
                        </a>
                      </Col>
                    ))}
                </Row>
              </div>

              <div id="videoInsights" style={{ marginTop: 32 }}>
                <TopBar
                  id="videoInsights"
                  title="Video Insights"
                  handleExploreMore={handleVideoInsights}
                  data={temp}
                  // toolTipText="Watch video insights from Neil Patel and team, guiding you on maximizing your ads account. Gain valuable strategies to boost your ROI, reduce wasted spend, and make the most of your campaigns."
                  toolTipText="Watch new marketing tactics to help you uncover valuable insights and practical tips to maximize the effectiveness of your advertising efforts."
                />

                <Row>
                  {temp
                    ?.filter(data => data.category === 'Video Insights')
                    .slice(0, 4)
                    .map(data => (
                      <Col span={6} key={data.id}>
                        <a
                          href={data.link}
                          aria-label={data.title}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LearningCard
                            image={data.preview_image ? data?.preview_image : noPreviewImage}
                            title={data.title}
                            tag={data.tag}
                            comments="15"
                            shares="20"
                          />
                        </a>
                      </Col>
                    ))}
                  {/* {setCheck(true)} */}
                </Row>
              </div>
              <div id="KnowledgeandGuides" style={{ marginTop: 32.5 }}>
                <TopBar
                  id="KnowledgeandGuides"
                  title="Knowledge and Guides"
                  handleExploreMore={handleKnowledge}
                  data={temp}
                  // toolTipText="Explore our latest and relevant blog articles to discover practical tips and tricks for optimizing your ads account. Learn how to increase conversions, boost click-through rates, and improve product sales effortlessly."
                  toolTipText="Read recent blogs from our site to stay up to date on digital marketing tips, trends, and strategy to help you build successful ad campaigns."
                />
                <Row>
                  {temp
                    ?.filter(data => data.category === 'Knowledge and Guides')
                    .slice(0, 4)
                    .map(data => (
                      <Col span={6} key={data.id}>
                        <a
                          href={data.link}
                          aria-label={data.title}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LearningCard
                            image={data.preview_image ? data?.preview_image : noPreviewImage}
                            style={{ width: '268px' }}
                            title={data.title}
                            tag={data.tag}
                            comments="15"
                            shares="20"
                          />
                        </a>
                      </Col>
                    ))}
                </Row>
              </div>
            </>
          )}

          <Support />
        </div>
      ) : // eslint-disable-next-line
      isKnowledgeGuidesOpen ? (
        <KnowledgeAndGuides
          catagory="Knowledge and Guides"
          handleLearningRedirect={handleLearningRedirect}
          tags={selectedValue}
        />
      ) : // eslint-disable-next-line
      isVideoInsightsOpen ? (
        <KnowledgeAndGuides
          catagory="Video Insights"
          handleLearningRedirect={handleLearningRedirect}
          tags={selectedValue}
        />
      ) : // eslint-disable-next-line
      isTrainingsDownloadsOpen ? (
        <KnowledgeAndGuides
          catagory="Trainings and Downloadables"
          handleLearningRedirect={handleLearningRedirect}
          tags={selectedValue}
        />
      ) : // eslint-disable-next-line
      isSearchValue ? (
        <SearchFeature
          // catagory="Knowledge and Guides"
          handleLearningRedirect={handleLearningRedirect}
          searchValue={searchValue}
          searchedValueFromNav={searchedValueFromNav}
          tags={selectedValue}
        />
      ) : (
        // eslint-disable-next-line
        ''
      )}
    </>
  )
}

export default LearningCenterFeature
