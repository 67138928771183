import { apiRoutes } from '@config/apiRoutes'
import api from './api'

export const generateReportForCustomer = async (customerId, parentCustomerId) => {
  const { url, method } = apiRoutes.generateReport
  try {
    const res = await api({
      url,
      method,
      data: {
        customer_id: customerId,
        parent_customer_id: parentCustomerId,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
