import React, { useState } from 'react'
import { Card, Col, Row } from 'antd'
import knowledgeImage1 from '@images/Learning/knowledge-image.png'
import Notification from '@images/Learning/Notification.png'
import Share from '@images/Learning/Share.png'
import downloadIcon from '@images/Learning/download-icon.png'
import Text from '@components/Text/text'

const TrainingsCards = ({ image, title, tag }) => {
  const [temp, setTemp] = useState()
  return (
    <div id="LearningCenterLearningCard">
      <Card style={{ maxHeight: 376 }}>
        <div style={{ textAlign: 'center' }}>
          {/* <img src={image} width={258} height={160} alt="Neil" /> */}
          <img
            src={image}
            height={160}
            alt="Neil"
            style={{
              maxWidth: '-moz-available',
              objectFit: 'contain',
              width: '-webkit-fill-available',
              marginLeft: 24,
              marginRight: 24,
            }}
          />
        </div>
        <div
          style={{
            marginLeft: 24,
            marginTop: 20,

            display: 'inline-flex',
            height: 30,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F1F3F4',
            borderRadius: '4px',
          }}
        >
          <Text size={14} style={{ color: '#8F8F8F', padding: '10px', fontWeight: 600 }}>
            {tag}
          </Text>
        </div>
        <div
          style={{
            maxWidth: 258,
            marginLeft: 24,
            marginTop: 16,
            fontSize: 16,
            color: '#202020',
            fontWeight: 400,
            lineHeight: '150%',
            textTransform: 'capitalize',
            minHeight: '48px',
            // overflow: 'auto',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
          }}
        >
          {title}
        </div>
        <div style={{ marginTop: 16 }}>
          <button
            type="submit"
            style={{
              marginLeft: 24,
              display: 'inline-flex',
              gap: 6,
              height: 32,
              // width: 132,
              fontSize: 12,
              // fontWeight: 700,
              background: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '12px',
              border: '1px solid #F16434',
              borderRadius: '2px',
              color: '#F16434',
              cursor: 'pointer',
            }}
          >
            <img src={downloadIcon} alt="download-icon" width={14} height={14} />
            <span
              style={{
                textDecoration: 'none',
                color: '#F16434',
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              Download
            </span>
          </button>
        </div>
      </Card>
    </div>
  )
}

export default TrainingsCards
