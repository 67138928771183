import React, { useState } from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import styles from './text.module.scss'

const Text = ({
  onClick,
  isMediumBold,
  cursor,
  direction,
  children,
  isTitle,
  size,
  color,
  isBold,
  isSemibold,
  isUppercase,
  className,
  style,
  isGenericBold,
}) => {
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(!clicked)

    if (onClick) {
      onClick()
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Space') {
      handleClick()
    }
  }

  const rotatedStyles = direction === 'vertical' ? { transform: 'rotate(-90deg)' } : {}

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      style={{ ...style, ...rotatedStyles, border: 'none', background: 'none' }}
      className={classNames([
        styles[`font${isTitle ? 'Title' : 'Text'}`],
        styles[`font${size}`],
        styles[`${color}`],
        { [styles.isBold]: isBold },
        { [styles.isSemibold]: isSemibold },
        { [styles.isMediumBold]: isMediumBold },
        { [styles.isGenericBold]: isGenericBold },
        { [styles.isUppercase]: isUppercase },

        { [styles.cursor]: cursor },
        className,
      ])}
    >
      {children}
    </div>
  )
}

Text.propTypes = {
  cursor: PropTypes.bool,
  direction: PropTypes.string,
  children: PropTypes.node,
  isTitle: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  isMediumBold: PropTypes.bool,
  isSemibold: PropTypes.bool,
  isGenericBold: PropTypes.bool,
  isUppercase: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
}

Text.defaultProps = {
  isGenericBold: false,
  cursor: false,
  children: '',
  isTitle: false,
  size: 16,
  color: 'black',
  isSemibold: false,
  isMediumBold: false,
  isBold: false,
  isUppercase: false,
  className: '',
  style: {},
  direction: 'horizontal',
  onClick: null,
}

export default Text
