/*  eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Divider,
  Form,
  Input,
  Popover,
  Progress,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import api from 'services/api'
import { fetchInvitedUsersList } from 'services/fetchInvitedUsersList'
import { notify } from 'utils/notify'
import { LS_KEY } from 'utils/constants'
import { fetchRoles } from 'services/fetchRoles'
import CustomersSelect from 'components/Select/customersSelect'
import { useTranslation } from 'react-i18next'
import ManageUsersTable from './manageUsersTable'

import styles from './manageUsers.module.css'

const { Title, Text } = Typography
const newUser = {
  id: Math.ceil(Math.random() * 2000),
  accepted: false,
  email: '',
  customer_id: null,
  created: new Date(),
  role: null,
  role_name: null,
  user: null,
}

const ManageUsers = ({ isDashboard, setIsPopoverOpen2, setIsPopoverOpen }) => {
  const isCustomers = localStorage.getItem('isCustomers')
  const [invitedUsersList, setInvitedUsersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [roleLoading, setRoleLoading] = useState(false)
  const [sendInviteLoading, setSendInviteLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [invitedRole, setInvitedRole] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)
  const customer = useSelector(state => state.customer)
  const userRole = useSelector(state => state?.role?.role)

  const { t } = useTranslation()

  const requiredRoles = ['Admin', 'Account Manager', 'Owner']
  const createValidationSchema = trans =>
    Yup.object({
      email: Yup.string()
        .email(trans('settings.manageUsers.validEmail'))
        .required(trans('settings.manageUsers.enterEmail')),
      role: Yup.number()
        .nullable()
        .typeError(trans('settings.manageUsers.selectRole'))
        .required(trans('settings.manageUsers.selectRole')),
    })
  useEffect(() => {
    setRoleLoading(true)
    if (!requiredRoles?.includes(JSON.parse(localStorage.getItem('role')))) {
      setInvitedRole(true)
    }
    fetchRoles()
      .then(res => {
        setRoleLoading(false)
        if (res.status === 200) {
          setSelectedRole(res.data.roles?.[0]?.id)
          const apiRole = res.data.roles
          const filteredRoles = apiRole.filter(role => role.name !== 'Admin Learning Center')
          setRoles(filteredRoles)
        }
      })
      .catch(e => setRoleLoading(false))
    // eslint-disable-next-line
  }, [])

  const fetchInvitedUsersListFunction = () => {
    if (customer?.id) {
      setIsLoading(true)
      fetchInvitedUsersList(customer?.id)
        .then(res => {
          setIsLoading(false)
          if (res) {
            setInvitedUsersList(res.data)
          }
        })
        .catch(e => {
          setIsLoading(false)
        })
    }
  }
  useEffect(() => {
    // if (customer?.id) {
    //   setIsLoading(true)
    //   fetchInvitedUsersList(customer?.id)
    //     .then(res => {
    //       setIsLoading(false)
    //       if (res) {
    //         setInvitedUsersList(res.data)
    //       }
    //     })
    //     .catch(e => {
    //       setIsLoading(false)
    //       console.log('error response', e.response)
    //     })
    // }
    fetchInvitedUsersListFunction()
    // eslint-disable-next-line
  }, [customer?.id])

  const formik = useFormik({
    initialValues: {
      email: '',
      role: null,
    },
    // validationSchema: Yup.object({
    //   email: Yup.string()
    //     .email(t('settings.manageUsers.validEmail'))
    //     .required(t('settings.manageUsers.enterEmail')),
    //   role: Yup.number()
    //     .nullable()
    //     .typeError('Please select role')
    //     .required(t('settings.manageUsers.selectRole')),
    // }),
    validationSchema: createValidationSchema(t),
    onSubmit: async (values, { resetForm }) => {
      if (invitedUsersList?.length >= 20) {
        notify(t('notification.limitExceed'), 'warning')
        return
      }
      const token = await JSON.parse(localStorage.getItem(LS_KEY))?.access_token
      const tempValues = { ...values }
      tempValues.customer_id = customer.id
      tempValues.customer_manager = Boolean(customer.manager)
      if (customer?.level) {
        tempValues.customer_level = customer.level
      }
      if (customer?.time_zone) {
        tempValues.customer_time_zone = customer.time_zone
      }
      if (customer?.resource_name) {
        tempValues.customer_resource_name = customer.resource_name
      }
      if (customer?.currency_code) {
        tempValues.customer_currency_code = customer.currency_code
      }
      if (customer?.descriptive_name) {
        tempValues.customer_descriptive_name = customer.descriptive_name
      }
      setSendInviteLoading(true)
      try {
        const response = await api({
          url: '/accounts/invite/',
          method: 'POST',
          data: tempValues,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setSendInviteLoading(false)
        if (response.status === 201) {
          notify(t('settings.manageUsers.sendInvitationMessage'), 'success')
          const roleName = roles.find(role => role.id === selectedRole).name
          const tempUser = { ...newUser, ...tempValues }
          setInvitedUsersList(pre => [...pre, { ...tempUser, role_name: roleName }])
          fetchInvitedUsersListFunction()
          resetForm()
          if (setIsPopoverOpen2) {
            setIsPopoverOpen2(false)
          } else {
            setIsPopoverOpen(false)
          }
        }
      } catch (e) {
        setSendInviteLoading(false)
        if (e?.response?.data?.message) {
          notify(e?.response?.data?.message, 'error')
        } else if (e.response.status >= 500) {
          notify(t('notification.internalServer'), 'error')
        } else {
          notify(t('notification.somethingWentWrong'), 'error')
        }
        if (setIsPopoverOpen2) {
          setIsPopoverOpen2(false)
        } else {
          setIsPopoverOpen(false)
        }
      }
    },
  })

  const handleRoleSelect = id => {
    formik.setFieldTouched('role', true)
    formik.setFieldValue('role', id)
    setSelectedRole(id)
  }
  const lang = localStorage.getItem('i18nextLng')
  useEffect(() => {
    const newValidationSchema = createValidationSchema(t)
    formik.setFormikState(prevState => ({
      ...prevState,
      validationSchema: newValidationSchema,
    }))
    formik.validateForm()
    // eslint-disable-next-line
  }, [lang])
  function getInputDivStyles(error) {
    if (error === 'Please enter a valid email address') {
      return {
        minWidth: '240px',
        display: 'flex',
        whiteSpace: 'normal',
      }
    }
    return {
      minWidth: '260px',
      display: 'flex',
      whiteSpace: 'normal',
    }
  }

  function getFormContainerStyle(error) {
    if (
      error === 'Please enter a valid email address' ||
      error === '' ||
      error === undefined ||
      error === 'Please enter your email address' ||
      error === 'Please select a role'
    ) {
      return {
        marginBottom: '20px',
      }
    }
    return {
      marginBottom: '50px',
    }
  }

  return (
    <>
      {!isDashboard && (
        <div className={styles.manageUsersContainer}>
          <Title level={3}>{t('settings.account.manageUsers')}</Title>
          <Text strong>{t('settings.manageUsers.userLimit')}</Text>
          <span>
            <Text strong>{invitedUsersList?.length}/20</Text>
            <Text> {t('settings.manageUsers.numberOfUsers')}</Text>
          </span>
          <div className={styles.progressContainer}>
            <Progress
              percent={invitedUsersList?.length * 5}
              showInfo={false}
              strokeColor="#85ECC1"
            />
          </div>
          <button type="button" className={styles.upgradeButton}>
            {t('settings.manageUsers.upgrade')}
          </button>
          <Divider />
          <div>
            <Title level={4}>{t('settings.manageUsers.AddUserstoAccount')}</Title>
            <div className={styles.accountContainer}>
              <CustomersSelect
                settingPage="true"
                selectedUserId={customer?.id}
                selectProps={{ size: 'medium', width: '100%', style: { maxWidth: '358px' } }}
                disabled={isCustomers === 'false'}
              />
            </div>
            <Text style={{ color: '#8F8F8F' }}>
              {/* {t('settings.manageUsers.sendAnEmailInvitation')} */}
            </Text>
          </div>
          <Form onFinish={formik.handleSubmit} layout="vertical">
            <p htmlFor="email">{t('loginForm.placeholder.email')}</p>
            {userRole ? (
              <Popover content={<p>{t('settings.manageUsers.noPermission')}</p>} trigger="hover">
                <div className={styles.formContainer} style={{ maxWidth: '850px' }}>
                  <div className={styles.inputContainer}>
                    <input
                      className={styles.emailInput}
                      placeholder={t('settings.manageUsers.emailPlaceholder')}
                      {...formik.getFieldProps('email')}
                      id="email"
                      disabled
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className={`${styles.error}`} style={{ overflowWrap: 'break-word' }}>
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                  <div className={styles.inputContainer}>
                    {!roleLoading ? (
                      <Select
                        value={formik.values.role}
                        onChange={handleRoleSelect}
                        className={styles.capitalize}
                        style={{ minWidth: '170px', width: '100%', maxWidth: '358px' }}
                        disabled
                      >
                        <Select.Option value={null}>
                          {t('settings.manageUsers.selectPlaceholder')}
                        </Select.Option>
                        {roles?.map(role => (
                          <Select.Option
                            key={role?.id}
                            value={role?.id}
                            className={styles.capitalize}
                          >
                            {/* {role?.name.replace('_', ' ')} */}
                            {t(role?.name)}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <div className={styles.loadingContainer}>
                        <Spin />
                      </div>
                    )}
                    {formik.errors.role && formik.touched.role && (
                      <p className={`${styles.error}`}>{formik.errors.role}</p>
                    )}
                  </div>

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled
                    className={styles.inviteButton}
                    loading={sendInviteLoading}
                  >
                    {t('settings.manageUsers.sendInvite')}
                  </Button>
                </div>
              </Popover>
            ) : (
              <div className={styles.formContainer} style={{ maxWidth: '850px' }}>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.emailInput}
                    placeholder={t('settings.manageUsers.emailPlaceholder')}
                    {...formik.getFieldProps('email')}
                    id="email"
                    disabled={isCustomers === 'false'}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className={`${styles.error}`} style={{ overflowWrap: 'break-word' }}>
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div className={styles.inputContainer}>
                  {!roleLoading ? (
                    <Select
                      value={formik.values.role}
                      onChange={handleRoleSelect}
                      className={styles.capitalize}
                      style={{ minWidth: '170px', width: '100%', maxWidth: '358px' }}
                      disabled={isCustomers === 'false'}
                    >
                      <Select.Option value={null}>
                        {t('settings.manageUsers.selectPlaceholder')}
                      </Select.Option>
                      {roles?.map(role => (
                        <Select.Option
                          key={role?.id}
                          value={role?.id}
                          className={styles.capitalize}
                        >
                          {/* {role?.name.replace('_', ' ')} */}
                          {t(role?.name)}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <div className={styles.loadingContainer}>
                      <Spin />
                    </div>
                  )}
                  {formik.errors.role && formik.touched.role && (
                    <p className={`${styles.error}`}>{formik.errors.role}</p>
                  )}
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={sendInviteLoading || isCustomers === 'false'}
                  className={styles.inviteButton}
                  loading={sendInviteLoading}
                >
                  {t('settings.manageUsers.sendInvite')}
                </Button>
              </div>
            )}
          </Form>
          <ManageUsersTable
            invitedUsersList={invitedUsersList}
            setInvitedUsersList={setInvitedUsersList}
            isLoading={isLoading}
          />
        </div>
      )}
      {isDashboard && (
        <Form onFinish={formik.handleSubmit} layout="vertical">
          <p htmlFor="email">{t('loginForm.placeholder.email')}</p>
          <div
            className={styles.formContainer}
            style={getFormContainerStyle(formik.errors.email || formik.errors.role)}
          >
            <div
              className={styles.inputContainer}
              style={getInputDivStyles(formik.errors.email)}
              // style={{
              //   whiteSpace: 'nowrap',
              //   minWidth: `${
              //     formik.errors.email === 'Please enter a valid email address' ? '240px' : '300px'
              //   }`,
              // }}
            >
              <input
                className={styles.emailInput}
                placeholder={t('settings.manageUsers.emailPlaceholder')}
                {...formik.getFieldProps('email')}
                id="email"
              />

              {formik.errors.email && formik.touched.email && (
                <p className={`${styles.error}`}>{formik.errors.email}</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              {!roleLoading ? (
                <Select
                  value={formik.values.role}
                  onChange={handleRoleSelect}
                  className={styles.capitalize}
                  style={{ minWidth: '170px', width: '100%', maxWidth: '358px' }}
                >
                  <Select.Option value={null}>
                    {t('settings.manageUsers.selectPlaceholder')}
                  </Select.Option>
                  {roles?.map(role => (
                    <Select.Option key={role?.id} value={role?.id} className={styles.capitalize}>
                      {/* {role?.name.replace('_', ' ')} */}
                      {t(role?.name)}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <div className={styles.loadingContainer}>
                  <Spin />
                </div>
              )}
              {formik.errors.role && formik.touched.role && (
                <p className={`${styles.error}`}>{formik.errors.role}</p>
              )}
            </div>

            <Button
              type="primary"
              htmlType="submit"
              disabled={sendInviteLoading}
              className={`${styles.inviteButton} ${styles.inviteButton2}`}
              loading={sendInviteLoading}
            >
              {t('settings.manageUsers.sendInvite')}
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}

export default ManageUsers
