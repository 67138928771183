import React from 'react'
import { Card, Divider, Switch, Tag } from 'antd'
import { Text } from '@components'
import ArrowRight from '@images/Alerts/ArrowRightOrange.png'

import Styles from './style.module.css'

const AlertCard = ({
  tagName,
  alertName,
  alertDetail,
  alertImage,
  enabledAlert,
  onSwitchChange,
  onGetStartedClick,
  onViewEditClick,
  step2,
  step3,
  step4,
}) => (
  <div className={step2}>
    <Card className={Styles.CardCustom}>
      <div className={Styles.parentCard}>
        <div className={Styles.cardContent}>
          <img src={alertImage} alt="Alerts-icon" className={Styles.CardImage} />
          <div className={Styles.RightContent}>
            <Tag bordered={false} className={Styles.Tag}>
              {tagName}
            </Tag>
            <Text isBold size={20} color="#000" style={{ marginBottom: '8px' }}>
              {alertName}
            </Text>
            <Text className={Styles.AlertDetail}>{alertDetail}</Text>
          </div>
        </div>
        <Divider />
        <div className={Styles.CardActions}>
          {/* <div className={Styles.cardNavigation}> */}
          {enabledAlert ? (
            <>
              <button type="button" onClick={onViewEditClick} className={Styles.ButtonCustom}>
                <Text className={Styles.ButtonCard}>View/Edit alert</Text>
                <img src={ArrowRight} alt="Right arrow" className={Styles.RightArrow} />
              </button>
            </>
          ) : (
            <>
              <div className={step3}>
                <button type="button" onClick={onGetStartedClick} className={Styles.ButtonCustom}>
                  <Text cursor className={Styles.ButtonCard}>
                    Get Started
                  </Text>

                  <img src={ArrowRight} alt="Right arrow" className={Styles.RightArrow} />
                </button>
              </div>
            </>
          )}
          {/* <img src={ArrowRight} alt="Right arrow" className={Styles.RightArrow} /> */}
          {/* </div> */}
          <div className={step4}>
            <Switch defaultChecked={enabledAlert} onChange={onSwitchChange} />
          </div>
        </div>
      </div>
    </Card>
  </div>
)

export default AlertCard
