import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchRoleInfo = async id => {
  const { url, method } = apiRoutes.roleInfo
  try {
    const res = await api({
      url: url(id),
      method,
    })
    return res
  } catch (e) {
    return e.response
  }
}
