import React from 'react'
import ReactDOM from 'react-dom'

import '@config/i18n'

import { ErrorBoundary, Provider } from '@rollbar/react'
import App from './app'

const rollbarConfig = {
  accessToken: '9c20bac0091f4251b677222bb09f7996',
  environment:
    // eslint-disable-next-line
    window.location.host === 'adsgrader.com'
      ? 'production'
      : // eslint-disable-next-line
      window.location.host === 'adsgrader-stage.netlify.app'
      ? 'staging'
      : window.location.host === 'np-ads-grader-dev.netlify.app'
      ? 'development'
      : 'localenvironment',

  captureUncaught: true,
  captureUnhandledRejections: true,
}

ReactDOM.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)
