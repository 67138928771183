import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRedirectStore } from '../../hooks/use-redirect-store'

const LandingPage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  useRedirectStore({ autoRedirect: true })

  const isProduction = process.env.NODE_ENV === 'production'

  useEffect(() => {
    if (window && typeof window !== 'undefined') {
      window.document.body.style = 'overflow:hidden;'
    }

    return () => {
      if (window && typeof window !== 'undefined') {
        window.document.body.style = ''
      }
    }
  }, [])

  // Only show the Landing Page on production enviroment
  // So we don't have this annoying iframe blocking clicks on all other pages
  return isProduction ? (
    <iframe
      style={{
        width: '100%',
        minHeight: '100vh',
        margin: 0,
        height: '100%',
        border: 0,
        outline: 0,
      }}
      title="landingpage"
      src="https://advanced.npdigital.com/ads-grader-a/"
    />
  ) : (
    <h1>
      Go to <Link to="/start">/start</Link>
    </h1>
  )
}

export default LandingPage
