import React, { useState } from 'react'
import SettingTabs from '../../components/Tabs/settingTabs'
import styles from './settings.module.css'

const Settings = () => {
  const [temp, setTemp] = useState()
  return (
    <>
      <div className={styles.tabsContainer}>
        <SettingTabs />
      </div>
    </>
  )
}

export default Settings
