import {
  UPDATE_LEARNING_SEARCH_STRING,
  UPDATE_LEARNING_SELECTED_VALUE,
  LEARNING_CENTER_API_DATA,
  LEAD_DATA,
  UPDATE_ACTIVE_BUTTON_STATE,
} from '@utils/constants'

const initialState = {
  searchString: '',
  selectedValue: '',
  apiData: [],
  leadData: [],
  updateActiveButton: false,
}
export const learningCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LEARNING_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      }
    case UPDATE_LEARNING_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: action.payload,
      }
    case LEARNING_CENTER_API_DATA:
      return {
        ...state,
        apiData: action.payload,
      }
    case LEAD_DATA:
      return {
        ...state,
        leadData: action.payload,
      }
    case UPDATE_ACTIVE_BUTTON_STATE:
      return {
        ...state,
        updateActiveButton: action.payload,
      }

    default:
      return state
  }
}
