import React from 'react'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import PrivacyFooter from '../../components/Footer/PrivacyFooter'
import styles from './policies.module.scss'

const PrivacyPage = () => (
  <div className={styles.pageWrapper}>
    <div className={styles.policiesHeader}>
      <Link to="/">
        <LogoAdsGrader notTranslate />
      </Link>
      <Text className={styles.title} size={48} color="white">
        Privacy Policy
      </Text>
      <Text size={22} color="white">
        Last Updated: November 24, 2021
      </Text>
    </div>

    <Row className={styles.row}>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 18, offset: 3 }}
        xl={{ span: 8, offset: 8 }}
      >
        <article className={styles.article} role="main">
          <div>
            <p>
              Neil Patel Digital, LLC is committed to safeguarding your privacy. Contact us at{' '}
              <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a> if you have any questions
              or problems regarding the use of your Personal Data and we will gladly assist you. We
              will keep strictly confidential all non-public information from you that we may
              collect in connection with your use of the software. This does not apply to any
              suggestions for updates you have made. We want you to know how we collect, use, share,
              disclose, and protect information about you. By using this site or/and our services,
              you consent to the Processing of your Personal Data as described in this Privacy
              Policy. We may change or add to this Privacy Policy, so we encourage you to review it
              periodically.
            </p>
            <h2>Table of Contents</h2>
            <ol>
              <li>
                <a href="#gdpr-definitions">Definitions used in this Policy</a>
              </li>
              <li>
                <a href="#gdpr-principles">Data protection principles we follow</a>
              </li>
              <li>
                <a href="#gdpr-rights">What rights do you have regarding your Personal Data</a>
              </li>
              <li>
                <a href="#gdpr-information">What Personal Data we gather about you</a>
              </li>
              <li>
                <a href="#gdpr-processing">How we use your Personal Data</a>
              </li>
              <li>
                <a href="#gdpr-sharing">Who else has access to your Personal Data</a>
              </li>
              <li>
                <a href="#gdpr-security">How we secure your data</a>
              </li>
              <li>
                <a href="#gdpr-cookies">Information about cookies</a>
              </li>
              <li>
                <a href="#gdpr-contact">Contact information</a>
              </li>
            </ol>
            <h2 id="gdpr-definitions">Definitions</h2>
            <p>
              <strong>Personal Data</strong> - any information relating to an identified or
              identifiable natural person; for California consumers, Personal Data is “Personal
              Information” as defined below.
            </p>
            <p>
              <strong>Processing</strong> - any operation or set of operations which is performed on
              Personal Data or on sets of Personal Data.
            </p>
            <p>
              <strong>Data subject</strong> - a natural person whose Personal Data is being
              Processed.
            </p>
            <p>
              <strong>Child</strong> - a natural person under 16 years of age.
            </p>
            <p>
              <strong>We/us</strong> (either capitalized or not) - Neil Patel Digital, LLC
            </p>
            <p>
              Personal Information (applicable to California consumers; also referred to as
              “Personal Data” in this Privacy Policy) - any information that identifies, relates to,
              describes, is reasonably capable of being associated with, or could reasonably be
              linked, directly or indirectly, with a particular consumer or household. Personal
              Information includes but is not limited to, the following if it identifies, relates
              to, describes, is capable of being associated with, or could be reasonably linked,
              directly or indirectly, with a particular consumer or household:
            </p>
            <ul>
              <li>
                Identifiers - real name, alias, postal address, unique personal identifier, online
                identifier, internet protocol address, email address, account name, social security
                number, driver`&apos;`s license number, passport number, or other similar
                identifiers.
              </li>
              <li>
                Commercial information - including records of personal property, products or
                services purchased, obtained, or considered, or other purchasing or consuming
                histories of tendencies
              </li>
              <li>Biometric Information</li>
              <li>
                Internet or other electronic network activity information - including but not
                limited to, browsing history, search history, and information regarding a
                consumer`&apos;`s interaction with an Internet Web site, application, or
                advertisement.
              </li>
              <li>Geolocation data</li>
              <li>Audio, electronic, visual, thermal, olfactory, or similar information.</li>
              <li>Professional or employment-related information.</li>
              <li>
                Inferences drawn from any of the information identified in this definition of
                Personal Information, to create a profile about a consumer reflecting the
                consumer`&apos;`s preferences, characteristics, psychological trends,
                predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
              </li>
              <li>
                Personal Information does not include publicly available information lawfully made
                available from government records or information that is deidentified or aggregated.
              </li>
            </ul>
            <h2 id="gdpr-principles">Data Protection Principles</h2>
            <p>We promise to follow the following data protection principles:</p>
            <ul>
              <li>
                Processing is lawful, fair, transparent. Our Processing activities have lawful
                grounds. We always consider your rights before Processing Personal Data. We will
                provide you information regarding Processing upon request.
              </li>
              <li>
                Processing is limited to the purpose. Our Processing activities fit the purpose for
                which Personal Data was gathered.
              </li>
              <li>
                Processing is done with minimal data. We only gather and Process the minimal amount
                of Personal Data required for any purpose.
              </li>
              <li>
                Processing is limited with a time period. We will not store your personal data for
                longer than needed.
              </li>
              <li>We will do our best to ensure the accuracy of data.</li>
              <li>We will do our best to ensure the integrity and confidentiality of data.</li>
            </ul>
            <h2 id="gdpr-rights">Data Subject`&apos;`s rights</h2>
            <p>The Data Subject has the following rights:</p>
            <ol>
              <li>
                <strong>Right to information</strong> - meaning you have to the right to know
                whether your Personal Data is being processed; what data is gathered, from where it
                is obtained and why and by whom it is processed.
              </li>
              <li>
                <strong>Right to access</strong> - meaning you have the right to access the data
                collected from/about you. This includes your right to request and obtain a copy of
                your Personal Data gathered.
              </li>
              <li>
                <strong>Right to rectification</strong> - meaning you have the right to request
                rectification or erasure of your Personal Data that is inaccurate or incomplete.
              </li>
              <li>
                <strong>Right to erasure</strong> - meaning in certain circumstances you can request
                for your Personal Data to be erased from our records.
              </li>
              <li>
                <strong>Right to restrict processing</strong> - meaning where certain conditions
                apply, you have the right to restrict the Processing of your Personal Data.
              </li>
              <li>
                <strong>Right to object to processing</strong> - meaning in certain cases you have
                the right to object to Processing of your Personal Data, for example in the case of
                direct marketing.
              </li>
              <li>
                <strong>Right to object to automated Processing</strong> - meaning you have the
                right to object to automated Processing, including profiling; and not to be subject
                to a decision based solely on automated Processing. This right you can exercise
                whenever there is an outcome of the profiling that produces legal effects concerning
                or significantly affecting you.
              </li>
              <li>
                <strong>Right to data portability</strong> - you have the right to obtain your
                Personal Data in a machine-readable format or if it is feasible, as a direct
                transfer from one Processor to another.
              </li>
              <li>
                <strong>Right to lodge a complaint</strong> - in the event that we refuse your
                request under the Rights of Access, we will provide you with a reason as to why. If
                you are not satisfied with the way your request has been handled please contact us.
              </li>
              <li>
                <strong>
                  Right for the help of <a href="#gdpr-contact">supervisory authority</a>
                </strong>{' '}
                - meaning you have the right for the help of a supervisory authority and the right
                for other legal remedies such as claiming damages.
              </li>
              <li>
                <strong>Right to withdraw consent</strong> - you have the right to withdraw any
                given consent for Processing of your Personal Data.
              </li>
            </ol>
            <h2 id="gdpr-information">Data we gather</h2>
            <p>
              <strong>Information you have provided to us</strong>
            </p>
            <p>
              Neil Patel Digital may obtain the following types of information from you or
              concerning your computer or device (“Information”), which may include information that
              can be used to identify you as specified below (“Personally Identifiable
              Information”):
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Mailing address</li>
              <li>Telephone (or mobile) number(s)</li>
              <li>Credit card information</li>
              <li>Blog or website URL</li>
              <li>Social networking handle or username</li>
              <li>Photo</li>
              <li>Demographic information (e.g., age, gender, interests, and preferences)</li>
              <li>Professional title</li>
              <li>Company</li>
              <li>Industry</li>
              <li>Activities or content viewed on Neil Patel Digital</li>
              <li>
                Information collected automatically ({' '}
                <a href="#information-collected-automatically">detailed below</a> )
              </li>
              <li>
                Information received from Third Parties ({' '}
                <a href="#information-received-from-third-parities">detailed below</a> )
              </li>
            </ul>
            <p>
              In certain cases, we may request that you provide some of the Information identified
              above in order to obtain specific products or services (such as registering for the
              Advanced Marketing Program or logging in to download AdsGrader.com reports). If you
              choose not to provide the Information we request, you may still use AdsGrader.com, but
              you may be unable to access certain features or services.
            </p>
            <p>
              When you register to receive any products or services from Neil Patel Digital or
              provide information to Neil Patel Digital in any other manner, you agree to provide
              only true, accurate, current and complete information.
            </p>
            <p id="information-collected-automatically">
              <strong>Information automatically collected about you</strong>
            </p>
            <p>
              Neil Patel Digital may automatically receive and log certain types of information when
              you visit or interact with our websites, services, mobile applications or other
              products, including:
            </p>
            <ul>
              <li>Browser information</li>
              <li>Operating system information</li>
              <li>
                Mobile device information (e.g., device identifier, mobile operating system, etc.)
              </li>
              <li>IP address</li>
              <li>Internet service provider</li>
              <li>Content viewed</li>
              <li>Geographic location</li>
              <li>Connection speed</li>
              <li>Time of visit</li>
              <li>Referring site, application, or service</li>
              <li>Registration time</li>
            </ul>
            <p>
              These tools use `&apos;`cookies`&apos;`, which are text files placed on your computer,
              to collect standard internet log information and visitor behavior information in an
              anonymous form. The information generated by the cookie about your use of the website
              (including your IP address) is transmitted to Google and sometimes other vendors. This
              information is then used to evaluate visitors`&apos;` use of the website and to
              compile statistical reports on website activity.
            </p>
            <p>
              We will never (and will not allow any third party) use the statistical analytics tool
              to track or to collect any personally identifiable information of visitors to our
              site. The Web Analytics vendors do not associate your IP address with any other data
              held by them. Neither we nor the web analytics Vendors will link, or seek to link, an
              IP address with the identity of a computer user. We will not associate any data
              gathered with any personally identifying information from any source unless you
              explicitly provide consent, on the understanding that this may restrict your use of
              the Services.
            </p>
            <p>
              You have the ability to accept or decline cookies by modifying the settings on your
              browser.
            </p>
            <p>Information collected if you provide your website and expressly consent</p>
            <ul>
              <li>Keywords your site ranks for</li>
              <li>Click volume</li>
              <li>Search volume</li>
              <li>Click volume from mobile versus desktop traffic</li>
            </ul>
            <p id="information-received-from-third-parities">
              <strong>Information from our partners</strong>
            </p>
            <p>
              If you choose to use any third-party website or service that is integrated with Neil
              Patel Digital, including a third-party social networking, reporting or blogging site,
              such as LinkedIn, Facebook, Twitter, Google Analytics, Hotjar, or WordPress
              (“Third-Party Websites”) - or if you use any Neil Patel Digital application provided
              through any Third-Party Website - we may receive Information, including Personally
              Identifying Information, from such Third-Party Websites, including, but not limited
              to:
            </p>
            <ul>
              <li>Name</li>
              <li>Nickname or username</li>
              <li>Photo or graphic avatar</li>
              <li>Unique Third-Party Website identifier (e.g., Facebook User ID)</li>
              <li>
                Biographical or demographic information (e.g. professional title, gender, college
                attended, etc.)
              </li>
              <li>Likes and interests</li>
              <li>Contacts/friends/associations</li>
              <li>Any information that is publicly available on Third-Party Websites</li>
            </ul>
            <p>
              For example, Neil Patel Digital may offer the ability to use certain Third-Party
              Websites to facilitate your registration on Neil Patel Digital. We may also use
              information about your profile and connections on Third-Party Websites to allow you to
              share or connect with your friends and contacts on Neil Patel Digital or to
              personalize your experience. In addition, Neil Patel Digital offers “plugins” and
              “widgets” from various Third-Party Websites that allow you to share Neil Patel Digital
              content off of our service.
            </p>
            <p>
              Your decision to use, or share with, a Third-Party Website when accessing or using
              Neil Patel Digital products and services is completely voluntary. Neil Patel Digital
              is not responsible for compliance with the policies or practices of any Third-Party
              Website. You should ensure that you are comfortable with the information such
              Third-Party Websites may make available to Neil Patel Digital by reviewing those
              Websites`&apos;` privacy policies and service terms, and by modifying your privacy
              settings and preferences on those Services.
            </p>
            <p>
              Any information we receive from Third-Party Websites will be governed by this Privacy
              Policy.
            </p>
            <p>
              <strong>Publicly available information</strong>
            </p>
            <p>We might gather information about you that is publicly available.</p>
            <p>
              <strong>Transfer of personal data abroad</strong>
            </p>
            <p>
              If you utilize the Services from a country other than the country where the Neil Patel
              Digital servers are located, your communications with Neil Patel Digital may result in
              transferring your personal data across international borders. Also, when you call Neil
              Patel Digital, we may provide you with support from our locations which may be outside
              of your country of origin. In these cases, your personal data is handled according to
              this Policy.
            </p>
            <h2 id="gdpr-processing">How we use your Personal Data</h2>
            <p>We use your Personal Data to:</p>
            <ul>
              <li>Provide Neil Patel Digital products and services</li>
              <li>Fulfill your requests</li>
              <li>Communicate with you</li>
              <li>Improve our products and services</li>
              <li>Develop new services or products</li>
              <li>Personalize and tailor your experience on Neil Patel Digital</li>
              <li>Operate our business</li>
              <li>Understand how users are engaging with Neil Patel Digital</li>
              <li>
                Protect Neil Patel Digital, our users, or others. This includes detecting,
                preventing, and responding to fraud, abuse, security risks, and technical issues
                that could harm Neil Patel Digital, our users, or others.
              </li>
            </ul>
            <p>We use your Personal Data on legitimate grounds and with your consent.</p>
            <p>
              On the grounds of entering into a contract or fulfilling contractual obligations, we
              Process your Personal Data for the following purposes:
            </p>
            <ul>
              <li>To identify you for communication purposes</li>
              <li>To provide you a service or to send/offer you a product</li>
              <li>To communicate either for sales or invoicing</li>
            </ul>
            <p>
              On the ground of legitimate interest, we Process your Personal Data for the following
              purposes:
            </p>
            <ul>
              <li>
                To send you personalized offers (from us and/or our carefully selected partners);
              </li>
              <li>
                To administer and analyze our client base (purchasing behavior and history) in order
                to improve the quality, variety, and availability of products/ services
                offered/provided;
              </li>
              <li>To conduct questionnaires concerning client satisfaction</li>
            </ul>
            <p>
              As long as you have not informed us otherwise, we consider offering you
              products/services that are similar or same to your purchasing history/browsing
              behavior to be our legitimate interest.
            </p>
            <p>With your consent we Process your Personal Data for the following purposes:</p>
            <ul>
              <li>
                To send you newsletters and campaign offers (from us and/or our carefully selected
                partners);
              </li>
              <li>
                To build a database of relevant search information to improve the quality, variety,
                and availability of our products and services;
              </li>
              <li>For other purposes we have asked your consent for</li>
            </ul>
            <p>
              We Process your Personal Data to fulfill obligations arising from law and/or use your
              Personal Data for options provided by law. We reserve the right to anonymize Personal
              Data gathered and to use any such data. We will use data outside the scope of this
              Policy only when it is anonymized. We save your billing information and other
              information gathered about you for as long as needed for accounting purposes or other
              obligations deriving from law.
            </p>
            <p>
              We might process your Personal Data for additional purposes that are not mentioned
              here but are compatible with the original purpose for which the data was gathered. To
              do this, we will ensure that:
            </p>
            <ul>
              <li>
                The link between purposes, context and nature of Personal Data is suitable for
                further Processing;
              </li>
              <li>The further Processing would not harm your interests; and</li>
              <li>There would be appropriate safeguard for Processing.</li>
            </ul>
            <p>We will inform you of any further Processing and purposes.</p>
            <h2 id="gdpr-sharing">Who else can access your Personal Data</h2>
            <p>
              We do not share your Personal Data with strangers. Personal Data about you is in some
              cases provided to our trusted partners in order to either make providing the service
              to you possible or to enhance your customer experience. We share your data with:
            </p>
            <p>
              <strong>Our processing partners:</strong>
            </p>
            <ul>
              <li>
                <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
                  Amazon Web Services
                </a>
              </li>
              <li>
                <a href="https://www.wpengine.com/" target="_blank" rel="noopener noreferrer">
                  WPengine
                </a>
              </li>
            </ul>
            <p>
              <strong>Our business partners:</strong>
            </p>
            <ul>
              <li>Neil Patel Digital, LLC</li>
              <li>Neil Patel Digital, LLP</li>
              <li>NPAccel, LLC</li>
              <li>Partners International Ltda</li>
              <li>Angels &amp; Entrepreneurs, LLC</li>
            </ul>
            <p>
              <strong>Connected Third Parties:</strong>
            </p>
            <ul>
              <li>
                <a href="https://www.crazyegg.com/" target="_blank" rel="noopener noreferrer">
                  Crazy Egg
                </a>
              </li>
              <li>
                <a href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer">
                  Google Analytics
                </a>
              </li>
              <li>
                <a href="https://optimize.google.com/" target="_blank" rel="noopener noreferrer">
                  Google Optimize
                </a>
              </li>
              <li>
                <a
                  href="https://cloud.google.com/bigquery"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google BigQuery
                </a>
              </li>
              <li>
                <a
                  href="https://developers.google.com/apis-explorer/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google API
                </a>
              </li>
              <li>
                <a href="https://hotjar.com/" target="_blank" rel="noopener noreferrer">
                  Hotjar
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.wordpress.com/" target="_blank" rel="noopener noreferrer">
                  WordPress
                </a>
              </li>
              <li>
                <a href="https://www.gotowebinar.com/" target="_blank" rel="noopener noreferrer">
                  GoToWebinar
                </a>
              </li>
              <li>
                <a href="https://www.webinarjam.com/" target="_blank" rel="noopener noreferrer">
                  WebinarJam
                </a>
              </li>
              <li>
                <a href="https://www.everwebinar.com/" target="_blank" rel="noopener noreferrer">
                  EverWebinar
                </a>
              </li>
              <li>
                <a href="https://www.convertkit.com/" target="_blank" rel="noopener noreferrer">
                  ConvertKit
                </a>
              </li>
              <li>
                <a href="https://www.subscribers.com/" target="_blank" rel="noopener noreferrer">
                  Subscribers
                </a>
              </li>
              <li>
                <a href="https://www.salesforce.com/" target="_blank" rel="noopener noreferrer">
                  Salesforce
                </a>
              </li>
            </ul>
            <p>
              We only work with Processing partners who are able to ensure an adequate level of
              protection to your Personal Data. We disclose your Personal Data to third parties or
              public officials when we are legally obliged to do so. We might disclose your Personal
              Data to third parties if you have consented to it or if there are other legal grounds
              for it including for business or commercial purposes. At times, we may not be
              available to provide marketing services for you so we have established relationships
              with companies who may be able to better serve your marketing needs.
            </p>
            <p>
              Some of our products will ask you to identify with either Google or Facebook so we can
              retrieve information on your behalf. We will not look at your individual data. We do
              reserve the right to aggregate usage data to measure the performance of our
              applications, but no identifiable personal information will ever be disclosed to third
              parties.
            </p>
            <p>
              To opt out, please contact us at{' '}
              <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>.
            </p>
            <h2>External Links</h2>
            <p>
              You should be aware that other Internet sites that are linked from the Neil Patel
              Digital websites or from Neil Patel Digital e-mail messages may contain privacy
              provisions that differ from the provisions of this Policy. To ensure your privacy is
              protected, we recommend that you review the privacy statements of these other linked
              sites, applications, or other digital platforms.
            </p>
            <h2>Nevada Consumers</h2>
            <p>
              Nevada law gives Nevada consumers the right to request that a company not sell their
              personal information. This right applies even if their personal information is not
              currently being sold. If you are a Nevada consumer and wish to exercise this right,
              please submit your request by filling out the form after clicking on{' '}
              <a href="https://neilpatel.com/do-not-track/">this link</a>.
            </p>
            <h2>Your California Privacy Rights</h2>
            <p>
              Neil Patel Digital, at times (including in the past 12 months), sells the Personal
              Information of users to Third Parties for the Third Parties`&apos;` direct marketing
              purposes and discloses Personal Information to Third Parties for our business
              purposes.
            </p>
            <p>
              <strong>If you are a California resident, you have the right to:</strong>
            </p>
            <ul>
              <li>
                Opt-out of the sale of your Personal Information to certain affiliates and other
                Third Parties for their marketing purposes. To exercise this right, please submit
                your request by filling out the form after clicking on{' '}
                <a href="https://neilpatel.com/do-not-track/">this link</a>, or by emailing{' '}
                <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>. You do not need to
                verify your identity to opt out. - Or you can go to this page,{' '}
                <a href="https://neilpatel.com/do-not-track/">Do Not Sell My Info</a>.
              </li>
              <li>
                Request to know the categories of your Personal Information we have collected, used,
                sold, and/or disclosed to Third Parties, for the Third Parties`&apos;` direct
                marketing purposes or for our business purpose, during the preceding twelve months;
              </li>
              <li>
                Request that we delete your Personal Information we have collected and notify Third
                Parties to whom we have sold your Personal Information within the 90 days prior to
                your request that you have exercised your right to opt-out, and instruct the Third
                Parties not to further sell the information. To ensure we don`&apos;`t delete
                information you don`&apos;`t truly want deleted, we will require a two-step process
                where we will ask you to confirm you want your Personal Information deleted; and
              </li>
              <li>
                Not receive discriminatory treatment by us for exercising your right to privacy
                under the California Consumer Privacy Act. To submit your request to know and/or
                delete, please call 1 (800) 656-1628 or{' '}
                <a href="https://neilpatel.com/do-not-track/">click here.</a>
                <ul>
                  <li>
                    1. For your protection, we will require that you verify your identity before we
                    provide you with information about the use of your Personal Information. The
                    means of verification will depend on the sensitivity of the information you are
                    requesting, but will not be more burdensome than necessary to protect your
                    Personal Information.
                    <ul>
                      <li>
                        (a)&nbsp; If you have a password-protected account, we may require you to
                        login to confirm your identity or we may ask that you comply with item (b)
                        below.
                      </li>
                      <li>
                        (b)&nbsp; If you do not have a password-protected account, we will ask that
                        you match personal information we maintain for you.
                      </li>
                    </ul>
                  </li>
                  <li>
                    2. <span style={{ textDecoration: 'underline' }}>Categories</span>: If you are
                    requesting the specific categories of personal information we maintain, we will
                    ask you to match at least two items of your Personal Information.
                  </li>
                  <li>
                    3. <span style={{ textDecoration: 'underline' }}>Specific pieces</span>: If you
                    want to know specific pieces of your Personal Information that we maintain, we
                    may require you to match at least three items of your Personal Information and
                    have you provide a declaration, signed under penalty of perjury, confirming your
                    identity.
                  </li>
                  <li>
                    4. <span style={{ textDecoration: 'underline' }}>Request to delete</span>:&nbsp;
                    Depending on the sensitivity of the information you are requesting be deleted,
                    we will require that you comply with either 1(a) or 1(b) of this Section
                  </li>
                  <li>
                    5. <span style={{ textDecoration: 'underline' }}>Authorized Agent</span>: An
                    Authorized Agent may exercise your right to know, right to delete, or right to
                    opt out, but we will need some extra paperwork to be sure you gave them
                    permission. The Authorized Agent will need to provide:
                    <ul>
                      <li>
                        (a) Written permission from you and verification of their own identity; or
                      </li>
                      <li>(b) A Power of Attorney.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <h2 id="gdpr-security">How we secure your data</h2>
            <p>
              We do our best to keep your Personal Data safe.&nbsp;We use safe protocols for
              communication and transferring data (such as HTTPS). We use anonymizing and
              pseudonymizing where suitable. We monitor our systems for possible vulnerabilities and
              attacks. Stored data is encrypted when possible.
            </p>
            <p>
              Even though we try our best we can not guarantee the security of information. However,
              we promise to notify suitable authorities of data breaches. We will also notify you if
              there is a threat to your rights or interests. We will do everything we reasonably can
              to prevent security breaches and to assist authorities should any breaches occur.
            </p>
            <p>
              If you have an account with us, note that you have to keep your username and password
              secret.
            </p>
            <h2>Children</h2>
            <p>
              We recognize the particular importance of protecting privacy where children are
              involved. We do not intend to collect, knowingly collect, sell, or solicit Personal
              Information from anyone under the age of 16. We do not target children with our
              services. If you are under 16, do not use or provide any information on the Website or
              through any of its features. If you believe that a child under the age of 16 may have
              provided us Personal Data online, we ask that a parent or guardian contact us at{' '}
              <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>.
            </p>
            <h2 id="gdpr-cookies">Cookies and other technologies we use</h2>
            <p>
              We use cookies and/or similar technologies to analyze customer behavior, administer
              the website, track users`&apos;` movements, and to collect information about users.
              This is done in order to personalize and enhance your experience with us.
            </p>
            <p>
              A cookie is a tiny text file stored on your computer. Cookies store information that
              is used to help make sites work. Only we can access the cookies created by our
              website. You can control your cookies at the browser level. Choosing to disable
              cookies may hinder your use of certain functions.
            </p>
            <p>
              <strong>We use cookies for the following purposes:</strong>
            </p>
            <ul>
              <li>
                Necessary cookies - these cookies are required for you to be able to use some
                important features on our website, such as logging in. These cookies don`&apos;`t
                collect any personal information.
              </li>
              <li>
                Functionality cookies - these cookies provide functionality that makes using our
                service more convenient and makes providing more personalized features possible. For
                example, they might remember your name and e-mail in comment forms so you
                don`&apos;`t have to re-enter this information next time when commenting.
              </li>
              <li>
                Analytics cookies - these cookies are used to track the use and performance of our
                website and services
              </li>
              <li>
                Advertising cookies - these cookies are used to deliver advertisements that are
                relevant to you and to your interests. In addition, they are used to limit the
                number of times you see an advertisement. They are usually placed to the website by
                advertising networks with the website operator`&apos;`s permission. These cookies
                remember that you have visited a website and this information is shared with other
                organizations such as advertisers. Often targeting or advertising cookies will be
                linked to site functionality provided by the other organization.
              </li>
            </ul>
            <p>
              <strong>Here are the cookies we use:</strong>
            </p>
            <ul>
              <li>
                Cookie policy - persistent cookie - 1-year duration - we set this cookie to limit
                showing the cookie policy on every page view/visits.
              </li>
              <li>
                <a href="https://google.com/analytics/" target="_blank" rel="noopener noreferrer">
                  Google Analytics
                </a>{' '}
                - persistent cookie - 2-year duration - we use Google Analytics to improve the user
                experience on the website (
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  you can opt out here
                </a>
                )
              </li>
              <li>
                <a href="https://crazyegg.com/" target="_blank" rel="noopener noreferrer">
                  Crazy Egg
                </a>{' '}
                - third party cookie - 6-month duration - we use Crazy Egg to improve the user
                experience on the website
              </li>
              <li>
                Redirection cookie - persistent cookie - 1-year duration - we redirect you to the
                language that is best suited from the country you are visiting from
              </li>
              <li>
                Popup cookie - persistent cookie - 1-year duration - we offer you the chance to be
                notified of new content and marketing materials
              </li>
              <li>
                <a href="https://subscribers.com/" target="_blank" rel="noopener noreferrer">
                  Subscribers.com
                </a>{' '}
                - persistent cookie - 10 year duration - you have to opt in through your browser to
                receive anything through subscribers and you can opt out through your browser
                settings.
              </li>
              <li>
                <a href="https://youtube.com/" target="_blank" rel="noopener noreferrer">
                  YouTube
                </a>{' '}
                - persistent cookie - 30-day duration - we embed YouTube videos onto our site to
                provide you with additional educational material.
              </li>
              <li>
                <a href="https://hubspot.com/" target="_blank" rel="noopener noreferrer">
                  HubSpot
                </a>{' '}
                - persistent cookie - 2-year duration - we use HubSpot as our CRM to follow up with
                sales leads
              </li>
              <li>
                <a href="https://www.oncehub.com/" target="_blank" rel="noopener noreferrer">
                  OnceHub
                </a>{' '}
                - persistent cookie - 2-year duration - this allows you to schedule calls with our
                team
              </li>
              <li>
                <a href="https://wistia.com/" target="_blank" rel="noopener noreferrer">
                  Wistia
                </a>{' '}
                - persistent cookie - 1-year duration - we use Wistia to stream educational content.
              </li>
            </ul>
            <p>
              You can remove cookies stored in your computer via your browser settings.
              Alternatively, you can control some 3rd party cookies by using a privacy enhancement
              platform such as{' '}
              <a href="http://optout.aboutads.info/#!/" target="_blank" rel="noopener noreferrer">
                optout.aboutads.info
              </a>{' '}
              or{' '}
              <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener noreferrer">
                youronlinechoices.com
              </a>
              . For more information about cookies, visit{' '}
              <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">
                allaboutcookies.org
              </a>
              .
            </p>
            <p>
              We use Google Analytics to measure traffic on our website. Google has their own
              Privacy Policy which you can review{' '}
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              . If you`&apos;`d like to opt out of tracking by Google Analytics, visit the{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics opt-out page
              </a>
              .
            </p>
            <h2>Our “Do Not Track” Policy</h2>
            <p>
              California and Delaware law requires us to let you know how we respond to web browser
              Do Not Track (DNT) signals. Because there isn`&apos;`t an industry or legal standard
              for recognizing or honoring browser DNT signals, we don`&apos;`t monitor or respond to
              them at this time.
            </p>
            <h2>Arbitration Clause</h2>
            <p>
              <strong>Individual Arbitration as Sole Remedy for Dispute Resolution</strong>
            </p>
            <p>
              Any dispute, claim or controversy arising out of or relating to this Privacy Policy or
              the breach, termination, enforcement, interpretation or validity thereof, including
              the determination of the scope or applicability of this agreement to arbitrate, shall
              be determined by final and binding individual (not class, representative, nor
              collective) arbitration in San Diego, California before one arbitrator. The language
              to be used in the arbitral proceedings will be English. The arbitration shall be
              administered by the Office of Judicial Arbitration and Mediation Service (“JAMS”).
              JAMS`&apos;`s rules governing the arbitration may be obtained from JAMS`&apos;`s
              website, which currently is{' '}
              <a href="http://www.jamsadr.com/" target="_blank" rel="noopener noreferrer">
                www.jamsadr.com
              </a>
              . Judgment on the Award may be entered in any court having jurisdiction. This clause
              shall not preclude parties from seeking provisional remedies in aid of arbitration
              from a court of appropriate jurisdiction.
            </p>
            <p>
              If you are not a resident of the United States, then any dispute, controversy or claim
              arising out of or relating to this Privacy Policy, including the formation,
              interpretation, breach or termination thereof, including whether the claims asserted
              are arbitrable, will be referred to and finally determined by final and binding
              individual arbitration in accordance with the JAMS International Arbitration Rules.
              The Tribunal will consist of one arbitrator. The place of arbitration will be San
              Diego, California. The language to be used in the arbitral proceedings will be
              English. Judgment upon the award rendered by the arbitrator may be entered in any
              court having jurisdiction thereof.
            </p>
            <p>
              <strong>Negotiation in Advance of Arbitration</strong>
            </p>
            <ol>
              <li>
                The parties shall attempt in good faith to resolve any dispute arising out of or
                relating to this Privacy Policy promptly by negotiation between persons who have
                authority to settle the controversy and who are at a higher level of management than
                the persons with direct responsibility for administration of this Privacy Policy.
                Any party may give the other party written notice of any dispute not resolved in the
                normal course of business. Within 15 days after delivery of the notice, the
                receiving party shall submit to the other a written response. The notice and
                response shall include with reasonable particularity (a) a statement of each
                party`&apos;`s position and a summary of arguments supporting that position, and (b)
                the name and title of the executive who will represent that party and of any other
                person who will accompany the executive. Within 30 days after delivery of the
                notice, the executives of both parties shall meet at a mutually acceptable time and
                place.
              </li>
              <li>
                Unless otherwise agreed in writing by the negotiating parties, the above-described
                negotiation shall end at the close of the first meeting of executives described
                above (“First Meeting”). Such closure shall not preclude continuing or later
                negotiations, if desired. All offers, promises, conduct and statements, whether oral
                or written, made in the course of the negotiation by any of the parties, their
                agents, employees, experts and attorneys are confidential, privileged and
                inadmissible for any purpose, including impeachment, in arbitration or other
                proceeding involving the parties, provided that evidence that is otherwise
                admissible or discoverable shall not be rendered inadmissible or non-discoverable as
                a result of its use in the negotiation.
              </li>
              <li>
                At no time prior to the First Meeting shall either side initiate an arbitration or
                litigation related to this Privacy Policy except to pursue a provisional remedy that
                is authorized by law or by JAMS Rules or by agreement of the parties. However, this
                limitation is inapplicable to a party if the other party refuses to comply with the
                requirements of Paragraph 1 above.
              </li>
            </ol>
            <p>
              <strong>Requirements for Modification or Revocation</strong> This agreement to
              arbitrate shall survive the termination of Neil Patel Digital`&apos;`s relationship
              with you. It can only be revoked or modified by a writing executed by Neil Patel
              Digital and you that specifically states an intent to revoke or modify this agreement
              to arbitrate.
            </p>
            <p>
              <strong>Claims Covered by Arbitration Clause</strong>
            </p>
            <p>
              Neil Patel Digital and you mutually consent to the resolution by final and binding
              arbitration of all claims or controversies (collectively, the “Claims”) that Neil
              Patel Digital may have against you or you may have against Neil Patel Digital or
              against its past, present, or future predecessors, successors, assigns, affiliates,
              parent and subsidiary companies, and joint ventures, and their respective past,
              present, or future officers, directors, employees, stockholders, representatives,
              managers, members, partners, agents, advisors, insurers, and indemnities (collectively
              referred to as the “Neil Patel Digital Parties”), relating to, resulting from, or in
              any way arising out of your relationship with Neil Patel Digital. The Claims include,
              but are not limited to claims for penalties, fines, claims for breach of any contract
              (express or implied); tort claims (including, but not limited to, those relating to
              reputation); claims for violation of trade secret, proprietary, or confidential
              information laws; claims for unfair business practices; and claims for violation of
              any public policy, federal, state, international, or other governmental law, statute,
              regulation, or ordinance.
            </p>
            <p>
              <strong>Required Notice of Claims and Statute of Limitations</strong>
            </p>
            <p>
              Neil Patel Digital may initiate arbitration by serving or mailing a written notice to
              you at the last known address. you may initiate arbitration by serving or mailing a
              written notice to Neil Patel Digital. The written notice must specify with reasonable
              particularity the claims asserted against the other party. Notice of any claim sought
              to be arbitrated must be served within the limitations period established by
              applicable federal or state law. After demand for arbitration has been made by serving
              written notice, the party demanding arbitration shall file a demand for arbitration
              with the Office of Judicial Arbitration and Mediation Service (“JAMS”) located in San
              Diego, California.
            </p>
            <p>
              <strong>Confidentiality</strong>
            </p>
            <p>
              The parties shall maintain the confidential nature of the arbitration proceeding and
              the award, if any, including the hearing, except as may be necessary to prepare for or
              conduct the arbitration hearing on the merits, or except as may be necessary in
              connection with a court application for a preliminary remedy, a judicial challenge to
              an award or its enforcement, or unless otherwise required by law or judicial decision.
            </p>
            <p>
              <strong>Dispositive Motions</strong>
            </p>
            <p>In any arbitration arising out of or related to this Agreement:</p>
            <ol>
              <li>
                Any party wishing to make a dispositive motion shall first submit a brief letter
                (not exceeding five pages) explaining why the motion has merit and why it would
                speed the proceeding and make it more cost-effective. The other side shall have a
                brief period within which to respond.
              </li>
              <li>
                Based on the letters, the arbitrator will decide whether to proceed with more
                comprehensive briefing and argument on the proposed motion.
              </li>
              <li>
                If the arbitrator decides to go forward with the motion, he/she will place page
                limits on the briefs and set an accelerated schedule for the disposition of the
                motion.
              </li>
              <li>
                Under ordinary circumstances, the pendency of such a motion will not serve to stay
                any aspect of the arbitration or adjourn any pending deadlines.
              </li>
            </ol>
            <p>
              <strong>Document Requests</strong>
            </p>
            <p>
              In any arbitration arising out of or related to this Agreement, requests for
              documents:
            </p>
            <ol>
              <li>
                Shall be limited to documents which are directly relevant to significant issues in
                the case or to the case`&apos;`s outcome;
              </li>
              <li>
                Shall be restricted in terms of time frame, subject matter and persons or entities
                to which the requests pertain; and
              </li>
              <li>
                Shall not include broad phraseology such as “all documents directly or indirectly
                related to.”
              </li>
            </ol>
            <p>
              <strong>E-Discovery</strong>
            </p>
            <p>In any arbitration arising out of or related to this Agreement:</p>
            <ol>
              <li>
                There shall be production of electronic documents only from sources used in the
                ordinary course of business. Absent a showing of compelling need, no such documents
                are required to be produced from backup servers, tapes or other media.
              </li>
              <li>
                Absent a showing of compelling need, the production of electronic documents shall
                normally be made on the basis of generally available technology in a searchable
                format which is usable by the party receiving the e-documents and convenient and
                economical for the producing party. Absent a showing of compelling need, the parties
                need not produce metadata, with the exception of header fields for email
                correspondence.
              </li>
              <li>
                The description of custodians from whom electronic documents may be collected shall
                be narrowly tailored to include only those individuals whose electronic documents
                may reasonably be expected to contain evidence that is material to the dispute.
              </li>
              <li>
                Where the costs and burdens of e-discovery are disproportionate to the nature of the
                dispute or to the amount in controversy, or to the relevance of the materials
                requested, the arbitrator will either deny such requests or order disclosure on
                condition that the requesting party advance the reasonable cost of production to the
                other side, subject to the allocation of costs in the final award<em>.&nbsp;</em>
              </li>
            </ol>
            <p>
              <strong>Interrogatories and Requests to Admit.</strong>&nbsp;In any arbitration
              arising out of or related to this Agreement, there shall be no interrogatories or
              requests to admit.
            </p>
            <p>
              <strong>Governing Law and Arbitrator Authority</strong>
            </p>
            <ol>
              <li>
                Except as otherwise provided herein, this Privacy Policy and the rights of the
                Parties hereunder shall be governed by and construed in accordance with the laws of
                the state of California, exclusive of conflict or choice of law rules.
              </li>
              <li>
                The Parties acknowledge that this Privacy Policy evidences a transaction involving
                interstate or international commerce. Notwithstanding the provision in the preceding
                paragraph with respect to applicable substantive law, any arbitration conducted
                pursuant to the terms of this Privacy Policy shall be governed by the Federal
                Arbitration Act (<em>9 U.S.C. §§ 1-16)</em>. The arbitrator shall apply the
                substantive laws (and the law of remedies, if applicable) of the state of
                California, federal law, or international law, as applicable to the claim(s)
                asserted.
              </li>
              <li>
                The arbitrator shall have exclusive authority to resolve any dispute relating to the
                interpretation, applicability, enforceability, or formation of this Privacy Policy,
                including, but not limited to, any claim that all or any part of this Privacy Policy
                is void or voidable and issues of arbitrability of any matter herein.
              </li>
              <li>
                The arbitrator is not empowered to award punitive or exemplary damages, except where
                permitted by statute, and the parties waive any right to recover any such damages.
              </li>
            </ol>
            <p>
              <strong>Arbitration Decision</strong>
            </p>
            <p>
              The arbitrator`&apos;`s decision shall be final and binding and shall not be subject
              to appeal. The arbitrator shall issue a written arbitration decision revealing the
              essential findings and conclusions upon which the decision and/or award is based. Any
              decision or award made by the arbitrator shall be enforceable by a court of competent
              jurisdiction. The parties irrevocably stipulate to the confidential nature of the
              arbitral award and proceedings and further stipulate that the award and any papers of
              the proceedings shall only be filed with a court of competent jurisdiction under
              confidential seal and only to enforce an arbitral award or decision.
            </p>
            <p>
              <strong>Application for Emergency Injunctive and/or Other Equitable Relief.</strong>
              &nbsp;JAMS Comprehensive Rules shall apply for the appointment of an Emergency
              Arbitrator to address and decide a request for emergency relief.
            </p>
            <p>
              <strong>WAIVER OF REPRESENTATIVE/CLASS ACTION PROCEEDINGS</strong>
            </p>
            <p>
              BY REGISTERING TO RECEIVE ANY PRODUCTS OR SERVICES FROM US OR PROVIDING YOUR
              INFORMATION TO US IN, YOU KNOWINGLY AND VOLUNTARILY AGREE TO BRING ANY CLAIMS
              (LAWSUITS) AGAINST NEIL PATEL DIGITAL IN YOUR INDIVIDUAL CAPACITY AND NOT AS A
              PLAINTIFF, CLASS MEMBER, GROUP, OR REPRESENTATIVE IN ANY PURPORTED REPRESENTATIVE,
              COLLECTIVE, OR CLASS ACTION. YOU AGREE TO WAIVE ANY RIGHT TO PARTICIPATE IN ANY
              REPRESENTATIVE, COLLECTIVE, OR CLASS ACTION PROCEEDING (A LAWSUIT WHERE YOU SUE AS A
              GROUP RATHER THAN BEING THE ONLY PLAINTIFF) RELATED TO ANY CLAIMS GOVERNED BY THIS
              PRIVACY POLICY. YOU FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN
              ONE INDIVIDUAL`&apos;`S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF
              REPRESENTATIVE, COLLECTIVE, OR CLASS ACTION PROCEEDING, INCLUDING, BUT NOT LIMITED TO,
              ANY REPRESENTATIVE ACTION UNDER CALIFORNIA BUSINESS AND PROFESSIONS CODE SECTIONS
              17200{' '}
              <strong>
                <em>ET SEQ.</em>
              </strong>
            </p>
            <p>
              <strong>WAIVER OF JURY TRIAL/EXCLUSIVE REMEDY</strong>
            </p>
            <p>
              THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION REWARD IS
              LIMITED.&nbsp; BY AGREEING TO ARBITRATION, NEIL PATEL DIGITAL AND YOU ARE AGREEING TO
              WAIVE ANY CONSTITUTIONAL RIGHT TO A JURY OR COURT TRIAL OF COVERED CLAIMS.
            </p>
            <p>
              <strong>Arbitration Fees and Costs</strong>
            </p>
            <p>
              Each party shall advance its own costs and expenses in any such arbitration and
              one-half (1/2) of the arbitrator`&apos;`s fees and costs, however, the arbitrator
              shall award attorneys`&apos;` fees and costs to Neil Patel Digital if Neil Patel
              Digital is the prevailing party. Any decision or award of such arbitration proceeding
              shall be confidential and may be made public only with the prior written consent of
              both you and Neil Patel Digital.
            </p>
            <p>
              <strong>Severability</strong> (Arbitration Clause)
            </p>
            <p>
              Neil Patel Digital and you agree and acknowledge that if any section, subsection,
              sentence, clause, or phrase contained in this Arbitration Clause is found to be
              invalid, unenforceable, or otherwise inoperative, such decision shall not affect the
              validity of the remaining portion(s) of this Arbitration Clause.
            </p>
            <h2 id="gdpr-contact">Contact Information</h2>
            <p>
              If you have any questions or comments about this Privacy Policy, or if you would like
              us to update information we have about you or your preferences, please contact us by
              email at: <a href="mailto:neil@neilpatel.com">neil@neilpatel.com</a>
            </p>
            <p>You may also write to us at:</p>
            <p>
              Neil Patel Digital
              <br />
              750 B ST STE 1400&nbsp;
              <br />
              SAN DIEGO CA 92101-8190
            </p>
            <h2>Severability (Privacy Policy)</h2>
            <p>
              Neil Patel Digital and you agree and acknowledge that if any section, subsection,
              sentence, clause, or phrase contained in this Privacy Policy is found to be invalid,
              unenforceable, or otherwise inoperative, such decision shall not affect the validity
              of the remaining portion(s) of this Privacy Policy.
            </p>
            <p>
              <strong>Changes to this Privacy Policy</strong>
            </p>
            <p>
              This Privacy Policy may be updated periodically and without prior notice to you. We
              will post a prominent notice on our Website to notify you of any significant changes
              to our Privacy Policy and indicate at the top of the Privacy Policy when it was most
              recently updated.
            </p>
          </div>
        </article>
      </Col>
    </Row>

    <PrivacyFooter />
  </div>
)

export default PrivacyPage
