import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Table } from 'antd'
import { FaQuestionCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { Text } from '@components'
import { benchmarkData } from '../../data/index'

import styles from './benchmark.module.css'

//  eslint-disable-next-line
export const RenderHtml = ({ value, color, totalValue, background }) => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, newValue] = param.split('=')
      values[key] = decodeURIComponent(newValue)
      localStorage.setItem(key, decodeURIComponent(newValue))
    })
  }
  const { t } = useTranslation()

  const resultingPercentage = 100 / totalValue
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text>{value === '' ? `${t('benchmark.notEnoughData')}` : Number(value).toFixed(2)}</Text>
        <div
          style={{
            backgroundColor: background,
            width: '174px',
            display: 'flex',
            flexDirection: 'row',
            height: '12px',
            borderRadius: '2px',
          }}
        >
          {/* eslint-disable-next-line */}
          <div
            style={{
              backgroundColor: color,
              width: `${Math.trunc(resultingPercentage * value)}%`,
              textAlign: 'right',
              display: 'flex',
              borderRadius: '2px',
            }}
          ></div>
        </div>
      </div>
    </>
  )
}
const TooltipRangeContent = ({ background, minValue, maxValue, heading }) => (
  <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '.5rem' }}>
    {/* eslint-disable-next-line */}
    <div
      style={{ height: '10px', width: '10px', borderRadius: '2px', backgroundColor: background }}
    ></div>
    <Text style={{ color: 'white', fontSize: '12px', fontWeight: '400' }}>{heading}</Text>
    <Text style={{ color: 'white', fontSize: '12px', fontWeight: '400' }}>
      {minValue} - {maxValue}
    </Text>
  </div>
)
function Benchmark() {
  const [perLeadMin, setPerLeadMin] = useState()
  const [perLeadMax, setPerLeadMax] = useState()
  const [perClickMin, setPerClickMin] = useState()
  const [perClickMax, setPerClickMax] = useState()
  const [CTRMin, setCTRMin] = useState()
  const [CTRMax, setCTRMax] = useState()
  const [CRMin, setCRMin] = useState()
  const [CRMax, setCRMax] = useState()

  const { t } = useTranslation()

  const filteredBenchmarkData = benchmarkData.filter(
    ({ perClick, perLead, clickThroughRate, conversionRate }) =>
      perClick !== '' || perLead !== '' || clickThroughRate !== '' || conversionRate !== ''
  )
  // ......................................................................................................................................
  // Extracting arrays for each property
  const perClickValues = filteredBenchmarkData
    .map(item => item.perClick)
    .filter(value => typeof value === 'number')
  const perLeadValues = filteredBenchmarkData
    .map(item => item.perLead)
    .filter(value => typeof value === 'number')
  const clickThroughRateValues = filteredBenchmarkData
    .map(item => item.clickThroughRate)
    .filter(value => typeof value === 'number')
  const conversionRateValues = filteredBenchmarkData
    .map(item => item.conversionRate)
    .filter(value => typeof value === 'number')

  // Finding minimum and maximum values for each property
  useEffect(() => {
    if (filteredBenchmarkData) {
      setPerClickMin(Math.min(...perClickValues))
      setPerClickMax(Math.max(...perClickValues))
      setPerLeadMin(Math.min(...perLeadValues))
      setPerLeadMax(Math.max(...perLeadValues))
      setCTRMin(Math.min(...clickThroughRateValues))
      setCTRMax(Math.max(...clickThroughRateValues))
      setCRMin(Math.min(...conversionRateValues))
      setCRMax(Math.max(...conversionRateValues))
    }
    // eslint-disable-next-line
  }, [filteredBenchmarkData])

  // ......................................................................................................................................

  const columns = [
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content} style={{ padding: '0 0 0 1.5rem' }}>
            {t('benchmark.headings.industries')}
          </Text>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (_, { name }) => (
        <Text className={styles.industries_list} style={{ padding: '0 1rem 0 1.5rem' }}>
          {t(name)}
        </Text>
      ),
    },
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>
            {t('benchmark.headings.AvgCostPerClick')} <span> ($)</span>
          </Text>
        </div>
      ),
      dataIndex: 'perClick',
      key: 'perClick',
      render: (_, { perClick }) => (
        <RenderHtml
          value={perClick}
          color="#FF9209"
          background="rgba(255, 146, 9, 0.30)"
          totalValue={perClickMax}
        />
      ),
    },
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>
            {t('benchmark.headings.AvgCostPerLead')} <span> ($)</span>
          </Text>
        </div>
      ),
      dataIndex: 'perLead',
      key: 'perLead',
      render: (_, { perLead }) => (
        <RenderHtml
          value={perLead}
          color="#F16435"
          background="rgba(241, 100, 53, 0.30)"
          totalValue={perLeadMax}
        />
      ),
    },
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>
            {t('benchmark.headings.AvgClickThroughRate')} <span> (%)</span>
          </Text>
        </div>
      ),
      dataIndex: 'clickThroughRate',
      key: 'clickThroughRate',
      render: (_, { clickThroughRate }) => (
        <RenderHtml
          value={clickThroughRate}
          color="#C23373"
          background="rgba(194, 51, 115, 0.30)"
          totalValue={CTRMax}
        />
      ),
    },
    {
      title: () => (
        <div className={styles.title_div}>
          <Text className={styles.title_content}>
            {t('benchmark.headings.AvgConversionRate')} <span> (%)</span>
          </Text>
        </div>
      ),
      dataIndex: 'conversionRate',
      key: 'conversionRate',
      render: (_, { conversionRate }) => (
        <RenderHtml
          value={conversionRate}
          color="#2B3499"
          background="rgba(43, 52, 153, 0.20)"
          totalValue={CRMax}
        />
      ),
    },
  ]

  return (
    <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', margin: '0px 30px' }}>
      <div style={{ margin: '24px 0' }}>
        <div className={styles.heading_div}>
          <Text className={styles.heading}>{t('benchMarks')}</Text>
          <Tooltip
            className={styles.tooltipText}
            placement="top"
            color="rgb(32, 32, 32)"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            title={
              <div style={{ padding: '0px' }}>
                <Text color="white" style={{ fontSize: '12px', fontWeight: '700' }}>
                  {t('minMax')}
                </Text>
                <TooltipRangeContent
                  background="#FF9209"
                  minValue={perClickMin}
                  maxValue={perClickMax}
                  heading={t('costPerClick')}
                />
                <TooltipRangeContent
                  background="#F16435"
                  minValue={perLeadMin}
                  maxValue={perLeadMax}
                  heading={t('costPerLead')}
                />
                <TooltipRangeContent
                  background="#C23373"
                  minValue={CTRMin}
                  maxValue={CTRMax}
                  heading={t('clickThroughRate')}
                />
                <TooltipRangeContent
                  background="#3B4BFF"
                  minValue={CRMin}
                  maxValue={CRMax}
                  heading={t('conversionRate')}
                />
              </div>
            }
          >
            <FaQuestionCircle style={{ color: '#A3B0B3' }} />
          </Tooltip>
        </div>
        <Text style={{ fontSize: '14px', fontWeight: '400', color: '#8F8F8F' }}>
          {t('benchMarksSubHeading')}
        </Text>
      </div>
      <Table
        style={{ border: '1px solid rgb(217, 217, 217)' }}
        dataSource={filteredBenchmarkData}
        columns={columns}
        pagination={false}
        className="custom-table"
        rowKey={item => item.id}
      />
    </div>
  )
}
export default Benchmark
