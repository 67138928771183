export const report = {
  loading: false,
  error: false,
  data: {
    id: 4394,
    unique_id: 'd5e15562-1c6b-4418-b593-027a7228afb6',
    customer_id: '123456789',
    created_at: '2023-07-24T13:26:32.575361Z',
    campaigns_total: 7,
    ad_groups_total: 24,
    ad_groups_per_campaign: 3.4285714285714284,
    keywords_total: 119,
    quality_score: 6.142857142857143,
    wasted_spent: 0.0,
    projected_wasted_spend: 100.08,
    click_through_rate: 0.0386331991836579,
    overall_spend: 5731.34545,
    overall_impressions: 104858.0,
    overall_click: 4051.0,
    overall_conversions: 308.0,
    overall_cpa: 18.608264448051948,
    display_spend: 0.0,
    display_impressions: 0.0,
    display_click: 0.0,
    display_conversions: 0.0,
    display_cpa: 0.0,
    search_spend: 2927.136619,
    search_impressions: 20544.0,
    search_click: 1337.0,
    search_conversions: 154.0,
    search_cpa: 19.007380642857143,
    performance_max_spend: 2804.208831,
    performance_max_impressions: 84314.0,
    performance_max_click: 2714.0,
    performance_max_conversions: 154.0,
    performance_max_cpa: 18.209148253246752,
    active_text_ads_total: 23.0,
    rsa_total: 23,
    eta_total: 0,
    exp_dsa_total: 0,
    rsa_per_ad_group_status: false,
    rsa_poor_and_average_percentage: 39.1,
    rsa_poor_and_average_status: true,
    rsa_poor_percentage: 21.7,
    rsa_average_percentage: 17.4,
    rsa_good_percentage: 26.1,
    rsa_excellent_percentage: 0.0,
    quality_score_more_clicks: 1012.75,
    quality_score_could_save_value: 1012.75,
    more_clicks_per_month: 1012,
    quality_score_over_impressions_chart: [
      {
        impressions: 0,
        quality_score: 0,
      },
      {
        impressions: 0,
        quality_score: 1,
      },
      {
        impressions: 0,
        quality_score: 2,
      },
      {
        impressions: 0,
        quality_score: 3,
      },
      {
        impressions: 0,
        quality_score: 4,
      },
      {
        impressions: 1072,
        quality_score: 5,
      },
      {
        impressions: 97,
        quality_score: 6,
      },
      {
        impressions: 1588,
        quality_score: 7,
      },
      {
        impressions: 950,
        quality_score: 8,
      },
      {
        impressions: 91,
        quality_score: 9,
      },
      {
        impressions: 0,
        quality_score: 10,
      },
    ],
    ctr_search_mobile_ctr: 0.06820399007979285,
    ctr_search_desktop_ctr: 0.025200928542339057,
    ctr_search_mobile_top_impressions_rate: 0.9024363714671454,
    ctr_search_desktop_top_impressions_rate: 0.7203091090242972,
    ctr_search_ctr: 0.049881858773396216,
    ctr_search_top_impressions_rate: 0.7492484934971476,
    ctr_display_mobile_ctr: 0.0,
    ctr_display_desktop_ctr: 0.0,
    ctr_display_mobile_top_impressions_rate: 0.0,
    ctr_display_desktop_top_impressions_rate: 0.0,
    ctr_display_ctr: 0.0,
    ctr_display_top_impressions_rate: 0.0,
    ctr_performance_max_ctr: 0.029201783458976144,
    has_overall: 7,
    has_search: 6,
    has_display: 0,
    has_performance_max: 1,
    wasted_spent_score: 75.0,
    quality_score_score: 87.75510204081634,
    general_score: 59.95904672695839,
    keywords_match_type_exact_percentage: 0.0,
    keywords_match_type_phrase_percentage: 5.0,
    keywords_match_type_broad_percentage: 95.0,
    search_absolute_top_impression_share: 55.829542190036214,
    search_budget_lost_absolute_top_impression_share: 6.9094239943144435,
    search_rank_lost_absolute_top_impression_share: 37.26103381564934,
    search_impression_share: 31.866163533439632,
    search_rank_lost_impression_share: 56.106980189847654,
    search_budget_lost_impression_share: 12.026856276712708,
    content_impression_share: 7.225920244454841,
    content_rank_lost_impression_share: 81.11152171600571,
    content_budget_lost_impression_share: 11.662558039539446,
    mobile_ad_projected_mobile_budget: 240.0,
    mobile_ad_projected_mobile_budget_percentage: 4.187498417147409,
    mobile_ad_mobile_cost: 5225.257171,
    mobile_ad_mobile_ctr: 0.060870209175131675,
    mobile_ad_mobile_top_imp_rate: 0.7219490971737164,
    mobile_ad_mobile_top_avg_cpc: 2.0540770832911153,
    mobile_ad_desktop_cost: 467.583239,
    mobile_ad_desktop_ctr: 0.030588646465658377,
    mobile_ad_desktop_top_imp_rate: 0.5762472872194377,
    mobile_ad_desktop_top_avg_cpc: 2.758481124244838,
    mobile_ad_sitelink_extension: 4.0,
    mobile_ad_call_extension: 0.0,
    language_targeting: true,
    mobile_bid_adjustments: true,
    geo_targeting: true,
    conversion_tracking: true,
    multiple_text_ads_running: false,
    modified_broad_match_type: false,
    negative_keywords: false,
    use_google_extensions: false,
    use_expanded_text_ads: true,
    negative_keywords_added: 0.0,
    ctr_increased: 4.829149897957238,
    ctr_more_clicks: 5064.0,
    ctr_more_conversion: 385.0,
    ctr_keyword_high: 107,
    ctr_keyword_low: 4,
    ctr_keyword_unspecified: 8,
    grader_ctr: 81.16218315894517,
    grader_long_tail: 6.25,
    grader_impression_share: 19.546041888947236,
    grader_best_practices: 50.0,
    grader_text_ads: 97.91666666666667,
    grader_cost_evolution: 100.0,
    recommendations: [
      {
        url:
          'https://adwords.google.com/aw_prime/recommendations/deeplink?opp=100&ocid=950152710&utm_source=astore&src=%SRC%&%ADDITIONAL_PARAMS%',
        uplift: 26.85,
        type_id: 2,
        priority: 1,
      },
      {
        url:
          'https://adwords.google.com/aw_prime/recommendations/deeplink?opp=228&ocid=950152710&campaignId=19871190475&utm_source=astore&src=%SRC%&%ADDITIONAL_PARAMS%',
        uplift: 9.94,
        type_id: 19,
        priority: 2,
      },
      {
        url:
          'https://adwords.google.com/aw_prime/recommendations/deeplink?opp=163&ocid=950152710&utm_source=astore&src=%SRC%&%ADDITIONAL_PARAMS%',
        uplift: 3.62,
        type_id: 3,
        priority: 3,
      },
    ],
    cost_evolution: {
      chart: [
        {
          date: '2023-06-24',
          cost_per_conversion: 20.126860470316565,
        },
        {
          date: '2023-06-27',
          cost_per_conversion: 21.704940885853528,
        },
        {
          date: '2023-06-28',
          cost_per_conversion: 24.25693875309713,
        },
        {
          date: '2023-06-29',
          cost_per_conversion: 20.82701192878683,
        },
        {
          date: '2023-06-30',
          cost_per_conversion: 30.73356480624025,
        },
        {
          date: '2023-07-01',
          cost_per_conversion: 20.98588483643723,
        },
        {
          date: '2023-07-02',
          cost_per_conversion: 29.05484854262626,
        },
        {
          date: '2023-07-03',
          cost_per_conversion: 18.598070214285713,
        },
        {
          date: '2023-07-04',
          cost_per_conversion: 13.649598790359494,
        },
        {
          date: '2023-07-05',
          cost_per_conversion: 13.249635256164607,
        },
        {
          date: '2023-07-06',
          cost_per_conversion: 14.581248833592655,
        },
        {
          date: '2023-07-07',
          cost_per_conversion: 15.091466060333044,
        },
        {
          date: '2023-07-08',
          cost_per_conversion: 27.134098217873188,
        },
        {
          date: '2023-07-09',
          cost_per_conversion: 17.595093305644067,
        },
        {
          date: '2023-07-10',
          cost_per_conversion: 16.15167014509816,
        },
        {
          date: '2023-07-11',
          cost_per_conversion: 17.864692610809758,
        },
        {
          date: '2023-07-12',
          cost_per_conversion: 21.94805753108631,
        },
        {
          date: '2023-07-13',
          cost_per_conversion: 15.160584654250842,
        },
        {
          date: '2023-07-14',
          cost_per_conversion: 26.517865669624758,
        },
        {
          date: '2023-07-15',
          cost_per_conversion: 17.71295941552212,
        },
        {
          date: '2023-07-16',
          cost_per_conversion: 11.388408591860692,
        },
        {
          date: '2023-07-17',
          cost_per_conversion: 12.142659637564895,
        },
        {
          date: '2023-07-18',
          cost_per_conversion: 20.5765675860824,
        },
        {
          date: '2023-07-19',
          cost_per_conversion: 0,
        },
      ],
      trendline: {
        initial_value: 23.918304648766654,
        increment_per_day: -0.4600963218365547,
      },
    },
    best_responsive_search_ad_id: '652457375238',
    best_responsive_search_ad_type_id: 15,
    best_responsive_search_ad_impressions: 6037,
    best_responsive_search_ad_clicks: 728,
    best_responsive_search_ad_ctr_percentage: 12.058969686930594,
    best_responsive_search_ad_headline: 'Adarco: proteção que resolve',
    best_responsive_search_ad_description:
      'Adarco é proteção veicular que resolve 100%, com os melhores benefícios para seu carro.',
    best_responsive_search_ad_url: 'https://souadarco.com.br/',
    worst_responsive_search_ad_id: '652453578870',
    worst_responsive_search_ad_type_id: 15,
    worst_responsive_search_ad_impressions: 6882,
    worst_responsive_search_ad_clicks: 239,
    worst_responsive_search_ad_ctr_percentage: 3.4728276663760536,
    worst_responsive_search_ad_headline: 'Proteja seu veículo com Adarco',
    worst_responsive_search_ad_description:
      'Adarco | Proteção veicular do DF e GO a partir de R$ 79,90/mês. Faça uma cotação.',
    worst_responsive_search_ad_url: 'https://souadarco.com.br/',
  },
}

export const pathname =
  '/report-demo/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMzA5NDAxMzk0MSIsInBhcmVudElkIjoiMzA5NDAxMzk0MSIsInV1aWQiOiIwNzYzY2MyOC1lYjEyLTQ2MDItYjBlYy0wMGExNDZkNDRhNWIiLCJjdXJyZW5jeSI6IkJSTCIsImN1c3RvbWVyTmFtZSI6IkhPU1RPTyJ9.jULxjb3sOxxEpzulmP6eERyC7ss1nhvEpcXSF6gVd0A'

export const reportToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMzA5NDAxMzk0MSIsInBhcmVudElkIjoiMzA5NDAxMzk0MSIsInV1aWQiOiIwNzYzY2MyOC1lYjEyLTQ2MDItYjBlYy0wMGExNDZkNDRhNWIiLCJjdXJyZW5jeSI6IkJSTCIsImN1c3RvbWVyTmFtZSI6IkhPU1RPTyJ9.jULxjb3sOxxEpzulmP6eERyC7ss1nhvEpcXSF6gVd0A'
