import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import BaordsDetailsNav from '@components/Boards/BoardList/BaordsDetailsNav'
import BoardsDetailsSection from '@components/Boards/BoardList/BoardsDetailsSection'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import {
  FETCH_SPECTFIC_BOARD_DATA,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
} from '@utils/constants'
import { RequestService } from '@services/requests'
import styles from './boardsDetail.module.css'

const BoardsDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const fetchSpecificBoardData = useSelector(state => state?.discoverAds?.fetchSpecificBoardData)
  const fetchSpecificBoardDataForTagsAndCount = useSelector(
    state => state?.discoverAds?.fetchSpecificBoardDataForTagsAndCount
  )
  const [loading, setLoading] = useState(true)
  const [boardDetailData, setBoardDetailData] = useState(fetchSpecificBoardData)
  const singleBoardID = localStorage.getItem('board_detail_id')
  const fetchSpecificBoardsList = async () => {
    try {
      setLoading(true)
      const response = await RequestService.get(`/create/board-ad-detail/${singleBoardID}/`)
      dispatch({ type: FETCH_SPECTFIC_BOARD_DATA, payload: response?.data?.boards[0]?.ads })
      dispatch({
        type: FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
        payload: response?.data?.boards[0],
      })
      setBoardDetailData(response?.data?.boards[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchSpecificBoardsList()
    // eslint-disable-next-line
  }, [])
  return (
    <>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 150 }}>
          <Spin />
        </div>
      ) : (
        <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
          <div style={{ display: 'flex', marginBottom: 20 }}>
            <Text
              size={14}
              onClick={() => history.push('/dashboard/boards')}
              cursor
              className={styles.learningTop}
            >
              Boards
            </Text>
            <div className={styles.slash}> /</div>
            <Text className={styles.catagory}>{boardDetailData?.board_name}</Text>
          </div>
          <BaordsDetailsNav data={fetchSpecificBoardDataForTagsAndCount} />
          <BoardsDetailsSection data={boardDetailData?.ads} />
        </div>
      )}
    </>
  )
}
export default BoardsDetail
