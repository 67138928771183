/* eslint-disable no-console */
import React, { useState } from 'react'
import { notify } from '@utils/notify'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styles from './LenguegeSelect.module.scss'

const LenguegeSelect = ({ color }) => {
  const { i18n } = useTranslation()

  const style = color ? { color } : {}

  const languages = [
    { key: 'en-US', label: 'English [EN]' },
    { key: 'es', label: 'Español [ES]' },
    { key: 'pt-BR', label: 'Português [BR]' },
    { key: 'fr', label: 'Français [FR]' },
    { key: 'it', label: 'Italiano [IT]' },
    { key: 'ja', label: 'Japanese [JA]' },
    { key: 'de', label: 'Deutsch [DE]' },
  ]
  const languageInitial = lang => {
    switch (lang) {
      case 'en-US':
        return 'EN'
      case 'pt-BR':
        return 'BR'
      case 'de':
        return 'DE'
      case 'it':
        return 'IT'
      case 'fr':
        return 'FR'
      case 'es':
        return 'ES'
      case 'ja':
        return 'JA'
      default:
        return 'en-US'
    }
  }

  const [language, setLanguage] = useState(languageInitial(i18n.language))

  // const changeLanguage = e => {
  //   i18n.changeLanguage(e.target.offsetParent.id)
  //   setLanguage(e.target.firstElementChild.innerText)
  // }
  const handleLanguageClick = ({ key }) => {
    i18n.changeLanguage(key)
    setLanguage(languageInitial(key))
  }
  return (
    <>
      {/* <div className={styles.container_switch}>
        <div className={styles.box_language} style={style}>
          {language}
          <span id="arrow" className={styles.arrow} style={style} />
        </div>
        <div id="dropdown" className={styles.dropdown}>
          <ul className={styles.list}>
            {languages.map(lang => (
              <li className={styles.itens} key={lang.id}>
                <Button
                  className={styles.button}
                  id={lang.id}
                  key={`button-${lang.id}`}
                  onClick={changeLanguage}
                >
                  <>
                    {lang.text} (<span>{lang.label}</span>)
                  </>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
      <Dropdown
        style={{ cursor: 'pointer' }}
        menu={{
          items: languages,
          onClick: handleLanguageClick,
        }}
        trigger={['click']}
      >
        <Space style={{ cursor: 'pointer' }}>
          {language}
          <span id="arrow" className={styles.arrow} style={style} />
        </Space>
      </Dropdown>
    </>
  )
}

export default LenguegeSelect
