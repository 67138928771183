import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import Text from '../Text/text'

const TabsComponent = ({
  contentOneTitle,
  contentTwoTitle,
  contentThreeTitle,
  contentOneBody,
  contentTwoBody,
  contentThreeBody,
}) => {
  const { TabPane } = Tabs
  return (
    <Tabs defaultActiveKey="1" className="tab">
      {contentOneTitle && (
        <TabPane tab={contentOneTitle} key="1">
          {contentOneBody}
        </TabPane>
      )}
      {contentTwoTitle && (
        <TabPane tab={contentTwoTitle} key="2">
          {contentTwoBody}
        </TabPane>
      )}
      {contentThreeTitle && (
        <TabPane tab={contentThreeTitle} key="3">
          {contentThreeBody}
        </TabPane>
      )}
    </Tabs>
  )
}

TabsComponent.propTypes = {
  contentOneTitle: PropTypes.string,
  contentTwoTitle: PropTypes.string,
  contentThreeTitle: PropTypes.string,
  contentOneBody: PropTypes.node,
  contentTwoBody: PropTypes.node,
  contentThreeBody: PropTypes.node,
}

TabsComponent.defaultProps = {
  contentOneTitle: '',
  contentTwoTitle: '',
  contentThreeTitle: '',
  contentOneBody: <Text size={16}>You can put any content that you want in here</Text>,
  contentTwoBody: <Text size={16}>You can put any content that you want in here</Text>,
  contentThreeBody: '',
}

export default TabsComponent
