import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Tabs, Progress, Card, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { BsLink45Deg } from 'react-icons/bs'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import useClipboard from 'react-use-clipboard'
import { ResponsiveFunnel } from '@nivo/funnel'
import NumberFormat from 'react-number-format'
import { FaQuestionCircle } from 'react-icons/fa'

import { useSelector } from 'react-redux'
import { SecretService } from '@services/secret'
import { Text, PerformanceCard, Quote, CTACard, CTABanner, InformationCard } from '@components'
import NeilSection from '@images/neil-userful-section.png'
import Footer from '../../components/Footer'
import { Header } from '../report/sections/Header'
import { OverallSection } from '../report/sections/Overall'
import {
  getCTR,
  formatCurrency,
  getPercentage,
  doubleDecimals,
  getBenchmarkProp,
} from '../../utils'
import { report, pathname, reportToken } from './demo-data'
import { RequestService, axiosInstance } from '../../services/requests'

import styles from './report.module.scss'

const { TabPane } = Tabs

const ReportDemoPage = () => {
  const { t } = useTranslation()

  const reportPayload = SecretService.decode(reportToken)
  const reportCurrency = useSelector(state => state?.customer?.currency_code)

  const [benchmarks, setBenchmarks] = useState([])

  const [isCopied, setCopied] = useClipboard(`${window.location.origin}/ads-report/${reportToken}`)

  const getBenchmarks = async () => {
    try {
      let benchmarksData
      if (pathname.match(/ad-report/)) {
        const { data } = await axiosInstance.get('/benchmarks/')

        benchmarksData = data
      } else {
        const { data } = await RequestService.get('/benchmarks/')

        benchmarksData = data
      }

      setBenchmarks(benchmarksData)
    } catch (e) {
      setBenchmarks([])
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    getBenchmarks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenCtaUrl = () => {
    window.open(
      `${process.env.REACT_APP_CTA_URL}/?ad-spend-monthly=${report.data.overall_spend.toFixed(
        2
      )}&currency=${reportPayload.currency}`,
      '_blank'
    )
  }

  return (
    <>
      <Header
        createdAt={report.data.created_at}
        score={report.data.general_score || 0}
        onCtaClick={handleOpenCtaUrl}
      />

      {/* QUOTE WITH IMAGE SECTION */}
      <section className={styles.container}>
        <div className={styles.quote}>
          <Quote>
            <Text size={16} color="black">
              {t('report.nielPatelQuoteText.part1')}
              <strong>
                {t('report.nielPatelQuoteText.part2', {
                  activeCampaigns: report.data.campaigns_total,
                })}
              </strong>
              {t('report.nielPatelQuoteText.part3', {
                adGroupPerCampaign: report.data.ad_groups_per_campaign.toFixed(0),
              })}

              {t('report.nielPatelQuoteText.part4')}
              <strong>
                {t('report.nielPatelQuoteText.part5', { adGroups: report.data.ad_groups_total })}
              </strong>
              <strong>
                {t('report.nielPatelQuoteText.part6', { keywords: report.data.keywords_total })}
              </strong>
              {t('report.nielPatelQuoteText.part7')}

              <strong style={{ color: '#F26234' }}>
                {t('report.nielPatelQuoteText.part8', {
                  percentage: (100 - report.data.general_score)?.toFixed(0),
                })}
              </strong>
            </Text>
          </Quote>
        </div>
      </section>

      {/* THREE STEPS SECTION */}
      <section className={styles.container}>
        <div className={styles.threeStepsTitle}>
          <Text isTitle size={32} weight={700}>
            <strong className={styles.orangeStrong}>{t('report.wait')}</strong>{' '}
            {t('report.waitText', { percentage: (100 - report.data.general_score)?.toFixed(0) })}
          </Text>
        </div>
        <div className={styles.CTACardsDiv}>
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isBold isTitle color="primary">
                    {report?.data?.quality_score?.toFixed(2)}
                  </Text>
                }
                title={t('report.yourQualityScore')}
                tooltip={t('report.yourQualityScoreHelp')}
                text={
                  <Text color="black" size={16}>
                    {t('report.yourQualityScoreText1')}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.yourQualityScoreText2')}
                    </strong>
                  </Text>
                }
                buttonText={t('report.yourQualityScoreButton')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isTitle isBold color="primary">
                    {formatCurrency(report.data.projected_wasted_spend, {
                      currency: reportPayload.currency ? reportPayload.currency : reportCurrency,
                      language: 'en-US',
                    }) || 0}
                  </Text>
                }
                title={t('report.projectedBudgetWasted')}
                tooltip={t('report.projectedBudgetWastedHelp')}
                text={
                  <Text color="black" size={16}>
                    {t('report.projectedBudgetWastedText1')}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.projectedBudgetWastedText2', {
                        money: formatCurrency(report.data.wasted_spent, {
                          currency: reportPayload.currency
                            ? reportPayload.currency
                            : reportCurrency,
                          language: 'en-US',
                        }),
                      })}
                    </strong>
                  </Text>
                }
                buttonText={t('report.projectedBudgetWastedButton')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <CTACard
                value={
                  <Text size={64} isTitle isBold color="primary">
                    {getPercentage(report.data.click_through_rate)}
                  </Text>
                }
                title={t('report.yourCTR')}
                tooltip={t('report.yourCTRHelp')}
                text={
                  <Text color="black" size={16}>
                    {t('report.yourCTRText1')}{' '}
                    <strong style={{ color: '#F26234' }}>
                      {t('report.yourCTRText2', {
                        percentage:
                          getBenchmarkProp('ctr_target', benchmarks) !== '-'
                            ? getBenchmarkProp('ctr_target', benchmarks)
                            : 4.76,
                      })}
                    </strong>
                  </Text>
                }
                buttonText={t('report.yourCTRbuttonText')}
                onCtaClick={handleOpenCtaUrl}
              />
            </Col>
          </Row>
        </div>
        <div className={styles.threeStepsFooter}>
          <CTABanner
            text={
              <Text color="black" isBold isTitle size={16}>
                {t('report.bookACall')}
              </Text>
            }
            buttonText={t('report.bookACallButton')}
            bgColor=""
            onCtaClick={handleOpenCtaUrl}
          />
        </div>
      </section>

      {/* PERFORMANCE METRICS SECTION */}
      <section className={styles.metricsSection}>
        <div className={styles.container}>
          <div className={styles.metricsTitle}>
            <Text size={32} isBold isTitle>
              {t('report.performanceMetrics')}
            </Text>
          </div>
          <div className={styles.tabsDiv}>
            <Tabs defaultActiveKey="1">
              <TabPane key="1" tab={t('report.performanceMetricsOverview')}>
                {report.data.has_overall === 0 ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={formatCurrency(report.data.overall_spend, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={formatCurrency(report.data.overall_cpa, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.overall_click,
                              report.data.overall_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.overall_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'overall_impressions',
                                    value: report.data.overall_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'overall_clicks',
                                    value: report.data.overall_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'overall_conversions',
                                    value: report.data.overall_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    <div className={styles.metricsFooter}>
                      <Text size={16}>{t('report.metricsFooterText')}</Text>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab={t('report.performanceMetricsPerformanceMax')} key="2">
                {!report.data.performance_max_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={formatCurrency(report.data.performance_max_spend, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={formatCurrency(report.data.performance_max_cpa, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.performance_max_click,
                              report.data.performance_max_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.performance_max_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'display_impressions',
                                    value: report.data.performance_max_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'display_clicks',
                                    value: report.data.performance_max_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'display_conversions',
                                    value: report.data.performance_max_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    {/* <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3' }} /> What am I seeing?
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>Display ads </strong>
                        act like TV Commercials and Billboards, they’re meant to amplify your
                        message and make customers aware of your brand. Display KPIs are clicks,
                        CTR, and view-through conversions
                      </Text>
                    </div> */}
                  </>
                )}
              </TabPane>
              <TabPane tab={t('report.performanceMetricsSearch')} key="3">
                {!report.data.search_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={formatCurrency(report.data.search_spend, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={formatCurrency(report.data.search_cpa, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.search_click,
                              report.data.search_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.search_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.search_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>{report.data.search_conversions?.toFixed(0)}</Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'search_impressions',
                                    value: report.data.search_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'search_clicks',
                                    value: report.data.search_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'search_conversions',
                                    value: report.data.search_conversions?.toFixed(0),
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>
                    <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3', verticalAlign: 'text-top' }} />{' '}
                        What am I seeing?
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>Search ads </strong>
                        usually target middle and bottom-funnel traffic which means these are your
                        last-click performance drivers!
                      </Text>
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab="DISPLAY" key="4">
                {!report.data.display_spend ? (
                  <Text>{t('report.performanceMetricsEmptyText')}</Text>
                ) : (
                  <>
                    <Row gutter={[30, 30]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title={t('report.spend')}
                            tooltipText=""
                            number={formatCurrency(report.data.display_spend, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CPA"
                            tooltipText=""
                            number={formatCurrency(report.data.display_cpa, {
                              currency: reportPayload.currency
                                ? reportPayload.currency
                                : reportCurrency,
                              language: 'en-US',
                            })}
                            isMoney={false}
                            color="primary"
                            tagText=""
                            footerText=""
                          />
                        </div>

                        <div className={styles.infoCardDiv}>
                          <InformationCard
                            title="CTR"
                            tooltipText=""
                            number={`${getCTR(
                              report.data.display_click,
                              report.data.display_impressions
                            )} %`}
                            color="black"
                            tagText=""
                            footerText=""
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <InformationCard title={t('report.adsFunnel')} color="black">
                          <div className={styles.chartWrapper}>
                            <div className={styles.chartLegends}>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.impressions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_impressions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.clicks')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_click}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                              <div className={styles.chartLegendCol}>
                                <Text size={20} isBold>
                                  {t('report.conversions')}
                                </Text>
                                <Text size={20}>
                                  <NumberFormat
                                    value={report.data.display_conversions}
                                    displayType="text"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                  />
                                </Text>
                              </div>
                            </div>
                            <div style={{ height: 350, flex: 3 }}>
                              <ResponsiveFunnel
                                data={[
                                  {
                                    id: 'display_impressions',
                                    value: report.data.display_impressions,
                                    label: t('report.impressions'),
                                  },
                                  {
                                    id: 'display_clicks',
                                    value: report.data.display_click,
                                    label: t('report.clicks'),
                                  },
                                  {
                                    id: 'display_conversions',
                                    value: report.data.display_conversions,
                                    label: t('report.conversions'),
                                  },
                                ]}
                                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                valueFormat=">-.4s"
                                labelColor="transparent"
                                beforeSeparatorLength={50}
                                beforeSeparatorOffset={20}
                                afterSeparatorLength={50}
                                afterSeparatorOffset={20}
                                currentPartSizeExtension={10}
                                motionConfig="wobbly"
                                colors={{ scheme: 'set3' }}
                              />
                            </div>
                          </div>
                        </InformationCard>
                      </Col>
                    </Row>

                    <div className={styles.metricsFooter}>
                      <Text size={20} isBold>
                        <FaQuestionCircle style={{ color: 'a3b0b3' }} /> What am I seeing?
                      </Text>
                      <Text size={16}>
                        <strong style={{ color: '#F26234' }}>Display ads </strong>
                        act like TV Commercials and Billboards, they’re meant to amplify your
                        message and make customers aware of your brand. Display KPIs are clicks,
                        CTR, and view-through conversions
                      </Text>
                    </div>
                  </>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>

      {/* SHARE WITH TEAM SECTION */}
      <section className={styles.teamSection}>
        <div className={styles.container}>
          <Row>
            <Col lg={12}>
              <Text color="black" size={18} isUppercase isBold isTitle>
                {t('report.shareWithYourTeamSection')}
              </Text>
              <Text color="darkerGray" size={16}>
                {t('report.shareWithYourTeamText')}
              </Text>
            </Col>
            <Col lg={12} className={styles.socialButtons}>
              <Button className={styles.socialButton} onClick={setCopied}>
                <div className={styles.socialIconText}>
                  <div className={styles.socialIcon}>
                    <BsLink45Deg />
                  </div>
                  {isCopied
                    ? t('report.shareWithYourTeamButtonConfirmed')
                    : t('report.shareWithYourTeamButton')}
                </div>
              </Button>
            </Col>
          </Row>
        </div>
      </section>

      {/* OVERALL SECTION */}
      <div className={styles.container}>
        <OverallSection
          report={report.data}
          language="en-US"
          currency={reportPayload.currency ? reportPayload.currency : reportCurrency}
          benchmarks={benchmarks}
          onCtaClick={handleOpenCtaUrl}
          hideDisplay={!report.data.display_spend}
        />
      </div>

      {/* DISPLAY SECTION */}
      {/* <section className={styles.container}>
        <div className={styles.displayHeader}>
          <Text isTitle size={32}>
            Display
          </Text>
        </div>
        <Collapse ghost>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Display ads</Text>
                <ProgressBar percentageNumber={75} />
              </div>
            }
            key="1"
          >
            <div>lorem</div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Display placements</Text>
                <ProgressBar percentageNumber={93} />
              </div>
            }
            key="2"
          >
            <div>lorem</div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseHeader}>
                <Text size={16}>Audience performance</Text>
                <ProgressBar percentageNumber={50} />
              </div>
            }
            key="3"
          >
            <div>lorem</div>
          </Panel>
        </Collapse>
      </section> */}

      {/* STATS SECTION */}
      {/* <section className={styles.container}>
        <div className={styles.statsTabContainer}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="SEARCH" key="1">
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Active Search Campaigns"
                    tooltipText="Something"
                    number={9}
                    color="black"
                    tagText="Amazing"
                    footerText=""
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Avg Ad Groups Per"
                    tooltipText="Something"
                    number={4.3}
                    color="black"
                    tagText=""
                    footerText=""
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Active Ad Groups"
                    tooltipText="Something"
                    number={39}
                    color="black"
                    tagText=""
                    footerText="52 Active Ads"
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                  <InformationCard
                    title="Avg Keywords"
                    tooltipText="Something"
                    number={23.4}
                    color="black"
                    tagText=""
                    footerText=""
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="DISPLAY" key="2">
              <div>wip tab</div>
            </TabPane>
          </Tabs>
        </div>
      </section> */}

      {/* SHARE SECTION */}
      <section className={styles.shareContainer}>
        <div className={styles.container}>
          <Row justify="center">
            <Col lg={12}>
              <div className={styles.shareLeftSide}>
                <div className={styles.shareLeftSideTitle}>
                  <Text size={64}>Did you find Ads Grader useful?</Text>
                </div>
                <Text size={24}>Share our cutting-edge Ads Grader with your network now.</Text>
                <div className={styles.shareButtonContainer}>
                  <Button type="primary" className={styles.shareButton} onClick={setCopied}>
                    {isCopied ? 'Public URL Copied!' : 'Share Ads Grader Now'}
                  </Button>
                </div>
                <div className={styles.shareConfidentialContainer}>
                  <div className={styles.shareConfidentialIcon}>
                    <AiOutlineSafetyCertificate />
                  </div>
                  <Text size={16}>
                    <strong>Confidential</strong> We will not disclose your score anywhere
                  </Text>
                </div>
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className={styles.shareRightSide}>
                <img src={NeilSection} alt="banner " className={styles.neilBannerImg} />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* REPORT SUMMARY SECTION */}
      <section className={styles.summarySection}>
        <div className={styles.container}>
          <Row justify="center">
            <Col lg={12}>
              <div className={styles.summaryLeftSide}>
                <div className={styles.reportTitleContainer}>
                  <Text size={80} color="white" className={styles.reportTitleContainer}>
                    Report Summary
                  </Text>
                </div>
                <div className={styles.summaryMiddleParagraph}>
                  <Text size={28} color="white">
                    Substantially improving your campaign&apos;s success starts with optimization
                    and cost-saving measures.
                  </Text>
                </div>
                <div className={styles.summaryLastParagraph}>
                  <Text size={20} color="white">
                    NP Digital can help your team craft campaigns that yield high ROI and drive
                    sustainable growth for your business.
                  </Text>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className={styles.reportRightSide}>
                <PerformanceCard
                  percentageNumber={report?.data?.general_score?.toFixed(0) || 0}
                  titleText={t('report.headerCardTitle')}
                  buttonText={t('report.headerCardButtonText')}
                  disclaimerText={t('report.headerCardDisclaimerText')}
                  onClick={handleOpenCtaUrl}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </>
  )
}

export default ReportDemoPage
