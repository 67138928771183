import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const deactivateAccount = async () => {
  const { url, method } = apiRoutes.deactivateAccount
  try {
    const res = await api({
      url,
      method,
      //   data: { customer_id: customerId },
    })
    return res
  } catch (error) {
    return error.response
  }
}
