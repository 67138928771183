import { Checkbox, Col, DatePicker, Divider, Input, Radio, Row, Select } from 'antd'
import React, { useState } from 'react'
import {
  ADS_SEARCH_COMPANY,
  ADS_SEARCH_KEYWORD,
  ADS_END_DATE,
  ADS_START_DATE,
  ADS_LOCATION,
  ADS_CHANNEL,
  ADS_FORMAT,
  LS_KEY,
  ADS_DATA,
  ADS_LOADER,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  AG_AC_TOKEN,
  SPINNER,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  META_ADS_PAGINATION_DATA,
} from '@utils/constants'
import moment from 'moment'
import ImageIcon from '@images/DiscoverAds/DropdownIcons/imageIcon.png'
import companySearchIcon from '@images/AdsPreview/companySearch.png'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import filterArrow from '@images/AdsPreview/filterArrow.png'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Text from '@components/Text/text'
import { notify } from '@utils/notify'
import { baseURL, RequestService } from '@services/requests'
import styles from './searchBar.module.css'

const { Option } = Select
const SearchBar = ({ emptyAdsData }) => {
  const [selectedOptionsFormat, setSelectedOptionsFormat] = useState([])
  const [selectedOptionsChannel, setSelectedOptionsChannel] = useState('Google')
  const [locationPlaceholder, setLocationPlaceholder] = useState('Location: Anywhere')
  const [selectedLocation, setSelectedLocation] = useState([])
  const dispatch = useDispatch()
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const selectedChannel = useSelector(state => state?.adsPreview?.channels)
  const location = useSelector(state => state?.adsPreview?.location)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const loading = useSelector(state => state?.adsPreview?.loading)
  const moreDataLoader = useSelector(state => state?.adsPreview?.moreDataLoader)
  const Metacountries = [
    { value: '', label: 'Anywhere' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IN', label: 'India' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'CA', label: 'Canada' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'BE', label: 'Belgium' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CO', label: 'Colombia' },
    { value: 'HR', label: 'Croatia' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EG', label: 'Egypt' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'DE', label: 'Germany' },
    { value: 'GR', label: 'Greece' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JP', label: 'Japan' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MX', label: 'Mexico' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NO', label: 'Norway' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PA', label: 'Panama' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Poland' },
    { value: 'RU', label: 'Russia' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SG', label: 'Singapore' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'KR', label: 'South Korea' },
    { value: 'ES', label: 'Spain' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TR', label: 'Turkey' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'PT', label: 'Portugal' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'IS', label: 'Iceland' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'KE', label: 'Kenya' },
    { value: 'HU', label: 'Hungary' },
    { value: 'MA', label: 'Morocco' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'RO', label: 'Romania' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'QA', label: 'Qatar' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'HN', label: 'Honduras' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'PS', label: 'Palestine' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'GH', label: 'Ghana' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'MT', label: 'Malta' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'MV', label: 'Maldives' },
    { value: 'OM', label: 'Oman' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'LV', label: 'Latvia' },
    { value: 'EE', label: 'Estonia' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AL', label: 'Albania' },
    { value: 'NP', label: 'Nepal' },
    { value: 'MO', label: 'Macau' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'SN', label: 'Senegal' },
    { value: 'GE', label: 'Georgia' },
    { value: 'BN', label: 'Brunei' },
    { value: 'UG', label: 'Uganda' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'BB', label: 'Barbados' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'LY', label: 'Libya' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'BW', label: 'Botswana' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'NA', label: 'Namibia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'BY', label: 'Belarus' },
    { value: 'JE', label: 'Jersey' },
    { value: 'GU', label: 'Guam' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'HT', label: 'Haiti' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'GY', label: 'Guyana' },
    { value: 'AM', label: 'Armenia' },
    { value: 'MW', label: 'Malawi' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GM', label: 'Gambia' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'BJ', label: 'Benin' },
    { value: 'AD', label: 'Andorra' },
    { value: 'GD', label: 'Grenada' },
    { value: 'VI', label: 'United States Virgin Islands' },
    { value: 'BZ', label: 'Belize' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'ML', label: 'Mali' },
    { value: 'AO', label: 'Angola' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'MC', label: 'Monaco' },
    { value: 'TG', label: 'Togo' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'CD', label: 'Democratic Republic of the Congo' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LA', label: 'Laos' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'VG', label: 'British Virgin Islands' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'DM', label: 'Dominica' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'SM', label: 'San Marino' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'NE', label: 'Niger' },
    { value: 'CG', label: 'Republic of the Congo' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'GN', label: 'Guinea' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'BI', label: 'Burundi' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'WS', label: 'Samoa' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'TO', label: 'Tonga' },
    { value: 'KM', label: 'Comoros' },
    { value: 'PW', label: 'Palau' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'SO', label: 'Somalia' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'VA', label: 'Vatican City' },
    { value: 'TD', label: 'Chad' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'ST', label: 'São Tomé and Principe' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'NR', label: 'Nauru' },
    { value: 'RE', label: 'Réunion' },
    { value: 'LR', label: 'Liberia' },
    { value: 'ZW', label: 'Zimbabwe' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'MM', label: 'Myanmar' },
    { value: 'AN', label: 'Netherlands Antilles' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'BQ', label: 'Bonaire' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'TF', label: 'French Southern and Antarctic Lands' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'XK', label: 'Kosovo' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'PN', label: 'Pitcairn Islands' },
    { value: 'BL', label: 'Saint Barthélemy' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'TL', label: 'East Timor' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
  ]
  const countries = [
    { value: null, label: 'Anywhere' },
    { value: '2008', label: 'Albania', flag: 'https://flagcdn.com/al.svg' },
    { value: '2010', label: 'Antarctica', flag: 'https://flagcdn.com/aq.svg' },
    { value: '2012', label: 'Algeria', flag: 'https://flagcdn.com/dz.svg' },
    { value: '2016', label: 'American Samoa', flag: 'https://flagcdn.com/as.svg' },
    { value: '2020', label: 'Andorra', flag: 'https://flagcdn.com/ad.svg' },
    { value: '2024', label: 'Angola', flag: 'https://flagcdn.com/ao.svg' },
    { value: '2028', label: 'Antigua and Barbuda', flag: 'https://flagcdn.com/ag.svg' },
    { value: '2031', label: 'Azerbaijan', flag: 'https://flagcdn.com/az.svg' },
    { value: '2032', label: 'Argentina', flag: 'https://flagcdn.com/ar.svg' },
    { value: '2036', label: 'Australia', flag: 'https://flagcdn.com/au.svg' },
    { value: '2040', label: 'Austria', flag: 'https://flagcdn.com/at.svg' },
    { value: '2044', label: 'The Bahamas', flag: 'https://flagcdn.com/bs.svg' },
    { value: '2048', label: 'Bahrain', flag: 'https://flagcdn.com/bh.svg' },
    { value: '2050', label: 'Bangladesh', flag: 'https://flagcdn.com/bd.svg' },
    { value: '2051', label: 'Armenia', flag: 'https://flagcdn.com/am.svg' },
    { value: '2052', label: 'Barbados', flag: 'https://flagcdn.com/bb.svg' },
    { value: '2056', label: 'Belgium', flag: 'https://flagcdn.com/be.svg' },
    { value: '2060', label: 'Bermuda', flag: 'https://flagcdn.com/bm.svg' },
    { value: '2064', label: 'Bhutan', flag: 'https://flagcdn.com/bt.svg' },
    { value: '2068', label: 'Bolivia', flag: 'https://flagcdn.com/bo.svg' },
    { value: '2070', label: 'Bosnia and Herzegovina', flag: 'https://flagcdn.com/ba.svg' },
    { value: '2072', label: 'Botswana', flag: 'https://flagcdn.com/bw.svg' },
    { value: '2074', label: 'Bouvet Island', flag: 'https://flagcdn.com/bv.svg' },
    { value: '2076', label: 'Brazil', flag: 'https://flagcdn.com/br.svg' },
    { value: '2084', label: 'Belize', flag: 'https://flagcdn.com/bz.svg' },
    { value: '2086', label: 'British Indian Ocean Territory', flag: 'https://flagcdn.com/io.svg' },
    { value: '2090', label: 'Solomon Islands', flag: 'https://flagcdn.com/sb.svg' },
    { value: '2092', label: 'British Virgin Islands', flag: 'https://flagcdn.com/vg.svg' },
    { value: '2096', label: 'Brunei', flag: 'https://flagcdn.com/bn.svg' },
    { value: '2100', label: 'Bulgaria', flag: 'https://flagcdn.com/bg.svg' },
    { value: '2104', label: 'Myanmar (Burma)', flag: 'https://flagcdn.com/mm.svg' },
    { value: '2108', label: 'Burundi', flag: 'https://flagcdn.com/bi.svg' },
    { value: '2112', label: 'Belarus', flag: 'https://flagcdn.com/by.svg' },
    { value: '2116', label: 'Cambodia', flag: 'https://flagcdn.com/kh.svg' },
    { value: '2120', label: 'Cameroon', flag: 'https://flagcdn.com/cm.svg' },
    { value: '2124', label: 'Canada', flag: 'https://flagcdn.com/ca.svg' },
    { value: '2132', label: 'Cabo Verde', flag: 'https://flagcdn.com/cv.svg' },
    { value: '2136', label: 'Cayman Islands', flag: 'https://flagcdn.com/ky.svg' },
    { value: '2140', label: 'Central African Republic', flag: 'https://flagcdn.com/cf.svg' },
    { value: '2144', label: 'Sri Lanka', flag: 'https://flagcdn.com/lk.svg' },
    { value: '2148', label: 'Chad', flag: 'https://flagcdn.com/td.svg' },
    { value: '2152', label: 'Chile', flag: 'https://flagcdn.com/cl.svg' },
    { value: '2156', label: 'China', flag: 'https://flagcdn.com/cn.svg' },
    { value: '2158', label: 'Taiwan', flag: 'https://flagcdn.com/tw.svg' },
    { value: '2162', label: 'Christmas Island', flag: 'https://flagcdn.com/cx.svg' },
    { value: '2166', label: 'Cocos (Keeling) Islands', flag: 'https://flagcdn.com/cc.svg' },
    { value: '2170', label: 'Colombia', flag: 'https://flagcdn.com/co.svg' },
    { value: '2174', label: 'Comoros', flag: 'https://flagcdn.com/km.svg' },
    { value: '2175', label: 'Mayotte', flag: 'https://flagcdn.com/yt.svg' },
    { value: '2178', label: 'Republic of the Congo', flag: 'https://flagcdn.com/cg.svg' },
    {
      value: '2180',
      label: 'Democratic Republic of the Congo',
      flag: 'https://flagcdn.com/dc.svg',
    },
    { value: '2184', label: 'Cook Islands', flag: 'https://flagcdn.com/ck.svg' },
    { value: '2188', label: 'Costa Rica', flag: 'https://flagcdn.com/cr.svg' },
    { value: '2191', label: 'Croatia', flag: 'https://flagcdn.com/hr.svg' },
    { value: '2196', label: 'Cyprus', flag: 'https://flagcdn.com/cy.svg' },
    { value: '2203', label: 'Czechia', flag: 'https://flagcdn.com/cz.svg' },
    { value: '2204', label: 'Benin', flag: 'https://flagcdn.com/bj.svg' },
    { value: '2208', label: 'Denmark', flag: 'https://flagcdn.com/dk.svg' },
    { value: '2212', label: 'Dominica', flag: 'https://flagcdn.com/dm.svg' },
    { value: '2214', label: 'Dominican Republic', flag: 'https://flagcdn.com/do.svg' },
    { value: '2218', label: 'Ecuador', flag: 'https://flagcdn.com/ec.svg' },
    { value: '2222', label: 'El Salvador', flag: 'https://flagcdn.com/sv.svg' },
    { value: '2226', label: 'Equatorial Guinea', flag: 'https://flagcdn.com/gq.svg' },
    { value: '2231', label: 'Ethiopia', flag: 'https://flagcdn.com/et.svg' },
    { value: '2232', label: 'Eritrea', flag: 'https://flagcdn.com/er.svg' },
    { value: '2233', label: 'Estonia', flag: 'https://flagcdn.com/ee.svg' },
    { value: '2234', label: 'Faroe Islands', flag: 'https://flagcdn.com/fo.svg' },
    {
      value: '2238',
      label: 'Falkland Islands (Islas Malvinas)',
      flag: 'https://flagcdn.com/fk.svg',
    },
    {
      value: '2239',
      label: 'South Georgia and the South Sandwich Islands',
      flag: 'https://flagcdn.com/gs.svg',
    },
    { value: '2242', label: 'Fiji', flag: 'https://flagcdn.com/fj.svg' },
    { value: '2246', label: 'Finland', flag: 'https://flagcdn.com/fi.svg' },
    { value: '2250', label: 'France', flag: 'https://flagcdn.com/fr.svg' },
    { value: '2254', label: 'French Guiana', flag: 'https://flagcdn.com/gf.svg' },
    { value: '2258', label: 'French Polynesia', flag: 'https://flagcdn.com/pf.svg' },
    {
      value: '2260',
      label: 'French Southern and Antarctic Lands',
      flag: 'https://flagcdn.com/tf.svg',
    },
    { value: '2262', label: 'Djibouti', flag: 'https://flagcdn.com/dj.svg' },
    { value: '2266', label: 'Gabon', flag: 'https://flagcdn.com/ga.svg' },
    { value: '2268', label: 'Georgia', flag: 'https://flagcdn.com/ge.svg' },
    { value: '2270', label: 'The Gambia', flag: 'https://flagcdn.com/gm.svg' },
    { value: '2275', label: 'Palestine', flag: 'https://flagcdn.com/ps.svg' },
    { value: '2276', label: 'Germany', flag: 'https://flagcdn.com/de.svg' },
    { value: '2288', label: 'Ghana', flag: 'https://flagcdn.com/gh.svg' },
    { value: '2292', label: 'Gibraltar', flag: 'https://flagcdn.com/gi.svg' },
    { value: '2296', label: 'Kiribati', flag: 'https://flagcdn.com/ki.svg' },
    { value: '2300', label: 'Greece', flag: 'https://flagcdn.com/gr.svg' },
    { value: '2304', label: 'Greenland', flag: 'https://flagcdn.com/gl.svg' },
    { value: '2308', label: 'Grenada', flag: 'https://flagcdn.com/gd.svg' },
    { value: '2312', label: 'Guadeloupe', flag: 'https://flagcdn.com/gp.svg' },
    { value: '2316', label: 'Guam', flag: 'https://flagcdn.com/gu.svg' },
    { value: '2320', label: 'Guatemala', flag: 'https://flagcdn.com/gt.svg' },
    { value: '2324', label: 'Guinea', flag: 'https://flagcdn.com/gn.svg' },
    { value: '2328', label: 'Guyana', flag: 'https://flagcdn.com/gy.svg' },
    { value: '2332', label: 'Haiti', flag: 'https://flagcdn.com/ht.svg' },
    {
      value: '2334',
      label: 'Heard Island and McDonald Islands',
      flag: 'https://flagcdn.com/hm.svg',
    },
    { value: '2336', label: 'Vatican City', flag: 'https://flagcdn.com/va.svg' },
    { value: '2340', label: 'Honduras', flag: 'https://flagcdn.com/hn.svg' },
    { value: '2344', label: 'Hong Kong', flag: 'https://flagcdn.com/hk.svg' },
    { value: '2348', label: 'Hungary', flag: 'https://flagcdn.com/hu.svg' },
    { value: '2352', label: 'Iceland', flag: 'https://flagcdn.com/is.svg' },
    { value: '2356', label: 'India', flag: 'https://flagcdn.com/in.svg' },
    { value: '2360', label: 'Indonesia', flag: 'https://flagcdn.com/id.svg' },
    { value: '2368', label: 'Iraq', flag: 'https://flagcdn.com/iq.svg' },
    { value: '2372', label: 'Ireland', flag: 'https://flagcdn.com/ie.svg' },
    { value: '2376', label: 'Israel', flag: 'https://flagcdn.com/il.svg' },
    { value: '2380', label: 'Italy', flag: 'https://flagcdn.com/it.svg' },
    { value: '2384', label: "Cote d'Ivoire", flag: 'https://flagcdn.com/ci.svg' },
    { value: '2388', label: 'Jamaica', flag: 'https://flagcdn.com/jm.svg' },
    { value: '2392', label: 'Japan', flag: 'https://flagcdn.com/jp.svg' },
    { value: '2398', label: 'Kazakhstan', flag: 'https://flagcdn.com/kz.svg' },
    { value: '2400', label: 'Jordan', flag: 'https://flagcdn.com/jo.svg' },
    { value: '2404', label: 'Kenya', flag: 'https://flagcdn.com/ke.svg' },
    { value: '2410', label: 'South Korea', flag: 'https://flagcdn.com/kr.svg' },
    { value: '2414', label: 'Kuwait', flag: 'https://flagcdn.com/kw.svg' },
    { value: '2417', label: 'Kyrgyzstan', flag: 'https://flagcdn.com/kg.svg' },
    { value: '2418', label: 'Laos', flag: 'https://flagcdn.com/la.svg' },
    { value: '2422', label: 'Lebanon', flag: 'https://flagcdn.com/lb.svg' },
    { value: '2426', label: 'Lesotho', flag: 'https://flagcdn.com/ls.svg' },
    { value: '2428', label: 'Latvia', flag: 'https://flagcdn.com/lv.svg' },
    { value: '2430', label: 'Liberia', flag: 'https://flagcdn.com/lr.svg' },
    { value: '2434', label: 'Libya', flag: 'https://flagcdn.com/ly.svg' },
    { value: '2438', label: 'Liechtenstein', flag: 'https://flagcdn.com/li.svg' },
    { value: '2440', label: 'Lithuania', flag: 'https://flagcdn.com/lt.svg' },
    { value: '2442', label: 'Luxembourg', flag: 'https://flagcdn.com/lu.svg' },
    { value: '2446', label: 'Macao', flag: 'https://flagcdn.com/mo.svg' },
    { value: '2450', label: 'Madagascar', flag: 'https://flagcdn.com/mg.svg' },
    { value: '2454', label: 'Malawi', flag: 'https://flagcdn.com/mw.svg' },
    { value: '2458', label: 'Malaysia', flag: 'https://flagcdn.com/my.svg' },
    { value: '2462', label: 'Maldives', flag: 'https://flagcdn.com/mv.svg' },
    { value: '2466', label: 'Mali', flag: 'https://flagcdn.com/ml.svg' },
    { value: '2470', label: 'Malta', flag: 'https://flagcdn.com/mt.svg' },
    { value: '2474', label: 'Martinique', flag: 'https://flagcdn.com/mq.svg' },
    { value: '2478', label: 'Mauritania', flag: 'https://flagcdn.com/mr.svg' },
    { value: '2480', label: 'Mauritius', flag: 'https://flagcdn.com/mu.svg' },
    { value: '2484', label: 'Mexico', flag: 'https://flagcdn.com/mx.svg' },
    { value: '2492', label: 'Monaco', flag: 'https://flagcdn.com/mc.svg' },
    { value: '2496', label: 'Mongolia', flag: 'https://flagcdn.com/mn.svg' },
    { value: '2498', label: 'Moldova', flag: 'https://flagcdn.com/md.svg' },
    { value: '2499', label: 'Montenegro', flag: 'https://flagcdn.com/me.svg' },
    { value: '2500', label: 'Montserrat', flag: 'https://flagcdn.com/ms.svg' },
    { value: '2504', label: 'Morocco', flag: 'https://flagcdn.com/ma.svg' },
    { value: '2508', label: 'Mozambique', flag: 'https://flagcdn.com/mz.svg' },
    { value: '2512', label: 'Oman', flag: 'https://flagcdn.com/om.svg' },
    { value: '2516', label: 'Namibia', flag: 'https://flagcdn.com/na.svg' },
    { value: '2520', label: 'Nauru', flag: 'https://flagcdn.com/nr.svg' },
    { value: '2524', label: 'Nepal', flag: 'https://flagcdn.com/np.svg' },
    { value: '2528', label: 'Netherlands', flag: 'https://flagcdn.com/nl.svg' },
    { value: '2531', label: 'Curacao', flag: 'https://flagcdn.com/cw.svg' },
    { value: '2533', label: 'Aruba', flag: 'https://flagcdn.com/aw.svg' },
    { value: '2534', label: 'Sint Maarten', flag: 'https://flagcdn.com/sx.svg' },
    { value: '2535', label: 'Bonaire', flag: 'https://flagcdn.com/bq.svg' },
    { value: '2538', label: 'New Caledonia', flag: 'https://flagcdn.com/nc.svg' },
    { value: '2540', label: 'New Zealand', flag: 'https://flagcdn.com/nz.svg' },
    { value: '2544', label: 'Nicaragua', flag: 'https://flagcdn.com/ni.svg' },
    { value: '2548', label: 'Niger', flag: 'https://flagcdn.com/ne.svg' },
    { value: '2552', label: 'Nigeria', flag: 'https://flagcdn.com/ng.svg' },
    { value: '2556', label: 'Niue', flag: 'https://flagcdn.com/nu.svg' },
    { value: '2560', label: 'Norfolk Island', flag: 'https://flagcdn.com/nf.svg' },
    { value: '2564', label: 'Northern Mariana Islands', flag: 'https://flagcdn.com/mp.svg' },
    { value: '2568', label: 'Norway', flag: 'https://flagcdn.com/no.svg' },
    { value: '2570', label: 'Oman', flag: 'https://flagcdn.com/om.svg' },
    { value: '2572', label: 'Pakistan', flag: 'https://flagcdn.com/pk.svg' },
    { value: '2576', label: 'Palau', flag: 'https://flagcdn.com/pw.svg' },
    { value: '2580', label: 'Panama', flag: 'https://flagcdn.com/pa.svg' },
    { value: '2584', label: 'Papua New Guinea', flag: 'https://flagcdn.com/pg.svg' },
    { value: '2588', label: 'Paraguay', flag: 'https://flagcdn.com/py.svg' },
    { value: '2592', label: 'Peru', flag: 'https://flagcdn.com/pe.svg' },
    { value: '2596', label: 'Philippines', flag: 'https://flagcdn.com/ph.svg' },
    { value: '2600', label: 'Pitcairn Islands', flag: 'https://flagcdn.com/pn.svg' },
    { value: '2604', label: 'Poland', flag: 'https://flagcdn.com/pl.svg' },
    { value: '2608', label: 'Portugal', flag: 'https://flagcdn.com/pt.svg' },
    { value: '2612', label: 'Puerto Rico', flag: 'https://flagcdn.com/pr.svg' },
    { value: '2616', label: 'Qatar', flag: 'https://flagcdn.com/qa.svg' },
    { value: '2618', label: 'Réunion', flag: 'https://flagcdn.com/re.svg' },
    { value: '2620', label: 'Romania', flag: 'https://flagcdn.com/ro.svg' },
    { value: '2624', label: 'Russia', flag: 'https://flagcdn.com/ru.svg' },
    { value: '2628', label: 'Rwanda', flag: 'https://flagcdn.com/rw.svg' },
    { value: '2632', label: 'Saint Barthélemy', flag: 'https://flagcdn.com/bl.svg' },
    { value: '2634', label: 'Saint Helena', flag: 'https://flagcdn.com/sh.svg' },
    { value: '2638', label: 'Saint Kitts and Nevis', flag: 'https://flagcdn.com/kn.svg' },
    { value: '2640', label: 'Saint Lucia', flag: 'https://flagcdn.com/lc.svg' },
    { value: '2644', label: 'Saint Martin', flag: 'https://flagcdn.com/mf.svg' },
    { value: '2648', label: 'Saint Pierre and Miquelon', flag: 'https://flagcdn.com/pm.svg' },
    {
      value: '2650',
      label: 'Saint Vincent and the Grenadines',
      flag: 'https://flagcdn.com/vc.svg',
    },
    { value: '2654', label: 'Samoa', flag: 'https://flagcdn.com/ws.svg' },
    { value: '2656', label: 'San Marino', flag: 'https://flagcdn.com/sm.svg' },
    { value: '2658', label: 'Sao Tome and Principe', flag: 'https://flagcdn.com/st.svg' },
    { value: '2660', label: 'Saudi Arabia', flag: 'https://flagcdn.com/sa.svg' },
    { value: '2664', label: 'Senegal', flag: 'https://flagcdn.com/sn.svg' },
    { value: '2668', label: 'Serbia', flag: 'https://flagcdn.com/rs.svg' },
    { value: '2672', label: 'Seychelles', flag: 'https://flagcdn.com/sc.svg' },
    { value: '2676', label: 'Sierra Leone', flag: 'https://flagcdn.com/sl.svg' },
    { value: '2680', label: 'Singapore', flag: 'https://flagcdn.com/sg.svg' },
    { value: '2684', label: 'Sint Maarten', flag: 'https://flagcdn.com/sx.svg' },
    { value: '2688', label: 'Slovakia', flag: 'https://flagcdn.com/sk.svg' },
    { value: '2692', label: 'Slovenia', flag: 'https://flagcdn.com/si.svg' },
    { value: '2696', label: 'Solomon Islands', flag: 'https://flagcdn.com/sb.svg' },
    { value: '2700', label: 'Somalia', flag: 'https://flagcdn.com/so.svg' },
    { value: '2704', label: 'South Africa', flag: 'https://flagcdn.com/za.svg' },
    { value: '2708', label: 'South Sudan', flag: 'https://flagcdn.com/ss.svg' },
    { value: '2710', label: 'Spain', flag: 'https://flagcdn.com/es.svg' },
    { value: '2712', label: 'Sri Lanka', flag: 'https://flagcdn.com/lk.svg' },
    { value: '2714', label: 'Sudan', flag: 'https://flagcdn.com/sd.svg' },
    { value: '2718', label: 'Suriname', flag: 'https://flagcdn.com/sr.svg' },
    { value: '2720', label: 'Sweden', flag: 'https://flagcdn.com/se.svg' },
    { value: '2724', label: 'Switzerland', flag: 'https://flagcdn.com/ch.svg' },
    { value: '2728', label: 'Syria', flag: 'https://flagcdn.com/sy.svg' },
    { value: '2732', label: 'Taiwan', flag: 'https://flagcdn.com/tw.svg' },
    { value: '2736', label: 'Tajikistan', flag: 'https://flagcdn.com/tj.svg' },
    { value: '2740', label: 'Tanzania', flag: 'https://flagcdn.com/tz.svg' },
    { value: '2744', label: 'Thailand', flag: 'https://flagcdn.com/th.svg' },
    { value: '2748', label: 'Togo', flag: 'https://flagcdn.com/tg.svg' },
    { value: '2752', label: 'Tokelau', flag: 'https://flagcdn.com/tk.svg' },
    { value: '2756', label: 'Tonga', flag: 'https://flagcdn.com/to.svg' },
    { value: '2760', label: 'Trinidad and Tobago', flag: 'https://flagcdn.com/tt.svg' },
    { value: '2764', label: 'Tunisia', flag: 'https://flagcdn.com/tn.svg' },
    { value: '2768', label: 'Turkey', flag: 'https://flagcdn.com/tr.svg' },
    { value: '2772', label: 'Turkmenistan', flag: 'https://flagcdn.com/tm.svg' },
    { value: '2776', label: 'Turks and Caicos Islands', flag: 'https://flagcdn.com/tc.svg' },
    { value: '2780', label: 'Tuvalu', flag: 'https://flagcdn.com/tv.svg' },
    { value: '2784', label: 'Uganda', flag: 'https://flagcdn.com/ug.svg' },
    { value: '2788', label: 'Ukraine', flag: 'https://flagcdn.com/ua.svg' },
    { value: '2790', label: 'United Arab Emirates', flag: 'https://flagcdn.com/ae.svg' },
    { value: '2792', label: 'United Kingdom', flag: 'https://flagcdn.com/gb.svg' },
    { value: '2794', label: 'United States', flag: 'https://flagcdn.com/us.svg' },
    { value: '2796', label: 'Uruguay', flag: 'https://flagcdn.com/uy.svg' },
    { value: '2800', label: 'Uzbekistan', flag: 'https://flagcdn.com/uz.svg' },
    { value: '2804', label: 'Vanuatu', flag: 'https://flagcdn.com/vu.svg' },
    { value: '2808', label: 'Venezuela', flag: 'https://flagcdn.com/ve.svg' },
    { value: '2814', label: 'Vietnam', flag: 'https://flagcdn.com/vn.svg' },
    { value: '2818', label: 'Wallis and Futuna', flag: 'https://flagcdn.com/wf.svg' },
    { value: '2820', label: 'Western Sahara', flag: 'https://flagcdn.com/eh.svg' },
    { value: '2824', label: 'Yemen', flag: 'https://flagcdn.com/ye.svg' },
    { value: '2826', label: 'Zambia', flag: 'https://flagcdn.com/zm.svg' },
    { value: '2830', label: 'Zimbabwe', flag: 'https://flagcdn.com/zw.svg' },
  ]

  const formatOptions = [
    { label: 'All Formats', value: 'All Formats' },
    { label: 'Images', value: 'Images' },
    { label: 'Texts', value: 'Texts' },
    { label: 'Videos', value: 'Videos' },
  ]

  const handleCompanySearch = e => {
    const enteredValue = e.target.value
    dispatch({ type: ADS_SEARCH_COMPANY, payload: enteredValue })
  }
  const handleKeywordSearch = e => {
    let enteredValue = e.target.value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: enteredValue })
  }

  const handleSelectFormat = selectedValues => {
    setSelectedOptionsFormat(selectedValues)
    dispatch({ type: ADS_FORMAT, payload: selectedValues.includes('All') ? '' : selectedValues })
  }
  const handleSelectChannels = selectedValues => {
    setSelectedOptionsFormat([])
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_LOCATION, payload: '' })
    setSelectedLocation([])
    setSelectedOptionsChannel(selectedValues)
    dispatch({ type: ADS_CHANNEL, payload: selectedValues })
  }

  const onChangeLocation = value => {
    dispatch({ type: ADS_LOCATION, payload: value })
    setSelectedLocation(value)
  }
  const onSearchLocation = value => {}
  const onDropdownVisibleChange = open => {
    setLocationPlaceholder(open ? 'Search by Country Name' : 'Location: Anywhere')
  }

  // Fetch Ads Data

  const handleAdsApi = async () => {
    dispatch({ type: ADS_LOADER, payload: true })
    dispatch({
      type: ADS_PAGINATION,
      payload: '',
    })
    dispatch({ type: SPINNER, payload: true })
    // dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    // emptyAdsData()

    const googleData = {
      region: location,
      search_term: searchKeyword,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      num: adsFormats === 'Videos' ? 20 : 100,
      channels: selectedChannel,
    }
    const metaData = {
      ad_reached_countries: [location],
      search_term: searchKeyword,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      num: 30,
      channels: selectedChannel,
    }
    if (selectedChannel === 'Google' || selectedChannel === 'G') {
      try {
        dispatch({ type: SPINNER, payload: true })
        const response = await RequestService.post('/ads-preview/competitors/', googleData)
        dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
        const formattedData = response?.data
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: false })
        if (formattedData?.google_ads_result?.google_ads[0]?.ad_creatives) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData?.google_ads_result?.google_ads[0]?.ad_creatives,
          })
        }
        if (formattedData?.google_ads_result?.google_ads[0]?.serpapi_pagination?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData?.google_ads_result?.google_ads[0]?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })

          if (adsFormats === 'Videos') {
            dispatch({ type: CLEANED_DATA_COUNT, payload: 20 })
          } else {
            dispatch({ type: CLEANED_DATA_COUNT, payload: 100 })
          }
        }
        if (!formattedData?.google_ads_result?.google_ads[0]?.ad_creatives) {
          dispatch({ type: ADS_NO_DATA, payload: true })
          dispatch({
            type: ADS_DATA,
            payload: [],
          })
        }
        dispatch({ type: SPINNER, payload: false })
      } catch {
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
    } else if (selectedChannel === 'Meta') {
      try {
        dispatch({ type: SPINNER, payload: true })
        const response = await RequestService.post('/ads-preview/competitors/', metaData)
        dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
        const formattedData = response?.data
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: false })
        if (formattedData?.meta_ads?.facebook_ads[0]?.data) {
          dispatch({
            type: ADS_DATA,
            payload: formattedData?.meta_ads?.facebook_ads[0]?.data,
          })
        }
        if (formattedData?.meta_ads?.facebook_ads[0]?.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData?.meta_ads?.facebook_ads[0]?.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        }
        dispatch({ type: CLEANED_DATA_COUNT, payload: 20 })
        if (formattedData?.meta_ads?.facebook_ads[0]?.data.length === 0) {
          dispatch({ type: ADS_NO_DATA, payload: true })
          dispatch({
            type: ADS_DATA,
            payload: [],
          })
        }
        dispatch({ type: SPINNER, payload: false })
      } catch {
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: ADS_LOADER, payload: false })
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
    }
  }

  const handleSearchButton = () => {
    if (searchKeyword.trim() !== '') {
      // dispatch({ type: META_ADS_PAGINATION_DATA, payload: [] })
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({
        type: HAS_MORE,
        payload: false,
      })

      handleAdsApi()
    } else {
      notify('Search Keyword cannot be empty', 'error')
    }
  }

  return (
    <div style={{ background: '#fff' }}>
      <Row gutter={12} className={styles.parentDiv}>
        <Col span={10} className={styles.inputContainer}>
          {/* <div id="search1" className={styles.input}>
            <Input
              id="companySearchInput"
              className={styles.inputText}
              placeholder="Search by company or advertiser name"
              prefix={
                // <SearchOutlined style={{ width: 24, height: 24, color: '#8F8F8F' }} />
                <img src={companySearchIcon} height={24} width={24} alt="search-icon" />
              }
              onPressEnter={handleCompanySearch}
            />
          </div> */}

          {/* Start of Keyword Input field */}
          <div className={styles.input}>
            <Input
              id="keywordSearchInput"
              className={styles.inputText}
              placeholder="Search by keyword"
              prefix={
                // <SearchOutlined style={{ width: 24, height: 24, color: '#8F8F8F' }} />
                <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
              }
              onChange={handleKeywordSearch}
              onPressEnter={loading || moreDataLoader ? '' : handleSearchButton}
            />
          </div>
          {/* End of Keyword Input field */}
        </Col>

        {/* Start of Location Dropdown */}
        <Col span={5} style={{ position: 'relative' }} id="area">
          {selectedChannel === 'Google' || selectedChannel === 'G' ? (
            <Select
              id="location"
              value={selectedLocation}
              showSearch
              placeholder={locationPlaceholder}
              onDropdownVisibleChange={onDropdownVisibleChange}
              optionFilterProp="children"
              onChange={onChangeLocation}
              onSearch={onSearchLocation}
              dropdownClassName="ads-preview-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              style={{
                display: 'block',
                fontSize: 14,
              }}
              filterOption={(input, option) => {
                const label = option.children[1] // Access the label part of the children
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {countries.map(country => (
                <Option
                  key={country.value}
                  value={country.value}
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '16px',
                  }}
                >
                  {country.flag ? (
                    <img
                      src={country.flag}
                      alt="flag"
                      width={24}
                      height={24}
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    ''
                  )}
                  {country.label}
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              id="location"
              showSearch
              value={selectedLocation}
              placeholder={locationPlaceholder}
              onDropdownVisibleChange={onDropdownVisibleChange}
              optionFilterProp="children"
              onChange={onChangeLocation}
              onSearch={onSearchLocation}
              dropdownClassName="ads-preview-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              style={{
                display: 'block',
                fontSize: 14,
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Metacountries.map(country => (
                <Option
                  key={country.value}
                  value={country.value}
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '16px',
                  }}
                >
                  {country.label}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        {/* End of Location Dropdown */}

        {/* <div id="AdsPreviewSearchSelect"> */}
        {/* <Col span={5} style={{ position: 'relative' }} id="area">
          <Select
            id="channels"
            // mode="multiple"
            placeholder="Channels: All Channels"
            style={{
              display: 'block',
              fontSize: 14,
            }}
            // mode="multiple"
            dropdownClassName="ads-preview-dropdown"
            onChange={handleSelectChannels}
            value={selectedOptionsChannel}
            showArrow
            showSearch={false}
            getPopupContainer={() => document.getElementById('area')}
            optionLabelProp="label"
            maxTagCount="responsive"
          >
            <Option value="All Channels" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox
                  disabled
                  id="ads-checkbox"
                  checked={selectedOptionsChannel.includes('All Channels')}
                />
                <span className={styles.checkbox}>All Channels</span>
              </div>
            </Option>

            <Option value="Google">
              <div className={styles.options}>
                <Checkbox checked={selectedOptionsChannel.includes('Google')} />
                <span className={styles.checkbox}>Google</span>
              </div>
            </Option>
            <Option value="Meta">
              <div className={styles.options}>
                <Checkbox checked={selectedOptionsChannel.includes('Meta')} />
                <span className={styles.checkbox}>Meta</span>
              </div>
            </Option>
            <Option value="Pinterest" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('Pinterest')} />
                <span className={styles.checkbox}>Pinterest</span>
              </div>
            </Option>
            <Option value="Twitter" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('Twitter')} />
                <span className={styles.checkbox}>Twitter</span>
              </div>
            </Option>
            <Option value="LinkedIn" disabled style={{ color: 'gray' }}>
              <div className={styles.options}>
                <Checkbox disabled checked={selectedOptionsChannel.includes('LinkedIn')} />
                <span className={styles.checkbox}>LinkedIn</span>
              </div>
            </Option>
          </Select>
        </Col> */}
        {/* </div> */}
        {/* <div id="AdsPreviewSearchSelect"> */}

        {/* Start of Formats dropdown */}
        <Col span={5}>
          {
            // eslint-disable-next-line
            selectedChannel === 'Google' || selectedChannel === 'G' ? (
              <Select
                placeholder="Ad Formats: All formats"
                style={{
                  display: 'block',
                  fontSize: 14,
                  borderColor: '#E8EBEC !important',
                }}
                dropdownClassName="ads-preview-dropdown"
                onChange={handleSelectFormat}
                showArrow
                value={selectedOptionsFormat}
                showSearch={false}
                getPopupContainer={() => document.getElementById('area')}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                <Option
                  value="All"
                  label={
                    <div className={styles.options}>
                      <span className={styles.checkbox}>All</span>
                    </div>
                  }
                >
                  <div className={styles.options}>
                    <span className={styles.checkbox}>All</span>
                  </div>
                </Option>
                <Option
                  value="Images"
                  label={
                    <div className={styles.options} style={{ alignItems: 'center' }}>
                      {/* <img src={ImageIcon} alt="imageIcon" width={24} height={24} /> */}
                      <span className={styles.checkbox}>Images</span>
                    </div>
                  }
                >
                  <div className={styles.options}>
                    {/* <img src={ImageIcon} alt="imageIcon" /> */}
                    <span className={styles.checkbox}>Images</span>
                  </div>
                </Option>
                <Option
                  value="Texts"
                  label={
                    <div className={styles.options}>
                      <span className={styles.checkbox}>Texts</span>
                    </div>
                  }
                >
                  <div className={styles.options}>
                    <span className={styles.checkbox}>Texts</span>
                  </div>
                </Option>
                <Option
                  value="Videos"
                  label={
                    <div className={styles.options}>
                      <span className={styles.checkbox}>Videos</span>
                    </div>
                  }
                >
                  <div className={styles.options}>
                    <span className={styles.checkbox}>Videos</span>
                  </div>
                </Option>
              </Select>
            ) : selectedChannel === 'Meta' ? (
              <Select
                placeholder="Ad Formats: All formats"
                style={{
                  // flex: 1,
                  display: 'block',
                  fontSize: 14,
                  borderColor: '#E8EBEC !important',
                }}
                // mode="multiple"
                dropdownClassName="ads-preview-dropdown"
                onChange={handleSelectFormat}
                showArrow
                value={selectedOptionsFormat}
                showSearch={false}
                getPopupContainer={() => document.getElementById('area')}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                <Option value="All">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('All')} /> */}
                    <span className={styles.checkbox}>All</span>
                  </div>
                </Option>
                <Option value="Images">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Images')} /> */}
                    <span className={styles.checkbox}>Images</span>
                  </div>
                </Option>
                {/* <Option value="Meme">
                  <div className={styles.options}> */}
                {/* <Radio checked={selectedOptionsFormat.includes('Texts')} /> */}
                {/* <span className={styles.checkbox}>Meme</span>
                  </div>
                </Option> */}
                <Option value="Videos">
                  <div className={styles.options}>
                    {/* <Radio checked={selectedOptionsFormat.includes('Videos')} /> */}
                    <span className={styles.checkbox}>Videos</span>
                  </div>
                </Option>
              </Select>
            ) : (
              ''
            )
          }
        </Col>
        {/* End of Search Queries Collapse */}

        <Col span={4}>
          <button
            type="submit"
            className={styles.searchButton}
            onClick={handleSearchButton}
            disabled={loading || moreDataLoader}
          >
            SEARCH
          </button>
        </Col>
        {/* </div> */}
        {/* </div> */}
      </Row>
    </div>
  )
}

export default SearchBar
