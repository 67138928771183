import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchRoles = async () => {
  const { url, method } = apiRoutes.roles
  try {
    const res = await api({
      url,
      method,
    })
    return res
  } catch (e) {
    return e.response
  }
}
