import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Tag } from 'antd'
import { FaQuestionCircle } from 'react-icons/fa'
import 'remixicon/fonts/remixicon.css'
import styles from './infoCard.module.scss'
import Text from '../Text/text'

const InformationCard = ({
  title,
  number,
  footerText,
  tooltipText,
  isMoney,
  color,
  tagText,
  tagColor,
  children,
}) => (
  <div className={styles.containerDiv}>
    <div className={styles.titleDiv}>
      <Text color="black" size={14} isBold isTitle isUppercase>
        {title}
      </Text>
      {tooltipText && (
        <Tooltip className={styles.tooltipText} placement="right" title="Tooltip is working">
          ?
        </Tooltip>
        // <Tooltip
        //   className={styles.tooltipText}
        //   placement="top"
        //   color={color}
        //   getPopupContainer={triggerNode => triggerNode.parentNode}
        //   title={
        //     <Text color="white" size={14}>
        //       {tooltipText}
        //     </Text>
        //   }
        // >
        //   <FaQuestionCircle />
        // </Tooltip>
      )}
    </div>
    <div className={styles.valueDiv}>
      <Text size={24} isTitle isBold color={color}>
        {isMoney && <span>$</span>}
        {number ?? 0}
      </Text>

      {children}

      {tagText && (
        <Tag className={styles.tag} color={tagColor}>
          <Text isUppercase isBold color="white">
            {tagText}
          </Text>
        </Tag>
      )}
    </div>
    {footerText && (
      <Text size={12} color="black">
        {footerText}
      </Text>
    )}
  </div>
)

InformationCard.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  tooltipText: PropTypes.string,
  color: PropTypes.string,
  isMoney: PropTypes.bool,
  footerText: PropTypes.string,
  tagColor: PropTypes.string,
  tagText: PropTypes.string,
  children: PropTypes.node,
}

InformationCard.defaultProps = {
  title: 'Title',
  number: '',
  tooltipText: '',
  isMoney: false,
  color: 'black',
  footerText: '',
  tagText: '',
  tagColor: '',
  children: '',
}

export default InformationCard
