import React from 'react'
import styles from './underlineTextButton.module.css'

const UnderlineTextButton = ({ children, ...rest }) => (
  <button className={styles.underlineText} type="button" {...rest}>
    {children}
  </button>
)

export default UnderlineTextButton
