/* eslint-disable import/no-unresolved */
import { apiRoutes } from 'config/apiRoutes'
import api from './api'

export const uploadProfilePicture = async data => {
  const { url, method } = apiRoutes.profileUpload
  try {
    const res = await api({
      url,
      method,
      data,
    })
    return res
  } catch (e) {
    return e.response
  }
}
