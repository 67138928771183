/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Collapse, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  AD_RELEVANCE_SECTION,
  CLICK_THROUGH_RATE_SECTION,
  COST_EVALUATION_SECTION,
  GOOGLE_ADS_BEST_PRACTICES_SECTION,
  IMPRESSION_SHARE_SECTION,
  KEYWORDS_MATCH_TYPE_SECTION,
  PERFORMANCE_SECTION,
  QUALITY_SCORE_SECTION,
  WASTED_SPEND_SECTION,
  ADS_BEDGET_COST_CACULATOR,
  ADS_PERFORMANCE_GRADE,
} from 'utils/constants'
import { useTranslation } from 'react-i18next'
import Calculator from 'pages/calculator'
import Benchmark from '@pages/benchmark'
import { LS_KEY } from '@utils/constants'
import { notify } from '@utils/notify'
import CustomersSelect from '@components/Select/customersSelect'
import { fetchRoleInfo } from '@services/fetchRoleInfo'
import { fetchReportDashboard } from '@services/fetchReportDashboard'
import { scrollToElement, sortByCreatedAt } from '../../utils/helperFunctions'
import ReportPageTemporary from '../../components/ReportPageTemporary/index'
import { fetchReports } from '../../services/fetchReports'
import reportImg from '../../images/report.png'
import consultingImg from '../../images/consulting.svg'

import styles from './dashboard.module.scss'
import ReportHeader from '../../components/Report/ReportHeader'

const { Panel } = Collapse

const generateRoles = ['Admin', 'Account Manager', 'Owner']

const ReportDashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const customer = useSelector(state => state.customer)
  const updatedReport = useSelector(state => state?.report)
  const [selectedTab, setSelectedTab] = useState('')
  const [reports, setReports] = useState([])
  const [currentReport, setCurrentReport] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(customer?.id ?? '')
  const [showGenerateButton, setShowGenerateButton] = useState(false)
  const [generateReportLoading, setGenerateReportLoading] = useState(false)
  const [currentSection, setCurrentSection] = useState(null)
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isBenchmarkOpen, setIsBenchmarkOpen] = useState(false)

  const [reportCustomerId, setReportCustomerId] = useState('')
  const [copiedName, setCopiedName] = useState('')
  const [copiedCustomerId, setCopiedCustomerId] = useState('')

  const [loadError, setLoadError] = useState(false)

  const token = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
  const history = useHistory()
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const sections = [
    { label: `${t('report.adsperformancegrade')}`, key: ADS_PERFORMANCE_GRADE },
    { label: `${t('report.performanceMetrics')}`, key: PERFORMANCE_SECTION },
    { label: `${t('report.tabs.costEvolution.title')}`, key: COST_EVALUATION_SECTION },
    { label: `${t('report.tabs.spend.title')}`, key: WASTED_SPEND_SECTION },
    { label: `${t('report.tabs.adRelevance.title')}`, key: AD_RELEVANCE_SECTION },
    { label: `${t('report.tabs.qualityScore.title')}`, key: QUALITY_SCORE_SECTION },
    { label: `${t('report.tabs.adComparisons.ctr')}`, key: CLICK_THROUGH_RATE_SECTION },
    { label: `${t('report.tabs.keywordsMatchType.title')}`, key: KEYWORDS_MATCH_TYPE_SECTION },
    { label: `${t('report.tabs.impShare.title')}`, key: IMPRESSION_SHARE_SECTION },
    { label: `${t('report.tabs.googleAdsBest.title')}`, key: GOOGLE_ADS_BEST_PRACTICES_SECTION },
    // { label: `${t('report.tabs.adsCalculator')}`, key: ADS_BEDGET_COST_CACULATOR },
  ]

  useEffect(() => {
    fetchReportDashboard(
      localStorage.getItem('copied_report_unique_id'),
      localStorage.getItem('copied_report_customer_id')
    )
      .then(response => {
        setIsLoading(true)
        setCopiedName(response?.data?.customer_descriptive_name)
        setCopiedCustomerId(response?.data?.customer_id)
        setReportCustomerId(response?.data?.customer_id)
        setCurrentReport(response?.data)
        setSelectedTab(response?.data?.id)
        setIsLoading(false)

        // ....................................................................................................................
        if (token) {
          setReports([])
          // setCurrentReport(null)
          setCurrentSection(null)
          // fetchReports(response?.data?.customer_id)
          //   .then(res => {
          //     setShowGenerateButton(false)
          //     setIsLoading(false)
          //     if (res.status === 200) {
          //       const sortedData = sortByCreatedAt(res?.data)
          //       setReports(sortedData)
          //       setSelectedTab('')
          //       setLoadError(false)
          //       if (sortedData?.length) {
          //         fetchRoleInfo(selectedUserId).then(r => {
          //           if (generateRoles.includes(r?.data?.role?.name) || !r?.data?.is_invited_user) {
          //             setShowGenerateButton(true)
          //           }
          //         })
          //       }
          //     } else if (res.status >= 500) {
          //       setSelectedTab('')
          //       setLoadError(true)
          //       // notify(`Your Ad's account does not have enough data.`, 'error')
          //       // notify(t('notification.unableToLoadData'), 'error')
          //     } else {
          //       console.log('reports error~: ', res)
          //     }
          //   })
          //   .catch(error => {
          //     setIsLoading(false)
          //     console.log(error)
          //   })
        } else if (!token) {
          history.push('/login')
        }
        // ..............................................................................................................
      })
      .catch(error => {
        // console.log('error', error)
      })
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   if (token && reportCustomerId) {
  //     setIsLoading(true)
  //     setReports([])
  //     setCurrentReport(null)
  //     setCurrentSection(null)
  //     fetchReports(reportCustomerId)
  //       .then(res => {
  //         setShowGenerateButton(false)
  //         setIsLoading(false)
  //         console.log('response of fetch rep', res.data)
  //         if (res.status === 200) {
  //           const sortedData = sortByCreatedAt(res?.data)
  //           setReports(sortedData)
  //           setSelectedTab('')
  //           setLoadError(false)
  //           if (sortedData?.length) {
  //             fetchRoleInfo(selectedUserId).then(r => {
  //               if (generateRoles.includes(r?.data?.role?.name) || !r?.data?.is_invited_user) {
  //                 setShowGenerateButton(true)
  //               }
  //             })
  //           }
  //         } else if (res.status >= 500) {
  //           setSelectedTab('')
  //           setLoadError(true)
  //           // notify(`Your Ad's account does not have enough data.`, 'error')
  //           // notify(t('notification.unableToLoadData'), 'error')
  //         } else {
  //           console.log('reports error~: ', res)
  //         }
  //       })
  //       .catch(error => {
  //         setIsLoading(false)
  //         console.log(error)
  //       })
  //   }
  //   // eslint-disable-next-line
  // }, [token, history, customer])
  // useEffect(() => {
  //   if (reports?.length) {
  //     setSelectedTab(reports[0]?.id)
  //     setCurrentReport(reports[0])
  //   }
  // }, [reports])

  // useEffect(() => {
  //   if (updatedReport) {
  //     setCurrentReport(updatedReport)
  //   }
  // }, [updatedReport])

  const handleSelectUser = customerId => {
    setSelectedUserId(customerId)
  }
  // const handleGenerateReport = async () => {
  //   const parentCustomerId = getParentCustomerId(customer.resource_name)
  //   console.log('generate', customer.id, parentCustomerId)
  //   setGenerateReportLoading(true)
  //   await generateReportForCustomer(String(customer?.id), parentCustomerId).then(res => {
  //     setGenerateReportLoading(false)
  //     if (res.status >= 200 && res.status <= 299) {
  //       notify('Report generated successfully', 'success')
  //       setReports(pre => [...pre, res.data])
  //     } else if (res.status >= 500) {
  //       notify('Internal server error!', 'error')
  //     } else {
  //       notify('Something went wrong', 'error')
  //     }
  //   })
  // }

  const handleSectionClick = sectionId => {
    const el = document.getElementById(sectionId)
    scrollToElement(el)
    setCurrentSection(sectionId)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
  }

  useEffect(() => {
    const loginThroughWebLinkLocalstorage = JSON.parse(localStorage.getItem('loginThroughWebLink'))
    if (loginThroughWebLinkLocalstorage) {
      const leadTest = JSON.parse(localStorage.getItem('lead_form'))
      if (leadTest) {
        history.push('/dashboard')
      }
      const selectaccountFromLocalstorage = JSON.parse(localStorage.getItem('selectAccount'))
      if (selectaccountFromLocalstorage) {
        if (selectaccountFromLocalstorage) {
          const customerFromLocalStorage = JSON.parse(localStorage.getItem('customer'))
          if (customerFromLocalStorage) {
            const generatingFromLocalstorage = JSON.parse(localStorage.getItem('report-generating'))
            if (!generatingFromLocalstorage) {
              history.push(`/report-generating/${selectaccountFromLocalstorage}`)
            }
          }
        }
      } else {
        history.push('/select-account')
      }
    }
    // eslint-disable-next-line
  }, [])
  const user = localStorage.getItem('np_ads_grader:session')
  const userObject = JSON.parse(user)
  const userEmail = userObject?.user.email
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isCalculatorOpen, isBenchmarkOpen])

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={styles.sidebarTop}>
          {/* <CustomersSelect
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            handleSelectUser={handleSelectUser}
            rightMenu
            setCurrentSection={setCurrentSection}
            setReports={setReports}
          /> */}
          {/* <button
            className={`${styles.sidebarButton} ${selectedTab === 'dashboard' && styles.active}`}
            type="button"
            onClick={() => setSelectedTab('dashboard')}
          >
            Dashboard
          </button> */}
          <div id="reportCollapse" className={styles.collapse} style={{ paddingBottom: '60px' }}>
            <Collapse accordion expandIconPosition="end" bordered={false} defaultActiveKey={['1']}>
              <Panel
                header={
                  <div
                    className={`${styles.reportHeader} ${
                      reports?.includes(selectedTab) && styles.active
                    } `}
                  >
                    {t('reports')}
                  </div>
                }
                key="1"
              >
                <div
                  className={`${styles.reportsAccordion} customScrollbar`}
                  style={{
                    height: '50%',
                    overflow: 'auto',
                    paddingBottom: '20px',
                  }}
                >
                  {sections.map(section => (
                    <button
                      type="button"
                      className={`${styles.sectionName} ${
                        currentSection === section.key &&
                        !isCalculatorOpen &&
                        !isBenchmarkOpen &&
                        styles.active
                      }`}
                      onClick={() => handleSectionClick(section.key)}
                      disabled={isLoading}
                    >
                      {section.label}
                    </button>
                  ))}
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
      <div className={`${styles.reportContainer} customScrollbar`}>
        {/* eslint-disable-next-line */}
        {selectedTab === 'Dashboard' ? (
          <div>{t('dashboardText')}</div>
        ) : // eslint-disable-next-line
        isCalculatorOpen ? (
          <div ref={containerRef}>
            <Calculator />
          </div>
        ) : isBenchmarkOpen ? (
          <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
            <Benchmark />
          </div>
        ) : (
          <div ref={containerRef}>
            {!isLoading && (
              <ReportHeader
                reports={reports}
                setCurrentReport={setCurrentReport}
                currentReport={currentReport}
              />
            )}
            {/* {selectedTab && !isLoading && (
              <>
                <ReportPageTemporary
                  selectedReportData={currentReport}
                  setCurrentSection={setCurrentSection}
                  reportDashboardPage="true"
                  copiedReportName={copiedName}
                  copiedCustomerId={copiedCustomerId}
                />
              </>
            )} */}
            {(selectedTab || selectedTab === undefined) &&
              !isLoading &&
              (userEmail?.includes('npaccel.com') ||
                userEmail?.includes('npdigital.com') ||
                userEmail?.includes('neilpatel.com') ||
                userEmail?.includes('ubersuggest.com') ||
                userEmail?.includes('grayphite.com')) && (
                <>
                  <ReportPageTemporary
                    selectedReportData={currentReport}
                    setCurrentSection={setCurrentSection}
                    reportDashboardPage="true"
                    copiedReportName={copiedName}
                    copiedCustomerId={copiedCustomerId}
                  />
                </>
              )}
            {!selectedTab && !isLoading && loadError && (
              <div className={styles.selectReport}>
                <h1 className={styles.selectReportHeading}>{t('loadReportErrorText')}</h1>
                {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
              </div>
            )}
            {selectedTab && !isLoading && !loadError && (
              <div className={styles.selectReport}>
                <h1 className={styles.selectReportHeading}>{t('noReportTextForUnauthorized')}</h1>
                {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
              </div>
            )}
            {!selectedTab && !isLoading && !loadError && (
              <div className={styles.selectReport}>
                <h1 className={styles.selectReportHeading}>{t('noReportTextForUnauthorized')}</h1>
                {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
              </div>
            )}
            {isLoading && (
              <div className={styles.loadingContainer}>
                <Spin />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ReportDashboard
