import jwt from 'jsonwebtoken'

const SECRET = process.env.REACT_APP_SECRET

const encode = payload => {
  const token = jwt.sign(payload, SECRET, {
    noTimestamp: true,
  })

  return token
}

const decode = token => {
  const payload = jwt.decode(token)

  return payload
}

export const SecretService = {
  encode,
  decode,
}
