import BoardList from '@components/Boards/BoardList'
import BoardsNavbar from '@components/Boards/BoardsNavbar'
import React, { useEffect, useState } from 'react'
import { RequestService } from '@services/requests'
import { API_DATA_BOARDS_LIST } from '@utils/constants'
import { useDispatch, useSelector } from 'react-redux'

const BoardsFeature = () => {
  const dispatch = useDispatch()
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)

  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log(':rocket: ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }
  useEffect(() => {
    hanldeDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      <BoardsNavbar />
      <BoardList boardsList={boardsList} />
    </div>
  )
}

export default BoardsFeature
