import { CloseOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Text from '@components/Text/text'
import {
  FILTERED_TAGS,
  TAGS,
  SCORE_AND_INDUSTRY,
  SCORE_AND_INDUSTRY_LOADER,
  RELATED_QUERIES,
  RELATED_QUERIES_LOADER,
} from '@utils/constants'
import { RequestService, bucketBaseURL } from '@services/requests'
import { useDispatch, useSelector } from 'react-redux'
import shareIcon from '../../../images/DiscoverAds/Modal/icons-share.png'
import Divider from '../../../images/DiscoverAds/Modal/Vector 6638.png'
import Next from '../../../images/DiscoverAds/Modal/next.png'
import Previous from '../../../images/DiscoverAds/Modal/previous.png'
import style from './adsModal.module.css'
import ShareModal from './ShareModal'
import ModalCard from './ModalCard'
import AdDetailCard from './AdDetailCard'

const AdsModal = ({
  modalOpen,
  handleOk,
  handleCancel,
  adsData,
  onPrevious,
  onNext,
  isFirst,
  isLast,
  boardsList,
}) => {
  const adsDate = new Date(adsData?.created_at).toLocaleDateString()
  const localDate = new Date().toLocaleDateString()
  const dispatch = useDispatch()
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [isModalOpen, setIsModalOpen] = useState(modalOpen)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const getScoresAndIndustry = async id => {
    if (id?.additional_info?.ad_score) {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      const customResponse = {
        // eslint-disable-next-line
        id: id?._id,
        industry: id?.additional_info?.industry,
        reasoning: id?.additional_info?.reasoning,
        score: id?.additional_info?.ad_score,
      }
      dispatch({ type: SCORE_AND_INDUSTRY, payload: customResponse })
    } else {
      dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: true })
      const data = {
        // eslint-disable-next-line
        ad_id: id?._id,
      }
      try {
        const response = await RequestService.post('/ads-preview/open-ai/', data)
        dispatch({ type: SCORE_AND_INDUSTRY, payload: response.data })
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      } catch (error) {
        dispatch({ type: SCORE_AND_INDUSTRY_LOADER, payload: false })
      }
    }
  }
  const getTags = async id => {
    try {
      const response = await RequestService.get(`/save/tags/?ad_id=${id}`)
      dispatch({ type: TAGS, payload: response.data })
      dispatch({ type: FILTERED_TAGS, payload: response.data })
    } catch (error) {
      dispatch({ type: TAGS, payload: [] })
      dispatch({ type: FILTERED_TAGS, payload: [] })
    }
  }
  const handleRelatedQueriesData = async () => {
    dispatch({ type: RELATED_QUERIES, payload: [] })
    dispatch({ type: RELATED_QUERIES_LOADER, payload: true })
    const targetDomain = adsData?.search_term
    try {
      const response = await RequestService.get(
        `/ads-preview/competitors/?search_term=${targetDomain}`
      )
      const formattedData = response?.data
      dispatch({ type: RELATED_QUERIES, payload: formattedData?.google_ads[0] })
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    } catch (error) {
      dispatch({ type: RELATED_QUERIES_LOADER, payload: false })
    }
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    // eslint-disable-next-line
    if (adsData?._id) {
      // Fetch the scores and tags for the updated adsData when it changes
      getScoresAndIndustry(adsData)
      // eslint-disable-next-line
      getTags(adsData._id)
      if (currentPath !== '/dashboard/creative-library') {
        handleRelatedQueriesData()
      }
    }
    // eslint-disable-next-line
  }, [adsData])
  const showModal = () => {
    setIsModalOpen(true)
  }

  const showShareModal = () => {
    setIsShareModalOpen(true)
  }

  const handleShareOk = () => {
    setIsShareModalOpen(false)
  }

  const handleShareCancel = () => {
    setIsShareModalOpen(false)
  }
  const handlePreviousClick = () => {
    if (!isFirst) {
      onPrevious()
    }
  }

  const handleNextClick = () => {
    if (!isLast) {
      onNext()
    }
  }

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="100%"
      footer={null}
      borderRadius="12px 12px 0px 0px"
    >
      <div className={style.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={style.closeButton} />
        </button>
      </div>
      <div className={style.modalContainer}>
        <div className={style.header}>
          <div className={style.heading}>
            {adsDate === localDate ? (
              <>
                {adsData?.additional_info?.page_profile_picture_url ? (
                  <img
                    src={adsData?.additional_info?.page_profile_picture_url}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                {adsData?.additional_info?.ads_grader_page_profile_picture_url ? (
                  <img
                    src={`https://adsgrader-glue-reports.s3.amazonaws.com/creative-library-assets/${bucketBaseURL}/assets/${adsData?.additional_info?.ads_grader_page_profile_picture_url}`}
                    alt="profile-pic"
                    width={48}
                    height={48}
                    style={{ borderRadius: '48px' }}
                  />
                ) : (
                  ''
                )}
              </>
            )}

            <div className={style.modalTitle}>
              <Text size={16} isBold style={{ color: '#202020', marginTop: 10 }}>
                {adsData?.additional_info?.page_name}
              </Text>
            </div>
          </div>
          <div className={style.headerButtonContainer}>
            {/* eslint-disable-next-line */}
            <img
              src={Previous}
              style={{
                cursor: isFirst ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isFirst ? 0.5 : 1,
              }}
              height={32}
              alt="previous"
              onClick={handlePreviousClick}
            />
            {/* eslint-disable-next-line */}
            <img
              src={Next}
              style={{
                cursor: isLast ? 'not-allowed' : 'pointer',
                marginTop: 8,
                opacity: isLast ? 0.5 : 1,
              }}
              height={32}
              alt="next"
              onClick={handleNextClick}
            />
          </div>
        </div>
        <Row style={{ marginTop: 32 }} gutter={32}>
          {/* Left Part of Modal */}
          <Col span={12}>
            <ModalCard adsData={adsData} />
          </Col>
          {/* Right Part of Modal */}
          <Col span={12}>
            <AdDetailCard adsData={adsData} boardsList={boardsList} />
          </Col>
        </Row>
      </div>
      {isShareModalOpen && (
        <ShareModal
          //   adsData={modalData}
          modalOpen={isShareModalOpen}
          handleOk={handleShareOk}
          handleCancel={handleShareCancel}
        />
      )}
    </Modal>
  )
}

export default AdsModal
