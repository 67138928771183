import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
// import { Button, Divider, Modal } from 'antd'
import Modal from '@components/Modal'
import { apiRoutes } from '../../config/apiRoutes'
import api from '../../services/api'
import Authentication from '../../components/Authentication'
import styles from './forgotPassword.module.css'

const ForgotPassword = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const validationSchema = yup.object().shape({
    email: yup.string().email('Enter valid email.').required('* Required'),
  })

  const showModal = () => {
    setOpenModal(true)
  }
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async values => {
      // console.log(values)
      const { url, method } = apiRoutes.forgotPassword
      setIsLoading(true)
      try {
        const res = await api({
          url,
          method,
          data: values,
        })
        if (res.status === 200) {
          // history.push('/')
          // console.log(res)
          setIsLoading(false)
          showModal()
        }
      } catch (e) {
        setIsLoading(false)
        // console.log('register error', e)
      }
    },
  })
  useEffect(() => {
    if (formik?.values?.email) {
      formik.setFieldTouched('email')
    }
    // eslint-disable-next-line
  }, [formik?.values?.email])
  const handleCancel = () => {
    setOpenModal(false)
  }
  return (
    <div className={styles.forgotPasswordContainer}>
      <Authentication
        componentType="forgotPassword"
        formik={formik}
        isLoading={isLoading}
        title="Reset Your Password"
        description="Enter your email and we'll send you a link to reset your password."
        buttonText="Reset Password"
      />
      <Modal open={openModal} onCancel={handleCancel} />
    </div>
  )
}

export default ForgotPassword
