/* eslint-disable react/jsx-curly-brace-presence */

import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import neilPatelImage from '@images/neil-img-home.png'
import iconChild from '@images/icon-check-ads-grader.png'
import adsFunelImage from '@images/ads-funel-exp.png'
import iconCheck from '@images/check.png'
import iconCheck2 from '@images/check-2.png'
import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import { useRedirectStore } from '../../hooks/use-redirect-store'
import InputProceed from './components/InputProceed'
import CardInfo from './components/CardInfo'
import { LS_KEY } from '../../utils/constants'

import styles from './home.module.scss'

const HomePage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  useRedirectStore({ autoRedirect: true })
  const { t } = useTranslation()
  const history = useHistory()
  const localStorageData = JSON.parse(localStorage.getItem(LS_KEY))

  useEffect(() => {
    const accessToken = localStorageData?.access_token
    if (accessToken) {
      history.push('/dashboard')
    }

    // eslint-disable-next-line
  }, [])
  // Only show the Landing Page on production enviroment
  // So we don't have this annoying iframe blocking clicks on all other pages
  return (
    <div>
      <div style={{ width: '100%', background: "url('/bg-header-home.png')" }}>
        <div className={styles.containerHome}>
          <div className={styles.haederLogo_home}>
            <div className={styles.wrapperLogo}>
              <LogoAdsGrader />
            </div>
            <div className={styles.logoTitle}>
              <Text color="white" size={16} isTitle>
                {t('home.header.textTop')}
              </Text>
            </div>
          </div>
          <div className={styles.headerTexts_home}>
            <div className={styles.infos_header}>
              <div className={styles.headerTitle}>
                <h1 style={{ margin: 0 }}>
                  <Text color="white" size={64} className={styles.responseTitle}>
                    {t('home.header.title')}
                  </Text>
                </h1>
                <div className={styles.headerDescription}>
                  <Text color="white" size={16}>
                    {t('home.header.subtitle')}
                  </Text>
                </div>
              </div>
              <div className={styles.inputContainer}>
                <InputProceed />
              </div>
              <div className={styles.headerProtectionText}>
                <div>
                  <img width={24} src={iconChild} alt="icon child" />
                </div>
                <div style={{ maxWidth: 334 }}>
                  <Text color="white">
                    {t('home.header.textProtection.part1')}
                    <em>{t('home.header.textProtection.never')}</em>
                    {t('home.header.textProtection.part2')}
                  </Text>
                </div>
              </div>
            </div>
            <div className={styles.wrapperImg_header}>
              <img style={{ width: '100%', maxWidth: 450 }} src={neilPatelImage} alt="Neil Patel" />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <div style={{ padding: '20px 0' }} className={styles.containerHome}>
          <div className={styles.containerAlignCenter}>
            <h2 className={styles.titleWrapper_adsFunel}>
              <Text color="black" size={40} className={styles.responseTitle}>
                {t('home.primarySection.title')}
              </Text>
            </h2>
          </div>
          <div className={styles.containerAlignCenter}>
            <div className={styles.wrapper_adsFunel}>
              <div>
                <img width="100%" src={adsFunelImage} alt="Gráfico Ads Funel" />
              </div>
              <div className={styles.wrapperText_adsFunel_desktop}>
                <Text isTitle size={18} className={styles.textLineHeight20}>
                  {t('home.primarySection.subtitle.part1')}
                </Text>
                <Text size={18} className={styles.textLineHeight20}>
                  {t('home.primarySection.subtitle.part2')}
                </Text>
                <Text size={18} className={styles.textLineHeight20}>
                  {t('home.primarySection.subtitle.part3')}
                </Text>
              </div>
              <div className={styles.wrapperText_adsFunel_mobile}>
                <Text size={20} className={styles.textLineHeight20}>
                  {t('home.primarySection.subtitle.mobile.part1')}
                  <Text size={20} isTitle>
                    {t('home.primarySection.subtitle.mobile.part2')}
                  </Text>
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.gridWrapper}>
            <CardInfo>{t('home.primarySection.wastedSpend')}</CardInfo>
            <CardInfo>{t('home.primarySection.textAds')}</CardInfo>
            <CardInfo>{t('home.primarySection.qualityScore')}</CardInfo>
            <CardInfo>{t('home.primarySection.longtailKeywords')}</CardInfo>
            <CardInfo>{t('home.primarySection.googleAdsBest')}</CardInfo>
            <CardInfo>{t('home.primarySection.clickthrough')}</CardInfo>
            <CardInfo>{t('home.primarySection.mobileAdvertising')}</CardInfo>
            <CardInfo>{t('home.primarySection.impressionShare')}</CardInfo>
          </div>
          <div className={styles.containerAlignCenter}>
            <div className={styles.buttonWrapper}>
              <Button className={styles.ctaButton} href="#proceed-section">
                <span
                // style={{ margin: '17px 0' }}
                >
                  <Text color="white" isTitle size={20}>
                    {t('home.ctaButton')}
                  </Text>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerHome}>
        <div className={styles.containerAlignCenter}>
          <div className={styles.tableWrapper_section}>
            <div className={styles.title_tableWrapper}>
              <Text size={48}>{t('home.tableSection.title')}</Text>
            </div>
            <div className={styles.tableContainer}>
              <div className={styles.columnlLeft_table}>
                <div className={styles.header_table}>
                  <Text isTitle size={40}>
                    {t('home.tableSection.problem.title')}
                  </Text>
                </div>
                <div className={styles.body_table}>
                  <div className={styles.wrapperIten_table}>
                    <span>
                      <img width={19} src={iconCheck} alt="icon check" />
                    </span>
                    <div style={{ paddingTop: 3 }}>
                      <Text isTitle size={18}>
                        {t('home.tableSection.problem.field1.title')}
                      </Text>
                      <Text size={18}>{t('home.tableSection.problem.field1.descri')}</Text>
                    </div>
                  </div>
                </div>
                <div className={styles.body_table}>
                  <div className={styles.wrapperIten_table}>
                    <span>
                      <img width={19} src={iconCheck} alt="icon check" />
                    </span>
                    <div style={{ paddingTop: 3 }}>
                      <Text isTitle size={18}>
                        {t('home.tableSection.problem.field2.title')}
                      </Text>
                      <Text size={18}>{t('home.tableSection.problem.field2.descri')}</Text>
                      <Text size={18}>(Google, 2017)</Text>
                    </div>
                  </div>
                </div>
                <div className={styles.body_table}>
                  <div className={styles.wrapperIten_table}>
                    <span>
                      <img width={19} src={iconCheck} alt="icon check" />
                    </span>
                    <div style={{ paddingTop: 3 }}>
                      <Text isTitle size={18}>
                        {t('home.tableSection.problem.field3.title')}
                      </Text>
                      <Text size={18}>{t('home.tableSection.problem.field3.descri')}</Text>
                      <Text size={18}>(Unbounce, 2019)</Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.column_table}>
                <div className={styles.header_table}>
                  <Text isTitle size={40}>
                    {t('home.tableSection.solutions.title')}
                  </Text>
                </div>
                <div className={styles.body_table}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field1.part1')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field1.part2')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field1.part3')}</Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.body_table}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field2.part1')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field2.part2')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field2.part3')}</Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.body_table}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field3.part1')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field3.part2')}</Text>
                      </div>
                    </div>
                    <div className={styles.wrapperIten_table}>
                      <span>
                        <img width={20} src={iconCheck2} alt="icon check" />
                      </span>
                      <div style={{ paddingTop: 3 }}>
                        <Text size={18}>{t('home.tableSection.solutions.field3.part3')}</Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="proceed-section" className={styles.containerAlignCenter}>
          <div className={styles.proceedWrapper_footer}>
            <h2>
              <Text size={48} className={styles.title_proceedWrapper}>
                {t('home.footerSection.title')}
              </Text>
            </h2>
            <Text size={20} color="black" className={styles.subtitle_proceedWrapper}>
              {t('home.footerSection.subtitle')}
            </Text>
            <div className={styles.input_footer}>
              <InputProceed isfooter="true" />
            </div>
          </div>
        </div>
        <div className={styles.containerAlignCenter}>
          <div className={styles.policiesRow}>
            <Link to="/terms" target="_blank">
              <Text size={18} color="primary">
                {t('home.footerSection.termsText')}
              </Text>
            </Link>
            <Link to="/privacy" target="_blank">
              <Text size={18} color="primary">
                {t('home.footerSection.policyText')}
              </Text>
            </Link>
          </div>
        </div>
        <div className={styles.containerAlignCenter}>
          <div className={styles.copyRow_footer}>
            <Text size={18} color="black">
              {t('home.footerSection.copyText')}
              <a href="https://npdigital.com" rel="noreferrer" target="_blank">
                <span className={styles.npDigital}>
                  {t('home.footerSection.copyTextNpDigital')}
                </span>
              </a>
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
