import { SearchOutlined } from '@ant-design/icons'
import { Input, message, Tag } from 'antd'
import { RequestService } from '@services/requests'
import Text from '@components/Text/text'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SAVED_PAGE_ALL_DATA,
  SAVED_PAGE_FILTER_ALL_DATA,
  SAVED_PAGE_UNIQUE_TAGS,
  TAGS,
  FILTERED_TAGS,
  BOARDS_LIST,
  BOARDS_LIST_COUNT,
  FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
  FETCH_SPECTFIC_BOARD_DATA,
} from '@utils/constants'

const Tags = ({ adsData }) => {
  // eslint-disable-next-line

  const dispatch = useDispatch()
  const tags = useSelector(state => state?.discoverAds?.tags)
  const originalTags = tags
  const filteredTags = useSelector(state => state?.discoverAds?.filteredTags)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = e => {
    const value = e.target.value.toLowerCase()
    setInputValue(value)
    // if (!value) {
    dispatch({ type: TAGS, payload: originalTags })
    dispatch({
      type: FILTERED_TAGS,
      payload: originalTags,
    })
    // }
    //  else {
    //   dispatch({
    //     type: FILTERED_TAGS,
    //     payload: tags.filter(tag => tag.name.toLowerCase() === value),
    //   })
    // }
  }
  const handleDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/board-ads/')

      dispatch({ type: BOARDS_LIST, payload: response?.data?.boards })
      dispatch({ type: BOARDS_LIST_COUNT, payload: response?.data?.boards?.length })
    } catch (error) {
      // console.log('🚀 ~ handleDiscoverAdsBoard ~ error:', error)
    }
  }
  const getSavedAds = async () => {
    try {
      const response = await RequestService.get('/save/ads/')
      const ads = response?.data?.meta_ads || []

      // Save the entire ads data locally
      // setAllAdsData(ads)
      dispatch({ type: SAVED_PAGE_ALL_DATA, payload: ads })
      dispatch({ type: SAVED_PAGE_FILTER_ALL_DATA, payload: ads })

      // setFilteredDiscoverData(ads)

      // Extract unique tags from the ads data
      const tagsSet = new Set()
      ads.forEach(item => {
        if (item.tags && Array.isArray(item.tags)) {
          item.tags.forEach(tag => {
            tagsSet.add(tag)
          })
        }
      })
      // setUniqueTags([...tagsSet])
      dispatch({ type: SAVED_PAGE_UNIQUE_TAGS, payload: [...tagsSet] })
    } catch (error) {
      // console.log('🚀 ~ getSavedAds ~ error:', error)
    }
  }
  const getTags = async () => {
    try {
      // eslint-disable-next-line
      const response = await RequestService.get(`/save/tags/?ad_id=${adsData?._id}`)
      dispatch({ type: TAGS, payload: response.data })
      dispatch({ type: FILTERED_TAGS, payload: response.data })
    } catch (error) {
      dispatch({ type: TAGS, payload: [] })
      dispatch({ type: FILTERED_TAGS, payload: [] })
    }
  }
  const fetchSpecificBoardsList = async () => {
    const singleBoardID = localStorage.getItem('board_detail_id')
    try {
      const response = await RequestService.get(`/create/board-ad-detail/${singleBoardID}/`)
      dispatch({ type: FETCH_SPECTFIC_BOARD_DATA, payload: response?.data?.boards[0]?.ads })
      dispatch({
        type: FETCH_SPECTFIC_BOARD_DATA_FOR_TAGS_AND_COUNT,
        payload: response?.data?.boards[0],
      })
    } catch (error) {
      // console.log('🚀 ~ fetchSpecificBoardsList ~ error:', error)
    }
  }
  const handleInputConfirm = async () => {
    const lowerCasedInput = inputValue.toLowerCase()
    const data = {
      // eslint-disable-next-line
      ad_id: adsData?._id,
      tag_name: lowerCasedInput,
    }

    if (lowerCasedInput && !tags.some(tag => tag.name === lowerCasedInput)) {
      try {
        // POST request to save tag
        const response = await RequestService.post('/save/tags/', data)

        // Assuming the response returns the saved tag with an ID
        const newTag = {
          id: response?.data?.id, // Assuming the API returns an id
          name: lowerCasedInput,
        }

        dispatch({ type: TAGS, payload: [...tags, newTag] })
        dispatch({ type: FILTERED_TAGS, payload: [...tags, newTag] })
        setInputValue('')
        getSavedAds()
        handleDiscoverAdsBoard()
        getTags()
        fetchSpecificBoardsList()
        message.success('Tag Added.')
      } catch (error) {
        console.error('Error creating tag:', error)
      }
    }
  }

  const handleTagClose = async removedTag => {
    const response = await RequestService.delete(
      // eslint-disable-next-line
      `/save/tags/?ad_id=${adsData?._id}&tag_id=${removedTag?.id}`
    )
    const updatedTags = tags?.filter(tag => tag.id !== removedTag.id)
    dispatch({ type: TAGS, payload: updatedTags })
    dispatch({ type: FILTERED_TAGS, payload: updatedTags })
    getSavedAds()
    fetchSpecificBoardsList()
  }

  useEffect(() => {
    getTags()
    // eslint-disable-next-line
  }, [])

  return (
    <div style={{ border: '1px solid #DFE3E4', background: 'white' }}>
      <div style={{ padding: 24 }}>
        <Text isBold>Tags</Text>
        <Input
          placeholder="Search or create tags"
          prefix={<SearchOutlined />}
          style={{ marginTop: 12 }}
          value={inputValue}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
        />
        <div style={{ marginTop: 18 }}>
          {filteredTags?.map(tag => (
            <Tag
              key={tag?.id}
              style={{ borderRadius: 48, padding: '10px', marginTop: '5px' }}
              closable
              onClose={() => handleTagClose(tag)}
            >
              {tag.name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tags
