// import { LS_KEY } from '../pages/login/login-route'
import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchReports = async customerId => {
  const { url, method } = apiRoutes.reports
  try {
    const res = await api({
      url: customerId ? `${url}?customer_id=${customerId}` : '',
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
