import React from 'react'
import AgAlertsAgAlertsOverView from '@components/AgAlerts/AgAlertsOverView'
import AgAlertsSetupEdit from '@components/AgAlerts/AgAlertsSetupEdit'
import AgAlertsFormula from '@components/AgAlerts/AgAlertsFormula'
import AgEmailAlerts from '@components/AgAlerts/AgEmailAlerts'

import { AG_ALERTS } from '@utils/constants'

const AgAlertsPage = ({ pannelItemClicked }) => (
  <div>
    <section id={AG_ALERTS}>
      <div style={{ padding: '52px' }}>
        {/* <p>page of {pannelItemClicked}</p> */}

        {pannelItemClicked === 'Alerts Overview' && <AgAlertsAgAlertsOverView />}
        {pannelItemClicked === 'Alerts Setup / Edits' && <AgAlertsSetupEdit />}
        {pannelItemClicked === 'Alerts Formula' && <AgAlertsFormula />}
        {pannelItemClicked === 'Manage Email Alerts' && <AgEmailAlerts />}
      </div>
    </section>
  </div>
)

export default AgAlertsPage
