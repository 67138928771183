/* eslint-disable no-unused-vars */ /* eslint-disable react/prop-types */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaQuestionCircle } from 'react-icons/fa'
import { Row, Col, Collapse, Table, Space, Alert, Tooltip, Button, Dropdown } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { useTranslation } from 'react-i18next'
import ApexChart from 'react-apexcharts'
import {
  AD_RELEVANCE_SECTION,
  CLICK_THROUGH_RATE_SECTION,
  COST_EVALUATION_SECTION,
  EXTRA_INSIGHTS_CLICK,
  EXTRA_INSIGHTS_DATA,
  GOOGLE_ADS_BEST_PRACTICES_SECTION,
  IMPRESSION_SHARE_SECTION,
  KEYWORDS_MATCH_TYPE_SECTION,
  QUALITY_SCORE_SECTION,
  WASTED_SPEND_SECTION,
} from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import AlertArrow from '@images/alert-arrow.svg'
import classNames from 'classnames/bind'
import iconChecked from '@images/check-icon.svg'
import ArrowRightOrange from '@images/AdsPreview/ArrowRightOrange.png'
import iconAlert from '@images/alert-icon.svg'
import ImprovementCard from '@components/ImprovementCard'
import { Text, ProgressBar, InformationCard } from '@components'
import {
  getPercentage,
  formatCurrency,
  doubleDecimals,
  getBenchmarkProp,
  checkingFeedback,
} from '@utils'
import { notify } from '@utils/notify'
import { fetchInsights } from '@services/fetchInsights'
import { PanelSection } from '../../pages/report/components/PanelSection'
import styles from '../../pages/report/sections/Overall/styles.module.scss'
import { costEvoChartConfig } from '../../pages/report/sections/Overall/costEvoChartConfig'
import AdGroupMetricsModal from './AdGroupMetricsModal'

const { Panel } = Collapse
const { Column } = Table

export const OverallSectionTemporary = ({
  report,
  benchmarks,
  currency,
  language,
  hideDisplay,
  handleImprovementMore,
  onCtaClick,
  formattedImprovement,
  viewQtd,
  // extraInsights,
  extraInsightsKeywords,
  selectedReportData,
  reportId,
}) => {
  const { t } = useTranslation()
  const reportCurrency = useSelector(state => state?.customer?.currency_code)
  const qualityScoreImpressionsChart = []
  const [viewQtdCost, setViewQtdCost] = useState(0)
  const [viewQtdWasted, setViewQtdWasted] = useState(0)
  const [viewQtdAdRelevance, setViewQtdAdRelevance] = useState(0)
  const [viewQtdQualityScore, setViewQtdQualityScore] = useState(0)
  const [viewQtdCtr, setViewQtdCtr] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [viewQtdKeyword, setViewQtdKeyword] = useState(0)
  const [extraInsights, setExtraInsights] = useState([])
  const [viewQtdImpression, setViewQtdImpression] = useState(0)
  const extraInsightsButton = useSelector(state => state?.extraInsights?.extraInsightsButton)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const dispatch = useDispatch()
  const [viewQtdGoogleAds, setViewQtdGoogleAds] = useState(0)
  const handleImprovementMoreCost = () => {
    setViewQtdCost(viewQtdCost + 1)
  }
  const handleImprovementMoreWasted = () => {
    setViewQtdWasted(viewQtdWasted + 1)
  }
  const handleImprovementMoreAdRelevance = () => {
    setViewQtdAdRelevance(viewQtdAdRelevance + 1)
  }
  const handleImprovementMoreQualityScore = () => {
    setViewQtdQualityScore(viewQtdQualityScore + 1)
  }
  const handleImprovementMoreCtr = () => {
    setViewQtdCtr(viewQtdCtr + 1)
  }
  const handleImprovementMoreKeyword = () => {
    setViewQtdKeyword(viewQtdKeyword + 1)
  }
  const handleImprovementMoreImpression = () => {
    setViewQtdImpression(viewQtdImpression + 1)
  }
  const handleImprovementMoreGoogleAds = () => {
    setViewQtdGoogleAds(viewQtdGoogleAds + 1)
  }
  const StatusEnum = {
    1: 'Paused',
    2: 'Enabled',
    3: 'Removed',
    4: 'Unknown',
    5: 'Unspecified',
  }
  function getStatusString(statusCode) {
    return StatusEnum[statusCode] || 'Enabled'
  }
  const showModal = () => {
    if (extraInsightsButton) {
      setIsLoading(true)
      dispatch({ type: EXTRA_INSIGHTS_DATA, payload: [] })
      fetchInsights(reportId)
        .then(res => {
          dispatch({ type: EXTRA_INSIGHTS_DATA, payload: res?.data })
          setIsLoading(false)
          if (res?.data?.customer_id) {
            dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: false })
          }
        })
        .catch(error => {
          notify(error, 'error')
          setIsLoading(false)
        })
    }
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  report.quality_score_over_impressions_chart?.forEach(item => {
    const object = {
      Impressions: item.impressions,
      Impressões: item.impressions,
      Impressionen: item.impressions,
      Impresiones: item.impressions,
      Impressioni: item.impressions,
      インプレッション: item.impressions,
      quality_score: item.quality_score,
    }

    qualityScoreImpressionsChart.push(object)
  })

  const items = [
    {
      key: '1',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Search Clicks: {report?.standard_metrics_data?.search_clicks?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Clicks: {report?.standard_metrics_data?.bidding_clicks?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '3',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Customer Clicks: {report?.standard_metrics_data?.customer_clicks?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '4',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Search Cost Micros: {report?.standard_metrics_data?.search_cost_micros?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '5',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Ads Group Ad Clicks: {report?.standard_metrics_data?.ads_group_ad_clicks?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '6',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Average CPC: {report?.standard_metrics_data?.bidding_average_cpc?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '7',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Conversions: {report?.standard_metrics_data?.bidding_conversions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '8',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Cost Micros: {report?.standard_metrics_data?.bidding_cost_micros?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '9',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Impressiones:{' '}
          {report?.standard_metrics_data?.bidding_impressions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '10',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Customer Conversions:{' '}
          {report?.standard_metrics_data?.customer_conversions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '11',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Customer Cost Micros:{' '}
          {report?.standard_metrics_data?.customer_cost_micros?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '12',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Customer Impressions:{' '}
          {report?.standard_metrics_data?.customer_impressions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '13',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Dynamic Search Clicks:{' '}
          {report?.standard_metrics_data?.dynamic_search_clicks?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '14',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Ads Group Ad Conversions:{' '}
          {report?.standard_metrics_data?.ads_group_ad_conversions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '15',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Ads Group Ad Cost Micros:{' '}
          {report?.standard_metrics_data?.ads_group_ad_cost_micros?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '16',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Ads Group Ad Impressiones:{' '}
          {report?.standard_metrics_data?.ads_group_ad_impressions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '17',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Customer All Conversions:{' '}
          {report?.standard_metrics_data?.customer_all_conversions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '18',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Dynamic Search Conversions:{' '}
          {report?.standard_metrics_data?.dynamic_search_conversions?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '19',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Dynamic Search Cost Micros:{' '}
          {report?.standard_metrics_data?.dynamic_search_cost_micros?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '20',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Dynamic Search Impressiones:{' '}
          {report?.standard_metrics_data?.dynamic_search_impressiones?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '21',
      label: (
        <span style={{ fontWeight: 'bold' }}>
          Bidding Cost Per Conversions:{' '}
          {report?.standard_metrics_data?.bidding_cost_per_conversion?.toFixed(2) ?? 0}
        </span>
      ),
    },
    {
      key: '22',
      label: <span style={{ fontWeight: 'bold' }}>Search Terms:</span>,
      children: report?.standard_metrics_data?.search_terms?.map((term, i) => ({
        key: `22-${i + 1}`,
        label: <span style={{ fontWeight: 'bold' }}>{term}</span>,
      })),
    },
    {
      key: '23',
      label: <span style={{ fontWeight: 'bold' }}>Dynamic Search Terms:</span>,
      children: report?.standard_metrics_data?.dynamic_search_terms?.map((term, i) => ({
        key: `23-${i + 1}`,
        label: <span style={{ fontWeight: 'bold' }}>{term}</span>,
      })),
    },
    {
      key: '24',
      label: <span style={{ fontWeight: 'bold' }}>Bidding Strategy Types:</span>,
      children: report?.standard_metrics_data?.bidding_strategy_types?.map((term, i) => ({
        key: `24-${i + 1}`,
        label: <span style={{ fontWeight: 'bold' }}>{term}</span>,
      })),
    },
    {
      key: '25',
      label: <span style={{ fontWeight: 'bold' }}>Search Term Match Types:</span>,
      children: report?.standard_metrics_data?.search_term_match_types?.map((term, i) => ({
        key: `25-${i + 1}`,
        label: <span style={{ fontWeight: 'bold' }}>{term}</span>,
      })),
    },
  ]
  const translationsMonths = [
    t('report.months.1'),
    t('report.months.2'),
    t('report.months.3'),
    t('report.months.4'),
    t('report.months.5'),
    t('report.months.6'),
    t('report.months.7'),
    t('report.months.8'),
    t('report.months.9'),
    t('report.months.10'),
    t('report.months.11'),
    t('report.months.12'),
  ]

  const graficConfig = costEvoChartConfig(
    report,
    t('report.tabs.costEvolution.chartLegendAvarege'),
    t('report.tabs.costEvolution.chartLegendTrendline'),
    translationsMonths
  )

  const lowSpendValue = report.overall_spend < 4000

  return (
    <section>
      <div className={styles.overallHeader}>
        <Text size={32} isBold isTitle>
          {t('report.overallSection')}
        </Text>
      </div>

      <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} ghost>
        <Panel
          id={COST_EVALUATION_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.costEvolution.title')}</Text>
              <ProgressBar percentageNumber={report.grader_cost_evolution} />
            </div>
          }
          key="1"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.costEvolution.title')}
            score={report.grader_cost_evolution}
            ctaTitle={t('report.tabs.costEvolution.ctaTitle')}
            ctaButtonText={t('report.tabs.costEvolution.ctaButtonText')}
            description={t('report.tabs.costEvolution.description')}
            meansDescription={checkingFeedback(
              report.grader_cost_evolution,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>
            )}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            viewQtdCost={viewQtdCost}
            handleImprovementMore={handleImprovementMore}
            handleImprovementMoreCost={handleImprovementMoreCost}
          >
            {report.cost_evolution !== null && graficConfig ? (
              <ApexChart
                options={graficConfig.options}
                height={380}
                series={graficConfig.series}
                type="line"
              />
            ) : (
              <Text size={16}>{t('report.tabs.costEvolution.chartError')}</Text>
            )}
          </PanelSection>
        </Panel>

        <Panel
          id={WASTED_SPEND_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.spend.title')}</Text>
              <ProgressBar percentageNumber={report.wasted_spent_score} />
            </div>
          }
          key="2"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.spend.title')}
            score={report.wasted_spent_score}
            ctaTitle={t('report.tabs.spend.ctaTitle')}
            ctaButtonText={t('report.tabs.spend.ctaButtonText')}
            description={
              <>
                {t('report.tabs.spend.subtitle1Part2')}
                <strong style={{ color: '#F26234' }}>
                  {formatCurrency(report.projected_wasted_spend, {
                    currency,
                    language,
                  }) || 0}
                </strong>{' '}
                {t('report.tabs.spend.subtitle1')}
                {t('report.tabs.spend.subtitle2Part2')}
                <strong style={{ color: '#F26234' }}>
                  {formatCurrency(report.wasted_spent, {
                    currency,
                    language,
                  }) || 0}{' '}
                </strong>
                {t('report.tabs.spend.subtitle2')}
              </>
            }
            meansDescription={checkingFeedback(
              report.wasted_spent_score,
              <Text size={16}>{t('report.tabs.spend.meansDescription.positive')}</Text>,
              <>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part2')}</Text>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.spend.meansDescription.negative.part1')}</Text>
                {/* <Text size={16}>{t('report.tabs.spend.meansDescription.negative.part2')}</Text> */}
              </>
            )}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            viewQtdWasted={viewQtdWasted}
            handleImprovementMoreWasted={handleImprovementMoreWasted}
          >
            <div className={styles.wastedCollapseContainer}>
              <Text size={20}>{t('report.tabs.spend.numberOfNegativeKeywordsAdded')}</Text>
              <div className={styles.comparisonGraphDiv}>
                <Text isBold isTitle size={18}>
                  {t('you')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div
                    className={styles.youGraph}
                    style={{
                      width:
                        getBenchmarkProp('negative_keywords', benchmarks) !== '-'
                          ? (report.negative_keywords_added /
                              getBenchmarkProp('negative_keywords', benchmarks)) *
                            200
                          : (report.negative_keywords_added / 1266) * 200,
                    }}
                  />
                  <Text size={16}>{report.negative_keywords_added}</Text>
                </div>
                <Text isBold isTitle size={18}>
                  {t('industryBenchmark')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div className={styles.benchmarkGraph} style={{ width: 200 }} />
                  <Text size={16}>
                    {getBenchmarkProp('negative_keywords', benchmarks) !== '-'
                      ? getBenchmarkProp('negative_keywords', benchmarks)
                      : 1266}
                  </Text>
                </div>
              </div>
            </div>
          </PanelSection>
        </Panel>

        <Panel
          id={AD_RELEVANCE_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.adRelevance.title')}</Text>
              <ProgressBar percentageNumber={report.grader_text_ads} />
            </div>
          }
          key="3"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            isBlur={report.search_spend < 1}
            score={report.grader_text_ads}
            adGroupAdStatus={report?.standard_metrics_data?.ad_group_ad_status}
            description=""
            title={t('report.tabs.adRelevance.title2')}
            titleOptional={t('report.tabs.adRelevance.title')}
            meansDescription={checkingFeedback(
              report.grader_text_ads,
              <>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.adRelevance.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.adRelevance.meansDescription.neutral.part2')}</Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.negative.part2')}
                </Text>
              </>
            )}
            ctaTitle={t('report.tabs.adRelevance.ctaTitle')}
            ctaButtonText={t('report.tabs.adRelevance.ctaButtonText')}
            onCtaClick={onCtaClick}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdAdRelevance={viewQtdAdRelevance}
            handleImprovementMoreAdRelevance={handleImprovementMoreAdRelevance}
          >
            {/* <Space direction="vertical" size={32}> */}
            <Row gutter={24}>
              <Col xs={16} sm={16} lg={16} className={styles.macCol16}>
                <div className={styles.containerBorder}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} lg={24}>
                      <div className={styles.wastedCollapseContainer}>
                        <Space direction="vertical" size={12}>
                          {/* <div style={{ display: 'flex' }}> */}
                          <Text size={16} isBold isTitle>
                            <span
                              style={{
                                textTransform: 'uppercase',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {t('report.tabs.adRelevance.activeSearchText')}
                              {/* <Tooltip
                                    className={styles.tooltipText}
                                    placement="top"
                                    title={<Text color="white" size={14} />}
                                  >
                                    <FaQuestionCircle />
                                  </Tooltip> */}
                            </span>
                          </Text>
                          {/* <Tooltip
                              className={styles.tooltipText}
                              placement="top"
                              color="black"
                              getPopupContainer={triggerNode => triggerNode.parentNode}
                              title={
                                <Text color="white" size={14}>
                                  Tooltip text to be provided later
                                </Text>
                              }
                            >
                              <FaQuestionCircle />
                            </Tooltip>
                          </div> */}
                          <Text isBold isTitle size={24}>
                            {report.active_text_ads_total}
                          </Text>

                          <Text>
                            {t('report.tabs.adRelevance.description', {
                              ASA: report.active_text_ads_total || 0,
                              RSA: report.rsa_total || 0,
                              ETA: report.eta_total || 0,
                              DSA: report.exp_dsa_total || 0,
                            })}
                          </Text>
                        </Space>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <hr className={styles.hrMargin} />
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <div className={styles.wastedCollapseContainer}>
                        <Space direction="vertical" size={12}>
                          <Row gutter={24}>
                            <Col xs={24} sm={24} lg={24}>
                              <div className={styles.wastedCollapseGraphsContainer}>
                                <Space direction="horizontal" size={16}>
                                  <div
                                    className={styles.wastedCollapseGraphsContainer}
                                    style={{ width: 32, height: 32, justifyContent: 'start' }}
                                  >
                                    {report.rsa_per_ad_group_status ? (
                                      <img src={iconChecked} alt="icon checked" width={32} />
                                    ) : (
                                      <img src={iconChecked} alt="icon alert" width={32} />
                                    )}
                                  </div>
                                  <Text size={16}>{t('report.tabs.adRelevance.rsaPerAd')}</Text>
                                </Space>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={24}>
                            <Col xs={24} sm={24} lg={24}>
                              <div className={styles.wastedCollapseGraphsContainer}>
                                <Space direction="horizontal" size={16}>
                                  <div
                                    className={styles.wastedCollapseGraphsContainer}
                                    style={{ width: 32, height: 32, justifyContent: 'start' }}
                                  >
                                    {report.rsa_poor_and_average_status ? (
                                      <img src={iconChecked} alt="icon checked" width={32} />
                                    ) : (
                                      <img src={iconAlert} alt="icon alert" width={32} />
                                    )}
                                  </div>
                                  <Text size={16}>
                                    {t('report.tabs.adRelevance.rsaPoorAvarege2')}
                                    <span style={{ color: '#202020' }}>
                                      {report.rsa_poor_and_average_percentage
                                        ? `${report.rsa_poor_and_average_percentage}%`
                                        : `0%`}
                                    </span>{' '}
                                    {t('report.tabs.adRelevance.rsaPoorAvarege')}
                                  </Text>
                                </Space>
                              </div>
                            </Col>
                          </Row>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={8} sm={8} lg={8} className={styles.macCol8}>
                <div className={styles.containerBorder}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <Text size={16} isBold isTitle>
                      <span
                        style={{
                          textTransform: 'uppercase',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {t('report.tabs.adRelevance.adsStrength')}
                        {/* <Tooltip
                          className={styles.tooltipText}
                          placement="top"
                          title={
                            <Text color="white" size={14}>
                              Tooltip text to be provided later
                            </Text>
                          }
                        >
                          <FaQuestionCircle />
                        </Tooltip> */}
                      </span>
                    </Text>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 6,
                      }}
                    >
                      <div>
                        <Text isBold isTitle size={18}>
                          {t('report.tabs.adRelevance.excellent')}
                        </Text>
                        <div className={styles.wastedCollapseGraphsContainer}>
                          <div
                            className={styles.graphBar}
                            style={{
                              backgroundColor: '#85ECC1',
                              width: `${doubleDecimals(report.rsa_excellent_percentage)}%`,
                              marginRight: 8,
                            }}
                          />
                          <Text size={16}>{`${doubleDecimals(
                            report.rsa_excellent_percentage
                          )}%`}</Text>
                        </div>
                      </div>
                      <div>
                        <Text isBold isTitle size={18}>
                          {t('report.tabs.adRelevance.good')}
                        </Text>
                        <div className={styles.wastedCollapseGraphsContainer}>
                          <div
                            className={styles.graphBar}
                            style={{
                              width: `${doubleDecimals(report.rsa_good_percentage)}%`,
                              marginRight: 8,
                              backgroundColor: '#E0FAEF',
                            }}
                          />
                          <Text size={16}>{`${doubleDecimals(report.rsa_good_percentage)}%`}</Text>
                        </div>
                      </div>
                      <div>
                        <Text isBold isTitle size={18}>
                          {t('report.tabs.adRelevance.average')}
                        </Text>
                        <div className={styles.wastedCollapseGraphsContainer}>
                          <div
                            className={styles.graphBar}
                            style={{
                              width: `${doubleDecimals(report.rsa_average_percentage)}%`,
                              marginRight: 8,
                              backgroundColor: '#F8BC54',
                            }}
                          />
                          <Text size={16}>
                            {`${doubleDecimals(report.rsa_average_percentage)}%`}
                          </Text>
                        </div>
                      </div>
                      <div>
                        <Text isBold isTitle size={18}>
                          {t('report.tabs.adRelevance.poor')}
                        </Text>
                        <div className={styles.wastedCollapseGraphsContainer}>
                          <div
                            className={styles.graphBar}
                            style={{
                              backgroundColor: '#FE8485',
                              width: `${doubleDecimals(report.rsa_poor_percentage)}%`,
                              marginRight: 8,
                            }}
                          />
                          <Text size={16}>{`${doubleDecimals(report.rsa_poor_percentage)}%`}</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {report?.best_responsive_search_ad_url &&
              report?.best_responsive_search_ad_headline &&
              report?.best_responsive_search_ad_description &&
              report?.worst_responsive_search_ad_url &&
              report?.worst_responsive_search_ad_headline &&
              report?.worst_responsive_search_ad_description && (
                <Row gutter={24}>
                  <Col xs={24} sm={24} lg={24}>
                    <hr className={styles.hrMargin} />
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <span style={{ paddingBottom: 24, display: 'block' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Text size={32}>{t('report.tabs.adComparisons.title')}</Text>
                        <button
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            // minWidth: '180%',
                          }}
                          onClick={showModal}
                          type="submit"
                        >
                          Extra Insights
                        </button>
                      </div>
                    </span>
                  </Col>

                  {isModalVisible && (
                    <AdGroupMetricsModal
                      modalVisibility={isModalVisible}
                      handleOk={handleOk}
                      handleCancel={handleCancel}
                      isLoading={isLoading}
                      tableData={extraInsights}
                    />
                  )}
                  <Col xs={24} sm={24} lg={24}>
                    <Text className={styles.textSubTitle} isTitle color="secondary">
                      {t('report.tabs.adComparisons.previewText')}
                    </Text>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    <div className={styles.card_adPerformance}>
                      <div className={styles.bgColorCard_adPerformance}>
                        <div className={styles.content_adPerformance}>
                          <Space style={{ width: '100%' }} direction="vertical" size={24}>
                            {/* <div className={styles.headerCard_adPerformance}>
                              <Text size={12} style={{ width: '80%' }}> */}
                            {/* {t('report.tabs.adComparisons.adText')} */}

                            {/* <a
                                  href={
                                    report?.best_responsive_search_ad_url
                                    // ?.replace('https://', 'www.')
                                    // ?.replace('/', '')
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span
                                    style={{
                                      color: '#1a73e8',
                                      fontSize: 12,
                                      fontWeight: 'normal',
                                      marginLeft: 10,
                                    }}
                                  >
                                    {t('clickHereToViewAd')}
                                  </span>
                                </a> */}

                            {/* <Text
                                  style={{
                                    display: 'inline',
                                    marginLeft: 4,
                                    wordWrap: 'break-word',
                                  }}
                                  size={12}
                                  isText
                                >
                                  {report?.best_responsive_search_ad_url
                                    ?.replace('https://', 'www.')
                                    ?.replace('/', '')}
                                  
                                </Text> */}
                            {/* </Text>
                              <span className={styles.threePoints} />
                            </div> */}
                            <Space direction="vertical" size={16}>
                              <Text size={24} style={{ color: '#1a73e8' }}>
                                {report?.best_responsive_search_ad_headline}
                                {/* Meu Catálogo Digital | Monte uma Loja Virtual */}
                              </Text>
                              <Text>
                                {report?.best_responsive_search_ad_description}
                                {/* Um Catálogo Digital Onde Você Cadastra Seus Produtos Como Uma
                                      Vitrine e Vende no WhatsApp. Monte uma Loja Vitrine Online */}
                              </Text>
                            </Space>
                          </Space>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    {/* <div className={styles.infoContainer_adPerformance}>
                    <div className={styles.inforContent_adPerformance}>
                      <Space direction="vertical" size={8} style={{ width: '300px' }}> */}
                    {/* <div style={{ width: '300px' }}> */}
                    <Text isTitle size={24}>
                      {t('report.tabs.adComparisons.bestTitle')}
                    </Text>
                    <Text size={18} isTitle>
                      {t('report.tabs.adComparisons.urlText')}
                    </Text>
                    <div>
                      {/* <a
                        href={
                          report?.best_responsive_search_ad_url +
                          (!report?.best_responsive_search_ad_url.endsWith('/') ? '/ads/' : 'ads/')
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span style={{ color: '#1a73e8' }}>{t('clickHereToViewAd')}</span>
                      </a> */}
                      {/* </Text> */}
                      <Text
                        size={16}
                        style={{
                          color: '#1a73e8',
                          letterSpacing: '0.05rem',
                          wordWrap: 'break-word',
                          minWidth: '140%',
                          maxWidth: '150%',
                        }}
                      >
                        {report?.best_responsive_search_ad_url +
                          (!report?.best_responsive_search_ad_url.endsWith('/') ? '/ads/' : 'ads/')}
                      </Text>
                    </div>
                    <div
                      className={styles.wrapperInfo_adPerformance}
                      style={{ minWidth: '170%' }}
                      // style={{ minWidth: '155%', maxWidth: '160%' }}
                    >
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.tabs.adComparisons.impression')}
                        </Text>
                        <Text size={12} isTitle>
                          {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_impressions
                          )}
                          {/* 32.080 */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.conversions')}
                        </Text>
                        <Text size={12} isTitle>
                          {report?.standard_metrics_data?.best_ad_conversions ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.ctr')}
                        </Text>
                        <Text size={12} isTitle>
                          {/* 17,90% */}
                          {report?.best_responsive_search_ad_ctr_percentage
                            ? // eslint-disable-next-line
                              new Intl.NumberFormat('en-IN').format(
                                parseInt(report?.best_responsive_search_ad_ctr_percentage, 10)
                              )
                            : 0}
                          %
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.tabs.adComparisons.clicks')}
                        </Text>
                        <Text size={12} isTitle>
                          {/* 5752 */}
                          {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )}
                        </Text>
                      </div>

                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          {t('report.tabs.adComparisons.totalSpend')}
                        </Text>
                        <Text size={12} isTitle>
                          {JSON.parse(localStorage.getItem('copied_report_customer_id'))
                            ? new Intl.NumberFormat('en-US').format(
                                report?.performance_max_spend?.toFixed(2)
                              )
                            : new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                // eslint-disable-next-line
                                currency: reportCurrency ? reportCurrency : 'USD',
                                // currencyDisplay: 'narrowSymbol',
                              }).format(report?.performance_max_spend?.toFixed(2))}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {/* {t('report.tabs.adComparisons.clicks')} */}
                          CPC
                        </Text>
                        <Text size={12} isTitle>
                          R${report?.standard_metrics_data?.best_ad_cpc?.toFixed(2) ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          {/* {t('report.tabs.adComparisons.clicks')} */}
                          COST MICROS*
                        </Text>
                        <Text size={12} isTitle>
                          {report?.standard_metrics_data?.best_ad_cost_micros?.toFixed(2) ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      {/* <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          Ad Status
                        </Text>
                        <Text size={12} isTitle>
                          {getStatusString(report?.standard_metrics_data?.best_ad_group_ad_status)}
                        </Text>
                      </div> */}
                    </div>
                    {/* </div> */}
                    {/* </Space>
                    </div>
                  </div> */}
                    <Text
                      style={{ paddingTop: 4, color: '#939395', fontSize: 13, minWidth: '190%' }}
                    >
                      * The sum of your cost-per-click (CPC) and cost-per-thousand impressions (CPM)
                      costs during this period.
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <hr className={styles.hrMargin} />
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <Text className={styles.textSubTitle} isTitle color="secondary">
                      {t('report.tabs.adComparisons.previewText')}
                    </Text>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    <div className={styles.card_adPerformance}>
                      <div className={styles.bgColorCard_adPerformance}>
                        <div className={styles.content_adPerformance}>
                          <Space style={{ width: '100%' }} direction="vertical" size={24}>
                            {/* <div className={styles.headerCard_adPerformance}>
                              <Text size={12} style={{ width: '80%' }}> */}
                            {/* {t('report.tabs.adComparisons.adText')} */}
                            {/* <a
                                  href={
                                    report?.worst_responsive_search_ad_url
                                    // ?.replace('https://', 'www.')
                                    // ?.replace('/', '')
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span
                                    style={{
                                      color: '#1a73e8',
                                      fontSize: 12,
                                      fontWeight: 'normal',
                                      marginLeft: 10,
                                    }}
                                  >
                                    {t('clickHereToViewAd')}
                                  </span>
                                </a> */}
                            {/* <Text
                                  style={{
                                    display: 'inline',
                                    marginLeft: 4,
                                    wordWrap: 'break-word',
                                  }}
                                  size={12}
                                  isText
                                >
                                  {report?.worst_responsive_search_ad_url
                                    ?.replace('https://', 'www.')
                                    ?.replace('/', '')}
                                </Text> */}
                            {/* </Text>
                              <span className={styles.threePoints} />
                            </div> */}
                            <Space direction="vertical" size={16}>
                              <Text size={24} style={{ color: '#1a73e8' }}>
                                {report?.worst_responsive_search_ad_headline}
                                {/* Meu Catálogo Digital | Monte uma Loja Virtual */}
                              </Text>
                              <Text>
                                {report?.worst_responsive_search_ad_description}
                                {/* Um Catálogo Digital Onde Você Cadastra Seus Produtos Como Uma
                                      Vitrine e Vende no WhatsApp. Monte uma Loja Vitrine Online */}
                              </Text>
                            </Space>
                          </Space>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={12}>
                    {/* <div className={styles.infoContainer_adPerformance}>
                    <div className={styles.inforContent_adPerformance}>
                      <Space direction="vertical" size={8} style={{ width: '300px' }}> */}
                    {/* <div style={{ width: '300px' }}> */}
                    <Text isTitle size={24}>
                      {t('report.tabs.adComparisons.worstTitle')}
                    </Text>
                    <Text size={18} isTitle>
                      {t('report.tabs.adComparisons.urlText')}
                    </Text>
                    {/* <div>
                      <a
                        href={
                          report?.worst_responsive_search_ad_url +
                          (!report?.worst_responsive_search_ad_url.endsWith('/') ? '/ads/' : 'ads/')
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span style={{ color: '#1a73e8' }}>{t('clickHereToViewAd')}</span>
                      </a>
                    </div> */}
                    <Text
                      size={16}
                      style={{
                        color: '#1a73e8',
                        letterSpacing: '0.05rem',
                        wordWrap: 'break-word',
                        minWidth: '140%',
                        maxWidth: '150%',
                      }}
                    >
                      {report?.worst_responsive_search_ad_url +
                        (!report?.worst_responsive_search_ad_url.endsWith('/') ? '/ads/' : 'ads/')}
                    </Text>

                    <div className={styles.wrapperInfo_adPerformance} style={{ minWidth: '170%' }}>
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.tabs.adComparisons.impression')}
                        </Text>
                        <Text size={12} isTitle>
                          {new Intl.NumberFormat('en-IN').format(
                            report?.worst_responsive_search_ad_impressions
                          )}
                          {/* 32.080 */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.conversions')}
                        </Text>
                        <Text size={12} isTitle>
                          {report?.standard_metrics_data?.worst_ad_conversions ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.ctr')}
                        </Text>
                        <Text size={12} isTitle>
                          {/* 17,90% */}
                          {report?.worst_responsive_search_ad_ctr_percentage
                            ? // eslint-disable-next-line
                              new Intl.NumberFormat('en-IN').format(
                                parseInt(report?.worst_responsive_search_ad_ctr_percentage, 10)
                              )
                            : 0}
                          %
                        </Text>
                      </div>

                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {t('report.tabs.adComparisons.clicks')}
                        </Text>
                        <Text size={12} isTitle>
                          {/* 5752 */}
                          {new Intl.NumberFormat('en-IN').format(
                            report?.worst_responsive_search_ad_clicks
                          )}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          {t('report.tabs.adComparisons.totalSpend')}
                        </Text>
                        <Text size={12} isTitle>
                          {JSON.parse(localStorage.getItem('copied_report_customer_id'))
                            ? new Intl.NumberFormat('en-US').format(
                                report?.projected_wasted_spend?.toFixed(2)
                              )
                            : new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                // eslint-disable-next-line
                                currency: reportCurrency ? reportCurrency : 'USD',
                                // currencyDisplay: 'narrowSymbol',
                              }).format(report?.projected_wasted_spend?.toFixed(2))}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text size={14} isTitle>
                          {/* {t('report.tabs.adComparisons.clicks')} */}
                          CPC
                        </Text>
                        <Text size={12} isTitle>
                          R${report?.standard_metrics_data?.worst_ad_cpc?.toFixed(2) ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          {/* {t('report.tabs.adComparisons.clicks')} */}
                          COST MICROS*
                        </Text>
                        <Text size={12} isTitle>
                          {report?.standard_metrics_data?.worst_ad_cost_micros?.toFixed(2) ?? 0}
                          {/* {new Intl.NumberFormat('en-IN').format(
                            report?.best_responsive_search_ad_clicks
                          )} */}
                        </Text>
                      </div>
                      {/* <span className={styles.divider} />
                      <div className={styles.infos_adPerformance}>
                        <Text style={{ textAlign: 'center' }} size={14} isTitle>
                          Ad Status
                        </Text>
                        <Text size={12} isTitle>
                          {getStatusString(report?.standard_metrics_data?.best_ad_group_ad_status)}
                        </Text>
                      </div> */}

                      {/* <div
                        className={styles.extraMetricsContainer}
                        style={{ position: 'relative' }}
                        id="area"
                      >
                        <Dropdown
                          menu={{
                            items,
                          }}
                          trigger={['click']}
                          getPopupContainer={() => document.getElementById('area')}
                          // placement="bottom"
                        >
                          <div style={{ display: 'flex', gap: 6, cursor: 'pointer' }}>
                            <Text size={14} isBold style={{ color: '#202020' }}>
                              Extra Metrics
                            </Text>
                            <img
                              src={ArrowRightOrange}
                              height={12}
                              width={12}
                              style={{ marginTop: 5 }}
                              alt="card-img"
                            />
                          </div>
                        </Dropdown>
                      </div> */}
                    </div>
                    {/* </div> */}
                    {/* </Space>
                    </div>
                  </div> */}
                    <Text
                      style={{ paddingTop: 4, color: '#939395', fontSize: 13, minWidth: '170%' }}
                    >
                      * The sum of your cost-per-click (CPC) and cost-per-thousand impressions (CPM)
                      costs during this period.
                    </Text>
                  </Col>
                </Row>
              )}
            {/* </Space> */}
          </PanelSection>
        </Panel>

        <Panel
          id={QUALITY_SCORE_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.qualityScore.title')}</Text>
              <ProgressBar percentageNumber={report.quality_score_score} />
            </div>
          }
          key="4"
        >
          <PanelSection
            isBlur={report.search_spend < 1}
            isCTA={!lowSpendValue}
            isMeansDescription={doubleDecimals(report.quality_score) <= 10}
            onCtaClick={onCtaClick}
            firstPageCpc={report?.standard_metrics_data?.first_page_cpc}
            firstPositionCpc={report?.standard_metrics_data?.first_position_cpc}
            adGroupCriterianStatus={report?.standard_metrics_data?.keyword_status}
            title={t('report.tabs.qualityScore.title')}
            score={report.quality_score <= 10 ? report.quality_score_score : 100}
            description={
              doubleDecimals(report.quality_score) <= 10 ? (
                <>
                  {t('report.tabs.qualityScore.description.part1')}
                  <strong style={{ color: '#F26234' }}>
                    {doubleDecimals(report.quality_score)}
                  </strong>
                  {doubleDecimals(report.quality_score) <= 9.0 ? (
                    <>{t('report.tabs.qualityScore.description.part2')}</>
                  ) : (
                    <>
                      {t('report.tabs.qualityScore.description.part6')}
                      <strong style={{ color: '#F26234' }}>
                        {doubleDecimals(10 - report.quality_score)}
                      </strong>
                      {t('report.tabs.qualityScore.description.part7')}
                    </>
                  )}

                  <strong style={{ color: '#F26234' }}>
                    {formatCurrency(Math.ceil(report.quality_score_could_save_value), {
                      currency,
                      language,
                    }) || 0}
                  </strong>
                  {t('report.tabs.qualityScore.description.part3')}

                  {t('report.tabs.qualityScore.description.part4')}
                  <strong style={{ color: '#F26234' }}>
                    {Math.ceil(report.quality_score_more_clicks)}
                  </strong>
                  {t('report.tabs.qualityScore.description.part5')}
                </>
              ) : (
                <>
                  {t('report.tabs.qualityScore.description.part1')}
                  <strong style={{ color: '#F26234' }}>10</strong>.
                </>
              )
            }
            meansDescription={checkingFeedback(
              report.quality_score_score,
              <>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.qualityScore.meansDescription.neutral')}</Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.negative.part2')}
                </Text>
              </>
            )}
            ctaTitle={t('report.tabs.qualityScore.ctaTitle')}
            ctaButtonText={t('report.tabs.qualityScore.ctaButtonText')}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdQualityScore={viewQtdQualityScore}
            handleImprovementMoreQualityScore={handleImprovementMoreQualityScore}
          >
            <Text size={20}>{t('report.tabs.qualityScore.chartTitle')}</Text>
            <div style={{ height: 350 }}>
              <ResponsiveBar
                data={qualityScoreImpressionsChart}
                keys={[t('report.tabs.qualityScore.impressions')]}
                indexBy="quality_score"
                margin={{ top: 50, right: 130, bottom: 50, left: 70 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                valueFormat={{ format: '', enabled: false }}
                colors={{ scheme: 'set3' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t('report.tabs.qualityScore.title'),
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t('report.tabs.qualityScore.impressions'),
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                axisTop={null}
                axisRight={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="transparent"
                legends={[]}
              />
            </div>
          </PanelSection>
        </Panel>

        <Panel
          id={CLICK_THROUGH_RATE_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.ctr.title')}</Text>
              <ProgressBar percentageNumber={report.grader_ctr} />
            </div>
          }
          key="5"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.ctr.title')}
            score={report.grader_ctr}
            description={
              <>
                {t('report.tabs.ctr.description.part1', {
                  percentage: doubleDecimals(report.ctr_increased),
                })}
                {t('report.tabs.ctr.description.part2')}
                <strong style={{ color: '#F26234' }}>
                  {!report.ctr_more_clicks < 1 ? report.ctr_more_clicks : 1}
                </strong>
                {t('report.tabs.ctr.description.part3')}
                <strong style={{ color: '#F26234' }}>
                  {Number(!report.ctr_more_conversion < 1 ? report.ctr_more_conversion : 1).toFixed(
                    0
                  )}
                </strong>
                {t('report.tabs.ctr.description.part4')}
              </>
            }
            meansDescription={checkingFeedback(
              report.grader_ctr,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.positive')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.neutral')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.negative.part1')}</Text>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.negative.part2')}</Text>
              </>
            )}
            ctaTitle={t('report.tabs.ctr.ctaTitle')}
            ctaButtonText={t('report.tabs.ctr.ctaButtonText')}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdCtr={viewQtdCtr}
            handleImprovementMoreCtr={handleImprovementMoreCtr}
          >
            <Text size={20}>{t('report.tabs.ctr.cardTitle')}</Text>
            <Text size={16} className={styles.subtitle}>
              {t('report.tabs.ctr.searchNetwork')}
            </Text>
            <Row gutter={24} justify="center">
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.mobile')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_mobile_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_mobile_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.desktop')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_desktop_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_desktop_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.overall')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
            </Row>

            {!hideDisplay && (
              <>
                <Text size={16} className={styles.subtitle}>
                  {t('report.tabs.ctr.displayNetworkText')}:
                </Text>
                <Row gutter={24} justify="center">
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title={t('report.tabs.ctr.mobile')}>
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_mobile_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_mobile_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title={t('report.tabs.ctr.desktop')}>
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_desktop_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_desktop_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title={t('report.tabs.ctr.overall')}>
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                </Row>
              </>
            )}
          </PanelSection>
        </Panel>

        <Panel
          id={KEYWORDS_MATCH_TYPE_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.keywordsMatchType.title')}</Text>
              <ProgressBar percentageNumber={report.grader_long_tail} />
            </div>
          }
          key="6"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            isBlur={report.search_spend < 1}
            onCtaClick={onCtaClick}
            extraInsightsTitle="Extra Insights"
            reportId={reportId}
            title={t('report.tabs.keywordsMatchType.title')}
            description=""
            score={report.grader_long_tail}
            ctaButtonText={t('report.tabs.keywordsMatchType.ctaButtonText')}
            ctaTitle={t('report.tabs.keywordsMatchType.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_long_tail,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.positive')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part3')}
                </Text>
              </>
            )}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdKeyword={viewQtdKeyword}
            handleImprovementMoreKeyword={handleImprovementMoreKeyword}
          >
            <Text size={20}>{t('report.tabs.keywordsMatchType.subtitle')}</Text>
            <div style={{ height: 400 }}>
              <ResponsivePie
                data={[
                  {
                    id: t('report.tabs.keywordsMatchType.exact'),
                    label: t('report.tabs.keywordsMatchType.exact'),
                    value: report.keywords_match_type_exact_percentage
                      ? Number(report.keywords_match_type_exact_percentage.toFixed(2))
                      : 1,
                    color: '#FE8485',
                  },
                  {
                    id: t('report.tabs.keywordsMatchType.phrase'),
                    label: t('report.tabs.keywordsMatchType.phrase'),
                    value: report.keywords_match_type_phrase_percentage
                      ? Number(report.keywords_match_type_phrase_percentage.toFixed(2))
                      : 1,
                    color: '#00bcd4',
                  },
                  {
                    id: t('report.tabs.keywordsMatchType.broad'),
                    label: t('report.tabs.keywordsMatchType.broad'),
                    value: report.keywords_match_type_broad_percentage
                      ? Number(report.keywords_match_type_broad_percentage.toFixed(2))
                      : 1,
                    color: '#85ECC1',
                  },
                ]}
                valueFormat={value => `${value}%`}
                colors={{ datum: 'data.color' }}
                sortByValue
                padAngle={1}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
                arcLabel={item => `${item.value}%`}
                enableArcLinkLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 48,
                    itemWidth: 70,
                    itemHeight: 18,
                    itemTextColor: '#666',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                  },
                ]}
              />
            </div>
          </PanelSection>
        </Panel>

        <Panel
          id={IMPRESSION_SHARE_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.impShare.title')}</Text>
              <ProgressBar percentageNumber={report.grader_impression_share} />
            </div>
          }
          key="7"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.impShare.title')}
            score={report.grader_impression_share}
            description={
              <>
                {t('report.tabs.impShare.description.part1')}
                <strong style={{ color: '#F26234' }}>
                  {doubleDecimals(100 - report.grader_impression_share)}%
                </strong>
                {t('report.tabs.impShare.description.part2')}
              </>
            }
            ctaButtonText={t('report.tabs.impShare.ctaButtonText')}
            ctaTitle={t('report.tabs.impShare.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_impression_share,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part3')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part4')}</Text>
              </>
            )}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdImpression={viewQtdImpression}
            handleImprovementMoreImpression={handleImprovementMoreImpression}
          >
            <Row gutter={24} justify="center">
              <Col xs={24} sm={24} lg={12}>
                <div className={styles.wastedCollapseContainer}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text size={20}>{t('report.tabs.impShare.seachImpression')}</Text>
                    <Tooltip
                      className={styles.tooltipText}
                      placement="top"
                      color="#333333"
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      title={
                        <Text color="white" size={14}>
                          {t('report.tabs.impShare.help')}
                        </Text>
                      }
                    >
                      <FaQuestionCircle />
                    </Tooltip>
                  </div>
                  <div className={styles.comparisonGraphDiv}>
                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareAcquired')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          backgroundColor: '#00ecc0',
                          width: `${doubleDecimals(report.search_impression_share)}%`,
                          marginRight: 8,
                        }}
                      />
                      <Text size={16}>{`${doubleDecimals(report.search_impression_share)}%`}</Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLostAdRank')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.search_rank_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ff8586',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.search_rank_lost_impression_share)}%`}
                      </Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLost')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.search_budget_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ffffb0',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.search_budget_lost_impression_share)}%`}
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <div className={styles.wastedCollapseContainer}>
                  <Text size={20}>{t('report.tabs.impShare.contentImpression')}</Text>
                  <div className={styles.comparisonGraphDiv}>
                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareAcquired')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          backgroundColor: '#00ecc0',
                          width: `${doubleDecimals(report.content_impression_share)}%`,
                          marginRight: 8,
                        }}
                      />
                      <Text size={16}>{`${doubleDecimals(report.content_impression_share)}%`}</Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLostAdRank')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.content_rank_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ff8586',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.content_rank_lost_impression_share)}%`}
                      </Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLost')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.content_budget_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ffffb0',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.content_budget_lost_impression_share)}%`}
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <ResponsivePie
                data={[
                  {
                    id: t('report.tabs.impShare.shareAcquired'),
                    label: t('report.tabs.impShare.shareAcquired'),
                    value: doubleDecimals(report.search_absolute_top_impression_share),
                    color: '#85ECC1',
                  },
                  {
                    id: t('report.tabs.impShare.shareLost'),
                    label: t('report.tabs.impShare.shareLost'),
                    value: doubleDecimals(report.search_budget_lost_absolute_top_impression_share),
                    color: '#ddd',
                  },
                  {
                    id: t('report.tabs.impShare.shareLostAdRank'),
                    label: t('report.tabs.impShare.shareLostAdRank'),
                    value: doubleDecimals(report.search_rank_lost_absolute_top_impression_share),
                    color: '#FE8485',
                  },
                ]}
                colors={{ datum: 'data.color' }}
                valueFormat={value => `${value}%`}
                sortByValue
                padAngle={1}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
                arcLabel={item => `${item.value}%`}
                enableArcLinkLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 140,
                    itemHeight: 18,
                    itemTextColor: '#666',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                  },
                ]}
              /> */}
          </PanelSection>
        </Panel>

        {/* <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>Mobile Advertising</Text>
              <ProgressBar percentageNumber={report.grader_mobile_ad} />
            </div>
          }
          key="9"
        >
          <PanelSection
            onCtaClick={onCtaClick}
            title="Mobile Advertising"
            score={report.grader_mobile_ad}
            description={
              <>
                <strong style={{ color: '#F26234' }}>
                  {formatCurrency(report.mobile_ad_projected_mobile_budget, {
                    currency,
                    language,
                  })}
                </strong>{' '}
                is the projected budget on mobile devices. It represent{' '}
                {doubleDecimals(report.mobile_ad_projected_mobile_budget_percentage)}% of your
                overall online advertising spend
              </>
            }
            ctaButtonText={t('report.tabs.mobile.ctaButtonText')}
            ctaTitle={t('report.tabs.mobile.ctaTitle')}
            meansDescription={
              <>
                Your campaigns are still missing out on opportunities to optimize for your mobile ad
                performance:
                <ul>
                  <li>
                    <strong>
                      {report.mobile_ad_sitelink_extension} of your {report.campaigns_total}{' '}
                      campaigns have mobile sitelink extension set up
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {report.mobile_ad_call_extension} of your {report.campaigns_total} campaigns
                      have mobile call extension set up
                    </strong>
                  </li>
                </ul>
              </>
            }
          >
            <div style={{ marginBottom: 32 }}>
              <Text size={20}>Mobile % of Total Budget</Text>
              <div className={styles.comparisonGraphDiv}>
                <Text isBold isTitle size={18}>
                  You
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div
                    className={styles.youGraph}
                    style={{
                      width:
                        getBenchmarkProp('mobile_%_of_total_budget', benchmarks) !== '-'
                          ? (report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2) /
                              getBenchmarkProp('mobile_%_of_total_budget', benchmarks)) *
                            200
                          : (report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2) / 20) *
                            200,
                    }}
                  />
                  <Text size={16}>
                    {report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2)}%
                  </Text>
                </div>
                <Text isBold isTitle size={18}>
                  {t('industryBenchmark')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div className={styles.benchmarkGraph} style={{ width: 200 }} />
                  <Text size={16}>
                    {getBenchmarkProp('mobile_%_of_total_budget', benchmarks) !== '-'
                      ? getBenchmarkProp('mobile_%_of_total_budget', benchmarks)
                      : 20}
                    %
                  </Text>
                </div>
              </div>
            </div>

            <Text size={20}>Device Comparison Analysis</Text>
            <Table
              pagination={false}
              dataSource={[
                {
                  title: 'Mobile',
                  cost: report.mobile_ad_mobile_cost,
                  ctr: report.mobile_ad_mobile_ctr,
                  topImpRate: report.mobile_ad_mobile_top_imp_rate,
                  avgCpc: report.mobile_ad_mobile_top_avg_cpc,
                },
                {
                  title: 'Desktop',
                  cost: report.mobile_ad_desktop_cost,
                  ctr: report.mobile_ad_desktop_ctr,
                  topImpRate: report.mobile_ad_desktop_top_imp_rate,
                  avgCpc: report.mobile_ad_desktop_top_avg_cpc,
                },
              ]}
              size="small"
            >
              <Column
                title="Device"
                dataIndex="title"
                key="title"
                render={value => <Text isBold>{value}</Text>}
              />
              <Column
                title="Cost"
                dataIndex="cost"
                key="cost"
                render={value =>
                  formatCurrency(value, {
                    currency,
                    language,
                  }) || 0
                }
              />
              <Column
                title="CTR"
                dataIndex="ctr"
                key="ctr"
                render={value => getPercentage(value)}
              />
              <Column
                title="Top Imp. Rate"
                dataIndex="topImpRate"
                key="topImpRate"
                render={value => getPercentage(value)}
              />
              <Column
                title="Avg CPC"
                dataIndex="avgCpc"
                key="avgCpc"
                render={value => formatCurrency(value, { currency, language })}
              />
            </Table>
          </PanelSection>
        </Panel> */}

        <Panel
          id={GOOGLE_ADS_BEST_PRACTICES_SECTION}
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.googleAdsBest.title')}</Text>
              <ProgressBar percentageNumber={report.grader_best_practices} />
            </div>
          }
          key="8"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.googleAdsBest.title')}
            score={report.grader_best_practices}
            description={
              <>
                {t('report.tabs.googleAdsBest.description.part1')}
                <strong style={{ color: '#F26234' }}>
                  {t('report.tabs.googleAdsBest.description.part2Jp')}
                  {
                    [
                      report.language_targeting,
                      report.mobile_bid_adjustments,
                      /* report.network_targeting, */
                      report.geo_targeting,
                      report.conversion_tracking,
                      report.multiple_text_ads_running,
                      /*  report.modified_broad_match_type, */
                      report.negative_keywords,
                      report.use_google_extensions,
                      /* report.use_expanded_text_ads, */
                    ].filter(item => item === false).length
                  }{' '}
                  {t('report.tabs.googleAdsBest.description.part2')}
                </strong>{' '}
                {t('report.tabs.googleAdsBest.description.part3')}
              </>
            }
            ctaButtonText={t('report.tabs.googleAdsBest.ctaButtonText')}
            ctaTitle={t('report.tabs.googleAdsBest.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_best_practices,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.positive.part2')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part4')}
                </Text>
              </>
            )}
            AlertArrow={AlertArrow}
            formattedImprovement={formattedImprovement}
            viewQtd={viewQtd}
            handleImprovementMore={handleImprovementMore}
            viewQtdGoogleAds={viewQtdGoogleAds}
            handleImprovementMoreGoogleAds={handleImprovementMoreGoogleAds}
          >
            <Alert
              message={t('report.tabs.googleAdsBest.failedText')}
              type="error"
              description={
                <ul>
                  {report.language_targeting === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.languageTargeting.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.languageTargeting.textFailed')}
                    </li>
                  )}

                  {report.mobile_bid_adjustments === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.mobileBid.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.mobileBid.textFailed')}
                    </li>
                  )}

                  {report.geo_targeting === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.geoTargeting.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.geoTargeting.textFailed')}
                    </li>
                  )}

                  {report.conversion_tracking === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.conversionTracking.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.conversionTracking.textFailed')}
                    </li>
                  )}

                  {report.multiple_text_ads_running === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.multipleTextAds.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.multipleTextAds.textFailed')}
                    </li>
                  )}

                  {report.negative_keywords === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.negativeKeywords.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.negativeKeywords.textFailed')}
                    </li>
                  )}

                  {report.use_google_extensions === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.gaaExtensions.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.gaaExtensions.textFailed')}
                    </li>
                  )}
                </ul>
              }
              showIcon
            />
            <br />
            <br />
            <Alert
              message={t('report.tabs.googleAdsBest.passedText')}
              type="success"
              description={
                <ul>
                  {report.language_targeting === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.languageTargeting.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.languageTargeting.textPessed')}
                    </li>
                  )}

                  {report.mobile_bid_adjustments === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.mobileBid.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.mobileBid.textPessed')}
                    </li>
                  )}

                  {report.geo_targeting === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.geoTargeting.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.geoTargeting.textPessed')}
                    </li>
                  )}

                  {report.conversion_tracking === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.conversionTracking.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.conversionTracking.textPessed')}
                    </li>
                  )}

                  {report.multiple_text_ads_running === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.multipleTextAds.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.multipleTextAds.textPessed')}
                    </li>
                  )}

                  {report.negative_keywords === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.negativeKeywords.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.negativeKeywords.textPessed')}
                    </li>
                  )}

                  {report.use_google_extensions === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.gaaExtensions.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.gaaExtensions.textPessed')}
                    </li>
                  )}
                </ul>
              }
              showIcon
            />
          </PanelSection>
        </Panel>
      </Collapse>
    </section>
  )
}
OverallSectionTemporary.propTypes = {
  report: PropTypes.instanceOf(Object).isRequired,
  benchmarks: PropTypes.instanceOf(Object).isRequired,
}

OverallSectionTemporary.defaultProps = {}
