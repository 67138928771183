import {
  WEEKLY_REPORT,
  REPORT_DIFFERENCE,
  SINGLE_REPORT,
  REPORT_INDEX,
  ALL_REPORTS,
  PAGINATED_REPORTS,
  PAGINATED_NEXT,
  REPORT_CURRENT_ID,
} from '@utils/constants'

const initialState = {
  weeklyReports: '',
  difference: '',
  singleReport: '',
  index: '',
  reports: '',
  allReport: [],
  next: '',
  id: 0,
}

export const weeklyReport = (state = initialState, action) => {
  switch (action.type) {
    case WEEKLY_REPORT:
      return {
        ...state,
        weeklyReports: action.payload,
      }
    case REPORT_DIFFERENCE:
      return {
        ...state,
        difference: action.payload,
      }
    case SINGLE_REPORT:
      return { ...state, singleReport: action.payload }
    case REPORT_INDEX:
      return { ...state, index: action.payload }
    case ALL_REPORTS:
      return { ...state, reports: action.payload }
    case PAGINATED_REPORTS:
      return { ...state, allReport: action.payload }
    case PAGINATED_NEXT:
      return { ...state, next: action.payload }
    case REPORT_CURRENT_ID:
      return { ...state, id: action.payload }
    default:
      return state
  }
}
