import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { RequestService, axiosInstance } from '../../services/requests'
import { formatMCC } from '../../utils'

const AccountName = ({ customerId, customerNameFallback }) => {
  const { pathname } = useLocation()
  const [customer, setCustomer] = useState({ loading: true, error: false, data: {} })
  const { t } = useTranslation()

  const getCustomer = useCallback(async () => {
    try {
      let customerInfoData

      if (pathname.match(/ad-report/)) {
        const { data } = await axiosInstance.get(`/customers/${customerId}/`)

        customerInfoData = data
      } else {
        const { data } = await RequestService.get(`/customers/${customerId}/`)

        customerInfoData = data
      }
      setCustomer({ error: false, loading: false, data: customerInfoData })
    } catch (e) {
      setCustomer({ error: true, loading: false, data: {} })

      // eslint-disable-next-line no-console
      console.error(e.message)
    }
  }, [customerId, pathname])

  useEffect(() => {
    getCustomer()
  }, [getCustomer])

  if (customer.loading && !customer.error) return t('loading')

  if (!customer.loading && customer.error) return customerNameFallback || formatMCC(customerId)

  return <>{customer.data.descriptive_name || customerNameFallback || formatMCC(customerId)}</>
}

AccountName.propTypes = {
  customerId: PropTypes.number.isRequired,
}

export default AccountName
