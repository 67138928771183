import React, { useState } from 'react'
import Text from '@components/Text/text'
import npTip from '@images/Learning/np-tip.png'
import notFound from '@images/Learning/not-found.png'
import { Col, Input, Modal, Row, Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
// import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CloseOutlined } from '@ant-design/icons'
import { LS_KEY, LEARNING_CENTER_MESSAGE_FROM_MODAL } from '@utils/constants'
import { notify } from '@utils/notify'
import { useDispatch, useSelector } from 'react-redux'
import LearningModal from '../LearningModal'

const SearchNotFound = () => {
  const { control, register } = useForm()
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user.access_token
  const baseURL = process.env.REACT_APP_API_URL
  const dispatch = useDispatch()
  const modalMessage = useSelector(state => state?.learningCenterModalMessage?.message)
  const [formData, setFormData] = useState({
    company_email: '',
    company_phone: 0,
    first_name: '',
    last_name: '',
    website_url: '',
    topic: '',
    industry: '',
    monthly_budget: 0,
    requested_resource: '',
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <div
      style={{
        // width: '1108px',
        height: '168px',
        border: '1px solid  #E8EBEC',
        background: '#F9FBFF',
      }}
    >
      <div
        style={{
          display: 'flex',
          // margin: '24px',
          padding: 24,
          justifyContent: 'center',
        }}
      >
        <div>
          <img
            src={notFound}
            width={120}
            height={88}
            alt="not-found"
            style={{ marginTop: '16px' }}
          />
        </div>
        <div>
          <Text
            // isBold
            size={24}
            style={{
              fontWeight: 500,
              color: '#26282C',
              marginTop: 32,
              marginLeft: 24,
              letterSpacing: '0.8px',
              width: '153px',
              lineHeight: '120%',
              justifyContent: 'center',
            }}
          >
            Still can&apos;t find an answer?
          </Text>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
        <div style={{}}>
          <Text
            size={14}
            style={{
              marginLeft: 24,
              marginTop: 38,
              height: 44,
              width: 336,
              fontSize: 14,
              fontWeight: 400,
              justifyContent: 'center',
              lineHeight: '157.143%',
            }}
          >
            Seek Neil Patel&apos;s advice for quick ad performance improvements and discover the
            latest marketing tricks.
          </Text>
        </div>
        <div style={{ marginLeft: '24px' }}>
          <button
            style={{
              marginTop: '44px',
              width: '126px',
              height: '32px',
              background: '#4285F4',
              color: '#fff',
              border: '2px solid #4285F4',
              borderRadius: '2px',
              fontSize: '12px',
              fontWeight: 700,
              letterSpacing: '0.12px',
              cursor: 'pointer',
            }}
            type="submit"
            onClick={showModal}
          >
            REQUEST
          </button>
        </div>
        {isModalOpen ? <LearningModal modalOpen={isModalOpen} handleCancel={handleCancel} /> : ''}
        {/* </div> */}
      </div>
    </div>
  )
}
export default SearchNotFound
