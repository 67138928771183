import React from 'react'
import PropTypes from 'prop-types'
import logoSrc from '@images/Ads-Grader-Logo-White.png'
import LenguegeSelect from '@components/LenguegeSelect'
import styles from './styles.module.scss'

const LogoAdsGrader = ({ notTranslate }) => (
  <div className={styles.wrapper}>
    {/* <img className={styles.logo} src={logoSrc} alt="Logo Google Ads Grader - Neilpatel" />
    <span>{!notTranslate && <LenguegeSelect />}</span> */}
  </div>
)

LogoAdsGrader.propTypes = {
  notTranslate: PropTypes.bool,
}

LogoAdsGrader.defaultProps = {
  notTranslate: false,
}

export default LogoAdsGrader
