import React, { useReducer, useEffect, useState, useRef } from 'react'
import { Col, Row, Checkbox, Collapse } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Text } from '@components'
import bargraph from '@images/Bargraph.svg'
import revenue from '@images/revenue.svg'
import business from '@images/business.svg'
import performance from '@images/Financialstrans.png'
import Conversions from '@images/Conversions.svg'
import MySlider from './MySlider'
import styles from './customslider.module.css'

const CustomSlider = () => {
  const { Panel } = Collapse
  const [googleAdsSpendsPreState, setGoogleAdsSpendsPreState] = useState(100)
  const [avgCostPerClickPrevState, setAvgCostPerClickPrevState] = useState(0.1)
  const [avgRevnuePerSalePrevState, setAvgRevnuePerSalePrevState] = useState(100)
  const [agencyInHouseFeePrevState, setAgencyInHouseFeePrevState] = useState(100)

  const [state, updateState] = useReducer((states, newState) => ({ ...states, ...newState }), {
    googleAdsSpends: 100,
    avgCostPerClick: 0.1,
    landingPageConversionRate: 1,
    qualifiedLeads: 1,
    proposalDelivered: 1,
    saleCloseRate: 1,
    avgRevnuePerSale: 100,
    productMargin: 1,
    agencyInHouseFee: 100,
    avgCostPerSale: 0,
    avgCostPerMonth: 0,
    grossProfitPerSale: 0,
    grossProfitPerMonth: 0,
    ROI: 0,
    ROAS: 0,
    totalClicks: 0,
    conversionLeadsInquery: 0,
    costPerRawInquiry: 0,
    leads: 0,
    proposals: 0,
    sales: 0,
    grossRevenuePerMonth: 0,
  })

  const {
    googleAdsSpends,
    avgCostPerClick,
    landingPageConversionRate,
    qualifiedLeads,
    proposalDelivered,
    saleCloseRate,
    avgRevnuePerSale,
    productMargin,
    agencyInHouseFee,
    totalClicks,
    conversionLeadsInquery,
    costPerRawInquiry,
    leads,
    proposals,
    sales,
    grossRevenuePerMonth,
    avgCostPerSale,
    avgCostPerMonth,
    grossProfitPerSale,
    grossProfitPerMonth,
    ROI,
    ROAS,
  } = state
  function customRound(number) {
    const decimalPart = number - Math.floor(number) // Get the decimal part of the number
    if (decimalPart >= 0.5) {
      return Math.ceil(number)
    }
    return Math.floor(number)
  }
  function roundToTwoDecimalPlaces(value) {
    return parseFloat(value.toFixed(2))
  }
  const [noOfVisits, setNoOfVisits] = useState(0)
  const [noOfConersions, setNoOfconversions] = useState(0)
  const [noOfQualifiedLeads, setNoOfQualifiedLeads] = useState(0)
  const [max, setMax] = useState(50000)
  const [noOfSalesPurposalDeliverd, setNoOfSalesPurposalDeliverd] = useState(0)
  const [noOfSales, setNoOfSales] = useState(0)
  const [noOfTotalAvgSales, setNoOfTotalAvgSales] = useState(0)
  const [noOfSalesProposalDelivered, setNoOfSalesProposalDelivered] = useState(0)
  const [noOfROAs, setNoOfROAS] = useState(0)
  const [landingPageConversionRateCheckBox, setLandingPageConversionRateCheckBox] = useState(true)
  const [qualifiedLeadsCheckBox, setQualifiedLeadsCheckBox] = useState(true)
  const [salesProposalDeliveredCheckBox, setSalesProposalDeliveredCheckBox] = useState(true)
  const [salesCloseRateCheckBox, setSalesCloseRateCheckBox] = useState(true)
  const [productMarginCheckBox, setProductMarginCheckBox] = useState(true)
  const sliderRef = useRef(null)
  const [costPerLead, setCostPerLead] = useState(0)
  const [costPerQualifiedLead, setCostPerQualifiedLead] = useState(0)
  const [costPerProposalDelivered, setCostPerProposalDelivered] = useState(0)
  const [costPerSale, setCostPerSale] = useState(0)
  const [valueOfLead, setValueOfLead] = useState(0)
  const [valueOfQualifiedLead, setValueOfQualifiedLead] = useState(0)
  const [costPerSaleIncludingFees, setCostPerSaleIncludingFees] = useState(0)
  const [activeItem, setActiveItem] = useState('dollar')
  const [managemnetFeeStatus, setManagemnetFeeStatus] = useState('dollar')
  const [landingPageConversionRatePrevState, setLandingPageConversionRatePrevState] = useState(1)
  const [qualifiedLeadsPrevState, setQualifiedLeadsPrevState] = useState(1)
  const [proposalDeliveredPrevState, setProposalDeliveredPrevState] = useState(1)
  const [salesCloseRatePrevState, setSalesCloseRatePrevState] = useState(1)
  const [productMarginPrevState, setProductMarginPrevState] = useState(1)
  const onChange = (e, identifier) => {
    const newState = e.target.checked
    // Handle different states based on identifier
    switch (identifier) {
      case 'checkbox1':
        setLandingPageConversionRateCheckBox(newState)
        if (landingPageConversionRateCheckBox === true) {
          updateState({
            landingPageConversionRate: 100,
          })
        } else {
          updateState({
            // here maintain state
            landingPageConversionRate: landingPageConversionRatePrevState,
          })
        }

        break
      case 'checkbox2':
        setQualifiedLeadsCheckBox(newState)
        if (qualifiedLeadsCheckBox === true) {
          updateState({ qualifiedLeads: 100 })
        } else {
          updateState({ qualifiedLeads: qualifiedLeadsPrevState })
        }
        break
      case 'checkbox3':
        setSalesProposalDeliveredCheckBox(newState)
        if (salesProposalDeliveredCheckBox === true) {
          updateState({ proposalDelivered: 100 })
        } else {
          updateState({ proposalDelivered: proposalDeliveredPrevState })
        }
        break
      case 'checkbox4':
        setSalesCloseRateCheckBox(newState)
        if (salesCloseRateCheckBox === true) {
          updateState({
            saleCloseRate: 100,
          })
        } else {
          updateState({
            saleCloseRate: salesCloseRatePrevState,
          })
        }
        break
      case 'checkbox5':
        setProductMarginCheckBox(newState)
        if (productMarginCheckBox === true) {
          updateState({ productMargin: 100 })
        } else {
          updateState({ productMargin: productMarginPrevState })
        }

        break
      default:
        break
    }
  }

  function formatValue(value) {
    const roundValue = roundToTwoDecimalPlaces(value)

    const absValue = Math.abs(roundValue)

    const trillion = 1e12
    const billion = 1e9
    const million = 1e6
    const thousand = 1e3

    if (absValue >= trillion) {
      // eslint-disable-next-line
      return formatResult(roundValue / trillion, 2, 'T')
      // eslint-disable-next-line
    } else if (roundValue >= billion) {
      // eslint-disable-next-line
      return formatResult(roundValue / billion, 2, 'B')
    } else if (absValue >= million) {
      // eslint-disable-next-line
      return formatResult(roundValue / million, 2, 'M')
    } else if (absValue >= thousand) {
      // eslint-disable-next-line
      return formatResult(roundValue / thousand, 2, 'K')
    } else {
      // eslint-disable-next-line
      return formatResult(roundValue, 2, '')
    }
  }

  // eslint-disable-next-line
  function formatResult(value, decimalPlaces, suffix) {
    // Check if the value is an integer (no decimal places)
    if (Math.round(value) === value) {
      // eslint-disable-next-line
      return Math.round(value) + suffix
      // eslint-disable-next-line
    } else {
      // eslint-disable-next-line
      return value.toFixed(decimalPlaces) + suffix
    }
  }

  useEffect(() => {
    if (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) {
      setCostPerSaleIncludingFees(100 / noOfTotalAvgSales)
    } else {
      setCostPerSaleIncludingFees(googleAdsSpends / noOfTotalAvgSales)
    }
  }, [googleAdsSpends, noOfTotalAvgSales])
  useEffect(() => {
    setValueOfLead(noOfTotalAvgSales / noOfConersions)
  }, [noOfTotalAvgSales, noOfConersions])

  useEffect(() => {
    setValueOfQualifiedLead(noOfTotalAvgSales / noOfQualifiedLeads)
  }, [noOfTotalAvgSales, noOfQualifiedLeads])
  useEffect(() => {
    if (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) {
      setCostPerLead(100 / noOfConersions)
    } else {
      setCostPerLead(googleAdsSpends / noOfConersions)
    }
  }, [googleAdsSpends, noOfConersions])
  useEffect(() => {
    if (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) {
      setCostPerQualifiedLead(100 / noOfQualifiedLeads)
    } else {
      setCostPerQualifiedLead(googleAdsSpends / noOfQualifiedLeads)
    }
  }, [googleAdsSpends, noOfQualifiedLeads])
  useEffect(() => {
    if (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) {
      setCostPerProposalDelivered(100 / noOfSalesPurposalDeliverd)
    } else {
      setCostPerProposalDelivered(googleAdsSpends / noOfSalesPurposalDeliverd)
    }
  }, [googleAdsSpends, noOfSalesPurposalDeliverd])
  useEffect(() => {
    if (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) {
      setCostPerSale(100 / noOfSales)
    } else {
      setCostPerSale(googleAdsSpends / noOfSales)
    }
  }, [googleAdsSpends, noOfSales])

  useEffect(() => {
    if (
      (googleAdsSpends.length === 0 || googleAdsSpends <= 0 || Number.isNaN(googleAdsSpends)) &&
      (avgCostPerClick.length === 0 || avgCostPerClick <= 0 || Number.isNaN(avgCostPerClick))
    ) {
      setNoOfVisits(100 / 0.1)
    } else if (
      googleAdsSpends > 0 &&
      (avgCostPerClick.length === 0 || avgCostPerClick <= 0 || Number.isNaN(avgCostPerClick))
    ) {
      setNoOfVisits(googleAdsSpends / 0.1)
    } else if (
      googleAdsSpends.length === 0 ||
      googleAdsSpends <= 0 ||
      Number.isNaN(googleAdsSpends)
    ) {
      setNoOfVisits(100 / avgCostPerClick)
    } else if (
      avgCostPerClick.length === 0 ||
      googleAdsSpends <= 0 ||
      Number.isNaN(googleAdsSpends) ||
      Number.isNaN(avgCostPerClick)
    ) {
      setNoOfVisits(googleAdsSpends / 0.1)
    } else {
      setNoOfVisits(googleAdsSpends / avgCostPerClick)
    }
  }, [googleAdsSpends, avgCostPerClick])

  useEffect(() => {
    setNoOfconversions((noOfVisits * landingPageConversionRate) / 100)
  }, [landingPageConversionRate, noOfVisits])
  useEffect(() => {
    setNoOfQualifiedLeads((qualifiedLeads * noOfConersions) / 100)
  }, [qualifiedLeads, noOfConersions])
  useEffect(() => {
    setNoOfSalesPurposalDeliverd((noOfQualifiedLeads * proposalDelivered) / 100)
  }, [noOfQualifiedLeads, proposalDelivered])
  useEffect(() => {
    setNoOfSales((noOfSalesPurposalDeliverd * saleCloseRate) / 100)
  }, [noOfSalesPurposalDeliverd, saleCloseRate])
  useEffect(() => {
    if (avgRevnuePerSale.length === 0 || avgRevnuePerSale <= 0 || Number.isNaN(avgRevnuePerSale)) {
      setNoOfTotalAvgSales(noOfSales * 100)
    } else {
      setNoOfTotalAvgSales(noOfSales * avgRevnuePerSale)
    }
  }, [noOfSales, avgRevnuePerSale])
  useEffect(() => {
    setNoOfSalesProposalDelivered((noOfTotalAvgSales * productMargin) / 100)
  }, [noOfTotalAvgSales, productMargin])

  useEffect(() => {
    if (managemnetFeeStatus === 'dollar') {
      if (agencyInHouseFee < 0 || Number.isNaN(agencyInHouseFee)) {
        setNoOfROAS(noOfSalesProposalDelivered - 0)
      } else {
        setNoOfROAS(noOfSalesProposalDelivered - agencyInHouseFee)
      }
    } else {
      setNoOfROAS(
        noOfSalesProposalDelivered - (agencyInHouseFee * noOfSalesProposalDelivered) / 100
      )
    }
  }, [agencyInHouseFee, noOfSalesProposalDelivered, managemnetFeeStatus])

  const handleItemClick = index => {
    setActiveItem(prevActiveItem => (prevActiveItem === index ? prevActiveItem : index))
    setManagemnetFeeStatus(index)
    if (index === 'dollar') {
      updateState({
        agencyInHouseFee: 100,
      })
    } else {
      updateState({
        agencyInHouseFee: 1,
      })
    }
  }

  useEffect(() => {
    // LPCR => Landing page conversion Rate
    // QL => Qualified Leads
    // SPD => sales proposal delivered
    // SCR => sales close rate
    // ACPS => avg cost per sale
    // PM => product margin
    // ACPM = avg cost per month
    // GPPS => gross profit per sale
    // GPPM => gross profit per month
    // ROI => retrun on invest
    // ROAS => return on Ads spend

    const LPCR = landingPageConversionRate / 100
    const QL = qualifiedLeads / 100
    const SPD = proposalDelivered / 100
    const SCR = saleCloseRate / 100
    const PM = productMargin / 100

    const clicks = Math.floor(googleAdsSpends / avgCostPerClick)
    const conversionLeads = customRound(clicks * LPCR)
    const CPRI = customRound(googleAdsSpends / conversionLeads)
    const totalLeads = customRound(clicks * LPCR * QL)
    const totalProposals = (totalLeads * SPD).toFixed(0)
    const totalSales = (totalProposals * SCR).toFixed(2)
    const revenuePerMonth = Math.floor(totalSales * avgRevnuePerSale)

    const expenses = googleAdsSpends + agencyInHouseFee
    const otherExpenses = totalSales * avgRevnuePerSale * PM
    let ACPS = 0
    if (totalSales > 0) {
      ACPS = customRound((expenses + otherExpenses) / totalSales)
    }
    const ACPM = customRound(expenses + otherExpenses)
    const GPPS = avgRevnuePerSale - ACPS
    const GPPM = revenuePerMonth - ACPM

    const totalCost = googleAdsSpends + agencyInHouseFee
    const returnOnAdSpend = (((revenuePerMonth - totalCost) / googleAdsSpends) * 100).toFixed(2)

    const retrunOnInvest = ((GPPM / ACPM) * 100).toFixed(2)

    updateState({
      totalClicks: clicks,
      conversionLeadsInquery: conversionLeads,
      costPerRawInquiry: CPRI,
      leads: totalLeads,
      proposals: totalProposals,
      sales: totalSales,
      avgRevnuePerSale,
      grossRevenuePerMonth: revenuePerMonth,
      avgCostPerSale: ACPS,
      avgCostPerMonth: ACPM,
      grossProfitPerSale: GPPS,
      grossProfitPerMonth: GPPM,
      ROI: retrunOnInvest,
      ROAS: returnOnAdSpend,
    })
  }, [
    googleAdsSpends,
    avgCostPerClick,
    landingPageConversionRate,
    qualifiedLeads,
    proposalDelivered,
    saleCloseRate,
    avgRevnuePerSale,
    grossRevenuePerMonth,
    agencyInHouseFee,
    productMargin,
    avgCostPerSale,
    sales,
    ROI,
  ])
  const handleGoogleAdsSpendPrevState = value => {
    setGoogleAdsSpendsPreState(value)
  }
  const handleGoogleAdsSpend = value => {
    updateState({ googleAdsSpends: value })
    // handleNoOfVisits()
  }
  const handleAvgCostPerClickPrevState = value => {
    setAvgCostPerClickPrevState(value)
  }
  const handleAvgCostPerClick = value => {
    updateState({ avgCostPerClick: value })
    // handleNoOfVisits()
  }
  const handleLandingPageConverionRate = value => {
    setLandingPageConversionRatePrevState(value)
    updateState({ landingPageConversionRate: value })
  }

  const handleQualifiedLeads = value => {
    setQualifiedLeadsPrevState(value)
    updateState({ qualifiedLeads: value })
  }
  const handleProposalDelivered = value => {
    setProposalDeliveredPrevState(value)
    updateState({ proposalDelivered: value })
  }
  const handleSaleCloseRate = value => {
    setSalesCloseRatePrevState(value)
    updateState({ saleCloseRate: value })
  }

  const handleRevnuePerSalePrevState = value => {
    setAvgRevnuePerSalePrevState(value)
  }
  const handleRevnuePerSale = value => {
    updateState({ avgRevnuePerSale: value })
  }
  const handleProductMargin = value => {
    setProductMarginPrevState(value)
    updateState({ productMargin: value })
  }
  const handleAgencyInHouseManagementFeePrevState = value => {
    setAgencyInHouseFeePrevState(value)
  }

  const handleAgencyInHouseManagementFee = value => {
    updateState({ agencyInHouseFee: value })
  }
  const hasBorder = true
  return (
    <>
      <Row gutter={30}>
        {/* left section */}
        <Col span={12}>
          <div className={styles.leftColCard}>
            <Collapse
              expandIcon={({ isActive }) => (
                <div className={styles.headingIconGrey}>
                  <DownOutlined rotate={isActive ? 180 : 360} />{' '}
                </div>
              )}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={['1']}
              ghost
              style={hasBorder ? {} : {}}
            >
              <Panel
                className={styles.customPannel}
                header={
                  <span style={{ display: 'flex' }}>
                    <div className={styles.headingIcon}>
                      <img src={performance} alt="icone priority" />
                    </div>
                    <Text isBold style={{ display: 'flex', alignItems: 'center' }}>
                      Performance Overview
                    </Text>
                  </span>
                }
                key="1"
              >
                <Row
                  gutter={20}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  {/* <Col span={12}>
<Text isTitle style={{ padding: '10px 0px' }}>
PPC Ads Metrics
</Text>
</Col> */}
                  <Col span={9}>
                    <Text size={16} className={styles.cardHeading}>
                      Google Ads Spend Per Month
                    </Text>
                    <div className={styles.whiteBox}>
                      <div className={styles.cardValue}>
                        <Text isBold className={styles.CardCurrency}>
                          &nbsp;$
                        </Text>
                        {/* <Text isBold size={18}>
{googleAdsSpends}
</Text> */}
                        <input
                          type="number"
                          placeholder="100"
                          value={googleAdsSpends <= 0 ? 1 : googleAdsSpends}
                          onChange={e => {
                            const value = parseFloat(e.target.value, 10)
                            handleGoogleAdsSpend(value)
                            handleGoogleAdsSpendPrevState(value) // calling another function
                          }}
                          style={{
                            outline: 'none',
                            fontSize: 18,
                            border: 'none',
                            fontWeight: 700,
                            appearance: 'textfield',
                            overflow: 'hidden',
                          }}
                        />
                      </div>
                      <MySlider
                        // min={1}
                        step={100}
                        sliderValue={googleAdsSpends}
                        handleSliderChange={handleGoogleAdsSpend}
                        max={googleAdsSpendsPreState <= 50000 ? 50000 : googleAdsSpendsPreState}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <Text size={16} className={styles.cardHeading}>
                      Average Cost Per Click
                    </Text>
                    <div className={styles.whiteBox}>
                      <div className={styles.cardValue}>
                        <Text isBold className={styles.CardCurrency}>
                          &nbsp;$
                        </Text>
                        {/* <Text isBold size={18}>
{avgCostPerClick}
</Text> */}
                        <input
                          type="number"
                          placeholder="0.1"
                          value={avgCostPerClick <= 0 ? '' : avgCostPerClick}
                          onChange={e => {
                            const value = parseFloat(e.target.value, 10)
                            handleAvgCostPerClick(value)
                            handleAvgCostPerClickPrevState(value)
                          }}
                          style={{
                            outline: 'none',
                            fontSize: 18,
                            border: 'none',
                            fontWeight: 700,
                            appearance: 'textfield',
                            overflow: 'hidden',
                          }}
                        />
                      </div>
                      <MySlider
                        min={0.1}
                        step={0.1}
                        sliderValue={avgCostPerClick}
                        handleSliderChange={handleAvgCostPerClick}
                        max={avgCostPerClickPrevState <= 50 ? 50 : avgCostPerClickPrevState}
                      />
                    </div>
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      # of Visits
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        {/* <Text isBold className={styles.CardCurrency}>
&nbsp;$
</Text> */}
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfVisits)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 2 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text size={16} className={styles.cardHeadingcheckBox}>
                        Landing Page Conversion Rate
                      </Text>
                      <Text isBold size={18} style={{ padding: '4px 0px' }}>
                        &nbsp;{landingPageConversionRate}{' '}
                        <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                      </Text>
                    </div>
                    <Checkbox
                      onChange={e => onChange(e, 'checkbox1')}
                      checked={landingPageConversionRateCheckBox}
                    >
                      {' '}
                      <Text className={styles.checkboxCustom}>
                        {landingPageConversionRateCheckBox ? (
                          'Uncheck to skip this step'
                        ) : (
                          <span style={{ color: '#F16434' }}>Check to enable this step</span>
                        )}
                      </Text>
                    </Checkbox>
                    <MySlider
                      min={1}
                      max={100}
                      step={1}
                      sliderValue={landingPageConversionRate}
                      handleSliderChange={handleLandingPageConverionRate}
                      disabled={!landingPageConversionRateCheckBox} // Disable slider when checkbox is unchecked
                    />
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      # of Conversions
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfConersions)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 3 */}
              </Panel>
            </Collapse>
            <Collapse
              expandIcon={({ isActive }) => (
                <div className={styles.headingIconGrey}>
                  <DownOutlined rotate={isActive ? 180 : 360} />
                </div>
              )}
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={['2']}
              ghost
              style={hasBorder ? {} : {}}
            >
              <Panel
                className={styles.customPannel}
                header={
                  <span style={{ display: 'flex' }}>
                    <img src={business} alt="icone priority" className={styles.image} />
                    <Text
                      isBold
                      style={{ display: 'flex', alignItems: 'center', marginLeft: '9px' }}
                    >
                      Sales & Business Metrics
                    </Text>
                  </span>
                }
                key="2"
              >
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text size={16} className={styles.cardHeadingcheckBox}>
                        Qualified Leads
                      </Text>
                      <Text isBold size={18} style={{ padding: '4px 0px' }}>
                        &nbsp;{qualifiedLeads}
                        <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                      </Text>
                    </div>
                    <Checkbox
                      onChange={e => onChange(e, 'checkbox2')}
                      checked={qualifiedLeadsCheckBox}
                    >
                      {' '}
                      <Text className={styles.checkboxCustom}>
                        {qualifiedLeadsCheckBox ? (
                          'Uncheck to skip this step'
                        ) : (
                          <span style={{ color: '#F16434' }}>Check to enable this step</span>
                        )}
                      </Text>
                    </Checkbox>
                    <MySlider
                      min={1}
                      max={100}
                      step={1}
                      sliderValue={qualifiedLeads}
                      handleSliderChange={handleQualifiedLeads}
                      disabled={!qualifiedLeadsCheckBox}
                    />
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      # of Qualified leads
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfQualifiedLeads)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 4 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text size={16} className={styles.cardHeadingcheckBox}>
                        Sales Proposal Delivered
                      </Text>
                      <Text isBold size={18} style={{ padding: '4px 0px' }}>
                        &nbsp;{proposalDelivered}
                        <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                      </Text>
                    </div>
                    <Checkbox
                      onChange={e => onChange(e, 'checkbox3')}
                      checked={salesProposalDeliveredCheckBox}
                    >
                      {' '}
                      <Text className={styles.checkboxCustom}>
                        {salesProposalDeliveredCheckBox ? (
                          'Uncheck to skip this step'
                        ) : (
                          <span style={{ color: '#F16434' }}>Check to enable this step</span>
                        )}
                      </Text>
                    </Checkbox>
                    <MySlider
                      min={1}
                      max={100}
                      step={1}
                      sliderValue={proposalDelivered}
                      handleSliderChange={handleProposalDelivered}
                      disabled={!salesProposalDeliveredCheckBox}
                    />
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      # of Sales Proposal Delivered
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfSalesPurposalDeliverd)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 5 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text size={16} className={styles.cardHeadingcheckBox}>
                        Sales Close Rate
                      </Text>
                      <Text isBold size={18} style={{ padding: '4px 0px' }}>
                        &nbsp;{saleCloseRate}
                        <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                      </Text>
                    </div>
                    <Checkbox
                      onChange={e => onChange(e, 'checkbox4')}
                      checked={salesCloseRateCheckBox}
                    >
                      {' '}
                      <Text className={styles.checkboxCustom}>
                        {salesCloseRateCheckBox ? (
                          'Uncheck to skip this step'
                        ) : (
                          <span style={{ color: '#F16434' }}>Check to enable this step</span>
                        )}
                      </Text>
                    </Checkbox>
                    <MySlider
                      min={1}
                      max={100}
                      step={1}
                      sliderValue={saleCloseRate}
                      handleSliderChange={handleSaleCloseRate}
                      disabled={!salesCloseRateCheckBox}
                    />
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      # of Sales
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfSales)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 6 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <Text size={16} className={styles.cardHeading}>
                      Average Revenue Per Sale
                    </Text>
                    <div className={styles.whiteBox}>
                      <div className={styles.cardValue}>
                        <Text isBold className={styles.CardCurrency}>
                          &nbsp;$
                        </Text>
                        {/* <Text isBold size={18}>
{avgRevnuePerSale}
</Text> */}
                        <input
                          type="number"
                          placeholder="100"
                          value={avgRevnuePerSale <= 0 ? '' : avgRevnuePerSale}
                          onChange={e => {
                            const value = parseFloat(e.target.value, 10)
                            handleRevnuePerSale(value)
                            handleRevnuePerSalePrevState(value)
                          }}
                          style={{
                            outline: 'none',
                            overflow: 'hidden',
                            fontSize: 18,
                            border: 'none',
                            fontWeight: 700,
                            appearance: 'textfield',
                          }}
                        />
                      </div>
                      <MySlider
                        min={100}
                        max={avgRevnuePerSalePrevState <= 50000 ? 50000 : avgRevnuePerSalePrevState}
                        step={100}
                        sliderValue={avgRevnuePerSale}
                        handleSliderChange={handleRevnuePerSale}
                      />
                    </div>
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      Total sales (avg)
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold className={styles.greyCardCurrency}>
                          &nbsp;$
                        </Text>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfTotalAvgSales)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 7 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={16}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Text size={16} className={styles.cardHeadingcheckBox}>
                        Product Margin
                      </Text>
                      <Text isBold size={18} style={{ padding: '4px 0px' }}>
                        &nbsp;{productMargin}
                        <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                      </Text>
                    </div>
                    <Checkbox
                      onChange={e => onChange(e, 'checkbox5')}
                      checked={productMarginCheckBox}
                    >
                      {' '}
                      <Text className={styles.checkboxCustom}>
                        {productMarginCheckBox ? (
                          'Uncheck to skip this step'
                        ) : (
                          <span style={{ color: '#F16434' }}>Check to enable this step</span>
                        )}
                      </Text>
                    </Checkbox>
                    <MySlider
                      min={1}
                      max={100}
                      step={1}
                      sliderValue={productMargin}
                      handleSliderChange={handleProductMargin}
                      disabled={!productMarginCheckBox}
                    />
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      Gross Profit
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold className={styles.greyCardCurrency}>
                          &nbsp;$
                        </Text>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfSalesProposalDelivered)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* row 8 */}
                <Row
                  gutter={24}
                  justify="space-between"
                  style={{ marginBottom: 10, marginTop: 15 }}
                >
                  <Col span={11}>
                    {activeItem === 'dollar' ? (
                      <>
                        <Text size={16} className={styles.cardHeading}>
                          Agency/In-House Management Fees
                        </Text>
                        <div className={styles.whiteBox}>
                          <div className={styles.cardValue}>
                            <Text isBold className={styles.CardCurrency}>
                              &nbsp;{activeItem === 'dollar' ? '$' : '%'}
                            </Text>
                            {/* <Text isBold size={18}>
{agencyInHouseFee}
</Text> */}
                            <input
                              type="number"
                              placeholder="100"
                              value={agencyInHouseFee}
                              onChange={e => {
                                const value = parseFloat(e.target.value, 10)
                                handleAgencyInHouseManagementFee(value)
                                handleAgencyInHouseManagementFeePrevState(value)
                              }}
                              style={{
                                overflow: 'hidden',
                                outline: 'none',
                                fontSize: 18,
                                border: 'none',
                                fontWeight: 700,
                                appearance: 'textfield',
                              }}
                            />
                          </div>
                          <MySlider
                            min={0}
                            max={
                              agencyInHouseFeePrevState <= 50000 ? 50000 : agencyInHouseFeePrevState
                            }
                            step={100}
                            sliderValue={agencyInHouseFee}
                            handleSliderChange={handleAgencyInHouseManagementFee}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Text size={16} className={styles.cardHeading}>
                            Agency/In-House Management Fees
                          </Text>
                          <Text isBold size={18} style={{ padding: '4px 0px' }}>
                            &nbsp;{agencyInHouseFee}
                            <span style={{ color: '#D1D7D9', marginLeft: '4px' }}>%</span>
                          </Text>
                        </div>
                        <MySlider
                          min={0}
                          max={100}
                          step={1}
                          sliderValue={agencyInHouseFee}
                          handleSliderChange={handleAgencyInHouseManagementFee}
                        />
                      </>
                    )}
                  </Col>
                  <Col
                    span={5}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <button
                        type="button"
                        onClick={() => handleItemClick('dollar')}
                        className={`${
                          activeItem === 'dollar'
                            ? styles.ActiveDollarPercentage
                            : styles.dollarPercentage
                        }`}
                      >
                        <Text
                          isBold
                          style={{ color: activeItem === 'dollar' ? '#F16434' : '#A3B0B3' }}
                        >
                          &nbsp;$
                        </Text>
                      </button>
                      <button
                        type="button"
                        onClick={() => handleItemClick('perecntage')}
                        className={`${
                          activeItem === 'perecntage'
                            ? styles.ActiveDollarPercentage
                            : styles.dollarPercentage
                        }`}
                      >
                        <Text
                          isBold
                          style={{ color: activeItem === 'perecntage' ? '#F16434' : '#A3B0B3' }}
                        >
                          &nbsp;%
                        </Text>
                      </button>
                    </div>
                  </Col>
                  <Col span={7}>
                    <Text size={16} className={styles.cardHeading}>
                      ROAS
                    </Text>
                    <div className={styles.greyBox}>
                      <div className={styles.greyCardValue}>
                        <Text isBold className={styles.greyCardCurrency}>
                          &nbsp;$
                        </Text>
                        <Text isBold size={18}>
                          {roundToTwoDecimalPlaces(noOfROAs)}
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </Col>

        {/* righht section */}
        <Col span={12}>
          <div className={styles.rightColCard}>
            <div style={{ marginBottom: '45px' }}>
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col span={24}>
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    <div className={styles.headingIcon}>
                      <img src={bargraph} alt="icone priority" />
                    </div>
                    <Text isBold style={{ display: 'flex', alignItems: 'center' }}>
                      Cost Metrics
                    </Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.pinkCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerLead)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost per lead</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.pinkCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerQualifiedLead)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost per qualified lead</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.pinkCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerProposalDelivered)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost Per Proposal Delivered</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.pinkCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerSale)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost per sale</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.pinkCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerSaleIncludingFees)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost per sale* including fees</Text>
                  </div>
                </Col>
              </Row>
            </div>
            {/* revenue metrics */}
            {/* ROAS (Return On Ad Spend) */}
            <div style={{ marginBottom: '45px' }}>
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col span={24}>
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    <div className={styles.headingIcon}>
                      <img src={revenue} alt="icone priority" />
                    </div>

                    <Text isBold style={{ display: 'flex', alignItems: 'center' }}>
                      Revenue Metrics
                    </Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.greenCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(valueOfLead)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Value of a lead</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.greenCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(valueOfQualifiedLead)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Value of a qualified lead</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.greenCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(Number.isNaN(avgRevnuePerSale) ? 100 : avgRevnuePerSale)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Value of a sale</Text>
                  </div>
                </Col>
              </Row>
            </div>
            {/* ROAS (Return On Ad Spend) */}
            <div style={{ marginBottom: '45px' }}>
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col span={24}>
                  <div style={{ display: 'flex', marginRight: '10px' }}>
                    <div className={styles.headingIcon}>
                      <img src={Conversions} alt="icone conversion" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text isBold> ROAS</Text>{' '}
                      <Text style={{ marginLeft: '5px' }}>(Return On Ad Spend)</Text>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.lightPurpleCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(noOfROAs)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Return on ad spend</Text>
                  </div>
                </Col>
                <Col span={8} className={styles.cardPinkmargin}>
                  <div className={styles.lightPurpleCard}>
                    <div className={styles.pinkCardValue}>
                      <Text isBold className={styles.CardCurrencyPink}>
                        &nbsp;$
                      </Text>
                      <Text isBold size={24}>
                        {formatValue(costPerQualifiedLead)}
                      </Text>
                    </div>
                    <Text className={styles.cardPinkcontent}>Cost per qualified lead</Text>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        {/* PPC Section */}
        {/* <Col span={12}>
<div style={{ border: '1px solid #d9d9d9', padding: '16px' }}>
<div>
<Text isTitle style={{ padding: '10px 0px' }}>
PPC Ads Metrics
</Text>
<span className={styles.divider} />
<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Google Ads Spend Per Month:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;${googleAdsSpends}
</Text>
</Row>
<MySlider
min={100}
max={50000}
step={100}
sliderValue={googleAdsSpends}
handleSliderChange={handleGoogleAdsSpend}
/>
</Col>
<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Average Cost Per Click:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;${avgCostPerClick}
</Text>
</Row>
<MySlider
min={0.1}
max={50}
step={0.1}
sliderValue={avgCostPerClick}
handleSliderChange={handleAvgCostPerClick}
/>
</Col>

<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Landing Page Conversion Rate (CVR):
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;{landingPageConversionRate}%
</Text>
</Row>
<MySlider
min={1}
max={100}
step={1}
sliderValue={landingPageConversionRate}
handleSliderChange={handleLandingPageConverionRate}
/>
</Col>
<Text isTitle style={{ padding: '10px 0px' }}>
Sales Metrics
</Text>
<span className={styles.divider} />
<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Qualified Leads:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;{qualifiedLeads}%
</Text>
</Row>
<MySlider
min={1}
max={100}
step={1}
sliderValue={qualifiedLeads}
handleSliderChange={handleQualifiedLeads}
/>
</Col>
<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Sales Proposal Delivered:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;{proposalDelivered}%
</Text>
</Row>
<MySlider
min={1}
max={100}
step={1}
sliderValue={proposalDelivered}
handleSliderChange={handleProposalDelivered}
/>
</Col>
<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Sales Close Rate:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;{saleCloseRate}%
</Text>
</Row>
<MySlider
min={1}
max={100}
step={1}
sliderValue={saleCloseRate}
handleSliderChange={handleSaleCloseRate}
/>
</Col>

<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Average Revenue Per Sale:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;${avgRevnuePerSale}
</Text>
</Row>
<MySlider
min={100}
max={50000}
step={100}
sliderValue={avgRevnuePerSale}
handleSliderChange={handleRevnuePerSale}
/>
</Col>

<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Product Margin:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;{productMargin}%
</Text>
</Row>
<MySlider
min={1}
max={100}
step={1}
sliderValue={productMargin}
handleSliderChange={handleProductMargin}
/>
</Col>

<Col>
<Row gutter={5}>
<Text style={{ padding: '4px 0px' }} size={16}>
Agency/In-House Management Fees:
</Text>
<Text isBold size={18} style={{ padding: '4px 0px' }}>
&nbsp;${agencyInHouseFee}
</Text>
</Row>
<MySlider
min={100}
max={50000}
step={100}
sliderValue={agencyInHouseFee}
handleSliderChange={handleAgencyInHouseManagementFee}
/>
</Col>
</div>
</div>
</Col> */}

        {/* Results Section */}
        {/* <Col span={12}>
<div
style={{
border: '3px solid white',
boxShadow: '0px 0px 20px 0px rgba(0,0,0,.2)',
padding: '16px',
backgroundColor: '#eee',
}}
> */}
        {/* PPC MATRICS */}
        {/* <Text color="white" style={{ backgroundColor: '#f16434', padding: '4px' }} size={24}>
PPC Ads Metrics
</Text>
<Col>
<Text size={16}>Total Website Sessions from PPC</Text>
<Text isBold size={18}>
{totalClicks}
</Text>
</Col>

<Col style={{ marginTop: '15px' }}>
<Text size={16}>Conversions/Leads/Inquiries</Text>
<Text isBold size={18}>
{conversionLeadsInquery}
</Text>
</Col>

<Col style={{ marginTop: '15px' }}>
<Text size={16}>Cost Per Raw Inquiry</Text>
<Text isBold size={18}>
${costPerRawInquiry}
</Text>
</Col> */}

        {/* SALE MATRICS */}
        {/* <Text color="white" style={{ backgroundColor: '#f16434', padding: '4px' }} size={24}>
Sales Metrics
</Text>
<Text style={{ padding: '4px' }} size={24}>
MONTHLY TOTALS
</Text>
<Col>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Prospect to Qualified Lead CVR
</Text>
<Text style={{ padding: '4px 0px' }}>{leads} Leads</Text>
</Col>

<Col>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Sales Proposal Delivered
</Text>
<Text style={{ padding: '4px 0px' }}>{proposals} Proposals</Text>
</Col>

<Col>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Sales
</Text>
<Text style={{ padding: '4px 0px' }}>{sales} Sales</Text>
</Col> */}

        {/* BUSINESS MATRICS */}
        {/* <Text color="white" style={{ backgroundColor: '#f16434', padding: '4px' }} size={24}>
Business Metrics
</Text>
<Col>
<Row>
<Col span={12}>
<Text style={{ padding: '4px 0px' }} size={24}>
PER SALE
</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Gross Revenue
</Text>
<Text style={{ padding: '4px 0px' }}>${avgRevnuePerSale}</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Average Costs
</Text>
<Text style={{ padding: '4px 0px' }}>${avgCostPerSale}</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Gross Profit
</Text>
<Text style={{ padding: '4px 0px' }}>${grossProfitPerSale}</Text>
</Col>
<Col span={12}>
<Text style={{ padding: '4px 0px' }} size={24}>
PER MONTH
</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Gross Revenue
</Text>
<Text style={{ padding: '4px 0px' }}>${grossRevenuePerMonth}</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Average Costs
</Text>
<Text style={{ padding: '4px 0px' }}>${avgCostPerMonth}</Text>
<Text style={{ padding: '4px 0px' }} isBold size={18}>
Gross Profit
</Text>
<Text style={{ padding: '4px 0px' }}>${grossProfitPerMonth}</Text>
</Col>
</Row>
<Text style={{ padding: '4px 0px' }} size={24}>
RETURN ON AD SPEND (ROAS)
</Text>
<Text style={{ padding: '4px 0px' }}>{ROAS}%</Text>
<Text style={{ padding: '4px 0px' }} size={24}>
RETURN ON INVESTMENT (ROI)
</Text>
<Text style={{ padding: '4px 0px' }}>{ROI} 
%</Text>
</Col> */}
        {/* </div>
</Col> */}
      </Row>
    </>
  )
}

export default CustomSlider
