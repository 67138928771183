import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const deleteInvitedUser = async (userId, customerId) => {
  const { url, method } = apiRoutes.deleteInvited
  try {
    const res = await api({
      url: url(userId),
      method,
      data: { customer_id: customerId },
    })
    return res
  } catch (error) {
    return error.response
  }
}
