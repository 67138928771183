import React from 'react'
import { Slider } from 'antd'
import styles from './customslider.module.css'

const MySlider = props => {
  const { sliderValue, handleSliderChange, min, max, step, disabled } = props

  const customTrackStyle = {
    // height: '5px',
    // borderRadius: '1.5rem',
    // border: '1px solid #eee',
    top: '21px',
    // width: '100%',
    backgroundColor: '#F16434',
  }

  const customHandleStyle = {
    border: '2px solid #ffff',
    // backgroundColor: '#f16434',
    // height: '20px',
    // width: '20px',
    // marginBottom: '-12px',
    backgroundColor: '#F16434',
    top: '21px',
  }
  const customHandleStyleDisable = {
    backgroundColor: '#939395',
    border: '2px solid #ffff',
    top: '21px',
  }
  const customRailStyle = {
    // backgroundColor: 'rgb(200,200,200)`',
    // height: '10px',
    // borderRadius: '1.5rem',
    // border: '0px solid rgb(207,207,207)',
    // marginBottom: '-12px',
    // backgroundColor: 'red',

    top: '21px',
  }

  return (
    <div>
      <Slider
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={handleSliderChange}
        tooltip={{ open: false }}
        trackStyle={customTrackStyle}
        handleStyle={disabled ? [customHandleStyleDisable] : [customHandleStyle]}
        railStyle={customRailStyle}
        className={styles.sliderMargin}
        disabled={disabled}
      />
    </div>
  )
}
export default MySlider
