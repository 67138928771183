import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import neilPatelImage from '@images/neil-img-home.png'
import iconChild from '@images/icon-check-ads-grader.png'
import adsFunelImage from '@images/ads-funel-exp.png'
import underDevelopmentImage from '@images/underdevelopmentBackground.png'
import iconCheck2 from '@images/check-2.png'
import { Text } from '@components'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'

import styles from './underDevelopment.module.css'

const UnderDevelopment = () => {
  //   const [temp, setTemp] = useState()
  const { t } = useTranslation()
  return (
    <>
      <div
        style={{
          width: '100%',
          background: `url(${underDevelopmentImage})`,
          backgroundSize: 'cover', // This line ensures the image covers the entire background
        }}
      >
        <div className={styles.infos_header}>
          <div className={styles.headerTitle}>
            <Text
              isBold
              color="white"
              size={64}
              className={styles.responseTitle}
              style={{ marginTop: '300px' }}
            >
              This website is under maintenance.
            </Text>
          </div>
          <Text color="white" size={32} style={{ marginBottom: '450px', textAlign: 'center' }}>
            Apologies for inconvenience. Please check back in a couple of hours.
          </Text>
        </div>
      </div>
    </>
  )
}
export default UnderDevelopment
