import React, { useState, useRef, useEffect } from 'react'
import { Input, Select, Checkbox, message } from 'antd'
import Text from '@components/Text/text'
import { useDispatch, useSelector } from 'react-redux'
import { RequestService } from '@services/requests'
import { API_DATA_BOARDS_LIST } from '@utils/constants'
import { addBoard } from '../../../../../store/actions'
import styles from './searchBoard.module.css'

const SearchBoard = ({ adsData, boardsList }) => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const [selectedBoards, setSelectedBoards] = useState([])
  const [allBoards, setAllBoards] = useState(apiDataBoardsList)
  const [disabledBoards, setDisabledBoards] = useState([])
  const selectRef = useRef(null)

  // Fetch boards from the server
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setAllBoards(response?.data)
    } catch (error) {
      console.error('Error fetching boards:', error)
    }
  }

  useEffect(() => {
    setAllBoards(apiDataBoardsList) // Sync state with the Redux store

    // Prepopulate boards that are pinned (disabled items)
    const pinnedBoards = apiDataBoardsList
      // eslint-disable-next-line
      .filter(board => board?.pinned_ads?.some(ad => ad.ad_id === adsData?._id))
      .map(board => board.name)

    setSelectedBoards(pinnedBoards)
    setDisabledBoards(pinnedBoards) // Set disabled boards as well
    // eslint-disable-next-line
  }, [apiDataBoardsList, adsData])

  useEffect(() => {
    hanldeDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    hanldeDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [adsData])

  const handleOkClick = async () => {
    const selectedBoardIds = allBoards
      .filter(board => selectedBoards.includes(board.name))
      .map(board => board.id)

    try {
      // eslint-disable-next-line
      for (const boardId of selectedBoardIds) {
        // eslint-disable-next-line
        await RequestService.post('/create/pinned-ads/', {
          // eslint-disable-next-line
          ad_id: adsData._id,
          board: boardId,
        })
      }
      setDisabledBoards([...disabledBoards, ...selectedBoards])
      hanldeDiscoverAdsBoard()
    } catch (error) {
      console.error('Error pinning ads to boards:', error)
    }
  }

  // Handle board selection (multi-select)
  const handleSelectChange = async selectedItems => {
    const newlySelectedBoards = selectedItems.filter(item => !selectedBoards.includes(item))

    if (newlySelectedBoards.length > 0) {
      const selectedBoard = allBoards.find(board => board.name === newlySelectedBoards[0])
      if (selectedBoard) {
        const selectedBoardId = selectedBoard.id
        try {
          // eslint-disable-next-line
          await RequestService.post('/create/pinned-ads/', {
            // eslint-disable-next-line
            ad_id: adsData._id,
            board: selectedBoardId,
          })
          setSelectedBoards([...selectedBoards, selectedBoard.name])
          setDisabledBoards([...disabledBoards, selectedBoard.name])
          message.success('Added to board')
        } catch (error) {
          message.error('Error while adding to board')
        }
      }
    }
  }

  // Handle adding a new board
  const handleAddBoard = async () => {
    const trimmedValue = searchTerm.trim() // Trim the input value
    if (trimmedValue) {
      const existingBoard = allBoards.find(
        board => board?.name?.toLowerCase() === trimmedValue.toLowerCase()
      )

      if (existingBoard) {
        // Automatically select the board if it exists
        if (!selectedBoards.includes(existingBoard.name)) {
          setSelectedBoards([...selectedBoards, existingBoard?.name])
        }
      } else {
        // Create a new board if it doesn't exist
        try {
          const response = await RequestService.post('/create/boards/', { name: trimmedValue })
          const createdBoard = response.data
          const updatedBoards = [...allBoards, createdBoard]
          setAllBoards(updatedBoards)
          setSelectedBoards([...selectedBoards, createdBoard.name]) // Automatically select the new board
          dispatch(addBoard(createdBoard))
          hanldeDiscoverAdsBoard()
          message.success('Board created successfully')
        } catch (error) {
          console.error('Error creating board:', error)
        }
      }
    }
    setSearchTerm('') // Reset the search term after submission
  }

  // Handle plus button click (perform add or pin functionality)
  const handlePlusClick = () => {
    if (searchTerm.trim()) {
      handleAddBoard()
    }
  }

  // Disable Enter key for the Input field and trigger only board creation
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault() // Prevent default behavior of the Enter key
      handlePlusClick() // Trigger board creation
    }
  }

  return (
    <div style={{ marginTop: 12 }} id="inputContainerModal">
      <Text style={{ fontSize: 14, color: '#67696B' }}>Add this ad to board</Text>
      <div style={{ marginTop: 12 }}>
        <Select
          ref={selectRef}
          mode="multiple"
          placeholder="Select board(s)"
          value={selectedBoards} // Ensure the value is tied to the state
          onChange={() => handleSelectChange} // Handle changes and update state
          style={{ width: '100%' }}
          showSearch={false} // Disables typing
          defaultActiveFirstOption={false} // Prevents automatic hovering on the first option
          getPopupContainer={() => document.getElementById('inputContainerModal')}
          dropdownRender={menu => (
            <>
              <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}>
                <Input
                  placeholder="Create a board"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown} // Disable Enter key
                  style={{ flex: 1 }}
                />
                <Text size={16} onClick={handlePlusClick} className={styles.addButton}>
                  +
                </Text>
              </div>
              {menu}
            </>
          )}
        >
          {allBoards.map(board => (
            <Select.Option
              key={board.id}
              value={board.name}
              // eslint-disable-next-line
              disabled={board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)}
              style={{
                // eslint-disable-next-line
                cursor: board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)
                  ? 'not-allowed'
                  : 'pointer',
              }}
            >
              <Checkbox
                onClick={e => e.stopPropagation()} // Prevent checkbox from stopping selection
                checked={
                  selectedBoards?.includes(board.name) ||
                  // eslint-disable-next-line
                  board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)
                }
                // eslint-disable-next-line
                disabled={board.pinned_ads?.some(ad => ad.ad_id === adsData?._id)}
                onChange={() => handleSelectChange([...selectedBoards, board.name])} // Update selection on checkbox click
              >
                {board.name}
              </Checkbox>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default SearchBoard
