import { EXTRA_INSIGHTS_CLICK, EXTRA_INSIGHTS_DATA } from '@utils/constants'

const initialState = {
  extraInsightsButton: true,
  extraInsightsData: [],
}

export const extraInsights = (state = initialState, action) => {
  switch (action.type) {
    case EXTRA_INSIGHTS_CLICK:
      return {
        ...state,
        extraInsightsButton: action.payload,
      }
    case EXTRA_INSIGHTS_DATA:
      return {
        ...state,
        extraInsightsData: action.payload,
      }
    default:
      return state
  }
}
