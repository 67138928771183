import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Text from '@components/Text/text'
import priorityIcon from '@images/priority-icon.svg'
import { SettingOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'

const ImprovementCard = ({ priority, title, description, impact, url }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapperMain}>
      <div className={styles.container}>
        <div className={styles.wrapperCards}>
          {impact >= 20 && (
            <div className={classNames(styles.cardPriority, [styles.high])}>
              <Text isTitle size={12}>
                {t('report.improvement.impactHigh')}
              </Text>
            </div>
          )}
          {impact < 20 && impact > 9 && (
            <div className={classNames(styles.cardPriority, [styles.medium])}>
              <Text isTitle size={12}>
                {t('report.improvement.impactMedium')}
              </Text>
            </div>
          )}
          {impact < 9 && (
            <div className={classNames(styles.cardPriority, [styles.low])}>
              <Text isTitle size={12}>
                {t('report.improvement.impactLow')}
              </Text>
            </div>
          )}
          <div className={styles.wrapperPriority}>
            <img src={priorityIcon} alt="icone priority" />
            <Text size={14} style={{ fontSize: '13px' }}>
              <span style={{ color: '#A3B0B3' }}>
                {t('report.improvement.priorityText')}: {priority}
              </span>
            </Text>
          </div>
        </div>
        {/* <div className={styles.iconWrapper}>
          <SettingOutlined style={{ color: '#A3B0B3' }} />
        </div> */}
        <div>
          <Text size={14} isBold>
            <span style={{ color: '#A3B0B3', textTransform: 'uppercase' }}>{title}</span>
          </Text>
          <Text size={14}>{description}</Text>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            window.open(url, '_blank')
          }}
          type="link"
          style={{ padding: '8px 16px', height: 'auto' }}
        >
          {t('report.improvement.button')}
        </Button>
      </div>
    </div>
  )
}

ImprovementCard.propTypes = {
  priority: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  impact: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
}

export default ImprovementCard
