import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchGoogleUserInfo = async code => {
  const { url, method } = apiRoutes.googleAuth
  try {
    const res = await api({
      url,
      method,
      data: { code },
    })
    return res
  } catch (e) {
    return e.response
  }
}
