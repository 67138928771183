import { ACCOUNT_TAB, MANAGE_USERS_TAB, PLATFORM_INTEGRATIONS } from '@utils/constants'

const tabs = [
  {
    label: 'Account',
    key: ACCOUNT_TAB,
  },
  {
    label: 'Manage Users',
    key: MANAGE_USERS_TAB,
  },
  // {
  //   label: 'Platform Integrations',
  //   key: PLATFORM_INTEGRATIONS,
  // },
]

const currencies = [
  {
    currency: 'USD',
    label: '$ USD Dollar',
  },
  {
    currency: 'GBP',
    label: '£ GBP Pound',
  },
  {
    currency: 'EUR',
    label: '€ EUR Euro',
  },
  {
    currency: 'CNY',
    label: '¥ CNY Yuan',
  },
  {
    currency: 'AUD',
    label: '$ AUD Dollar',
  },
  {
    currency: 'CAD',
    label: '$ CAD Dollar',
  },
]

const reportFormats = [
  {
    label: 'Adobe PDF',
    format: 'pdf',
  },
  // {
  //   label: 'Microsoft Excel',
  //   format: 'excel',
  // },
  // {
  //   label: 'Microsoft Word',
  //   format: 'word',
  // },
]
/* eslint-disable */
const benchmarkData = [
  {
    key: '1',
    name: 'benchmark.labels.animalPet',
    perClick: 2.42,
    perLead: 23.13,
    clickThroughRate: 9.26,
    conversionRate: 9.26,
  },
  {
    key: '2',
    name: 'benchmark.labels.apparelFashionJewelry',
    perClick: 1.2,
    perLead: 35.94,
    clickThroughRate: 7.24,
    conversionRate: 5.9,
  },
  {
    key: '3',
    name: 'benchmark.labels.attorneysLawLegal',
    perClick: 12.95,
    perLead: 124.32,
    clickThroughRate: 7.29,
    conversionRate: 7.59,
  },
  {
    key: '4',
    name: 'benchmark.labels.artsEntertainment',
    perClick: 1.83,
    perLead: 72.48,
    clickThroughRate: 8.73,
    conversionRate: 7.14,
  },
  {
    key: '5',
    name: 'benchmark.labels.automotive',
    perClick: 2.17,
    perLead: 52.88,
    clickThroughRate: 9.44,
    conversionRate: 7.1,
  },
  {
    key: '6',
    name: 'benchmark.labels.beautyCosmeticsPersonalCare',
    perClick: 2.28,
    perLead: 48.35,
    clickThroughRate: 7.12,
    conversionRate: 5.98,
  },
  {
    key: '7',
    name: 'benchmark.labels.careerEmployment',
    perClick: 3.78,
    perLead: 132.95,
    clickThroughRate: 6.67,
    conversionRate: 6.67,
  },
  {
    key: '8',
    name: 'benchmark.labels.ecommerce',
    perClick: 1.06,
    perLead: '',
    clickThroughRate: 4.25,
    conversionRate: 4.88,
  },
  {
    key: '9',
    name: 'benchmark.labels.educationCoachingInstruction',
    perClick: 3.74,
    perLead: 132.27,
    clickThroughRate: 8.05,
    conversionRate: 6.71,
  },
  {
    key: '10',
    name: 'benchmark.labels.farmingAgricultureSustainability',
    perClick: 2.46,
    perLead: '',
    clickThroughRate: 2.66,
    conversionRate: '',
  },
  {
    key: '11',
    name: 'benchmark.labels.financeBanksInvestmentsAccounting',
    perClick: 3.78,
    perLead: 71.84,
    clickThroughRate: 5.59,
    conversionRate: 4.98,
  },
  {
    key: '12',
    name: 'benchmark.labels.fitnessSupplementsVitamins',
    perClick: 4.18,
    perLead: 42.33,
    clickThroughRate: 7.1,
    conversionRate: 4.31,
  },
  {
    key: '13',
    name: 'benchmark.labels.healthRelatedWellnessMedical',
    perClick: 3.0,
    perLead: 43.11,
    clickThroughRate: 6.29,
    conversionRate: 4.33,
  },
  {
    key: '14',
    name: 'benchmark.labels.healthcare',
    perClick: 8.1,
    perLead: 484.78,
    clickThroughRate: 8.6,
    conversionRate: 8.04,
  },
  {
    key: '15',
    name: 'benchmark.labels.houseDecorFurnitureHomeImprovement',
    perClick: 4.92,
    perLead: 38.03,
    clickThroughRate: 4.79,
    conversionRate: 3.84,
  },
  {
    key: '16',
    name: 'benchmark.labels.heavyIndustryEnergyManufacturing',
    perClick: 2.84,
    perLead: 81.87,
    clickThroughRate: 6.76,
    conversionRate: 6.02,
  },
  {
    key: '17',
    name: 'benchmark.labels.insurance',
    perClick: 5.02,
    perLead: 49.53,
    clickThroughRate: 5.73,
    conversionRate: 5.73,
  },
  {
    key: '18',
    name: 'benchmark.labels.logisticsTransportation',
    perClick: '',
    perLead: '',
    clickThroughRate: '',
    conversionRate: '',
  },
  {
    key: '19',
    name: 'benchmark.labels.marketingAdvertisingDigitalServices',
    perClick: 5.36,
    perLead: 96.98,
    clickThroughRate: 2.63,
    conversionRate: 2.63,
  },
  {
    key: '20',
    name: 'benchmark.labels.pharma',
    perClick: '',
    perLead: '',
    clickThroughRate: '',
    conversionRate: '',
  },
  {
    key: '21',
    name: 'benchmark.labels.professionalServicesContractors',
    perClick: '',
    perLead: 69.09,
    clickThroughRate: 8.42,
    conversionRate: 6.85,
  },
  {
    key: '22',
    name: 'benchmark.labels.realEstate',
    perClick: 1.76,
    perLead: 42.78,
    clickThroughRate: 7.99,
    conversionRate: 5.36,
  },
  {
    key: '23',
    name: 'benchmark.labels.restaurantsFoodsBeverages',
    perClick: 1.11,
    perLead: 4.93,
    clickThroughRate: 6.29,
    conversionRate: 5.42,
  },
  {
    key: '24',
    name: 'benchmark.labels.saaSITSoftware',
    perClick: 1.4,
    perLead: '',
    clickThroughRate: '',
    conversionRate: 2.64,
  },
  {
    key: '25',
    name: 'benchmark.labels.travelHotelTourism',
    perClick: 1.26,
    perLead: 12.77,
    clickThroughRate: 9.68,
    conversionRate: 6.49,
  },
  {
    key: '26',
    name: 'benchmark.labels.other',
    perClick: '',
    perLead: '',
    clickThroughRate: '',
    conversionRate: '',
  },
]

export { reportFormats, currencies, tabs, benchmarkData }
