import React, { useEffect, useState } from 'react'
import { UPDATE_LEARNING_SEARCH_STRING, UPDATE_LEARNING_SELECTED_VALUE } from '@utils/constants'
import Text from '@components/Text/text'
import { Card, Col, Row, Spin } from 'antd'
import npContact from '@images/Learning/np-contact.png'
import noPreviewImage from '@images/Learning/noPreviewImage.webp'
import Navbar from '@components/LearningCenter/Navbar'
import SearchNotFound from '@components/LearningCenter/SearchNotFound'
import LearningModal from '@components/LearningCenter/LearningModal'
import { useDispatch, useSelector } from 'react-redux'
import style from './search.module.css'

const SearchFeature = ({ searchValue, tags, handleLearningRedirect, catagory }) => {
  const apiData = useSelector(state => state?.learningCenter?.apiData)
  const searchString = useSelector(state => state?.learningCenter?.searchString)
  const activeButtonStateChangeValue = useSelector(
    state => state?.learningCenter?.updateActiveButton
  )
  const [tag, setTag] = useState(tags)
  const [dummy, setDummy] = useState()
  const [data, setData] = useState(apiData)
  const [sortTab, setSortTab] = useState(false)
  const [isMatch, setIsMatch] = useState('')
  const [activeButton, setActiveButton] = useState('')
  const [knowledgeGuidesCount, setKnowledgeGuidesCount] = useState(0)
  const [videoInsightsCount, setVideoInsightsCount] = useState(0)
  const [otherCount, setOtherCount] = useState(0)
  const [searchedValueFromNav, setSearchedValueFromNav] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [componentSearch, setComponentSearch] = useState(true)
  const [tempText, setTempText] = useState(false)
  const dispatch = useDispatch()
  const countCategories = catagories => {
    setKnowledgeGuidesCount(0)
    setVideoInsightsCount(0)
    setOtherCount(0)
    catagories.forEach(item => {
      switch (item.category) {
        case 'Knowledge and Guides':
          setKnowledgeGuidesCount(prevCount => prevCount + 1)
          break
        case 'Video Insights':
          setVideoInsightsCount(prevCount => prevCount + 1)
          break
        case 'Trainings and Downloadables':
          setOtherCount(prevCount => prevCount + 1)
          break
        default:
          break
      }
    })
  }

  const countCategoriesForExtendedSearch = dataFromParameters => {
    setKnowledgeGuidesCount(0)
    setVideoInsightsCount(0)
    setOtherCount(0)
    const result = dataFromParameters.filter(singledata => {
      const title = singledata.title ? singledata.title.toLowerCase() : ''
      const searchLower = searchString.toLowerCase()
      return title.includes(searchLower)
    })

    result.forEach(item => {
      switch (item.category) {
        case 'Knowledge and Guides':
          setKnowledgeGuidesCount(prevCount => prevCount + 1)
          break
        case 'Video Insights':
          setVideoInsightsCount(prevCount => prevCount + 1)
          break
        case 'Trainings and Downloadables':
          setOtherCount(prevCount => prevCount + 1)
          break
        default:
          break
      }
    })
  }
  const countCategoriesForAdvancedSearch = items => {
    setKnowledgeGuidesCount(0)
    setVideoInsightsCount(0)
    setOtherCount(0)
    const result = items.filter(singledata => {
      const title = singledata.title ? singledata.title.toLowerCase() : ''
      const searchLower = searchString.toLowerCase()
      return title.includes(searchLower)
    })
    result.forEach(item => {
      if (tag === '1') {
        if (item.category === 'Knowledge and Guides' && item.tag === 'Beginner') {
          setKnowledgeGuidesCount(prevCount => prevCount + 1)
        } else if (item.category === 'Video Insights' && item.tag === 'Beginner') {
          setVideoInsightsCount(prevCount => prevCount + 1)
        } else if (item.category === 'Trainings and Downloadables' && item.tag === 'Beginner') {
          setOtherCount(prevCount => prevCount + 1)
        }
      } else if (tag === '2') {
        if (item.category === 'Knowledge and Guides' && item.tag === 'Intermediate') {
          setKnowledgeGuidesCount(prevCount => prevCount + 1)
        } else if (item.category === 'Video Insights' && item.tag === 'Intermediate') {
          setVideoInsightsCount(prevCount => prevCount + 1)
        } else if (item.category === 'Trainings and Downloadables' && item.tag === 'Intermediate') {
          setOtherCount(prevCount => prevCount + 1)
        }
      } else if (tag === '3') {
        if (item.category === 'Knowledge and Guides' && item.tag === 'Advanced') {
          setKnowledgeGuidesCount(prevCount => prevCount + 1)
        } else if (item.category === 'Video Insights' && item.tag === 'Advanced') {
          setVideoInsightsCount(prevCount => prevCount + 1)
        } else if (item.category === 'Trainings and Downloadables' && item.tag === 'Advanced') {
          setOtherCount(prevCount => prevCount + 1)
        }
      }
    })
  }

  const handleSearchFilterForExtentedSearch = searchedDataFromFunction => {
    const result = searchedDataFromFunction.filter(singledata => {
      const title = singledata.title ? singledata.title.toLowerCase() : ''
      const searchLower = searchString.toLowerCase()
      return title.includes(searchLower)
    })

    if (result) {
      setData(result)
    } else {
      setIsMatch(false)
    }

    if (result.length === 0) {
      setIsMatch(false)
    } else if (result.length > 0) {
      setIsMatch(true)
    }
  }

  const handleSearchFilter = () => {
    const result = apiData.filter(singledata => {
      const title = singledata.title ? singledata.title.toLowerCase() : ''
      const searchLower = searchString.toLowerCase()
      return title.includes(searchLower)
    })
    setData(result)
    if (result.length === 0) {
      setIsMatch(false)
    } else if (result.length > 0) {
      setIsMatch(true)
    }
  }

  const handleEnterButton = () => {
    setSortTab(true)
    setComponentSearch(true)

    setTempText(true)
    setSortTab(false)
  }
  const handleSearchValue = value => {
    setSearchedValueFromNav(value)
  }
  const hanldeInputChange = e => {
    setSortTab(false)
    dispatch({ type: UPDATE_LEARNING_SEARCH_STRING, payload: e.target.value })
    setComponentSearch(false)
  }

  useEffect(() => {
    setDummy(searchValue)
    // eslint-disable-next-line
  }, [searchValue])

  const handleSelectChange = value => {
    dispatch({ type: UPDATE_LEARNING_SELECTED_VALUE, payload: value })
    setSortTab(true)
    setTag(value)
    setComponentSearch(true)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  // here are use effects of filter
  const catagoreySort = (catagoreyId, sortData) => {
    if (catagoreyId === 1) {
      const filteredData = sortData.filter(item => item.category === 'Knowledge and Guides')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(apiData)
    } else if (catagoreyId === 2) {
      const filteredData = sortData.filter(item => item.category === 'Video Insights')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(apiData)
    } else if (catagoreyId === 3) {
      const filteredData = sortData.filter(item => item.category === 'Trainings and Downloadables')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(apiData)
    } else {
      handleSearchFilterForExtentedSearch(apiData)
      countCategoriesForExtendedSearch(apiData)
    }
  }

  const tagSortForNoPage = (tagId, sortData) => {
    if (tagId === '1') {
      const filteredData = sortData.filter(item => item.tag === 'Beginner')
      setData(sortData.filter(item => item.tag === 'Beginner'))
      countCategoriesForExtendedSearch(filteredData)
    } else if (tagId === '2') {
      const filteredData = sortData.filter(item => item.tag === 'Intermediate')
      setData(sortData.filter(item => item.tag === 'Intermediate'))
      countCategoriesForExtendedSearch(filteredData)
    } else if (tagId === '3') {
      const filteredData = sortData.filter(item => item.tag === 'Advanced')
      setData(sortData.filter(item => item.tag === 'Advanced'))
      countCategoriesForExtendedSearch(filteredData)
    } else {
      setData(sortData)
      countCategoriesForExtendedSearch(apiData)
    }
  }
  const tagSort = (tagId, sortData) => {
    if (tagId === '1') {
      const filteredData = sortData.filter(item => item.tag === 'Beginner')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(filteredData)
    } else if (tagId === '2') {
      const filteredData = sortData.filter(item => item.tag === 'Intermediate')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(filteredData)
    } else if (tagId === '3') {
      const filteredData = sortData.filter(item => item.tag === 'Advanced')
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForExtendedSearch(filteredData)
    } else {
      setData(sortData)
      countCategoriesForExtendedSearch(apiData)
    }
  }
  const tagAndCatagoreyForNoPage = (tagId, catagoreyID, sortData) => {
    if (tagId === '1' && catagoreyID === 1) {
      setData(
        sortData.filter(item => item.tag === 'Beginner' && item.category === 'Knowledge and Guides')
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '1' && catagoreyID === 2) {
      setData(
        sortData.filter(item => item.tag === 'Beginner' && item.category === 'Video Insights')
      )
    } else if (tagId === '1' && catagoreyID === 3) {
      setData(
        sortData.filter(
          item => item.tag === 'Beginner' && item.category === 'Trainings and Downloadables'
        )
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 1) {
      setData(
        sortData.filter(
          item => item.tag === 'Intermediate' && item.category === 'Knowledge and Guides'
        )
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 2) {
      setData(
        sortData.filter(item => item.tag === 'Intermediate' && item.category === 'Video Insights')
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 3) {
      setData(
        sortData.filter(
          item => item.tag === 'Intermediate' && item.category === 'Trainings and Downloadables'
        )
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '3' && catagoreyID === 1) {
      setData(
        sortData.filter(item => item.tag === 'Advanced' && item.category === 'Knowledge and Guides')
      )
    } else if (tagId === '3' && catagoreyID === 2) {
      setData(
        sortData.filter(item => item.tag === 'Advanced' && item.category === 'Video Insights')
      )
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '3' && catagoreyID === 3) {
      setData(
        sortData.filter(
          item => item.tag === 'Advanced' && item.category === 'Trainings and Downloadables'
        )
      )
      countCategoriesForAdvancedSearch(apiData)
    } else {
      handleSearchFilterForExtentedSearch(apiData)
      countCategoriesForAdvancedSearch(apiData)
    }
  }
  const tagAndCatagorey = (tagId, catagoreyID, sortData) => {
    if (tagId === '1' && catagoreyID === 1) {
      const filteredData = sortData.filter(
        item => item.tag === 'Beginner' && item.category === 'Knowledge and Guides'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '1' && catagoreyID === 2) {
      const filteredData = sortData.filter(
        item => item.tag === 'Beginner' && item.category === 'Video Insights'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '1' && catagoreyID === 3) {
      const filteredData = sortData.filter(
        item => item.tag === 'Beginner' && item.category === 'Trainings and Downloadables'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 1) {
      const filteredData = sortData.filter(
        item => item.tag === 'Intermediate' && item.category === 'Knowledge and Guides'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 2) {
      const filteredData = sortData.filter(
        item => item.tag === 'Intermediate' && item.category === 'Video Insights'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '2' && catagoreyID === 3) {
      countCategoriesForAdvancedSearch(apiData)
      const filteredData = sortData.filter(
        item => item.tag === 'Intermediate' && item.category === 'Trainings and Downloadables'
      )
      handleSearchFilterForExtentedSearch(filteredData)
    } else if (tagId === '3' && catagoreyID === 1) {
      const filteredData = sortData.filter(
        item => item.tag === 'Advanced' && item.category === 'Knowledge and Guides'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '3' && catagoreyID === 2) {
      const filteredData = sortData.filter(
        item => item.tag === 'Advanced' && item.category === 'Video Insights'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else if (tagId === '3' && catagoreyID === 3) {
      const filteredData = sortData.filter(
        item => item.tag === 'Advanced' && item.category === 'Trainings and Downloadables'
      )
      handleSearchFilterForExtentedSearch(filteredData)
      countCategoriesForAdvancedSearch(apiData)
    } else {
      //      handleSearchFilterForExtentedSearch(apiData)
      countCategoriesForAdvancedSearch(apiData)
    }
  }

  useEffect(() => {
    if (searchString.length === 0) {
      setComponentSearch(true)
    }
    // eslint-disable-next-line
  }, [searchString])
  useEffect(() => {
    if (tag === undefined || tag.length === 0) {
      if (searchString.length > 0 && componentSearch) {
        catagoreySort(activeButton, apiData)
      } else if (searchString.length === 0) {
        handleSearchFilter(apiData)
        // countCategories(apiData)
      }
    } else if (tag) {
      if (searchString.length > 0 && componentSearch) {
        // handleSearchFilter()
        // catagoreySort(activeButton, apiData)
      } else if (searchString.length === 0) {
        handleSearchFilter(apiData)
        // catagoreySort(activeButton, apiData)
      }
    }
    // eslint-disable-next-line
  }, [searchString, componentSearch, activeButton, tag])
  useEffect(() => {
    if ((tag === undefined || tag.length === 0) && componentSearch) {
      if (activeButton) {
        catagoreySort(activeButton, apiData)
      }
      if (activeButton.length === 0) {
        handleSearchFilterForExtentedSearch(apiData)
        countCategoriesForExtendedSearch(apiData)
      }
      if (!isMatch) {
        // setActiveButton('')
        // setData(apiData)
      }
    }
    // eslint-disable-next-line
  }, [activeButton, searchString, componentSearch, isMatch])

  useEffect(() => {
    if (tag && activeButton.length === 0 && componentSearch) {
      if (isMatch) {
        tagSort(tag, apiData)
      }

      if (tag && activeButton.length === 0 && componentSearch) {
        tagSort(tag, apiData)
      }
      if (tag && searchString.length > 0 && activeButton.length === 0 && componentSearch) {
        tagSort(tag, apiData)
      }
      if (tag && searchString.length > 0 && activeButton.length === 0) {
        tagSort(tag, apiData)
      }
    }

    // eslint-disable-next-line
  }, [tag, activeButton, componentSearch, isMatch])

  useEffect(() => {
    if (
      (tag === undefined || tag.length === 0) &&
      activeButton.length === 0 &&
      searchString.length === 0
    ) {
      countCategories(apiData)
    }
    // eslint-disable-next-line
  }, [activeButton, tag, data, searchString])
  useEffect(() => {
    if (componentSearch) {
      setActiveButton('')
    }
    // eslint-disable-next-line
  }, [componentSearch])
  useEffect(() => {
    if (tag && activeButton.length === 0 && !isMatch && componentSearch) {
      if (isMatch === false) {
        // setActiveButton('')

        // tagSortForNoPage(tag, apiData)
        tagSort(tag, apiData)
      } else if (tag && searchString.length > 0) {
        tagSort(tag, apiData)
      } else if (!isMatch && searchString.length === 0) {
        tagSort(tag, apiData)
      } else if (!isMatch && activeButton.length === 0 && searchString.length > 0) {
        // tagSort(tag, apiData)
        // tagSortForNoPage(tag, apiData)
      }
    } else if (tag && !isMatch && componentSearch) {
      // tagSort(tag, apiData)
    } else if (tag && isMatch && componentSearch) {
      tagSort(tag, apiData)
    } else if (tag && activeButton.length === 0 && componentSearch) {
      tagSort(tag, apiData)
    }

    // eslint-disable-next-line
  }, [tag, activeButton, isMatch, searchString, componentSearch])
  // end tag serch

  // start of both apis
  useEffect(() => {
    if (tag && activeButton) {
      if (componentSearch) {
        tagAndCatagorey(tag, activeButton, apiData)
      }
      if (!isMatch) {
        tagAndCatagoreyForNoPage(tag, activeButton, apiData)
      }
    }
    // eslint-disable-next-line
  }, [tag, activeButton, componentSearch, isMatch])
  useEffect(() => {
    if ((tag?.length === 0 || tag === undefined) && activeButton) {
      catagoreySort(activeButton, apiData)
    }
    // eslint-disable-next-line
  }, [tag, activeButton])

  // useEffect(() => {
  //   if (catagory) {
  //     if (catagory === 'Knowledge and Guides') {
  //       setActiveButton(1)
  //     } else if (catagory === 'Video Insights') {
  //       setActiveButton(2)
  //     } else if (catagory === 'Trainings and Downloadables') {
  //       setActiveButton(3)
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [catagory])
  useEffect(() => {
    if (activeButton) {
      handleEnterButton()
    }
    // eslint-disable-next-line
  }, [activeButton])

  useEffect(() => {
    if (tag === undefined && componentSearch) {
      if (searchString && activeButton.length === 0) {
        countCategories(data)
      } else if (searchString.length === 0 && activeButton.length === 0) {
        countCategories(data)
      } else if (activeButton && searchString.length === 0) {
        countCategories(apiData)
      } else if (activeButton && searchString) {
        countCategoriesForExtendedSearch(apiData)
      }
    }
    // eslint-disable-next-line
  }, [searchString, tag, activeButton, data, componentSearch])
  useEffect(() => {
    if (activeButtonStateChangeValue) {
      setActiveButton('')
    }
    // eslint-disable-next-line
  }, [activeButtonStateChangeValue])
  // end of start of only active button only
  const [searchValues, setSearchValues] = useState('')

  const handleSearchInputChange = value => {
    setSearchValues(value)
  }
  function formatDate(createdAt) {
    const dateObj = new Date(!createdAt ? '2000-01-01' : createdAt)

    // Define months to replace
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    // Get the day, month, and year
    const day = dateObj.getDate()
    const month = months[dateObj.getMonth()]
    const year = dateObj.getFullYear()

    // Format the date string
    const formattedDate = `${day} ${month} ${year}`
    return formattedDate
  }
  useEffect(() => {
    if (tag.length > 0 && activeButton.length > 0) {
      countCategoriesForAdvancedSearch(apiData)
    }
    // eslint-disable-next-line
  }, [tag, activeButton])
  return (
    <>
      <Navbar
        handleSearchInputChangess={handleSearchInputChange}
        title="Learning Center"
        handleSearchValue={handleSearchValue}
        handleLearningRedirect={handleLearningRedirect}
        searchedValueFromNav={searchValue}
        dummy={dummy}
        tag={tag}
        onSelectChange={handleSelectChange}
      />
      <Row style={{ marginTop: 32 }} gutter={18}>
        <Col span={19}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
              // eslint-disable-next-line
              searchString.length === 0 ? (
                ''
              ) : // eslint-disable-next-line
              isMatch === true ? (
                <Text
                  style={{ marginTop: 24, color: '#26282C', fontSize: '16px', fontStyle: 'normal' }}
                >
                  <span style={{ fontWeight: 400 }}>Search Results for</span>
                  <strong> &quot;{searchString}&quot;</strong>
                </Text>
              ) : // eslint-disable-next-line
              isMatch === false ? (
                <Text
                  style={{
                    maxWidth: 912,
                    marginTop: 25,
                    color: '#26282C',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    // maxWidth: '912px',
                  }}
                >
                  <span style={{ fontWeight: 400 }}>Sorry! We could not find results for</span>
                  <strong> &quot;{searchString}&quot;.</strong>
                  <span style={{ fontWeight: 400 }}>
                    Try rephrasing or using more specific keywords. You can also browse these
                    related topics.
                  </span>
                </Text>
              ) : (
                ''
              )
            }
            {searchString.length > 0 ? (
              <Text
                className={style.clearText}
                type="submit"
                size={14}
                style={{
                  fontWeight: 500,
                  letterSpacing: '1.12px',
                  marginTop: 24,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={handleLearningRedirect}
              >
                Clear
              </Text>
            ) : (
              ''
            )}
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '24px',
              // width: '1108px',
              minHeight: '46px',
              border: '1px solid #E8EBEC',
            }}
          >
            <div
              style={{ display: 'inline-flex', marginTop: '24px', marginLeft: '24px', gap: '32px' }}
            >
              <button
                type="submit"
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '14px',
                  borderBottom: activeButton === '' ? '2.5px solid #F16434' : 'none',
                  fontWeight: 600,
                  fontStyle: 'normal',
                  textTransform: 'uppercase',
                  color: activeButton === '' ? '#F16434' : '#939395',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveButton('')}
              >
                All
              </button>
              <button
                type="submit"
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '14px',
                  borderBottom: activeButton === 3 ? '2.5px solid #F16434' : 'none',
                  fontWeight: 600,
                  fontStyle: 'normal',
                  textTransform: 'uppercase',
                  color: activeButton === 3 ? '#F16434' : '#939395',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveButton(3)}
              >
                Trainings and Downloadables ({otherCount})
              </button>
              <button
                type="submit"
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '14px',
                  borderBottom: activeButton === 2 ? '2.5px solid #F16434' : 'none',
                  fontWeight: 600,
                  fontStyle: 'normal',
                  textTransform: 'uppercase',
                  color: activeButton === 2 ? '#F16434' : '#939395',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveButton(2)}
              >
                Video Insights ({videoInsightsCount})
              </button>

              <button
                type="submit"
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '14px',
                  borderBottom: activeButton === 1 ? '2.5px solid #F16434' : 'none',
                  fontWeight: 600,
                  fontStyle: 'normal',
                  textTransform: 'uppercase',
                  color: activeButton === 1 ? '#F16434' : '#939395',
                  cursor: 'pointer',
                }}
                onClick={() => setActiveButton(1)}
              >
                Knowledge and Guides ({knowledgeGuidesCount})
              </button>
            </div>
          </div>

          {
            // eslint-disable-next-line
            !data ? (
              <Spin style={{ textAlign: 'center' }} className={style.loadingContainer} />
            ) : // eslint-disable-next-line
            isMatch === true || isMatch === '' ? (
              data?.map((singledata, id) => (
                <Card style={{ height: '208px', cursor: 'pointer' }} key={singledata.id}>
                  <a
                    href={singledata.link}
                    aria-label={data.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div style={{ display: 'flex' }}>
                      <div>
                        <img
                          src={
                            singledata.preview_image ? singledata?.preview_image : noPreviewImage
                          }
                          alt="search-data"
                          height={160}
                          width={267}
                        />
                      </div>
                      <div style={{ marginTop: 12, marginLeft: 32, height: '136px' }}>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <div
                            style={{
                              // minWidth: '204px',
                              display: 'inline-flex',
                              height: '30px',
                              alignItems: 'center',
                              padding: '10px',
                              justifyContent: 'center',
                              background:
                                // eslint-disable-next-line
                                singledata.category === 'Knowledge and Guides'
                                  ? // eslint-disable-next-line
                                    '#E0FAEF'
                                  : // eslint-disable-next-line
                                  singledata.category === 'Trainings and Downloadables'
                                  ? '#ECF3FE'
                                  : singledata.category === 'Video Insights'
                                  ? '#F2EAFE'
                                  : '',
                              // border: '1px solid #FFEFEA',
                              borderRadius: '4px',
                            }}
                          >
                            <Text
                              isSemiBold
                              size={14}
                              style={{
                                // height: '30px',
                                textAlign: 'center',
                                color:
                                  // eslint-disable-next-line
                                  singledata.category === 'Knowledge and Guides'
                                    ? // eslint-disable-next-line
                                      '#00A862'
                                    : // eslint-disable-next-line
                                    singledata.category === 'Trainings and Downloadables'
                                    ? '#4285F4'
                                    : singledata.category === 'Video Insights'
                                    ? '#7524FA'
                                    : '',
                                fontStyle: 'normal',
                                fontWeight: 600,
                              }}
                            >
                              {singledata?.category}
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'inline-flex',
                              width: 'auto',
                              height: '30px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#F1F3F4',
                              borderRadius: '4px',
                            }}
                          >
                            <Text
                              size={14}
                              style={{ color: '#8F8F8F', padding: '10px', fontWeight: 600 }}
                            >
                              {singledata?.tag}
                            </Text>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: '20px',
                            // minWidth: '557px',
                            minHeight: '48px',
                          }}
                        >
                          <Text
                            style={{
                              color: '#202020',
                              fontSize: '20px',
                              fontStyle: 'normal',
                              lineHeight: '140%',
                              textTransform: 'capitalize',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                            }}
                          >
                            <span
                              style={{ fontWeight: 400, textDecoration: 'none', color: '#202020' }}
                            >
                              {singledata.title}
                            </span>
                          </Text>
                        </div>
                      </div>
                    </div>
                  </a>
                </Card>
              ))
            ) : (
              // eslint-disable-next-line
              <>
                <SearchNotFound />
              </>
            )
          }
        </Col>

        <Col span={5}>
          {!isMatch ? (
            <Card style={{ background: '#F9FBFF', padding: '0px' }}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={npContact}
                  alt="np-contact"
                  width={74.287}
                  height={96}
                  // style={{ marginLeft: '83px' }}
                />
              </div>
              <Text
                style={{
                  // width: '240px',
                  minHeight: '84px',
                  marginTop: 16,
                  fontSize: '19px',
                  lineHeight: '140%',
                  textAlign: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                <strong
                  style={{
                    color: '#FF5E29',
                    fontWeight: 900,
                    textAlign: 'center',
                    fontStyle: 'normal',
                  }}
                >
                  Ask Neil
                </strong>
                <span style={{ color: '#26282C', fontWeight: 700, fontStyle: 'normal' }}>
                  {' '}
                  anything related to Google ads or ads account.
                </span>
              </Text>

              <button
                style={{
                  marginTop: '16px',
                  width: '100%',
                  height: '44px',
                  border: '1px solid #F16434',
                  background: '#F16434',
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  letterSpacing: '1.12px',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                type="submit"
                onClick={showModal}
              >
                ASK NEIL NOW!
              </button>
              {isModalOpen ? (
                <LearningModal modalOpen={isModalOpen} handleCancel={handleCancel} />
              ) : (
                ''
              )}
            </Card>
          ) : (
            <Card
              style={{
                background: '#F9FBFF',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <img
                  src={npContact}
                  alt="np-contact"
                  width={74.287}
                  height={96}
                  // style={{ marginLeft: '83px' }}
                />
              </div>
              <Text
                style={{
                  // width: '240px',
                  // height: '84px',
                  marginTop: 16,
                  fontSize: '19px',
                  lineHeight: '140%',
                  textAlign: 'center',
                  fontWeight: 400,
                  // paddingLeft: '20px',
                  // paddingRight: '20px',
                }}
              >
                <strong
                  style={{
                    color: '#FF5E29',
                    fontWeight: 900,
                    textAlign: 'center',
                    fontStyle: 'normal',
                  }}
                >
                  Looking
                </strong>
                <span style={{ color: '#26282C', fontWeight: 700, fontStyle: 'normal' }}>
                  {' '}
                  for additional info in the learning center?
                </span>
              </Text>
              <Text
                style={{
                  // width: '240px',
                  // height: '84px',
                  marginTop: 11,
                  fontSize: '14px',
                  lineHeight: '153%',
                  textAlign: 'center',
                  color: '#26282C',
                  // paddingLeft: '20px',
                  // paddingRight: '20px',
                }}
              >
                Seek Neil Patel&apos;s advice for quick ad performance improvements and discover the
                latest marketing tricks.
              </Text>
              <button
                style={{
                  marginTop: '20px',
                  width: '100%',
                  height: '44px',
                  border: '1px solid #F16434',
                  background: '#F16434',
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  letterSpacing: '1.12px',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                }}
                onClick={showModal}
                type="submit"
              >
                REQUEST NOW
              </button>
              {isModalOpen ? (
                <LearningModal modalOpen={isModalOpen} handleCancel={handleCancel} />
              ) : (
                ''
              )}
            </Card>
          )}
        </Col>
      </Row>
    </>
  )
}
export default SearchFeature
