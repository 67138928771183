import { UPDATE_CURRENT_REPORT } from '@utils/constants'

const initialState = null

export const currentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_REPORT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
