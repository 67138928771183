/* eslint-disable import/named */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Card, Col, Row, Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Text } from '@components'
import { axiosInstance } from '@services/requests'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'
import { delay, formatCurrency } from '../../utils'
import Footer from '../../components/Footer'
import styles from './generating.module.scss'
import { SecretService } from '../../services/secret'
import { LoginForm } from '../login-form'

const GeneratingPage = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const { reportToken } = useParams()
  const [adSpend, setAdSpend] = useState(0)
  const [wastedSpendTotal, setWastedSpendTotal] = useState(0)
  const [adsGraderScore, setAdsGraderScore] = useState(0)
  const [impressionShare, setImpressionShare] = useState(0)
  const [showForm, setShowForm] = useState(localStorage.getItem('showForm') || false)
  const [progress, setProgress] = useState({ percent: 0, text: '' })
  const [overallSpend, setOverallSpend] = useState()
  const [adsGraderCustomerId, setAdsGraderCustomerId] = useState(0)
  const [wholeReportData, setWholeReportData] = useState({})
  const history = useHistory()
  const customerId = useSelector(state => state?.customer?.id)

  const { t } = useTranslation()

  const reportPayload = useMemo(() => SecretService.decode(reportToken), [reportToken])
  useEffect(() => {
    if (window.location.href.includes('/generating/')) {
      history.push(`/login/?reportId=${reportPayload.uuid}&customerId=${reportPayload.customerId}`)
    }
    // eslint-disable-next-line
  }, [window.location.href.includes('/generating/')])

  const startProgressAnimation = useCallback(async () => {
    await delay()
    setProgress({ percent: 16, text: t('report.loadingText1') })
    const { data } = await axiosInstance.get(`/reports/${reportPayload.uuid}/`)
    setWholeReportData(data && data)
    setAdsGraderCustomerId(data?.customer_id)
    setOverallSpend(data?.overall_spend)
    setAdSpend(
      formatCurrency(data.overall_spend, {
        currency: reportPayload.currency,
        language: 'en-US',
      })
    )
    setWastedSpendTotal(
      formatCurrency(data.projected_wasted_spend, {
        currency: reportPayload.currency,
        language: 'en-US',
      })
    )
    setAdsGraderScore(data.general_score)
    setImpressionShare(data.grader_impression_share)
    // setImpressionShare(data.search_rank_lost_impression_share)

    await delay(1500)
    if (data) {
      const report = data
      setProgress({
        percent: 32,
        text: t('report.generating.text1', { total: report.campaigns_total }),
      })
      await delay(4000)

      setProgress({
        percent: 40,
        text: t('report.generating.text2', { total: report.ad_groups_total }),
      })
      await delay(2000)

      setProgress({ percent: 56, text: t('report.generating.text3') })
      await delay(2500)

      setProgress({
        percent: 72,
        text: t('report.generating.text4', {
          total: formatCurrency(report.overall_spend, {
            currency: reportPayload.currency,
            language: 'en-US',
          }),
        }),
      })
      await delay(2000)

      setProgress({
        percent: 88,
        text: t('report.generating.text5', { total: `${report.search_conversions.toFixed(0)}%` }),
      })
      await delay(2000)

      setProgress({ percent: 100, text: t('report.generating.text6') })
      await delay(1500)

      setShowForm(true)
      localStorage.setItem('showForm', showForm)
    } else {
      setProgress({ percent: 30, text: t('report.loadingText2') })
      await delay(1500)

      setProgress({ percent: 45, text: t('report.loadingText3') })
      await delay(1500)

      setProgress({ percent: 75, text: t('report.loadingText4') })
      await delay(2500)

      setProgress({ percent: 90, text: t('report.loadingText5') })
      await delay(2000)

      setProgress({ percent: 100, text: t('report.loadingText6') })
      await delay(1500)

      setShowForm(true)
      localStorage.setItem('showForm', showForm)
    }
  }, [t, reportPayload, showForm])

  useEffect(() => {
    startProgressAnimation()
  }, [startProgressAnimation])

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.loginHeader}>
        <LogoAdsGrader />
        <Text className={styles.title} size={40} color="white">
          {showForm ? t('loginForm.title') : t('report.loadingTitle')}
        </Text>

        <Row className={styles.row}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xl={{ span: 10, offset: 7 }}
          >
            <Card className={styles.loginCard}>
              {showForm ? (
                <LoginForm
                  amount={overallSpend}
                  currencyCode={reportPayload?.currency}
                  adSpend={adSpend}
                  impressionShare={impressionShare}
                  adsGraderScore={adsGraderScore}
                  wastedSpendTotal={wastedSpendTotal}
                  googleAdsCustomerId={adsGraderCustomerId}
                  reportId={wholeReportData?.id}
                  reportUniqueId={wholeReportData?.unique_id}
                  location={window?.location?.origin}
                />
              ) : (
                <>
                  <Progress
                    percent={progress.percent}
                    strokeWidth={16}
                    status="active"
                    showInfo={false}
                  />
                  <Text>{progress.text}</Text>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  )
}

export default GeneratingPage
