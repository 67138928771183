import { Tabs, Input, Select, Row, Col, AutoComplete } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  ADS_CHANNEL,
  ADS_FORMAT,
  ADS_LOCATION,
  ADS_SEARCH_KEYWORD,
  ADS_LOADER,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  SPINNER,
  ADS_NO_DATA,
  ADS_DATA,
  ADS_HOME_STATE,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  CLEAR_STATE,
  TEMP_COUNT,
  CLEAR_BRANDS_FLITER,
  IS_META_STATUS,
  IS_GOOGLE_STATUS,
} from '@utils/constants'
import ImageIcon from '@images/DiscoverAds/DropdownIcons/imageIcon.png'
import { notify } from '@utils/notify'
import { RequestService } from '@services/requests'
import keywordSearchIcon from '@images/AdsPreview/keywordSearch.png'
import FilterArrow from '@images/DiscoverAds/SearchBar/filterArrow.png'
import GoogleIcon from '@images/DiscoverAds/SearchBar/Meta Icons.png'
import MetaIcon from '@images/DiscoverAds/SearchBar/MetaIcon.png'
import { googleCountries, metaCountries } from '../../../data/discoverData'
import styles from './searchBar.module.css'

const { TabPane } = Tabs
const { Option } = Select

const SearchBar = () => {
  const [brandChosen, setBrandChosen] = useState(false)
  const searchSuggestions = useSelector(state => state?.discoverAds?.searchSuggestions)
  const [metaOptions, setMetaOptions] = useState([])
  const [googleOptions, setGoogleOptions] = useState([])
  const searchKeyword = useSelector(state => state?.discoverAds?.searchKeyword)
  const [isRotated, setIsRotated] = useState(false)
  const [selectedOptionsFormat, setSelectedOptionsFormat] = useState()
  const [selectedLocation, setSelectedLocation] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [isFilterActive, setIsFilterActive] = useState(true)
  const filterClick = () => {
    setIsFilterActive(!isFilterActive)
    setIsRotated(!isRotated)
  }

  const dispatch = useDispatch()
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const location = useSelector(state => state?.discoverAds?.location)
  const adsFormats = useSelector(state => state?.discoverAds?.formats)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const moreDataLoader = useSelector(state => state?.discoverAds?.moreDataLoader)

  const handleKeywordSearchForMeta = value => {
    setBrandChosen(true)
    let enteredValue = value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }

    // Filter options based on input
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: value })
    const filteredOptions = searchSuggestions.meta_brands

      .filter(suggestion => suggestion.brand_name.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.brand_name,
        id: suggestion?.meta_page_id,
      }))
    setMetaOptions(filteredOptions)
    // dispatch({ type: ADS_SEARCH_KEYWORD, payload: filteredOptions })
  }
  const handleInputChangeForMeta = value => {
    setBrandChosen(false)
    let enteredValue = value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }

    // Filter options based on input
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: value })
    const filteredOptions = searchSuggestions.meta_brands
      .filter(suggestion => suggestion.brand_name.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.brand_name,
        id: suggestion?.meta_page_id,
      }))
    setMetaOptions(filteredOptions)
    // dispatch({ type: ADS_SEARCH_KEYWORD, payload: filteredOptions })
  }
  const handleKeywordSearchForGoogle = value => {
    setBrandChosen(true)
    let enteredValue = value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }

    // Filter options based on input
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: value })
    const filteredOptions = searchSuggestions?.google_brands
      .filter(suggestion => suggestion.brand_name.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.brand_name,
        id: suggestion?.google_advertiser_id,
      }))
    setGoogleOptions(filteredOptions)
    // dispatch({ type: ADS_SEARCH_KEYWORD, payload: filteredOptions })
  }
  const handleInputChangeForGoogle = value => {
    setBrandChosen(false)
    let enteredValue = value
    if (enteredValue.startsWith('www.')) {
      enteredValue = enteredValue.slice(4)
    }

    // Filter options based on input
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: value })
    const filteredOptions = searchSuggestions?.google_brands
      .filter(suggestion => suggestion.brand_name.toLowerCase().includes(value.toLowerCase()))
      .map(suggestion => ({
        value: suggestion?.brand_name,
        id: suggestion?.google_advertiser_id,
      }))
    setGoogleOptions(filteredOptions)
    // dispatch({ type: ADS_SEARCH_KEYWORD, payload: filteredOptions })
  }

  const handleSelectFormat = selectedValues => {
    setSelectedOptionsFormat(selectedValues)
    if (selectedChannel === 'Meta') {
      dispatch({
        type: ADS_FORMAT,
        payload: selectedValues.includes('All') ? 'all' : selectedValues,
      })
    } else {
      dispatch({ type: ADS_FORMAT, payload: selectedValues.includes('All') ? '' : selectedValues })
    }
  }
  const handleSelectChannels = selectedValues => {
    const newMetaOptions = searchSuggestions?.meta_brands?.map(suggestion => ({
      value: suggestion?.brand_name, // Assuming each suggestion object has a 'keyword' property
      id: suggestion?.meta_page_id,
    }))

    const newGoogleOptions = searchSuggestions?.google_brands?.map(suggestion => ({
      value: suggestion.brand_name, // Assuming each suggestion object has a 'keyword' property
      id: suggestion.google_advertiser_id,
    }))

    setMetaOptions(newMetaOptions)
    setGoogleOptions(newGoogleOptions)
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    setSelectedOptionsFormat([]) // Clear ad formats
    setSelectedLocation([]) // Clear location
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    if (selectedValues === '2') {
      dispatch({ type: ADS_FORMAT, payload: 'all' })
    } else {
      dispatch({ type: ADS_FORMAT, payload: '' })
    }
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ADS_HOME_STATE, payload: true })

    if (selectedValues === '1') {
      dispatch({ type: ADS_CHANNEL, payload: 'Google' })
    } else if (selectedValues === '2') {
      dispatch({ type: ADS_CHANNEL, payload: 'Meta' })
    }
  }

  const onChangeLocation = value => {
    dispatch({ type: ADS_LOCATION, payload: value })
    setSelectedLocation(value)
  }

  const handleAdsApi = async () => {
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
    try {
      dispatch({
        type: CLEAR_STATE,
        payload: [],
      })
      dispatch({ type: SPINNER, payload: true })
      dispatch({ type: ADS_LOADER, payload: true })
      let response = []

      if (selectedChannel === 'Meta') {
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${20}&creative_format=${adsFormats
            .toString()
            .toLowerCase()
            .replace(
              /s$/,
              ''
            )}&channels=[${selectedChannel}]&ad_reached_countries=["${location}"]&is_brand=${brandChosen}&advertiser_id=${
            metaOptions[0]?.id
          }`
        )

        dispatch({ type: IS_META_STATUS, payload: response?.data?.is_meta })
      } else if (selectedChannel === 'Google') {
        let paginationCounnt = 30
        if (adsFormats === 'Videos' || adsFormats === 'all') {
          paginationCounnt = 7
        }
        response = await RequestService.get(
          `/ads-preview/competitors-prod/?search_term=${searchKeyword}&num=${paginationCounnt}&creative_format=${adsFormats
            .toString()
            .toLowerCase()
            .replace(
              /s$/,
              ''
            )}&channels=[${selectedChannel}]&region=${location}&is_brand=${brandChosen}&page_id=${
            googleOptions[0]?.id
          }`
        )
        dispatch({ type: IS_GOOGLE_STATUS, payload: response?.data?.is_facebook })
      }
      dispatch({ type: ADS_LOADER, payload: false })
      const formattedData = response?.data?.result?.facebook_ads?.data
      if (formattedData.length > 0) {
        if (formattedData.length >= 20) {
          dispatch({ type: TEMP_COUNT, payload: 20 })
        } else {
          dispatch({ type: TEMP_COUNT, payload: formattedData.length })
        }
      } else if (formattedData.length === 0) {
        dispatch({ type: TEMP_COUNT, payload: 0 })
      }

      if (formattedData.length > 0) {
        dispatch({
          type: ADS_DATA,
          payload: formattedData,
        })
        dispatch({ type: ADS_NO_DATA, payload: false })
      }
      if (
        response?.data?.result?.facebook_ads?.paging?.next &&
        formattedData.length > 0 &&
        selectedChannel === 'Meta'
      ) {
        dispatch({
          type: ADS_PAGINATION,
          payload: response?.data?.result?.facebook_ads?.paging?.next,
        })
        dispatch({
          type: HAS_MORE,
          payload: true,
        })
      }
      if (
        response?.data?.result?.facebook_ads?.serpapi_pagination?.next &&
        formattedData.length > 0 &&
        selectedChannel === 'Google'
      ) {
        dispatch({
          type: ADS_PAGINATION,
          payload: response?.data?.result?.facebook_ads?.serpapi_pagination?.next,
        })
        dispatch({
          type: HAS_MORE,
          payload: true,
        })
      } else if (
        response?.data?.result?.facebook_ads?.paging?.next &&
        formattedData.length > 0 &&
        selectedChannel === 'Google'
      ) {
        dispatch({
          type: ADS_PAGINATION,
          payload: response?.data?.result?.facebook_ads?.paging?.next,
        })
        dispatch({
          type: HAS_MORE,
          payload: true,
        })
      }
      if (formattedData?.length === 0) {
        dispatch({ type: ADS_NO_DATA, payload: true })
      }
      dispatch({ type: SPINNER, payload: false })
    } catch {
      dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      dispatch({ type: ADS_NO_DATA, payload: true })
    }
  }

  const handleSearchButton = () => {
    if (searchKeyword.trim() !== '') {
      dispatch({ type: ADS_SEARCH_KEYWORD, payload: searchKeyword })
      dispatch({ type: ADS_HOME_STATE, payload: false })
      dispatch({ type: CLEANED_DATA_COUNT, payload: '' })
      dispatch({
        type: HAS_MORE,
        payload: false,
      })
      handleAdsApi()
    } else {
      notify('Search Keyword cannot be empty', 'error')
    }
  }
  useEffect(() => {
    // Convert searchSuggestions array of objects to array of AutoComplete options
    const newMetaOptions = searchSuggestions?.meta_brands?.map(suggestion => ({
      value: suggestion?.brand_name, // Assuming each suggestion object has a 'keyword' property
      id: suggestion?.meta_page_id,
    }))

    const newGoogleOptions = searchSuggestions?.google_brands?.map(suggestion => ({
      value: suggestion.brand_name, // Assuming each suggestion object has a 'keyword' property
      id: suggestion.google_advertiser_id,
    }))

    setMetaOptions(newMetaOptions)
    setGoogleOptions(newGoogleOptions)
  }, [searchSuggestions])
  return (
    <div className={styles.searchBar}>
      <Row align="middle" justify="space-between">
        <Col flex="auto">
          <Tabs
            defaultActiveKey="1"
            className="search-tabs"
            onChange={key => {
              if (!loading && !moreDataLoader) {
                setActiveKey(key)
                handleSelectChannels(key)
              }
            }}
          >
            <TabPane
              tab={
                <div style={{ display: 'flex', gap: 8 }}>
                  <img src={GoogleIcon} alt="google-icon" />
                  <span
                    style={{
                      color: activeKey === '1' ? '#26282C' : '#8F8F8F',
                      fontWeight: activeKey === '1' ? 'bold' : 'normal', // Set bold for active tab
                    }}
                    className={styles.tabTitle}
                  >
                    Google Ads
                  </span>
                </div>
              }
              key="1"
              disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
            />
            <TabPane
              tab={
                <div style={{ display: 'flex' }}>
                  <img src={MetaIcon} alt="meta-icon" height={24} style={{ marginTop: '1px' }} />
                  <span
                    style={{
                      color: activeKey === '2' ? '#26282C' : '#8F8F8F',
                      fontWeight: activeKey === '2' ? 'bold' : 'normal', // Set bold for active tab
                      marginTop: '2.85px',
                    }}
                    className={styles.tabTitle}
                  >
                    All Meta Ads
                  </span>
                </div>
              }
              key="2"
              disabled={loading || moreDataLoader} // Disable tab if loading or moreDataLoader is true
            />
          </Tabs>
        </Col>
        <Col>
          {/* eslint-disable-next-line */}
          <div className={styles.filterButtonContainer} onClick={filterClick}>
            <button type="button" className={styles.filterButton}>
              Filters
            </button>
            <img
              src={FilterArrow}
              style={{
                marginTop: '-8px',
                transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease', // Smooth transition
              }}
              height={6}
              alt="filter-arrow"
            />
          </div>
        </Col>
      </Row>

      <div style={{ display: isFilterActive ? 'block' : 'none' }}>
        <Row gutter={12} className={styles.parentDiv}>
          <Col span={10} className={styles.inputContainer}>
            {/* Start of Keyword Input field */}
            {selectedChannel === 'Meta' ? (
              <div className={styles.input} id="MetaAutoComplete">
                <AutoComplete
                  getPopupContainer={() => document.getElementById('MetaAutoComplete')}
                  options={metaOptions}
                  onSelect={value => handleKeywordSearchForMeta(value)}
                  onSearch={value => handleInputChangeForMeta(value)}
                  style={{ width: '100%' }}
                  value={searchKeyword}
                >
                  <Input
                    id="keywordSearchInput"
                    className={styles.inputText}
                    placeholder="Search by keyword"
                    prefix={
                      <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                    }
                    onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                  />
                </AutoComplete>
              </div>
            ) : (
              <div className={styles.input} id="GoogleAutoComplete">
                <AutoComplete
                  getPopupContainer={() => document.getElementById('GoogleAutoComplete')}
                  options={googleOptions}
                  onSelect={value => handleKeywordSearchForGoogle(value)}
                  onSearch={value => handleInputChangeForGoogle(value)}
                  style={{ width: '100%' }}
                  value={searchKeyword}
                >
                  <Input
                    id="keywordSearchInput"
                    className={styles.inputText}
                    placeholder="Search by keyword"
                    prefix={
                      <img src={keywordSearchIcon} height={24} width={24} alt="search-icon" />
                    }
                    onPressEnter={loading || moreDataLoader ? undefined : handleSearchButton}
                  />
                </AutoComplete>
              </div>
            )}
          </Col>
          <Col span={5} style={{ position: 'relative' }} id="area">
            <Select
              id="location"
              showSearch
              value={selectedLocation}
              placeholder={selectedChannel === 'Google' ? 'Location: Anywhere' : 'Language: All'}
              optionFilterProp="children"
              onChange={onChangeLocation}
              dropdownClassName="ads-preview-dropdown"
              getPopupContainer={() => document.getElementById('area')}
              style={{
                display: 'block',
                fontSize: 14,
              }}
              filterOption={(input, option) => {
                const label = option.children[1] // Access the label part of the children
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {selectedChannel === 'Meta' &&
                metaCountries.map(country => (
                  <Option
                    key={country.value}
                    value={country.value}
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      lineHeight: '16px',
                    }}
                  >
                    {country.flag ? (
                      <img
                        src={country.flag}
                        alt="flag"
                        width={24}
                        height={24}
                        style={{ marginRight: 8 }}
                      />
                    ) : (
                      ''
                    )}

                    {country.label}
                  </Option>
                ))}

              {selectedChannel === 'Google' &&
                googleCountries.map(country => (
                  <Option
                    key={country.value}
                    value={country.value}
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      lineHeight: '16px',
                    }}
                  >
                    {country.flag ? (
                      <img
                        src={country.flag}
                        alt="flag"
                        width={24}
                        height={24}
                        style={{ marginRight: 8 }}
                      />
                    ) : (
                      ''
                    )}
                    {country.label}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={5}>
            {
              <Select
                placeholder="Ad Formats: All formats"
                style={{
                  display: 'block',
                  fontSize: 14,
                  borderColor: '#E8EBEC !important',
                }}
                dropdownClassName="ads-preview-dropdown"
                onChange={handleSelectFormat}
                showArrow
                value={selectedOptionsFormat}
                showSearch={false}
                getPopupContainer={() => document.getElementById('area')}
                optionLabelProp="label"
              >
                <Option value="All">
                  <div className={styles.options}>
                    <span className={styles.checkbox}>All</span>
                  </div>
                </Option>

                {selectedChannel === 'Google' && (
                  <Option value="Text">
                    <div className={styles.options}>
                      {/* <img src={ImageIcon} alt="imageIcon" /> */}
                      <span className={styles.checkbox}>Text</span>
                    </div>
                  </Option>
                )}
                <Option value="Videos">
                  <div className={styles.options}>
                    {/* <img src={ImageIcon} alt="imageIcon" /> */}
                    <span className={styles.checkbox}>Videos</span>
                  </div>
                </Option>
                <Option value="Images">
                  <div className={styles.options}>
                    {/* <img src={ImageIcon} alt="imageIcon" /> */}
                    <span className={styles.checkbox}>Images</span>
                  </div>
                </Option>
              </Select>
            }
          </Col>

          <Col span={4}>
            <button
              type="submit"
              className={styles.searchButton}
              onClick={handleSearchButton}
              disabled={loading || moreDataLoader}
            >
              SEARCH
            </button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default SearchBar
