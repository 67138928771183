// import { LS_KEY } from '../pages/login/login-route'
import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'

export const fetchInsights = async reportId => {
  const { url, method } = apiRoutes.extraInsights
  try {
    const res = await api({
      url: reportId ? `${url}?report_id=${reportId}` : '',
      method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
