import { UPDATE_REPORT_DASHBOARD } from '@utils/constants'

const initialState = {
  reportDashboard: '',
}

export const reportDashboard = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REPORT_DASHBOARD:
      return action.payload
    default:
      return state
  }
}
