import React, { useState } from 'react'
import Tour from 'reactour'
import { Row, Col, Avatar, Divider, Modal, Tag, Select, Button, Input } from 'antd'
import { Text } from '@components'
import RectangleComponent from '@components/Rectangle'
import InputField from '../Input'
import AlertCard from './AlertCard/AlertCard'
import ProfileImg from '../../images/neil-face.png'
import BudgetScaling from '../../images/Alerts/BudgetScaling.png'
import LowQualityKeywrods from '../../images/Alerts/LowQualityKeywrods.png'
import ProfitLessKeyword from '../../images/Alerts/ProfitLessKeyword.png'
import MinorCost from '../../images/Alerts/MinorCost.png'
import DecreaseBid from '../../images/Alerts/DecreaseBid.png'
import competitorAlert from '../../images/Alerts/competitorAlert.png'
import modalAndLine from '../../images/Alerts/modalAndLine.png'
import rightIcon from '../../images/Alerts/is-greater-than.svg'
import FeatureSettings from './AgAlertFeatureSettings/index'
import reLoad from '../../images/Alerts/reloader.svg'
import guidedFlowImage from '../../images/Alerts/guidedFlowImage.png'
import FormulaVector from '../../images/Alerts/FormulaVector.svg'
import crossIcon from '../../images/Alerts/CrossIcon.svg'
import Styles from './style.module.css'

const AgAlertsOverView = () => {
  // start of modal for Request new formula
  const [formulasModalformData, setFormulasModalformData] = useState({
    name: '',
    importance: '',
    logic: '',
  })

  const [isTextModalOpen, setIsTextModalOpen] = useState(false)

  const handleFormulasModalInputChange = (key, value) => {
    setFormulasModalformData({
      ...formulasModalformData,
      [key]: value,
    })
  }
  const remainingCharacters = 250 - formulasModalformData.logic.length
  const { TextArea } = Input
  // End of modal for Request new formula

  // Start of guided flow

  const [isTourOpen, setIsTourOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const hanldeFormulasModal = () => {
    setIsTextModalOpen(true)
  }
  const closeTour = () => {
    setIsTourOpen(false)
  }

  const startTour = () => {
    setIsTourOpen(true)
  }
  const handleNextTour = () => {
    setCurrentStep(prevStep => {
      const nextStep = prevStep + 1
      return nextStep
    })
  }
  const handlePreviousStepTour = () => {
    setCurrentStep(prevStep => {
      const previousStep = prevStep - 1
      return previousStep
    })
  }
  const handleEndStepTour = () => {
    setIsTourOpen(false)
  }

  const [steps] = useState([
    {
      style: { backgroundColor: '#26282C' },
      selector: '.step-1',
      position: [1350, 350],
      content: (
        <div className={Styles.mainContent}>
          <Text isBold size={14} style={{ color: 'white' }}>
            Welcome from Neil Patel 👋
          </Text>
          <Row>
            <Col span={16}>
              <Text size={14} className={Styles.textDetails}>
                Share your ad data for alerts, or use industry standards for a quick overview.
              </Text>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '42px',
                }}
              >
                <Button
                  onClick={handleNextTour}
                  className={Styles.nextButton}
                  // style={{ marginTop: '14px' }}
                >
                  <Text isBold size={12} style={{ color: 'white' }}>
                    NEXT
                  </Text>
                </Button>

                <Text size={12} className={Styles.progressText}>
                  01/05
                </Text>
              </div>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <img src={guidedFlowImage} alt="img.png" style={{ minHeight: '135px' }} width={108} />
            </Col>
          </Row>
        </div>
      ),
    },
    {
      selector: '.step-2',

      content: (
        <div className={Styles.mainContent}>
          <Text isBold size={14} style={{ color: 'white' }}>
            One of the Basic Alerts 🚀
          </Text>
          <Row>
            <Col span={16}>
              <Text size={14} className={Styles.textDetails}>
                This card holds key details for the basic alert—your go-to for specific information.
              </Text>
              <Row style={{ marginTop: '42px' }}>
                <Col span={20}>
                  <div style={{ display: 'flex' }}>
                    <Text
                      onClick={handlePreviousStepTour}
                      isBold
                      size={12}
                      className={Styles.backButton}
                      cursor
                    >
                      BACK
                    </Text>

                    <Button
                      onClick={handleNextTour}
                      className={Styles.nextButton}
                      style={{ marginLeft: '6px' }}
                    >
                      <Text isBold size={12} style={{ color: 'white' }}>
                        Next
                      </Text>
                    </Button>
                  </div>
                </Col>
                <Col span={4}>
                  <Text size={12} className={Styles.progressText}>
                    02/05
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <img src={guidedFlowImage} alt="img.png" style={{ minHeight: '135px' }} width={108} />
            </Col>
          </Row>
        </div>
      ),
      position: [530, 700],
      style: { backgroundColor: '#26282C' },
    },
    {
      selector: '.step-3',
      content: (
        <div className={Styles.mainContent}>
          <Text isBold size={14} style={{ color: 'white' }}>
            Let’s Get Started 💫
          </Text>
          <Row>
            <Col span={16}>
              <Text size={14} className={Styles.textDetails}>
                Customize your alerts and get started with the personalized notifications.
              </Text>
              <Row style={{ marginTop: '42px' }}>
                <Col span={20}>
                  <div style={{ display: 'flex' }}>
                    <Text
                      onClick={handlePreviousStepTour}
                      isBold
                      size={12}
                      className={Styles.backButton}
                      cursor
                    >
                      BACK
                    </Text>

                    <Button
                      onClick={handleNextTour}
                      className={Styles.nextButton}
                      style={{ marginLeft: '6px' }}
                    >
                      <Text isBold size={12} style={{ color: 'white' }}>
                        NEXT
                      </Text>
                    </Button>
                  </div>
                </Col>
                <Col span={4}>
                  <Text size={12} className={Styles.progressText}>
                    03/05
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <img src={guidedFlowImage} alt="img.png" style={{ minHeight: '135px' }} width={108} />
            </Col>
          </Row>
        </div>
      ),
      position: [260, 680],
      style: { backgroundColor: '#26282C' },
    },
    {
      selector: '.step-4', // Replace with the actual CSS selector of your first target element
      content: (
        <div className={Styles.mainContent}>
          <Text isBold size={14} style={{ color: 'white' }}>
            Toggle the Alerts 🚨
          </Text>
          <Row>
            <Col span={16}>
              <Text size={14} className={Styles.textDetails}>
                Receive personalized alerts directly in your Ads Grader app, browser, and email.
              </Text>
              <Row style={{ marginTop: '42px' }}>
                <Col span={20}>
                  <div style={{ display: 'flex' }}>
                    <Text
                      onClick={handlePreviousStepTour}
                      isBold
                      size={12}
                      className={Styles.backButton}
                      cursor
                    >
                      BACK
                    </Text>

                    <Button
                      onClick={handleNextTour}
                      className={Styles.nextButton}
                      style={{ marginLeft: '6px' }}
                    >
                      <Text
                        isBold
                        size={12}
                        style={{
                          color: 'white',
                          // marginTop: '2px',

                          alignItems: 'center',
                        }}
                      >
                        NEXT
                      </Text>
                    </Button>
                  </div>
                </Col>
                <Col span={4}>
                  <Text size={12} className={Styles.progressText}>
                    04/05
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <img src={guidedFlowImage} alt="img.png" style={{ minHeight: '135px' }} width={108} />
            </Col>
          </Row>
        </div>
      ),
      position: [830, 670],

      style: { backgroundColor: '#26282C' },
    },
    {
      selector: '.step-5',
      content: (
        <div className={Styles.mainContent}>
          <Text isBold size={14} style={{ color: 'white' }}>
            Request Your Needs 👌
          </Text>
          <Row>
            <Col span={16}>
              <Text size={14} className={Styles.textDetails}>
                Can&apos;t find what you need? Click to request instantly. We&apos;re here to help!
              </Text>

              <Row style={{ marginTop: '42px' }}>
                <Col span={20}>
                  <div style={{ display: 'flex' }}>
                    <Text
                      onClick={handlePreviousStepTour}
                      isBold
                      size={12}
                      className={Styles.backButton}
                      cursor
                    >
                      BACK
                    </Text>

                    <Button
                      onClick={handleEndStepTour}
                      className={Styles.nextButton}
                      Style={{ marginLeft: '6px' }}
                    >
                      <Text isBold size={12} style={{ color: 'white' }}>
                        END
                      </Text>
                    </Button>
                  </div>
                </Col>
                <Col span={4}>
                  <Text size={12} className={Styles.progressText}>
                    05/05
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <img src={guidedFlowImage} alt="img.png" style={{ minHeight: '135px' }} width={108} />
            </Col>
          </Row>
        </div>
      ),
      position: [1450, 200],
      style: { backgroundColor: '#26282C' },
    },
  ])

  // End of guided flow
  const [randerComponentState, setRanderComponentState] = useState(true)

  const [modalState, setModalState] = useState({
    isOpen: false,
    alertInfo: null,
  })

  const setModalOpen = (isOpen, alertInfo) => {
    setModalState({
      isOpen,
      alertInfo,
    })
  }

  const handleAlertRedirect = () => {
    // Your function logic goes here
    setRanderComponentState(true)
    setModalOpen(false)
  }

  const handleOk = () => {
    setRanderComponentState(false)
  }

  const handleCancel = () => {
    setIsTextModalOpen(false)
    setModalOpen(false)
  }
  const { Option } = Select
  const [alertStates, setAlertStates] = useState({
    budgetScaling: false,
    lowQualityKeywords: false,
    profitlessKeywords: false,
    monitorCostConversion: false,
    decreaseBid: false,
    competitorAlert: false,
  })
  const handleSwitchChange = (alertKey, checked) => {
    setAlertStates(prevStates => ({
      ...prevStates,
      [alertKey]: checked,
    }))
  }

  return (
    <>
      {randerComponentState ? (
        <div>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={Styles.HeadingContainer}>
                  <Text isBold className={Styles.Heading}>
                    Alerts Overview
                  </Text>
                  <div className={Styles.EllipseCustom} />
                  <Text className={Styles.SubHeading}>Available Alerts</Text>
                </div>
                <div className="step-5">
                  <div style={{ display: 'flex' }}>
                    <img
                      src={reLoad}
                      alt="reload.svg"
                      className={Styles.reloadImage}
                      width="20px"
                      height="20px"
                    />

                    <div>
                      <Text cursor className={Styles.newFormulaLink} onClick={hanldeFormulasModal}>
                        Request New Formulas
                      </Text>

                      {isTextModalOpen && (
                        <Modal
                          title={
                            <>
                              <div
                                style={{
                                  marginTop: '19px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center', // Center the content vertically
                                  textAlign: 'center',
                                }}
                              >
                                <Text isBold size={24} style={{ marginLeft: '253px' }}>
                                  Request New Formula
                                </Text>

                                <button
                                  type="button"
                                  style={{ color: 'white', background: 'white', border: 'none' }}
                                  onClick={handleCancel}
                                >
                                  <img
                                    src={crossIcon}
                                    alt="cross.svg"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </button>
                              </div>

                              <div style={{ textAlign: 'center', marginTop: '6px' }}>
                                <img src={FormulaVector} alt="vector.svg" width={80} />
                              </div>
                            </>
                          }
                          okText="SUBMIT"
                          cancelText="CANCEL"
                          width="740px"
                          height="640px"
                          okButtonProps={{
                            style: {
                              width: '200px',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              background: '#F16434',
                              borderColor: '#1890ff',
                              marginTop: '29px',
                              marginBottom: '30px',
                              transform: 'translateX(-72%)',
                            },
                          }}
                          cancelButtonProps={{
                            style: {
                              backgroundColor: 'white',
                              fontWeight: 'bold',
                              borderColor: '#A3B0B3',
                              border: '1px solid var(--Secondary-Gray, #A3B0B3)',
                              fontSize: '14px',
                              borderRadius: '2px',
                              width: '200px',
                              color: '#A3B0B3',
                              transform: 'translateX(-80%)',
                            },
                          }}
                          open={isTextModalOpen}
                          onCancel={handleCancel}
                        >
                          <div>
                            <Text size={14} isBold>
                              Give a name to this alert
                            </Text>
                            <Input
                              style={{ marginTop: '12px' }}
                              placeholder="Enter a name to this alert (i.e. Budget Scaling)"
                              value={formulasModalformData.name}
                              onChange={e => handleFormulasModalInputChange('name', e.target.value)}
                            />
                          </div>
                          <div style={{ marginTop: '24px' }}>
                            <Text size={14} isBold>
                              Importance of this alert
                            </Text>
                            <Input
                              style={{ marginTop: '12px' }}
                              placeholder="Enter the importance of this alert "
                              value={formulasModalformData.importance}
                              onChange={e =>
                                handleFormulasModalInputChange('importance', e.target.value)
                              }
                            />
                          </div>
                          <div style={{ marginTop: '24px' }}>
                            <Text size={14} isBold>
                              Describe the logic of this alert
                            </Text>
                            <TextArea
                              rows={3}
                              style={{ marginTop: '12px' }}
                              placeholder="Describe logic here... "
                              value={formulasModalformData.logic}
                              onChange={e =>
                                handleFormulasModalInputChange('logic', e.target.value)
                              }
                            />
                            <Text
                              size={14}
                              className={Styles.formulaText}
                              style={{ marginTop: '12px' }}
                            >
                              {`${remainingCharacters}/250 characters left`}
                            </Text>
                          </div>
                        </Modal>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <div className="step-1">
                <div className={Styles.container}>
                  <Avatar className={Styles.image} src={ProfileImg} />
                  <Text size={16} color="black" className={Styles.quoteContent}>
                    Make your Alerts super accurate by sharing your ad account data with us. Or, if
                    you prefer, use standard industry numbers for a quick overview.
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
          {/* Basic Alerts */}
          <Row style={{ marginTop: '40px', marginBottom: '32px' }}>
            <Col span={24}>
              <Text isBold size={20}>
                Basic Alerts
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <AlertCard
                tagName="Maximize ROI"
                alertName="Budget Scaling"
                alertDetail="Optimize campaign spending by notifying users on budget thresholds—avoid overspending or underutilization."
                alertImage={BudgetScaling}
                enabledAlert={alertStates.budgetScaling}
                onSwitchChange={checked => handleSwitchChange('budgetScaling', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Maximize ROI',
                    alertName: 'Budget Scaling',
                    alertDetail:
                      'Optimize campaign spending by notifying users on budget thresholds—avoid overspending or underutilization.',
                    alertImage: BudgetScaling,
                    enabledAlert: alertStates.budgetScaling,
                  })
                }
                step2="step-2"
                step3="step-3"
                step4="step-4"
              />
            </Col>

            <Col span={12}>
              <AlertCard
                tagName="Keywords Score"
                alertName="Low Quality Score Keywords"
                alertDetail="Instantly notify users of low-quality scores, ensuring prompt action on ad relevance and landing page issues."
                alertImage={LowQualityKeywrods}
                enabledAlert={alertStates.lowQualityKeywords}
                onSwitchChange={checked => handleSwitchChange('lowQualityKeywords', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Keywords Score',
                    alertName: 'Low Quality Score Keywords',
                    alertDetail:
                      'Instantly notify users of low-quality scores, ensuring prompt action on ad relevance and landing page issues.',
                    alertImage: LowQualityKeywrods,
                    enabledAlert: alertStates.lowQualityKeywords,
                  })
                }
              />
            </Col>
          </Row>
          {/* Advanced Alerts */}
          <Row style={{ marginTop: '40px', marginBottom: '32px' }}>
            <Col span={24}>
              <Text isBold size={20}>
                Advanced Alerts
              </Text>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <AlertCard
                tagName="Benchmark Keywords"
                alertName="Profitless Keywords"
                alertDetail="Instantly inform users when keywords yield clicks but no conversions, optimizing ad spend for better ROI."
                alertImage={ProfitLessKeyword}
                enabledAlert={alertStates.profitlessKeywords}
                onSwitchChange={checked => handleSwitchChange('profitlessKeywords', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Benchmark Keywords',
                    alertName: 'Profitless Keywords',
                    alertDetail:
                      'Instantly inform users when keywords yield clicks but no conversions, optimizing ad spend for better ROI.',
                    alertImage: ProfitLessKeyword,
                    enabledAlert: alertStates.profitlessKeywords,
                  })
                }
              />
            </Col>
            <Col span={12}>
              <AlertCard
                tagName="Cost Conversion"
                alertName="Monitor Cost/Conversion"
                alertDetail="Signal users when campaign conversions exceed target costs, ensuring cost-effectiveness in advertising efforts."
                alertImage={MinorCost}
                enabledAlert={alertStates.monitorCostConversion}
                onSwitchChange={checked => handleSwitchChange('monitorCostConversion', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Cost Conversion',
                    alertName: 'Monitor Cost/Conversion',
                    alertDetail:
                      'Signal users when campaign conversions exceed target costs, ensuring cost-effectiveness in advertising efforts.',
                    alertImage: MinorCost,
                    enabledAlert: alertStates.monitorCostConversion,
                  })
                }
              />
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: '24px', marginBottom: '24px' }}>
            <Col span={12}>
              <AlertCard
                tagName="Average CPC"
                alertName="Decrease a Bid"
                alertDetail="Adjust bids as CPC and clicks surpass limits with low conversions, optimizing cost-effectiveness."
                alertImage={DecreaseBid}
                enabledAlert={alertStates.decreaseBid}
                onSwitchChange={checked => handleSwitchChange('decreaseBid', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Average CPC',
                    alertName: 'Decrease a Bid',
                    alertDetail:
                      'Adjust bids as CPC and clicks surpass limits with low conversions, optimizing cost-effectiveness.',
                    alertImage: DecreaseBid,
                    enabledAlert: alertStates.decreaseBid,
                  })
                }
              />
            </Col>
            <Col span={12}>
              <AlertCard
                tagName="Ads Scoring"
                alertName="Competitor Alerts"
                alertDetail="Stay informed as competitors add or remove ad creatives, enabling swift strategic responses."
                alertImage={competitorAlert}
                enabledAlert={alertStates.competitorAlert}
                onSwitchChange={checked => handleSwitchChange('competitorAlert', checked)}
                onGetStartedClick={() =>
                  setModalOpen(true, {
                    tagName: 'Ads Scoring',
                    alertName: 'Competitor Alerts',
                    alertDetail:
                      'Stay informed as competitors add or remove ad creatives, enabling swift strategic responses.',
                    alertImage: competitorAlert,
                    enabledAlert: alertStates.competitorAlert,
                  })
                }
              />
            </Col>
          </Row>
          <div id="modal">
            <Modal
              okText="GET STARTED "
              cancelText="GO BACK"
              width="726px"
              okButtonProps={{
                style: {
                  fontWeight: 'bold',
                  fontSize: '14px',
                  background: '#F16434',
                  borderColor: '#1890ff',
                  marginTop: '40px',
                  marginBottom: '40px',
                  transform: 'translateX(-145%)',
                },
              }}
              cancelButtonProps={{
                style: {
                  backgroundColor: 'white',
                  fontWeight: 'bold',
                  borderColor: '#A3B0B3',
                  border: '1px solid var(--Secondary-Gray, #A3B0B3)',
                  fontSize: '14px',
                  borderRadius: '2px',
                  color: '#A3B0B3',
                  transform: 'translateX(-190%)',
                },
              }}
              open={modalState.isOpen}
              onOk={handleOk}
              onCancel={() => setModalOpen(false, null)}
            >
              {modalState.alertInfo && (
                <>
                  <div>
                    <Row>
                      <Col span={24}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Tag bordered={false} className={Styles.Tag}>
                            {modalState.alertInfo.tagName}
                          </Tag>
                          <button
                            type="button"
                            style={{ color: 'white', background: 'white', border: 'none' }}
                            onClick={handleCancel}
                          >
                            <img src={crossIcon} alt="cross.svg" style={{ cursor: 'pointer' }} />
                          </button>
                        </div>
                        <div style={{ marginRight: '92px', marginLeft: '10px' }}>
                          <Text isBold size={20} color="#000" style={{ marginBottom: '8px' }}>
                            {modalState.alertInfo.alertName}
                          </Text>
                          <Text className={Styles.AlertDetail}>
                            {modalState.alertInfo.alertDetail}
                          </Text>
                        </div>

                        <div className={Styles.DefaultFormula}>
                          <div style={{ marginLeft: '20px' }}>
                            <Text isBold size={16} style={{ marginTop: '10px' }}>
                              Default settings of the formula
                            </Text>
                            <div style={{ marginTop: '25px', position: 'relative' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={Styles.AND}>
                                  <Text direction="vertical" className={Styles.andText}>
                                    AND
                                  </Text>
                                </div>
                                <img
                                  src={modalAndLine}
                                  alt="icon.svg"
                                  height="47.7px"
                                  width="111.3px"
                                />
                              </div>
                              <div className={Styles.modalClicksCol}>
                                <RectangleComponent
                                  marginRight="11.71px"
                                  minWidth="122.246px"
                                  height="35.136px"
                                  border-radius="1.464px"
                                  border="0.732px solid var(--Secondary-Light-Gray, #D1D7D9)"
                                  background="var(--White-White, #FFF)"
                                >
                                  <div className={Styles.modalClicksText}>Clicks</div>
                                </RectangleComponent>
                                <div id="modalSelect">
                                  <Select
                                    // className="your-custom-height-class"
                                    size="small"
                                    style={{ width: '150.794px' }}
                                    maxTagCount="responsive"
                                  >
                                    <Option value="campaign1">
                                      Neil Patel Ad account (GMT - 05:00 / New York)
                                    </Option>
                                    <Option value="campaign2">
                                      Neil Patel Ad account (GMT - 05:00 / New York)
                                    </Option>
                                    <Option value="campaign3">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                    <Option value="campaign4">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                    <Option value="campaign5">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                  </Select>
                                </div>

                                <img
                                  src={rightIcon}
                                  alt="arrow"
                                  style={{ marginLeft: '11.71px', marginRight: '11.71px' }}
                                />
                                <RectangleComponent
                                  marginRight="11.71px"
                                  minWidth="122.246px"
                                  height="35.136px"
                                  border-radius="1.464px"
                                  border="0.732px solid var(--Secondary-Light-Gray, #D1D7D9)"
                                  background="var(--White-White, #FFF)"
                                />
                              </div>
                              <div className={Styles.modalConversionsCol}>
                                <RectangleComponent
                                  minWidth="122.246px"
                                  marginRight="11.71px"
                                  height="35.136px"
                                  border-radius="1.464px"
                                  border="0.732px solid var(--Secondary-Light-Gray, #D1D7D9)"
                                  background="var(--White-White, #FFF)"
                                >
                                  <div className={Styles.modalClicksText}>Conversions</div>
                                </RectangleComponent>
                                <div id="modalSelect">
                                  <Select
                                    size="small"
                                    height="20px"
                                    style={{ width: '150.794px' }}
                                    maxTagCount="responsive"
                                  >
                                    <Option value="campaign1">
                                      Neil Patel Ad account (GMT - 05:00 / New York)
                                    </Option>
                                    <Option value="campaign2">
                                      Neil Patel Ad account (GMT - 05:00 / New York)
                                    </Option>
                                    <Option value="campaign3">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                    <Option value="campaign4">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                    <Option value="campaign5">
                                      Neil Patel Ad account (GMT - 05:00 / New York){' '}
                                    </Option>
                                  </Select>
                                </div>

                                <img
                                  src={rightIcon}
                                  alt="arrow"
                                  style={{ marginLeft: '11.71px', marginRight: '11.71px' }}
                                />
                                <RectangleComponent
                                  marginRight="11.71px"
                                  minWidth="122.246px"
                                  height="35.136px"
                                  border-radius="1.464px"
                                  border="0.732px solid var(--Secondary-Light-Gray, #D1D7D9)"
                                  background="var(--White-White, #FFF)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ marginTop: '32px', marginLeft: '10px' }}>
                      <Text size={18}>Example notify a user when:</Text>

                      <Row gutter={20} style={{ marginTop: '32px' }}>
                        <Col span={8}>
                          <div className={Styles.ModalParent}>
                            <div className={Styles.wraperOrange}>
                              {' '}
                              <div className={Styles.ModalOrangeBox} />
                            </div>
                            <div className={Styles.ModalExampleContent}>
                              Clicks are less than 10% (warning) of the bench mark threshold number
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={Styles.ModalParent}>
                            <div className={Styles.wraperOrange}>
                              {' '}
                              <div className={Styles.ModalOrangeBox} />
                            </div>
                            <div className={Styles.ModalExampleContent}>
                              Clicks are less than 25% (critical) of the bench mark threshold number{' '}
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className={Styles.ModalParent}>
                            <div className={Styles.wraperOrange}>
                              {' '}
                              <div className={Styles.ModalOrangeBox} />
                            </div>
                            <div className={Styles.ModalExampleContent}>
                              AND, Conversion is less than 1 (0){' '}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              )}
            </Modal>
          </div>
          <button type="button" onClick={startTour}>
            Start Tour
          </button>
          <div>
            <Tour
              steps={steps}
              isOpen={isTourOpen}
              onRequestClose={closeTour}
              showCloseButton
              showNumber={false}
              showNavigation={false}
              prevButton={<Text style={{ display: 'none' }}>Back</Text>}
              nextButton={
                <Text
                  style={{
                    display: 'none',
                  }}
                >
                  Next
                </Text>
              }
              goToStep={currentStep}
              getCurrentStep={curr => setCurrentStep(curr)}
            />
          </div>
        </div>
      ) : (
        <FeatureSettings
          FeatureSetting={modalState.alertInfo}
          handleAlertRedirect={handleAlertRedirect}
        />
      )}
    </>
  )
}

export default AgAlertsOverView
