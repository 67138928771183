import React, { useState } from 'react'
import { Table, Space, Spin, Typography, List, Avatar, Button, Modal } from 'antd'
import { useSelector } from 'react-redux'
import { DeleteOutlined, ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { formatDate } from '@utils/helperFunctions'
import { notify } from '@utils/notify'
import { deleteInvitedUser } from '@services/deleteInvitedUser'

import './table.css'

const ManageUsersTable = ({ invitedUsersList, setInvitedUsersList, isLoading }) => {
  const [deleteLoading, setDeleteLoading] = useState({})
  const customerId = useSelector(state => state.customer?.id)
  const { t } = useTranslation()

  const handleDeleteLoading = id => {
    setDeleteLoading(pre => ({
      ...pre,
      [id]: !pre.id,
    }))
  }

  const closeModal = () => {}
  const handleDelete = async userId => {
    handleDeleteLoading(userId)
    try {
      const res = await deleteInvitedUser(userId, customerId)
      if (res.status === 204) {
        handleDeleteLoading(userId)
        notify(t('notification.userRemoved'), 'success')
        setInvitedUsersList(pre => pre.filter(item => item.id !== userId))
      } else {
        handleDeleteLoading(userId)
        notify(t('notification.somethingWentWrong'), 'error')
        // console.log('error while deleting user!', res)
      }
    } catch (e) {
      handleDeleteLoading(userId)
    }
  }
  const confirm = userId => {
    Modal.confirm({
      title: t('removeUserHeading'),
      icon: <ExclamationCircleOutlined />,
      // content: 'Are you sure, You want to remove the selected user?',
      content: t('removeUser'),
      okText: t('delete'),
      cancelText: t('cancel'),
      okButtonProps: { size: 'small' },
      cancelButtonProps: { size: 'small' },
      confirmLoading: deleteLoading[userId],
      onOk: () => handleDelete(userId),
      onCancel: closeModal,
    })
  }
  const handleDeleteIconClick = userId => {
    confirm(userId)
  }
  const columns = [
    {
      title: `${t('settings.manageUsers.name')}`,
      dataIndex: 'first_name',
      key: 'first_name',
      width: '30%',
      render: (_, { email, user }) => (
        <List.Item.Meta
          className="listItem"
          grid={{ gutter: 1 }}
          avatar={
            <UserOutlined
              style={{ border: '1px solid gray', borderRadius: '100%', padding: '5px' }}
            />
          }
          // eslint-disable-next-line
          title={(user?.first_name ?? '') + ' ' + (user?.last_name ?? '')}
          description={email}
        />
      ),
    },
    {
      title: `${t('settings.manageUsers.role')}`,
      dataIndex: 'role',
      key: 'role',
      // eslint-disable-next-line
      render: (_, { user, role_name }) => (
        <Typography.Text className="capitalize">
          {/* {role_name?.replace('_', ' ') ?? '-'} */}
          {t(role_name)}
        </Typography.Text>
      ),
    },
    {
      title: `${t('settings.manageUsers.lastLogin')}`,
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (_, { user }) => {
        const lastLoginDate = formatDate(user?.profile?.last_login)
        let lastLogin = '_'
        if (lastLoginDate) {
          const [month, date, year] = lastLoginDate.split(' ')
          const space = ' '
          lastLogin = t(month) + space + date + space + year
        }
        return <Typography.Text>{lastLogin}</Typography.Text>
      },
    },
    {
      title: `${t('settings.manageUsers.invitationStatus')}`,
      dataIndex: 'invitation',
      key: 'invitation',
      render: (_, { accepted }) => (
        // eslint-disable-next-line
        <Typography.Text>{accepted ? t('Accepted') : t('Pending')}</Typography.Text>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, user) => (
        <Space>
          {deleteLoading[user?.id] ? (
            <Spin />
          ) : (
            <DeleteOutlined
              onClick={() => handleDeleteIconClick(user?.id)}
              type="error"
              className="deleteIcon"
            />
          )}
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table
        dataSource={invitedUsersList}
        columns={columns}
        className="custom-table"
        pagination={false}
        loading={isLoading}
        rowKey={item => item.id}
      />
    </>
  )
}

export default ManageUsersTable
