import React from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PUBLISHED_VERSION } from '@config/enviroment'
import GooglePartnerImg from '@images/google-partner-badge.png'
import Text from '../Text/text'

import styles from './footer.module.scss'

const TermsFooter = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.container}>
      <div className={styles.footerContainer}>
        <Row justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
            <div className={styles.footerTitleContainer}>
              <Text size={18} isUppercase isBold>
                {t('footer.safeSecure')}
              </Text>
            </div>
            <Text size={16} className={styles.footerDesc}>
              {t('footer.description')}
            </Text>
            <Text size={14} className={styles.copyright}>
              {t('home.footerSection.copyText')}
              <a href="https://npdigital.com" rel="noreferrer" target="_blank">
                <span className={styles.npDigital}>
                  {t('home.footerSection.copyTextNpDigital')}
                </span>
              </a>
              <br />
              <Text size={12} color="secondary">
                {PUBLISHED_VERSION}
              </Text>
            </Text>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
            {/* <div className={styles.footerImageContainer}>
              <img src={GooglePartnerImg} className={styles.footerImage} alt="google" />
            </div> */}
            <div className={styles.subFooter}>
              {/* <Text size={14}>
            {t('home.footerSection.copyText')}
            <br />
            <Text size={12} color="secondary">
              {PUBLISHED_VERSION}
            </Text>
          </Text> */}
              <div className={styles.footerImageContainer}>
                <img src={GooglePartnerImg} className={styles.footerImage} alt="google" />
              </div>

              <div
                className={styles.policiesRow}
                style={{ marginRight: window.location.pathname === '/start' && '5rem' }}
              >
                <Text size={14} style={{ color: 'gray' }}>
                  {t('home.footerSection.termsText')}
                </Text>

                <Link to="/privacy" target="_blank">
                  <Text size={14} color="primary" style={{ marginLeft: '12px' }}>
                    {t('home.footerSection.policyText')}
                  </Text>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        {/* <br /> */}
      </div>
    </footer>
  )
}

TermsFooter.propTypes = {}

TermsFooter.defaultProps = {}

export default TermsFooter
