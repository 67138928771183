import { UPDATE_REPORT } from '@utils/constants'

const initialState = null

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REPORT:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
