import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import googleG from '@images/google-g-logo.svg'
import googleGDisabled from '@images/google-g-logo-disabled.svg'
import styles from './styles.module.scss'

const SignInWithGoogleButton = ({ onClick, disabled }) => {
  const { t } = useTranslation()
  return (
    <button type="button" onClick={onClick} className={styles.googleBtn}>
      <div className={styles.googleIconWrapper}>
        <img className={styles.googleIcon} src={googleG} alt="Google Brand" />
        <img
          className={styles.googleIconDisabled}
          src={googleGDisabled}
          alt="Google Brand Disabled"
        />
      </div>
      <p className={styles.btnText}>{t('login.siginWithGoogle')}</p>
    </button>
  )
}

SignInWithGoogleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default SignInWithGoogleButton
