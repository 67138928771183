import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const NotFound = () => {
  const history = useHistory()

  useEffect(() => {
    history.push('/')
  })
  return <></>
}
export default NotFound
