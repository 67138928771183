import React, { useState } from 'react'
import {
  Col,
  Row,
  Switch,
  Card,
  Divider,
  Tag,
  Progress,
  Table,
  Radio,
  Select,
  Menu,
  Button,
} from 'antd'
import RectangleComponent from '@components/Rectangle'
import { Text } from '@components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import dolarFinancials from '../../../images/Alerts/DollarFinancials.svg'
import informationIcon from '../../../images/Alerts/informationIcon.svg'
import rightArrow from '../../../images/Arrow Right - White.svg'
import neilPetailImage from '../../../images/Alerts/NeilPetelGroup.png'
import reLoad from '../../../images/Alerts/reloader.svg'
import straingtLine from '../../../images/Alerts/straightLineVector.svg'
import isGreater from '../../../images/Alerts/is-greater-than.svg'
import stroke from '../../../images/Alerts/stroke.svg'
import styles from './AgAlertsSetupEdit.module.css'

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}
const items = [
  getItem(
    'Bi Weekly',

    'sub1',
    <div
      style={{
        // color: 'black',
        width: '12px',
        height: '12px',
        background: 'gray',
        borderRadius: '2px',
      }}
    >
      .
    </div>,
    [
      getItem('Item 1', 'g1', null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
      getItem('Item 2', 'g2', null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
    ]
  ),
]
const AgAlertsSetupEdit = ({ FeatureSetting, handleAlertRedirect }) => {
  const { Option } = Select

  const onChange = (checked: boolean) => {
    // console.log(`switch to ${checked}`)
  }

  const [radioValue, setRadioValue] = useState(1)
  const radioChange = e => {
    setRadioValue(e.target.value)
  }
  const onClick = e => {}

  const data = [
    {
      key: '1',
      name: 'Black Friday Sale',
      tags: ['Good'],
      totalSpent: 1000,
      cpc: 0.5,
      ctr: 2.5,
      convRate: 1.2,
      roi: 30.43,
    },
    {
      key: '2',
      name: 'Flash Sales',
      tags: ['Profitless'],
      totalSpent: 800,
      cpc: 0.4,
      ctr: 3.0,
      convRate: 1.5,
      roi: 75.24,
    },
    // Add more data as needed
  ]

  const columns = [
    {
      title: 'Keywords',
      dataIndex: 'name',
      key: 'name',
      width: '580px',
      render: text => (
        <Text isBold size={16}>
          {text}
        </Text>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      // width: '52px',
      render: tags => (
        <>
          {tags.map(tag => (
            <Tag
              key={tag}
              style={{
                textAlign: 'center',
                border: 'none',
                borderRadius: '4px',
                fontSize: '14px',
                fontWeight: 'bold',
                // padding: '7px 8px',
                lineHeight: '26.029px',
                color: tag === 'Good' ? '#00A862' : '#E53435',
                background: tag === 'Good' ? '#E0FAEF' : '#FFDEDE',
                width: tag === 'Good' ? '52px' : '72px',
                // margin: '7px',
                // height: '24px',
              }}
            >
              {tag}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Total Spent',
      dataIndex: 'totalSpent',
      key: 'totalSpent',
      sorter: (a, b) => a.totalSpent - b.totalSpent,
      sortDirections: ['ascend', 'descend'],
      render: totalSpent => <Text size={16}>${totalSpent}</Text>,
      defaultSortOrder: 'descend', // You can set it to 'descend' for default descending order
    },
    {
      title: 'CPC ($)',
      dataIndex: 'cpc',
      key: 'cpc',
      render: cpc => <Text size={16}>${cpc.toFixed(2)}</Text>,
    },
    {
      title: 'CTR (%)',
      dataIndex: 'ctr',
      key: 'ctr',
      render: ctr => <Text size={16}>${ctr.toFixed(2)}%</Text>,
    },
    {
      title: 'Conv. Rate (%)',
      dataIndex: 'convRate',
      key: 'convRate',
      render: convRate => <Text size={16}>${convRate.toFixed(2)}%</Text>,
    },
    {
      title: 'ROI (%)',
      dataIndex: 'roi',
      key: 'roi',
      render: roi => <Text size={16}>${roi.toFixed(2)}%</Text>,
    },
  ]

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Text size={16} className={styles.alert} onClick={handleAlertRedirect} cursor>
          Alerts
        </Text>
        <div className={styles.slash}> /</div>
        <Text className={styles.profitless}>{FeatureSetting.alertName}</Text>
      </div>
      <div style={{ display: 'flex', marginTop: '44.5px' }}>
        <Text isMediumBold size={22} className={styles.orangeProfitless}>
          {FeatureSetting.alertName}
        </Text>

        <img src={stroke} alt="stroke.svg" height="24px" />
        <Switch className={styles.toggle} defaultChecked onChange={onChange} handleBg="red" />
        <Text className={styles.alertsEnable}>Alerts Enable</Text>
      </div>

      <Row style={{ marginTop: '24px' }}>
        <Col span={12}>
          <Card style={{ height: '175px' }}>
            <Row>
              <Col span={10}>
                <div style={{ display: 'flex' }}>
                  <img src={dolarFinancials} alt="Alerts-icon" className={styles.dollarImage} />
                  <Text size={16} color="#000">
                    Profitless Keywords
                  </Text>
                  <img
                    src={informationIcon}
                    alt="Alerts-icon"
                    className={styles.informationImage}
                  />
                  {/* <Divider /> */}
                </div>
                <div style={{ display: 'flex' }} className={styles.numbersClass}>
                  <Text isBold className={styles.numberClassFirst}>
                    800
                  </Text>
                  <Text isBold className={styles.numberClassSecond}>
                    /1,294
                  </Text>
                </div>
              </Col>

              <Col span={14}>
                <div id="progressBar">
                  <Progress
                    percent={100}
                    successPercent={20}
                    showInfo={false}
                    strokeColor="#FE8485"
                    strokeWidth="20px"
                    strokeLinecap="butt"
                  />
                </div>

                <Row style={{ marginTop: '41px' }}>
                  <Col span={14}>
                    <div style={{ display: 'flex' }}>
                      <div className={styles.goodKeyWordsRectangle} />
                      <Text className={styles.goodKeyWords}>Good Keywords</Text>
                    </div>
                    <Text isBold size={14} style={{ marginTop: '6px' }}>
                      494
                    </Text>
                  </Col>
                  <Col span={10}>
                    <div style={{ display: 'flex' }}>
                      <div className={styles.goodKeyWordsProfitLess} />
                      <Text className={styles.goodKeyWords}>Profitless</Text>
                    </div>
                    <Text isBold size={14} style={{ marginTop: '6px' }}>
                      800
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ background: '#FFFAF9' }}>
            <Row>
              <Col span={15}>
                <Text isBold size={24}>
                  Looking for a custom <span style={{ color: '#FF5E29' }}>alerts?</span>
                </Text>
                <Text size={14} style={{ marginTop: '20px' }}>
                  Elevate your business with a personalized formula!
                </Text>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <Text className={styles.requestNewFormulas} size={18}>
                    Request New Formulas
                  </Text>
                  <img src={rightArrow} alt="Alerts-icon" />
                </div>
              </Col>
              <Col span={9}>
                <img src={neilPetailImage} alt="Alerts-icon" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Start of Table */}
      <Card>
        <div className={styles.cardHeaderSetting}>
          <div style={{ display: 'flex' }}>
            <Text isBold size={18}>
              Keywords Performance
            </Text>
            <img src={informationIcon} alt="Alerts-icon" className={styles.informationImage} />
          </div>
          <Text size={12} className={styles.lastDaysSpent}>
            {' '}
            Last 30 days of spend
          </Text>
        </div>
        <div style={{ marginTop: '33.5px' }}>
          <div id="table">
            <Table columns={columns} dataSource={data} />
          </div>
        </div>
      </Card>
      {/* End of Table */}

      {/* Stat of Notifications Card */}
      <div style={{ marginTop: '45px' }}>
        <div className={styles.cardHeaderSetting} style={{ background: 'background: #F9FAFB' }}>
          <Text className={styles.notificationLevels}>Notification level</Text>
          <div style={{ display: 'flex' }}>
            <img src={reLoad} alt="reload.svg" className={styles.reloadImage} />
            <Text size={10} className={styles.restoreToDefault}>
              Restore to Default Settings
            </Text>
          </div>
        </div>

        <Card style={{ marginTop: '16px' }}>
          <Row>
            <Col span={6} style={{ marginRight: '50px' }}>
              <Text isBold size={18}>
                Select Level
              </Text>

              <Radio.Group
                onChange={radioChange}
                value={radioValue}
                size="large"
                style={{ marginTop: '27px' }}
              >
                <Radio value={1}>Account Level</Radio>
                <Radio value={2}>Campaign Level</Radio>
              </Radio.Group>
              <Text size={14} className={styles.stayUpdated}>
                Gain strategic insights with real-time updates at the account level.
              </Text>
            </Col>

            <Col span={7}>
              <Text isSemibold size={14} style={{ marginTop: '57px' }}>
                Select a Account
              </Text>
              {/* it has verions issue thats why the arrow is now showing */}
              <Select
                mode="multiple"
                maxTagCount="responsive"
                style={{ width: '100%', marginTop: '12px' }}
              >
                <Option value="campaign1">Campaign name xyz</Option>
                <Option value="campaign2">Campaign name abc</Option>
                <Option value="campaign3">Campaign name hak</Option>
                <Option value="campaign4">Campaign name def</Option>
                <Option value="campaign5">Campaign name ijh</Option>
              </Select>
            </Col>
            <Col span={1} style={{ textAlign: 'center', marginTop: '30px' }}>
              <img src={straingtLine} alt="vetcor" />
            </Col>
            <Col span={7}>
              <Text size={14} style={{ marginTop: '57px' }}>
                Timezone
              </Text>

              <Select style={{ width: '100%', marginTop: '12px' }} maxTagCount="responsive">
                <Option value="campaign1">Neil Patel Ad account (GMT - 05:00 / New York)</Option>
                <Option value="campaign2">Neil Patel Ad account (GMT - 05:00 / New York)</Option>
                <Option value="campaign3">Neil Patel Ad account (GMT - 05:00 / New York) </Option>
                <Option value="campaign4">Neil Patel Ad account (GMT - 05:00 / New York) </Option>
                <Option value="campaign5">Neil Patel Ad account (GMT - 05:00 / New York) </Option>
              </Select>
            </Col>
          </Row>
        </Card>
        {/* End of Notifications Card */}
      </div>

      {/* Start Of Rule Settings */}
      <div style={{ marginTop: '45px' }}>
        <div className={styles.cardHeaderSetting} style={{ background: 'background: #F9FAFB' }}>
          <Text className={styles.notificationLevels}>Rules Settings</Text>
          <div style={{ display: 'flex' }}>
            <img
              src={reLoad}
              alt="reload.svg"
              className={styles.reloadImage}
              width="20px"
              height="20px"
            />
            <Text size={10} className={styles.restoreToDefault}>
              Restore to Default Settings
            </Text>
          </div>
        </div>

        <Card style={{ marginTop: '16px' }}>
          <Row>
            <Col span={1} className={styles.and}>
              <Text direction="vertical">AND</Text>
            </Col>

            <Col span={6}>
              <Text>Metrics</Text>
              <RectangleComponent
                border="1px solid var(--secondary-tints-light-gray-70, #DFE3E4)"
                width="328px"
                height="48px"
                color="black"
                backgroundColor="#F9FBFF"
                marginTop="12px"
              >
                <div className={styles.orangeDot}>
                  {' '}
                  <div className={styles.clicks} />
                  Clicks
                </div>
              </RectangleComponent>

              <RectangleComponent
                border="1px solid var(--secondary-tints-light-gray-70, #DFE3E4)"
                width="328px"
                height="48px"
                color="black"
                backgroundColor="#F9FBFF"
                marginTop="12px"
              >
                <div className={styles.blueDot}>
                  {' '}
                  <div className={styles.conersion} />
                  Conversion
                </div>
              </RectangleComponent>
            </Col>
            <Col span={9}>
              <Text>
                Period <span className={styles.periodSpan}>(we suggest no less than a week)</span>
              </Text>
              <div id="menuSelect">
                <div style={{ marginTop: '12px' }}>
                  <Menu
                    // onOpenChange={}
                    mode="inline"
                    // inlineCollapsed="false"

                    onClick={onClick}
                    style={{
                      // width: 256,
                      border: '1px solid #D1D7D9',
                    }}
                    defaultSelectedKeys={['1']}
                    // defaultOpenKeys={[]}
                    items={items}
                  />
                </div>
                <div style={{ marginTop: '12px' }}>
                  <Menu
                    mode="inline"
                    onClick={onClick}
                    style={{
                      // width: 256,
                      border: '1px solid #D1D7D9',
                    }}
                    defaultSelectedKeys={['1']}
                    // defaultOpenKeys={[]}
                    items={items}
                  />
                </div>
              </div>
            </Col>
            <Col span={1}>
              <div style={{ marginTop: '50px', marginLeft: '24px', marginRight: '24px' }}>
                <img src={isGreater} alt="ok" />
              </div>
              <div style={{ marginTop: '28px', marginLeft: '24px', marginRight: '24px' }}>
                <img src={isGreater} alt="ok" />
              </div>
            </Col>

            <Col span={6}>
              <Text>Your Goal</Text>
              <RectangleComponent
                border="1px solid var(--secondary-tints-light-gray-70, #DFE3E4)"
                width="328px"
                height="48px"
                color="black"
                backgroundColor="#F9FBFF"
                marginTop="12px"
              >
                <div className={styles.goalPercentage}>
                  %
                  <div />
                  <div className={styles.goalPercentageNumber}>10%</div>
                </div>
              </RectangleComponent>
              <RectangleComponent
                border="1px solid var(--secondary-tints-light-gray-70, #DFE3E4)"
                width="328px"
                height="48px"
                color="black"
                backgroundColor="#F9FBFF"
                marginTop="12px"
              >
                <div className={styles.goalPercentageNumber2}>1</div>
              </RectangleComponent>{' '}
            </Col>
          </Row>
          <div style={{ marginTop: '32px' }}>
            <Text isBold size={18}>
              Example
            </Text>
            <Text size={14} isBold className={styles.pauseAds}>
              Pause (Ad set) if
            </Text>
            <div style={{ display: 'flex', marginTop: '13px' }}>
              <div className={styles.exampleLine} />

              <Text size={14}>
                Spend Last 3 days (incl. today) <RightOutlined style={{ fontSize: '12px' }} /> $30
                and <br /> Purchase Last 3 days (incl. today){' '}
                <LeftOutlined style={{ fontSize: '12px' }} /> 1
              </Text>
            </div>
          </div>
          <Divider />
          <div style={{ display: 'flex', marginTop: '77px', justifyContent: 'space-between' }}>
            <Text className={styles.saveAsDraft}>Save as draft</Text>
            <div style={{ justifyContent: 'space-between' }}>
              <Button
                type="text"
                className={styles.cancel}
                // style={{ border: 'none', fontSize: '14px' }}
              >
                CANCEL
              </Button>
              <Button className={styles.saveAndPublish} type="primary">
                SAVE & PUBLISH
              </Button>
            </div>
          </div>
        </Card>
      </div>
      {/* End of Rule Settings */}
    </>
  )
}

export default AgAlertsSetupEdit
