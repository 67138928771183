import { UPDATE_ERROR_CUSTOMER } from '@utils/constants'

const initialState = {
  errorCustomer: false,
}

export const errorCustomer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ERROR_CUSTOMER:
      return action.payload
    default:
      return state
  }
}
