import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import { FaQuestionCircle } from 'react-icons/fa'

import styles from './ctacard.module.scss'
import Text from '../Text/text'

const CTACard = ({ value, title, tooltip, onCtaClick, text, buttonText, hiddenButton, color }) => (
  <div className={styles.containerDiv}>
    <div className={styles.body}>
      {value}
      <div className={styles.titleDiv}>
        <Text color="black" size={16} isTitle isBold isUppercase>
          {title}
        </Text>

        <Tooltip
          className={styles.tooltipText}
          placement="top"
          color={color}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          title={
            <Text color="white" size={14}>
              {tooltip}
            </Text>
          }
        >
          <FaQuestionCircle />
        </Tooltip>
      </div>
      <div className={styles.textDiv}>{text}</div>
    </div>
    {!hiddenButton && (
      <Button onClick={onCtaClick} className={styles.consultantButton} type="primary">
        {buttonText}
      </Button>
    )}
  </div>
)

CTACard.propTypes = {
  value: PropTypes.node,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  text: PropTypes.node,
  buttonText: PropTypes.string,
  onCtaClick: PropTypes.func,
  hiddenButton: PropTypes.bool,
}

CTACard.defaultProps = {
  value: '',
  title: '',
  tooltip: '',
  text: '',
  buttonText: '',
  hiddenButton: false,
  onCtaClick: () => {},
}

export default CTACard
