import React, { useState } from 'react'
import { Text } from '@components'
import styles from './noData.module.css'
import noDataImage from '../../../../images/AdsPreview/previewThumbnail.png'
import arrowIcon from '../../../../images/AdsPreview/arrowIcon.svg'

const NoData = () => {
  const [temp, setTemp] = useState()
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        {' '}
        <div style={{ display: 'flex', justifyContent: 'center', gap: 24 }}>
          <div style={{ marginTop: '92px', textAlign: 'center' }}>
            <img src={noDataImage} alt="thumnail.png" width={496} />
          </div>
          <div style={{ marginLeft: 24, maxWidth: 496 }}>
            <div style={{ marginTop: 24, textAlign: 'end' }}>
              <img src={arrowIcon} alt="arrowicon.svg" />
            </div>
            <div style={{}}>
              {' '}
              <Text size={24} isBold style={{ marginTop: 23, textAlign: 'left', color: '#26282C' }}>
                Ads Grader offers Ads transparency for a safe & open internet
              </Text>
            </div>
            <div style={{ marginTop: 32 }}>
              <Text size={16} style={{ color: '#67696B', textAlign: 'left' }}>
                Search all of the ads currently running across all advertising channels.{' '}
              </Text>
              <Text size={16} className={styles.noDataText}>
                Ads that have run anywhere in the past years.
              </Text>{' '}
              <Text size={16} className={styles.noDataText}>
                To find an ad, search for the correct keywords, an advertiser or a verified company
                name.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default NoData
