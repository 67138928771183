import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styles from './performanceCard.module.scss'
import Text from '../Text/text'

const PerformanceCard = ({
  titleText,
  percentageNumber,
  buttonText,
  disclaimerText,
  onClick,
  hiddenButton,
}) => {
  const percentageColor = percentage => {
    if (percentage <= 40) {
      return 'percentage1'
    }
    if (percentage < 65) {
      return 'percentage2'
    }
    return 'percentage3'
  }
  return (
    <div className={!hiddenButton ? styles.container : styles.lowContainer}>
      <div className={styles.titleDiv}>
        <Text size={24} color="black" isUppercase>
          {titleText}
        </Text>
      </div>

      <div className={styles.percentageDiv}>
        <Text color={percentageColor(percentageNumber)} isTitle isBold size={150}>
          {Number(percentageNumber) < 0 ? 0 : percentageNumber}
          <span>%</span>
        </Text>
        <div className={styles.progressBar}>
          <div
            className={classNames([[styles[`progress-${percentageColor(percentageNumber)}`]]])}
            style={{ height: 14, width: percentageNumber * 2.7, borderRadius: 16 }}
          />
        </div>
      </div>
      {!hiddenButton ? (
        <div className={styles.footer}>
          <Button onClick={onClick} className={styles.consultantButton} type="primary">
            {buttonText}
          </Button>
          <Text color="white" size={16}>
            {disclaimerText}
          </Text>
        </div>
      ) : (
        <div className={styles.range}>
          <div />
        </div>
      )}
    </div>
  )
}

PerformanceCard.propTypes = {
  titleText: PropTypes.string,
  percentageNumber: PropTypes.node,
  buttonText: PropTypes.string,
  disclaimerText: PropTypes.string,
  onClick: PropTypes.func,
  hiddenButton: PropTypes.bool,
}

PerformanceCard.defaultProps = {
  titleText: '',
  percentageNumber: '',
  buttonText: '',
  disclaimerText: '',
  hiddenButton: false,
  onClick: () => {},
}

export default PerformanceCard
