import { Text } from '@components'
import AdsCard from '@components/DiscoverAds/AdsCard'
import SearchBar from '@components/DiscoverAds/SearchBar'
import AdsCounter from '@components/DiscoverAds/AdsCounter'
import { RequestService } from '@services/requests'
import AdsPreviewFeature from '@pages/google-ads-preview/ads-preview-feature'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import NoData from '@components/GoogleAdsPreview/ErrorStates/NoData'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ADS_DATA,
  ADS_LOADER,
  TEMP_COUNT,
  HAS_MORE,
  ADS_PAGINATION,
  CLEANED_DATA_COUNT,
  ADS_CHANNEL,
  CLEAR_BRANDS_FLITER,
  MORE_DATA_LOADER,
  API_DATA_BOARDS_LIST,
  ADS_FORMAT,
  ADS_HOME_STATE,
  ADS_LOCATION,
  EMPTY_ADS_PREVIEW_DATA,
  SEARCH_SUGGESTIONS,
} from '@utils/constants'
import CategoryButton from '@components/DiscoverAds/CategoryButton'
import SearchResultsError from '@components/GoogleAdsPreview/ErrorStates/SearchResultsError'
import { Spin } from 'antd'
import styled from 'styled-components'
import metaStyles from './discoverAdsFeature.module.css'

const DiscoverAdsFeature = () => {
  const [newDataSpin, setNewDataSpin] = useState(false)
  const dispatch = useDispatch()
  const apiDataBoardsList = useSelector(state => state?.discoverAds?.apiDataBoardsList)
  const isMetaStatus = useSelector(state => state?.discoverAds?.isMetaStatus)
  const isGoogleStatus = useSelector(state => state?.discoverAds?.isGoogleStatus)
  const [boardsList, setBoardsList] = useState(apiDataBoardsList)
  const [filteredDiscoverData, setFilteredDiscoverData] = useState([])
  const [dataForBrands, setDataForBrands] = useState([])
  const selectedChannel = useSelector(state => state?.discoverAds?.channels)
  const homeState = useSelector(state => state?.discoverAds?.homeState)
  const adsPreviewData = useSelector(state => state?.discoverAds?.adsPreviewData)
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const adsPagination = useSelector(state => state?.discoverAds?.adsPagination)
  const hasMore = useSelector(state => state?.discoverAds?.hasMore)
  const boards = useSelector(state => state?.discoverAds?.boards)
  const loading = useSelector(state => state?.discoverAds?.loading)
  const tempCount = useSelector(state => state?.discoverAds?.tempCount)
  const adsNoData = useSelector(state => state?.discoverAds?.adsNoData)
  const brandsFilter = useSelector(state => state?.discoverAds?.brandsFilter)
  const searchSuggestions = useSelector(state => state?.discoverAds?.searchSuggestions)
  useEffect(() => {
    dispatch({
      type: ADS_CHANNEL,
      payload: 'Google',
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (adsPreviewData.length > 0) {
      // Remove duplicates based on _id
      const uniqueAds = adsPreviewData.reduce((acc, current) => {
        // eslint-disable-next-line
        const duplicate = acc.find(
          item => item.additional_info?.page_name === current.additional_info?.page_name
        )
        if (!duplicate) {
          acc.push(current)
        }
        return acc
      }, [])

      setDataForBrands(uniqueAds)
      const uniqueAdss = adsPreviewData.reduce((acc, current) => {
        // Skip if both ads_grader_video_url and ads_grader_image_url are null or empty
        if (
          (current.ads_grader_video_url === null || current.ads_grader_video_url === '') &&
          (current.ads_grader_image_url === null || current.ads_grader_image_url === '')
        ) {
          return acc
        }

        // Check if an item with the same ads_grader_video_url or ads_grader_image_url exists in the accumulator
        const duplicateByVideo = acc.find(
          item =>
            item.ads_grader_video_url && item.ads_grader_video_url === current.ads_grader_video_url
        )

        const duplicateByImage =
          !duplicateByVideo &&
          acc.find(
            item =>
              item.ads_grader_image_url &&
              item.ads_grader_image_url === current.ads_grader_image_url
          )

        // Push to accumulator only if no duplicates are found
        if (!duplicateByVideo && !duplicateByImage) {
          acc.push(current)
        }

        return acc
      }, [])

      setFilteredDiscoverData(uniqueAdss)
    } else if (adsPreviewData.length === 0) {
      setFilteredDiscoverData([])
    }
    // eslint-disable-next-line
  }, [adsPreviewData])
  const hanldeDiscoverAdsBoard = async () => {
    try {
      const response = await RequestService.get('/create/boards/')
      dispatch({ type: API_DATA_BOARDS_LIST, payload: response.data })
      setBoardsList(response?.data)
    } catch (error) {
      // console.log('🚀 ~ hanldeDiscoverAdsBoard ~ error:', error)
    }
  }

  useEffect(() => {
    hanldeDiscoverAdsBoard()
    // eslint-disable-next-line
  }, [selectedChannel])

  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleShowModal = index => {
    setSelectedIndex(index)
  }

  const handlePrevious = () => {
    setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : filteredDiscoverData.length - 1))
  }

  const handleNext = () => {
    setSelectedIndex(prevIndex => (prevIndex < filteredDiscoverData.length - 1 ? prevIndex + 1 : 0))
  }

  const handleCategoryClick = selectedCategoryName => {
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: true })
    setFilteredDiscoverData(
      filteredDiscoverData.filter(item => item?.additional_info?.page_name === selectedCategoryName)
    )
    setDataForBrands(
      dataForBrands.filter(item => item?.additional_info?.page_name === selectedCategoryName)
    )
  }

  const fetchMoreData = async () => {
    // if (selectedChannel === 'Meta') {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    try {
      const previousDataLength = filteredDiscoverData.length
      setNewDataSpin(true)
      let response = []
      if (selectedChannel === 'Meta') {
        if (isMetaStatus) {
          response = await RequestService.get(
            `/ads-preview/competitors-prod/?next_url_meta=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isMetaStatus) {
          response = await RequestService.get(`${adsPagination}`)
        }
      } else if (selectedChannel === 'Google') {
        if (isGoogleStatus) {
          response = await RequestService.get(
            `/ads-preview/competitors-prod/?next_url=${encodeURIComponent(
              adsPagination
            )}&channels=[${selectedChannel}]&search_term=${searchKeyword}`
          )
        } else if (!isGoogleStatus) {
          response = await RequestService.get(`${adsPagination}`)
        }
      }

      // dispatch({ type: ADS_LOADER, payload: false })
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      const formattedData = response?.data.result?.facebook_ads
      if (formattedData?.data?.length > 0) {
        dispatch({
          type: ADS_DATA,
          payload: formattedData?.data,
        })
      }
      if (formattedData?.data?.length > 0) {
        dispatch({ type: TEMP_COUNT, payload: tempCount + 20 })
      }
      const newItemsLength = formattedData?.data.length

      // Extract only the new items using slicing
      const newItems = filteredDiscoverData.slice(
        previousDataLength,
        previousDataLength + newItemsLength
      )

      // You can now use `newItems` to print or log the newly fetched ads
      if (newItems.length > 0) {
        setFilteredDiscoverData(newItems)
      }
      if (selectedChannel === 'Meta') {
        if (formattedData.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData.paging?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      } else if (selectedChannel === 'Google') {
        if (response?.data.result?.facebook_ads?.serpapi_pagination?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: response?.data.result?.facebook_ads?.serpapi_pagination?.next,
          })
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
      }
    } catch (error) {
      setNewDataSpin(false)
      dispatch({ type: MORE_DATA_LOADER, payload: false })
      // console.log(error)
      // dispatch({ type: SPINNER, payload: false })
      dispatch({ type: ADS_LOADER, payload: false })
      // dispatch({ type: ADS_NO_DATA, payload: true })
    }
    // }
  }

  // start of Google Ads data
  const cleanedDataCount = useSelector(state => state?.adsPreview?.cleanedDataCount)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const adsDatas = useSelector(state => state?.adsPreview?.adsPreviewData)

  const filterAndSaveData = () => {
    const cleanedData = adsDatas.filter(data => {
      if (data.format === 'image' || data.format === 'text') {
        return data.image
      } // eslint-disable-next-line
      else {
        return data.video_link
      }
    })
    const uniqueImagesOrIframes = new Set(cleanedData.map(data => data.image || data.iframe_url))
    const uniqueData = Array.from(uniqueImagesOrIframes).map(item =>
      cleanedData.find(data => data.image === item || data.iframe_url === item)
    )

    if (cleanedData.length < 100 && adsFormats !== 'Videos' && cleanedDataCount < 100) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    } else if (adsFormats === 'Videos' && cleanedDataCount < 20) {
      dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedData.length })
    }
  }

  useEffect(() => {
    if (adsDatas[0]?.advertiser) {
      filterAndSaveData()
    }
    // eslint-disable-next-line
  }, [adsDatas])
  // end of google ads data
  const [showButton, setShowButton] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [showNextButton, setShowNextButton] = useState(false)
  const containerRef = useRef(null)

  const checkScrollPosition = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
      setShowBackButton(scrollLeft > 0)
      setShowNextButton(scrollLeft < scrollWidth - clientWidth - 1) // -1 for rounding errors
    }
  }

  useEffect(() => {
    checkScrollPosition()
    window.addEventListener('resize', checkScrollPosition)
    return () => window.removeEventListener('resize', checkScrollPosition)
  }, [filteredDiscoverData]) // Re-check when data changes

  const scroll = direction => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth / 2
      containerRef.current.scrollBy({
        left: direction === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      })
    }
  }
  const handleClearFilters = () => {
    dispatch({ type: CLEAR_BRANDS_FLITER, payload: false })
    if (adsPreviewData.length > 0) {
      const uniqueAds = adsPreviewData.reduce((acc, current) => {
        // eslint-disable-next-line
        const duplicate = acc.find(
          item => item?.additional_info?.page_name === current?.additional_info?.page_name
        )
        if (!duplicate) {
          acc.push(current)
        }
        return acc
      }, [])
      setDataForBrands(uniqueAds)
      setFilteredDiscoverData(adsPreviewData)
    } else if (adsPreviewData.length === 0) {
      setFilteredDiscoverData([])
    }
  }

  const ButtonContainer = styled.span`
    position: fixed;
    bottom: 32px;
    right: 32px;
    transform: translate(50%, 50%);
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 1;
    background: #f16434;
    border-radius: 5rem;
    // transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
    display: 'flex';
    // &:hover {
    //   opacity: 1;
    // }
  `

  const scrollToTop = () => {
    const targetDiv = document.getElementById('report')

    // if (targetDiv) {
    targetDiv.scrollIntoView({ behavior: 'smooth' })
    setShowButton(false)
    // }
  }

  window.addEventListener('wheel', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  window.addEventListener('keydown', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })
  useEffect(() => {
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    if (selectedChannel === 'Meta') {
      dispatch({ type: ADS_FORMAT, payload: 'all' })
    } else {
      dispatch({ type: ADS_FORMAT, payload: '' })
    }
    dispatch({ type: ADS_LOCATION, payload: '' })
    dispatch({ type: ADS_DATA, payload: [] })
    dispatch({ type: ADS_HOME_STATE, payload: true })
    // eslint-disable-next-line
  }, [])
  const searchSuggestionsList = async () => {
    try {
      const response = await RequestService.get('/save/brands/')
      dispatch({ type: SEARCH_SUGGESTIONS, payload: response?.data })
    } catch (error) {
      // console.log('🚀 ~ searchSuggestions ~ error:', error)
    }
  }
  useEffect(() => {
    if (searchSuggestions.length <= 0) {
      searchSuggestionsList()
    }
    // eslint-disable-next-line
  }, [])
  return (
    <div id="scrollDiv">
      <Text className={metaStyles.heading}>Discover Ads</Text>
      <div
        style={{
          marginTop: 20,
          position: 'sticky',
          top: '-1px',
          zIndex: 99,
        }}
      >
        <SearchBar />
      </div>

      {homeState ? <NoData /> : ''}
      {adsNoData && !loading ? <SearchResultsError /> : ''}
      {
        // eslint-disable-next-line
        loading ? (
          <div style={{ textAlign: 'center', marginTop: 150 }}>
            <Spin />
          </div>
        ) : (
          <>
            {filteredDiscoverData.length > 0 && (
              <>
                <div
                  style={{
                    position: 'relative',
                    marginTop: 20,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {showBackButton && (
                    <LeftCircleOutlined
                      onClick={() => scroll('back')}
                      style={{
                        cursor: 'pointer',
                        marginRight: 10,
                        fontSize: '27px',
                        color: '#f16434',
                      }}
                    />
                  )}
                  <div
                    ref={containerRef}
                    style={{
                      display: 'flex',
                      gap: 16,
                      overflowX: 'auto',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                      flex: 1,
                    }}
                    onScroll={checkScrollPosition}
                  >
                    {dataForBrands?.map(c => (
                      <CategoryButton categryData={c} onClick={handleCategoryClick} key={c.name} />
                    ))}
                  </div>
                  {brandsFilter ? (
                    <Text onClick={handleClearFilters} className={metaStyles.clearFilter}>
                      Clear
                    </Text>
                  ) : (
                    ''
                  )}

                  {showNextButton && (
                    <div style={{}}>
                      <RightCircleOutlined
                        onClick={() => scroll('next')}
                        style={{
                          cursor: 'pointer',
                          marginLeft: 10,
                          color: '#f16434',
                          fontSize: '27px',
                          marginTop: '2px',
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ marginTop: 24 }}>
                    <AdsCounter />
                  </div>

                  {filteredDiscoverData.length > 0 ? (
                    <InfiniteScroll
                      style={{ overflow: 'hidden' }}
                      dataLength={filteredDiscoverData.length} // This is the length of the data currently rendered
                      next={fetchMoreData} // Function to be called when user reaches bottom
                      hasMore={hasMore && !brandsFilter} // Set to false if no more data is available
                      loader={
                        newDataSpin ? (
                          <div
                            style={{
                              marginTop: 150,
                              textAlign: 'center',
                            }}
                          >
                            <Spin />
                          </div>
                        ) : (
                          ''
                        )
                      } // Loader component when more data is being loaded
                      scrollableTarget="scrollableDiv"
                    >
                      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                        <Masonry columnsCount={3} gutter="20px">
                          {filteredDiscoverData?.map((item, index) => (
                            <AdsCard
                              adsData={item}
                              onShowModal={() => handleShowModal(index)}
                              onPrevious={handlePrevious}
                              onNext={handleNext}
                              selectedData={filteredDiscoverData[selectedIndex]}
                              isFirst={selectedIndex === 0}
                              isLast={selectedIndex === filteredDiscoverData.length - 1}
                              boardsList={boardsList}
                            />
                          ))}
                        </Masonry>
                      </ResponsiveMasonry>
                    </InfiniteScroll>
                  ) : (
                    ''
                  )}
                </div>
              </>
            )}
          </>
        )
      }

      {filteredDiscoverData.length > 0 && showButton ? (
        <ButtonContainer onClick={scrollToTop}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </ButtonContainer>
      ) : (
        ''
      )}
    </div>
  )
}

export default DiscoverAdsFeature
