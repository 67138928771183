import React, { useState } from 'react'
import Navbar from '@components/LearningCenter/Navbar'
import LearningCard from '@components/LearningCenter/LearningCard'
import Outshine from '@components/LearningCenter/Outshine'
import Support from '@components/LearningCenter/Support'
import knowledgeImage1 from '@images/Learning/knowledge-image.png'
import { Col, Row } from 'antd'

const VideoInsights = () => {
  const [temp, setTemp] = useState()
  return (
    <>
      <Navbar title="Video Insights" />
      <Row>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
      </Row>
      <Outshine />
      <Row>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
        <Col span={6}>
          <LearningCard
            image={knowledgeImage1}
            title="Ensuring a Comliant ADA Website & Keyword research"
            comments="15"
            shares="20"
          />
        </Col>
      </Row>
      <Support />
    </>
  )
}
export default VideoInsights
