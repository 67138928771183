import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { ADS_PERFORMANCE_GRADE } from '@utils/constants'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiCalendar } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Col, Row, Avatar, Space, Dropdown, Menu } from 'antd'
import { Text, PerformanceCard, AccountName } from '@components'
import User from '@services/user'
import LogoAdsGrader from '@components/LogoAdsGrader/LogoAdsGrader'

import styles from './styles.module.scss'

export const Header = ({
  customerId,
  customerNameFallback,
  createdAt,
  children,
  score,
  onCtaClick,
  lowSpendValue,
}) => {
  const [dateRange, setDateRange] = useState('')
  const { t } = useTranslation()
  const history = useHistory()
  const user = User.get()

  const handleLogout = () => {
    User.remove()

    history.push('/')
  }

  const [_, month, date, year, time, _fromat] = dayjs(createdAt)
    .format('ddd, MMM DD, YYYY, h:mm A')
    .split(' ')
  const [day] = dayjs(createdAt).format('ddd, MMM DD, YYYY, h:mm A').split(',')
  function generateReportDateRange(inputDate) {
    const currentDate = new Date(inputDate)
    const endDate = new Date(currentDate)
    endDate.setDate(currentDate.getDate() - 1)
    const startDate = new Date(endDate)
    startDate.setDate(endDate.getDate() - 29)

    const format = datee => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(datee)
    }

    const formattedStartDate = format(startDate)
    const formattedEndDate = format(endDate)

    return ` from ${formattedStartDate} to ${formattedEndDate}.`
  }

  // Example usage
  useEffect(() => {
    if (createdAt) {
      const reportString = generateReportDateRange(createdAt && createdAt)
      setDateRange(reportString)
    }
  }, [createdAt])
  const isCustomerLIst = useSelector(state => state?.sampleReport?.sampleReport)

  return (
    <header className={styles.header}>
      <div className={styles.container} id={ADS_PERFORMANCE_GRADE}>
        <div className={styles.logoRow}>
          <div className={styles.logoDiv}>
            <LogoAdsGrader />
          </div>
          {/* 
          <Dropdown
            trigger={['click', 'hover']}
            overlay={
              <Menu>
                <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
              </Menu>
            }
          >
            <a href="javacript:void(0)" style={{ height: 32 }}>
              <Space>
                {user && (
                  <Avatar
                    src={
                      user.data &&
                      user.data.social_accounts.length &&
                      user.data.social_accounts[0].extra_data.picture
                    }
                  />
                )}
                <Text size={18} color="white">
                  {user && user.user.first_name}
                </Text>
              </Space>
            </a>
          </Dropdown> */}
        </div>

        {!children ? (
          <Row justify="center">
            <Col lg={12}>
              <div className={styles.headerLeftSide}>
                {isCustomerLIst ? (
                  <Text size={40} color="white">
                    <>
                      {t('report.sampleReport')}{' '}
                      <div>
                        <Text size={20} className={styles.notGoogleAdsAcc}>
                          {t('report.notGoogleAdsAcc')}
                          <br />
                          {t('report.notGoogleAdsAcc2')}
                        </Text>
                      </div>
                    </>
                  </Text>
                ) : (
                  <Text size={40} color="white">
                    {customerId && customerNameFallback ? (
                      <>
                        {t('report.header')}{' '}
                        <AccountName
                          customerId={customerId}
                          customerNameFallback={customerNameFallback}
                        />
                        {t('report.header2')}
                        <Text size={28} color="white">
                          ID: {customerId}
                        </Text>
                      </>
                    ) : (
                      <>
                        <div>
                          <Text size={28} color="white">
                            {t('report.header')} ID: {customerId}
                          </Text>
                        </div>
                      </>
                    )}
                  </Text>
                )}
              </div>
              <div className={styles.headerFooter}>
                <Text size={14} color="white">
                  <FiCalendar style={{ marginBottom: -2 }} /> {t('report.headerDateText.part1')}{' '}
                  <strong>
                    {' '}
                    {t(day)}, {t(month)} {date} {year} {time} {_fromat}{' '}
                  </strong>
                  {/* <strong>{dayjs(createdAt).format('ddd, MMM DD, YYYY, h:mm A')}</strong> */}
                  {t('report.headerDateText.part2')}:{dateRange}
                </Text>
              </div>
            </Col>
            <Col lg={12}>
              <div className={styles.performanceCard}>
                <PerformanceCard
                  hiddenButton={lowSpendValue}
                  percentageNumber={score?.toFixed(0)}
                  titleText={t('report.headerCardTitle')}
                  buttonText={t('report.headerCardButtonText')}
                  disclaimerText={t('report.headerCardDisclaimerText')}
                  onClick={onCtaClick}
                />
              </div>
            </Col>
          </Row>
        ) : (
          children
        )}
      </div>
    </header>
  )
}

Header.propTypes = {
  createdAt: PropTypes.string,
  score: PropTypes.number,
  children: PropTypes.node,
  onCtaClick: PropTypes.func,
  lowSpendValue: PropTypes.bool,
}

Header.defaultProps = {
  createdAt: '',
  score: 0,
  children: undefined,
  lowSpendValue: false,
  onCtaClick: () => {},
}
