import React, { useState } from 'react'
import { Text } from '@components'
import { Card } from 'antd'
import googleIcon from '@images/AdsPreview/googleIcon.png'
import noPreviewImage from '@images/AdsPreview/noPreviewImage.webp'
import styles from './modalCard.module.css'

const ModalCard = ({ adsData }) => {
  const [temp, setTemp] = useState()

  return (
    <>
      {adsData.format === 'video' ? (
        <Card className={styles.card}>
          <div className={styles.profile}>
            {/* <div style={{ marginTop: '-7px' }}>
            <img src={noPreviewImage} alt="profile-pic" height={52} />
          </div> */}

            <Text isBold size={16}>
              {adsData?.advertiser}
            </Text>

            <img src={googleIcon} className={styles.profileIcons} alt="profile-pic" />
          </div>

          <div className={styles.cardImageContainer}>
            <iframe
              style={{ width: '-webkit-fill-available', height: '300px' }}
              title="video"
              src={`https://www.youtube.com/embed/${adsData.video_link}?mute=0&autoplay=1`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Card>
      ) : (
        <Card className={styles.card}>
          <div className={styles.profile}>
            {/* <div style={{ marginTop: '-7px' }}>
            <img src={noPreviewImage} alt="profile-pic" height={52} />
          </div> */}

            <Text isBold size={16}>
              {adsData?.advertiser}
            </Text>

            <img src={googleIcon} className={styles.profileIcons} alt="profile-pic" />
          </div>

          <div className={styles.cardImageContainer}>
            <img
              src={adsData?.image ? adsData.image : noPreviewImage}
              className={styles.cardImage}
              alt="card-img"
            />
          </div>
        </Card>
      )}
    </>
  )
}

export default ModalCard
