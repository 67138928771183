/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Tabs, Spin, Dropdown, Space, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CameraOutlined, DownOutlined } from '@ant-design/icons'
import { updateLocalStorage } from 'utils/helperFunctions'
import { updateUserData } from 'store/actions'
import { useTranslation } from 'react-i18next'
import Text from 'components/Text/text'
import campagin from '@images/campaign.png'
import { notify } from '@utils/notify'
import api from '@services/api'
import { apiRoutes } from '@config/apiRoutes'
import { uploadProfilePicture } from '@services/uploadProfilePicture'
import { ACCOUNT_TAB, MANAGE_USERS_TAB, PLATFORM_INTEGRATIONS, AG_AC_TOKEN } from '@utils/constants'
import { currencies, reportFormats } from '../../../data'
import RadioGroup, { CheckboxGroup } from '../../RadioGroup'
import UnderlineTextButton from '../../UnderlineTextButton'
import InputField from '../../Input'
import dummyUser from '../../../images/user-96.png'
import PlatformIntegrations from './platformIntegrations'
import ManageUsers from './manageUsers'

import styles from './tabContent.module.css'

const TabContent = ({
  tab,
  formik,
  userUpdateLoading,
  callBack,
  handleCancel,
  DeactivateAccount,
}) => {
  const [showChangePasswordFields, setShowChangePasswordFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [fileUrl, setFileUrl] = useState(null)
  const [file, setFile] = useState(null)
  const fileRef = useRef(null)
  const isGoogleUser = useSelector(state => state.user?.isGoogleUser)
  const profilePicture = useSelector(state => state.user?.user?.profile?.profile_picture)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { getFieldProps } = formik

  const changePassword = useFormik({
    initialValues: {
      old_password: '',
      new_password1: '',
      new_password2: '',
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required().label('Old password'),
      new_password1: Yup.string().min(8).required().label('New password'),
      new_password2: Yup.string().min(8).required().label('Confirm password'),
    }),
    onSubmit: async values => {
      const { url, method } = apiRoutes.changePassword
      setIsLoading(true)
      try {
        const res = await api({
          url,
          method,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
          },
          data: values,
        })

        setIsLoading(false)
        notify(res.data.detail, 'success')
      } catch (e) {
        notify(t('notification.somethingWentWrong'), 'error')
        setIsLoading(false)
      }
    },
  })

  const handleFileUploadClick = async uploadedFile => {
    if (uploadedFile) {
      const formData = new FormData()
      formData.append('profile_picture', uploadedFile)
      setFileUploadLoading(true)
      try {
        await uploadProfilePicture(formData)
          .then(res => {
            setFileUploadLoading(false)
            if (res.status === 200) {
              const userData = { ...res.data.user }
              localStorage.setItem('profilePic', res?.data?.user?.profile?.profile_picture)
              notify(t('notification.profileImageUploaded'), 'success')
              updateLocalStorage(userData)
              dispatch(updateUserData({ user: userData }))
            } else if (res.status >= 500) {
              notify(t('notification.internalServer'), 'error')
            }
          })
          .catch(e => {
            setFileUploadLoading(false)
            notify(t('notification.somethingWentWrong'), 'error')
          })
      } catch (e) {
        // console.log(e)
      }
    }
  }
  const handleFileSelectClick = async () => {
    fileRef.current.click()
  }
  const handleFileChange = event => {
    const uploadedFile = event.target.files[0]
    setFile(uploadedFile)
    if (uploadedFile) {
      const blobURL = URL.createObjectURL(uploadedFile)
      setFileUrl(blobURL)
      handleFileUploadClick(uploadedFile)
    }
  }

  // RENDER EACH TAB USING THE SPECIFIED KEY, TO RENDER MORE TABS JUST ADD THE NEW COMPONENT
  const { Option } = Select

  if (tab.key === ACCOUNT_TAB) {
    return (
      <div className={styles.accountTab}>
        <h2 className={styles.bold}>{t('settings.account.account')}</h2>
        <div className={styles.accountSection}>
          <div className={styles.basicInfo}>
            <InputField
              label={t('settings.account.emailAddress')}
              inputProps={{
                name: 'email',
                placeholder: `${t('settings.account.emailAddress')}`,
                disabled: true,
                ...getFieldProps('email'),
              }}
            />
            <div className={styles.name}>
              <InputField
                label={t('loginForm.placeholder.firstName')}
                inputProps={{
                  name: 'first_name',
                  placeholder: `${t('settings.account.fnamePlaceholder')}`,
                  ...getFieldProps('first_name'),
                }}
              />
              <InputField
                label={t('loginForm.placeholder.lastName')}
                inputProps={{
                  name: 'last_name',
                  placeholder: `${t('settings.account.lnamePlaceholder')}`,
                  ...getFieldProps('last_name'),
                }}
              />
            </div>
            {/* {!isGoogleUser && (
              <>
                <UnderlineTextButton
                  onClick={() => setShowChangePasswordFields(!showChangePasswordFields)}
                >
                  Change Password
                </UnderlineTextButton>
                {showChangePasswordFields && (
                  <div className={styles.changePassword}>
                    <InputField
                      label="Old Password"
                      inputProps={{
                        name: 'old_password',
                        type: 'password',
                        placeholder: 'Enter your old password',
                        ...changePassword.getFieldProps('old_password'),
                      }}
                    />
                    {changePassword.touched.old_password &&
                      Boolean(changePassword.errors.old_password) && (
                        <p className={styles.error}>{changePassword.errors.old_password}</p>
                      )}
                    <div className={styles.name}>
                      <div className={styles.newPassword}>
                        <InputField
                          label="New Password"
                          inputProps={{
                            name: 'new_password1',
                            type: 'password',
                            placeholder: 'Enter new password',
                            ...changePassword.getFieldProps('new_password1'),
                          }}
                        />
                        {changePassword.touched.new_password1 &&
                          Boolean(changePassword.errors.new_password1) && (
                            <p className={styles.error}>{changePassword.errors.new_password1}</p>
                          )}
                      </div>
                      <div className={styles.confirmNewPassword}>
                        <InputField
                          label="Confirm New Password"
                          inputProps={{
                            name: 'new_password2',
                            type: 'password',
                            placeholder: 'confirm new password',
                            ...changePassword.getFieldProps('new_password2'),
                          }}
                        />
                        {changePassword.touched.new_password2 &&
                          Boolean(changePassword.errors.new_password2) && (
                            <p className={styles.error}>{changePassword.errors.new_password2}</p>
                          )}
                      </div>
                    </div>
                    <Button
                      type="primary"
                      size="small"
                      className={styles.saveButton}
                      onClick={changePassword.handleSubmit}
                    >
                      {isLoading ? <Spin /> : 'Change Password'}
                    </Button>
                  </div>
                )}
              </>
            )} */}
          </div>
          <div className={styles.profilePictureBox}>
            <p className={styles.bold}>{t('settings.account.profilePhoto')}</p>
            <div className={styles.photCircle}>
              <img src={fileUrl ?? profilePicture ?? dummyUser} alt="" className={styles.image} />
              <div className={styles.photoActions}>
                <CameraOutlined
                  style={{ color: '#f16434', fontSize: '1.5rem' }}
                  onClick={() => handleFileSelectClick()}
                />
              </div>
            </div>
            <div>
              <div className={styles.uploadButton}>
                {!fileUploadLoading ? (
                  <UnderlineTextButton onClick={() => handleFileSelectClick()}>
                    {t('settings.account.uploadPhoto')}
                  </UnderlineTextButton>
                ) : (
                  <Spin />
                )}
                <input
                  type="file"
                  className={styles.fileInput}
                  ref={fileRef}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.divider} />
        <div className={styles.otherPreferences}>
          <h2 className={styles.bold}>Other Preferences</h2>
          <Text size={14} className={styles.text}>
            Choose what notifications you want to receive
          </Text>

          <div
            style={{
              marginTop: 24,
              marginLeft: 20,
              display: 'flex',
              width: '665px',
              alignItems: 'center',
            }}
          >
            <img src={campagin} alt="campaignImg" width={40} height={40} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text size={16} isBold style={{ marginLeft: '12px' }}>
                Campaign Performance Alerts
              </Text>

              <div style={{ width: 116, marginLeft: 83 }}>
                <InputField
                  inputProps={{
                    name: 'weekly',
                    // placeholder: 'Weekly',
                    disabled: true,
                    ...getFieldProps('campaign_performance_alert'),
                  }}
                />
              </div>
              {/* <Select
                value="Weekly"
                disabled
                style={{ width: 116, marginLeft: 83 }}
                // onChange={value =>
                //   formik.setFieldValue('profile.campaign_performance_alert', value)
                // }
              >
                <Option value="WEEKLY">Weekly</Option>
                <Option value="FORTNIGHTLY">Fortnightly</Option>
                <Option value="MONTHLY  ">Monthly</Option>
                <Option value="ANNUAL">Annual</Option>
              </Select> */}
            </div>
          </div>
        </div>
        {/* <div className={styles.otherPreferences}>
          <h2 className={styles.bold}>Other Preferences</h2>
          <p>Currency</p>
          <div className={styles.radioContainer}>
            <RadioGroup
              options={currencies}
              name="currency"
              {...getFieldProps('profile.currency')}
            />
          </div>
        </div> */}

        {/* <a href="#google" className="moreCurrencies">
            <UnderlineTextButton>More Currencies</UnderlineTextButton>
            <img
              src={dropArrow}
              alt="dropDown"
              className={styles.image}
              style={{ marginLeft: '5px' }}
            />
          </a> */}

        {/* <div className={styles.otherPreferences}>
          <p>Report Format</p>
          <div className={styles.radioContainer}>
            <CheckboxGroup
              options={reportFormats}
              name="reportFormat"
              // selectedValue={formik.values.profile.report_format}
              selectedValue="pdf"
              onChange={value => formik.setFieldValue('profile.report_format', value)}
              onBlur={formik.handleBlur}
            />
          </div>
        </div> */}

        {/* <div className={styles.otherPreferences}>
            <p>Timezone</p>
            <div className={styles.timeZoneDropdown}>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
              >
                <Space
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  GMT+05:00 Pakistan Standard Time
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </div> */}
        <div className={styles.divider} />
        <div className={styles.submitSection}>
          <UnderlineTextButton onClick={DeactivateAccount}>
            {t('settings.account.deleteMyAccount')}
          </UnderlineTextButton>
          <div className={styles.formControlButton}>
            <Button size="small" className={styles.cancelButton} onClick={handleCancel}>
              {t('settings.account.cancel')}
            </Button>
            <Button
              type="primary"
              size="small"
              className={styles.saveButton}
              onClick={formik.handleSubmit}
              loading={userUpdateLoading}
              disabled={userUpdateLoading}
            >
              {t('settings.account.save')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (tab.key === MANAGE_USERS_TAB) {
    return <ManageUsers />
  }
  if (tab.key === PLATFORM_INTEGRATIONS) {
    return <PlatformIntegrations />
  }
  return <div />
}

export default TabContent
