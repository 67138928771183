import { AG_AC_TOKEN } from '../utils/constants'
import { apiRoutes } from '../config/apiRoutes'
import api from './api'
import tokenService from './tokenService'

export const fetchInvitersList = async () => {
  const { url, method } = apiRoutes.inviters
  const accessToken = tokenService.getLocalAccessToken()
  try {
    const res = await api({
      url,
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return res
  } catch (e) {
    return e.response
  }
}
