/* eslint-disable no-unused-vars */ /* eslint-disable react/prop-types */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaQuestionCircle } from 'react-icons/fa'
import { Row, Col, Collapse, Table, Space, Alert, Tooltip } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { useTranslation } from 'react-i18next'
import ApexChart from 'react-apexcharts'

import iconChecked from '@images/check-icon.svg'
import iconAlert from '@images/alert-icon.svg'

import { Text, ProgressBar, InformationCard } from '@components'
import {
  getPercentage,
  formatCurrency,
  doubleDecimals,
  getBenchmarkProp,
  checkingFeedback,
} from '@utils'

import { PanelSection } from '../../components/PanelSection'

import styles from './styles.module.scss'
import { costEvoChartConfig } from './costEvoChartConfig'

const { Panel } = Collapse
const { Column } = Table

export const OverallSection = ({
  report,
  benchmarks,
  currency,
  language,
  hideDisplay,
  onCtaClick,
}) => {
  const { t } = useTranslation()
  const qualityScoreImpressionsChart = []

  report.quality_score_over_impressions_chart.forEach(item => {
    const object = {
      Impressions: item.impressions,
      Impressões: item.impressions,
      Impressionen: item.impressions,
      Impresiones: item.impressions,
      Impressioni: item.impressions,
      インプレッション: item.impressions,
      quality_score: item.quality_score,
    }

    qualityScoreImpressionsChart.push(object)
  })

  const translationsMonths = [
    t('report.months.1'),
    t('report.months.2'),
    t('report.months.3'),
    t('report.months.4'),
    t('report.months.5'),
    t('report.months.6'),
    t('report.months.7'),
    t('report.months.8'),
    t('report.months.9'),
    t('report.months.10'),
    t('report.months.11'),
    t('report.months.12'),
  ]

  const graficConfig = costEvoChartConfig(
    report,
    t('report.tabs.costEvolution.chartLegendAvarege'),
    t('report.tabs.costEvolution.chartLegendTrendline'),
    translationsMonths
  )

  const lowSpendValue = report.overall_spend < 4000

  return (
    <section>
      <div className={styles.overallHeader}>
        <Text size={32} isBold isTitle>
          {t('report.overallSection')}
        </Text>
      </div>

      <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} ghost>
        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.costEvolution.title')}</Text>
              <ProgressBar percentageNumber={report.grader_cost_evolution} />
            </div>
          }
          key="1"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.costEvolution.title')}
            score={report.grader_cost_evolution}
            ctaTitle={t('report.tabs.costEvolution.ctaTitle')}
            ctaButtonText={t('report.tabs.costEvolution.ctaButtonText')}
            description={t('report.tabs.costEvolution.description')}
            meansDescription={checkingFeedback(
              report.grader_cost_evolution,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.positive.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.positive.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.neutral.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.neutral.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.costEvolution.meansDescription.negative.part4')}
                </Text>
                <ol className={styles.meansList}>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item1')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item2')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item3')}
                    </Text>
                  </li>
                  <li>
                    <Text size={16}>
                      {t('report.tabs.costEvolution.meansDescription.negative.list.item4')}
                    </Text>
                  </li>
                </ol>
              </>
            )}
          >
            {report.cost_evolution !== null && graficConfig ? (
              <ApexChart
                options={graficConfig.options}
                height={380}
                series={graficConfig.series}
                type="line"
              />
            ) : (
              <Text size={16}>{t('report.tabs.costEvolution.chartError')}</Text>
            )}
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.spend.title')}</Text>
              <ProgressBar percentageNumber={report.wasted_spent_score} />
            </div>
          }
          key="2"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.spend.title')}
            score={report.wasted_spent_score}
            ctaTitle={t('report.tabs.spend.ctaTitle')}
            ctaButtonText={t('report.tabs.spend.ctaButtonText')}
            description={
              <>
                {t('report.tabs.spend.subtitle1Part2')}
                <strong style={{ color: '#F26234' }}>
                  {formatCurrency(report.projected_wasted_spend, {
                    currency,
                    language,
                  }) || 0}
                </strong>{' '}
                {t('report.tabs.spend.subtitle1')}
                {t('report.tabs.spend.subtitle2Part2')}
                {formatCurrency(report.wasted_spent, {
                  currency,
                  language,
                }) || 0}{' '}
                {t('report.tabs.spend.subtitle2')}
              </>
            }
            meansDescription={checkingFeedback(
              report.wasted_spent_score,
              <Text size={16}>{t('report.tabs.spend.meansDescription.positive')}</Text>,
              <>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part2')}</Text>
                <Text size={16}>{t('report.tabs.spend.meansDescription.neutral.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.spend.meansDescription.negative.part1')}</Text>
                <Text size={16}>{t('report.tabs.spend.meansDescription.negative.part2')}</Text>
              </>
            )}
          >
            <div className={styles.wastedCollapseContainer}>
              <Text size={20}>{t('report.tabs.spend.numberOfNegativeKeywordsAdded')}</Text>
              <div className={styles.comparisonGraphDiv}>
                <Text isBold isTitle size={18}>
                  {t('you')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div
                    className={styles.youGraph}
                    style={{
                      width:
                        getBenchmarkProp('negative_keywords', benchmarks) !== '-'
                          ? (report.negative_keywords_added /
                              getBenchmarkProp('negative_keywords', benchmarks)) *
                            200
                          : (report.negative_keywords_added / 1266) * 200,
                    }}
                  />
                  <Text size={16}>{report.negative_keywords_added}</Text>
                </div>
                <Text isBold isTitle size={18}>
                  {t('industryBenchmark')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div className={styles.benchmarkGraph} style={{ width: 200 }} />
                  <Text size={16}>
                    {getBenchmarkProp('negative_keywords', benchmarks) !== '-'
                      ? getBenchmarkProp('negative_keywords', benchmarks)
                      : 1266}
                  </Text>
                </div>
              </div>
            </div>
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.adRelevance.title')}</Text>
              <ProgressBar percentageNumber={report.grader_text_ads} />
            </div>
          }
          key="3"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            isBlur={report.search_spend < 1}
            score={report.grader_text_ads}
            description=""
            title={t('report.tabs.adRelevance.title2')}
            titleOptional={t('report.tabs.adRelevance.title')}
            meansDescription={checkingFeedback(
              report.grader_text_ads,
              <>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.positive.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.adRelevance.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.adRelevance.meansDescription.neutral.part2')}</Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.adRelevance.meansDescription.negative.part2')}
                </Text>
              </>
            )}
            ctaTitle={t('report.tabs.adRelevance.ctaTitle')}
            ctaButtonText={t('report.tabs.adRelevance.ctaButtonText')}
            onCtaClick={onCtaClick}
          >
            <>
              <Space direction="vertical" size={32}>
                <Row gutter={24}>
                  <Col xs={16} sm={16} lg={16}>
                    <div className={styles.containerBorder}>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} lg={24}>
                          <div className={styles.wastedCollapseContainer}>
                            <Space direction="vertical" size={12}>
                              <Text size={16} isBold isTitle>
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {t('report.tabs.adRelevance.activeSearchText')}
                                  {/* <Tooltip
                                    className={styles.tooltipText}
                                    placement="top"
                                    title={<Text color="white" size={14} />}
                                  >
                                    <FaQuestionCircle />
                                  </Tooltip> */}
                                </span>
                                <Text isBold isTitle size={24}>
                                  {report.active_text_ads_total}
                                </Text>
                              </Text>

                              <Text>
                                {t('report.tabs.adRelevance.description', {
                                  ASA: report.active_text_ads_total || 0,
                                  RSA: report.rsa_total || 0,
                                  ETA: report.eta_total || 0,
                                  DSA: report.exp_dsa_total || 0,
                                })}
                              </Text>
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} lg={24}>
                          <hr className={styles.hrMargin} />
                        </Col>
                        <Col xs={24} sm={24} lg={24}>
                          <div className={styles.wastedCollapseContainer}>
                            <Space direction="vertical" size={12}>
                              <Row gutter={24}>
                                <Col xs={24} sm={24} lg={24}>
                                  <div className={styles.wastedCollapseGraphsContainer}>
                                    <Space direction="horizontal" size={16}>
                                      <div
                                        className={styles.wastedCollapseGraphsContainer}
                                        style={{ width: 32, height: 32, justifyContent: 'start' }}
                                      >
                                        {report.rsa_per_ad_group_status ? (
                                          <img src={iconChecked} alt="icon checked" width={32} />
                                        ) : (
                                          <img src={iconChecked} alt="icon alert" width={32} />
                                        )}
                                      </div>
                                      <Text size={16}>{t('report.tabs.adRelevance.rsaPerAd')}</Text>
                                    </Space>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={24}>
                                <Col xs={24} sm={24} lg={24}>
                                  <div className={styles.wastedCollapseGraphsContainer}>
                                    <Space direction="horizontal" size={16}>
                                      <div
                                        className={styles.wastedCollapseGraphsContainer}
                                        style={{ width: 32, height: 32, justifyContent: 'start' }}
                                      >
                                        {report.rsa_poor_and_average_status ? (
                                          <img src={iconChecked} alt="icon checked" width={32} />
                                        ) : (
                                          <img src={iconAlert} alt="icon alert" width={32} />
                                        )}
                                      </div>
                                      <Text size={16}>
                                        {t('report.tabs.adRelevance.rsaPoorAvarege2')}
                                        <span style={{ color: '#202020' }}>
                                          {report.rsa_poor_and_average_percentage
                                            ? `${report.rsa_poor_and_average_percentage}%`
                                            : `0%`}
                                        </span>{' '}
                                        {t('report.tabs.adRelevance.rsaPoorAvarege')}
                                      </Text>
                                    </Space>
                                  </div>
                                </Col>
                              </Row>
                            </Space>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={8} sm={8} lg={8}>
                    <div className={styles.containerBorder}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                        }}
                      >
                        <Text size={16} isBold isTitle>
                          <span
                            style={{
                              textTransform: 'uppercase',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {t('report.tabs.adRelevance.adsStrength')}
                            {/* <Tooltip
                              className={styles.tooltipText}
                              placement="top"
                              title={<Text color="white" size={14} />}
                            >
                              <FaQuestionCircle />
                            </Tooltip> */}
                          </span>
                        </Text>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 6,
                          }}
                        >
                          <div>
                            <Text isBold isTitle size={18}>
                              {t('report.tabs.adRelevance.excellent')}
                            </Text>
                            <div className={styles.wastedCollapseGraphsContainer}>
                              <div
                                className={styles.graphBar}
                                style={{
                                  backgroundColor: '#85ECC1',
                                  width: `${doubleDecimals(report.rsa_excellent_percentage)}%`,
                                  marginRight: 8,
                                }}
                              />
                              <Text size={16}>{`${doubleDecimals(
                                report.rsa_excellent_percentage
                              )}%`}</Text>
                            </div>
                          </div>
                          <div>
                            <Text isBold isTitle size={18}>
                              {t('report.tabs.adRelevance.good')}
                            </Text>
                            <div className={styles.wastedCollapseGraphsContainer}>
                              <div
                                className={styles.graphBar}
                                style={{
                                  width: `${doubleDecimals(report.rsa_good_percentage)}%`,
                                  marginRight: 8,
                                  backgroundColor: '#E0FAEF',
                                }}
                              />
                              <Text size={16}>{`${doubleDecimals(
                                report.rsa_good_percentage
                              )}%`}</Text>
                            </div>
                          </div>
                          <div>
                            <Text isBold isTitle size={18}>
                              {t('report.tabs.adRelevance.average')}
                            </Text>
                            <div className={styles.wastedCollapseGraphsContainer}>
                              <div
                                className={styles.graphBar}
                                style={{
                                  width: `${doubleDecimals(report.rsa_average_percentage)}%`,
                                  marginRight: 8,
                                  backgroundColor: '#F8BC54',
                                }}
                              />
                              <Text size={16}>
                                {`${doubleDecimals(report.rsa_average_percentage)}%`}
                              </Text>
                            </div>
                          </div>
                          <div>
                            <Text isBold isTitle size={18}>
                              {t('report.tabs.adRelevance.poor')}
                            </Text>
                            <div className={styles.wastedCollapseGraphsContainer}>
                              <div
                                className={styles.graphBar}
                                style={{
                                  backgroundColor: '#FE8485',
                                  width: `${doubleDecimals(report.rsa_poor_percentage)}%`,
                                  marginRight: 8,
                                }}
                              />
                              <Text size={16}>{`${doubleDecimals(
                                report.rsa_poor_percentage
                              )}%`}</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <div>
                  <hr className={styles.hrMargin} />
                  <Row>
                    <Col xs={24} sm={24} lg={24}>
                      <span style={{ paddingBottom: 24, display: 'block' }}>
                        <Text size={32}>{t('report.tabs.adComparisons.title')}</Text>
                      </span>
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <Text className={styles.textSubTitle} isTitle color="secondary">
                        {t('report.tabs.adComparisons.previewText')}
                      </Text>
                      <div className={styles.container_adPerformance}>
                        <div className={styles.card_adPerformance}>
                          <div className={styles.bgColorCard_adPerformance}>
                            <div className={styles.content_adPerformance}>
                              <Space style={{ width: '100%' }} direction="vertical" size={24}>
                                <div className={styles.headerCard_adPerformance}>
                                  <Text size={12} isTitle>
                                    {t('report.tabs.adComparisons.adText')}
                                    <Text
                                      style={{ display: 'inline', marginLeft: 4 }}
                                      size={12}
                                      isText
                                    >
                                      www.teste.com
                                    </Text>
                                  </Text>
                                  <span className={styles.threePoints} />
                                </div>
                                <div>
                                  <Space direction="vertical" size={16}>
                                    <Text size={24} style={{ color: '#1a73e8' }}>
                                      Meu Catálogo Digital | Monte uma Loja Virtual
                                    </Text>
                                    <Text>
                                      Um Catálogo Digital Onde Você Cadastra Seus Produtos Como Uma
                                      Vitrine e Vende no WhatsApp. Monte uma Loja Vitrine Online
                                    </Text>
                                  </Space>
                                </div>
                              </Space>
                            </div>
                          </div>
                        </div>
                        <div className={styles.infoContainer_adPerformance}>
                          <div className={styles.inforContent_adPerformance}>
                            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                              <Text isTitle size={24}>
                                {t('report.tabs.adComparisons.bestTitle')}
                              </Text>
                              <Text size={18} isTitle>
                                {t('report.tabs.adComparisons.urlText')}
                              </Text>
                              <Text
                                size={16}
                                style={{ color: '#1a73e8', letterSpacing: '0.05rem' }}
                              >
                                https://www.teste.com/ads/
                              </Text>
                              <div className={styles.wrapperInfo_adPerformance}>
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.ctr')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    17,90%
                                  </Text>
                                </div>
                                <span className={styles.divider} />
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.impression')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    32.080
                                  </Text>
                                </div>
                                <span className={styles.divider} />
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.clicks')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    5752
                                  </Text>
                                </div>
                              </div>
                            </Space>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <hr className={styles.hrMargin} />
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <Text className={styles.textSubTitle} isTitle color="secondary">
                        {t('report.tabs.adComparisons.previewText')}
                      </Text>
                      <div className={styles.container_adPerformance}>
                        <div className={styles.card_adPerformance}>
                          <div className={styles.bgColorCard_adPerformance}>
                            <div className={styles.content_adPerformance}>
                              <Space style={{ width: '100%' }} direction="vertical" size={24}>
                                <div className={styles.headerCard_adPerformance}>
                                  <Text size={12} isTitle>
                                    {t('report.tabs.adComparisons.adText')}
                                    <Text
                                      style={{ display: 'inline', marginLeft: 4 }}
                                      size={12}
                                      isText
                                    >
                                      www.teste.com
                                    </Text>
                                  </Text>
                                  <span className={styles.threePoints} />
                                </div>
                                <div>
                                  <Space direction="vertical" size={16}>
                                    <Text size={24} style={{ color: '#1a73e8' }}>
                                      Meu Catálogo Digital | Monte uma Loja Virtual
                                    </Text>
                                    <Text>
                                      Um Catálogo Digital Onde Você Cadastra Seus Produtos Como Uma
                                      Vitrine e Vende no WhatsApp. Monte uma Loja Vitrine Online
                                    </Text>
                                  </Space>
                                </div>
                              </Space>
                            </div>
                          </div>
                        </div>
                        <div className={styles.infoContainer_adPerformance}>
                          <div className={styles.inforContent_adPerformance}>
                            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                              <Text isTitle size={24}>
                                {t('report.tabs.adComparisons.worstTitle')}
                              </Text>
                              <Text size={18} isTitle>
                                {t('report.tabs.adComparisons.urlText')}
                              </Text>
                              <Text
                                size={16}
                                style={{ color: '#1a73e8', letterSpacing: '0.05rem' }}
                              >
                                https://www.teste.com/ads/
                              </Text>
                              <div className={styles.wrapperInfo_adPerformance}>
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.ctr')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    3,71%
                                  </Text>
                                </div>
                                <span className={styles.divider} />
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.impression')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    10.900
                                  </Text>
                                </div>
                                <span className={styles.divider} />
                                <div className={styles.infos_adPerformance}>
                                  <Text size={16} isTitle>
                                    {t('report.tabs.adComparisons.clicks')}
                                  </Text>
                                  <Text size={24} isTitle>
                                    378
                                  </Text>
                                </div>
                              </div>
                            </Space>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div> */}
              </Space>
            </>
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.qualityScore.title')}</Text>
              <ProgressBar percentageNumber={report.quality_score_score} />
            </div>
          }
          key="4"
        >
          <PanelSection
            isBlur={report.search_spend < 1}
            isCTA={!lowSpendValue}
            isMeansDescription={doubleDecimals(report.quality_score) <= 10}
            onCtaClick={onCtaClick}
            title={t('report.tabs.qualityScore.title')}
            score={report.quality_score <= 10 ? report.quality_score_score : 100}
            description={
              doubleDecimals(report.quality_score) <= 10 ? (
                <>
                  {t('report.tabs.qualityScore.description.part1')}
                  <strong style={{ color: '#F26234' }}>
                    {doubleDecimals(report.quality_score)}
                  </strong>
                  {doubleDecimals(report.quality_score) <= 9.0 ? (
                    <>{t('report.tabs.qualityScore.description.part2')}</>
                  ) : (
                    <>
                      {t('report.tabs.qualityScore.description.part6')}
                      <strong style={{ color: '#F26234' }}>
                        {doubleDecimals(10 - report.quality_score)}
                      </strong>
                      {t('report.tabs.qualityScore.description.part7')}
                    </>
                  )}

                  <strong style={{ color: '#F26234' }}>
                    {formatCurrency(Math.ceil(report.quality_score_could_save_value), {
                      currency,
                      language,
                    }) || 0}
                  </strong>
                  {t('report.tabs.qualityScore.description.part3')}

                  {t('report.tabs.qualityScore.description.part4')}
                  <strong style={{ color: '#F26234' }}>
                    {Math.ceil(report.quality_score_more_clicks)}
                  </strong>
                  {t('report.tabs.qualityScore.description.part5')}
                </>
              ) : (
                <>
                  {t('report.tabs.qualityScore.description.part1')}
                  <strong style={{ color: '#F26234' }}>10</strong>.
                </>
              )
            }
            meansDescription={checkingFeedback(
              report.quality_score_score,
              <>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.positive.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.qualityScore.meansDescription.neutral')}</Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.qualityScore.meansDescription.negative.part2')}
                </Text>
              </>
            )}
            ctaTitle={t('report.tabs.qualityScore.ctaTitle')}
            ctaButtonText={t('report.tabs.qualityScore.ctaButtonText')}
          >
            <Text size={20}>{t('report.tabs.qualityScore.chartTitle')}</Text>
            <div style={{ height: 350 }}>
              <ResponsiveBar
                data={qualityScoreImpressionsChart}
                keys={[t('report.tabs.qualityScore.impressions')]}
                indexBy="quality_score"
                margin={{ top: 50, right: 130, bottom: 50, left: 70 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                valueFormat={{ format: '', enabled: false }}
                colors={{ scheme: 'set3' }}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t('report.tabs.qualityScore.title'),
                  legendPosition: 'middle',
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: t('report.tabs.qualityScore.impressions'),
                  legendPosition: 'middle',
                  legendOffset: -60,
                }}
                axisTop={null}
                axisRight={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="transparent"
                legends={[]}
              />
            </div>
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.ctr.title')}</Text>
              <ProgressBar percentageNumber={report.grader_ctr} />
            </div>
          }
          key="5"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.ctr.title')}
            score={report.grader_ctr}
            description={
              <>
                {t('report.tabs.ctr.description.part1', {
                  percentage: doubleDecimals(report.ctr_increased),
                })}
                {t('report.tabs.ctr.description.part2')}
                <strong style={{ color: '#F26234' }}>
                  {!report.ctr_more_clicks < 1 ? report.ctr_more_clicks : 1}
                </strong>
                {t('report.tabs.ctr.description.part3')}
                <strong style={{ color: '#F26234' }}>
                  {Number(!report.ctr_more_conversion < 1 ? report.ctr_more_conversion : 1).toFixed(
                    0
                  )}
                </strong>
                {t('report.tabs.ctr.description.part4')}
              </>
            }
            meansDescription={checkingFeedback(
              report.grader_ctr,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.positive')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.neutral')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.negative.part1')}</Text>
                <Text size={16}>{t('report.tabs.ctr.meansDescription.negative.part2')}</Text>
              </>
            )}
            ctaTitle={t('report.tabs.ctr.ctaTitle')}
            ctaButtonText={t('report.tabs.ctr.ctaButtonText')}
          >
            <Text size={20}>{t('report.tabs.ctr.cardTitle')}</Text>
            <Text size={16} className={styles.subtitle}>
              {t('report.tabs.ctr.searchNetwork')}
            </Text>
            <Row gutter={24} justify="center">
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.mobile')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_mobile_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_mobile_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.desktop')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_desktop_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_desktop_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
              <Col xs={24} sm={24} lg={8}>
                <InformationCard title={t('report.tabs.ctr.overall')}>
                  <Space direction="vertical" size={0}>
                    <Text>
                      {t('report.tabs.ctr.crt')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_ctr)}
                      </span>
                    </Text>
                    <Text>
                      {t('report.tabs.ctr.topImp')}
                      <span style={{ color: '#F26234' }}>
                        {getPercentage(report.ctr_search_top_impressions_rate)}
                      </span>
                    </Text>
                  </Space>
                </InformationCard>
              </Col>
            </Row>

            {!hideDisplay && (
              <>
                <Text size={16} className={styles.subtitle}>
                  Display Network:
                </Text>
                <Row gutter={24} justify="center">
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title="Mobile">
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_mobile_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_mobile_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title="Desktop">
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_desktop_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_desktop_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                  <Col xs={24} sm={24} lg={8}>
                    <InformationCard title="Overall">
                      <Space direction="vertical" size={0}>
                        <Text>
                          {t('report.tabs.ctr.crt')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_ctr)}
                          </span>
                        </Text>
                        <Text>
                          {t('report.tabs.ctr.topImp')}
                          <span style={{ color: '#F26234' }}>
                            {getPercentage(report.ctr_display_top_impressions_rate)}
                          </span>
                        </Text>
                      </Space>
                    </InformationCard>
                  </Col>
                </Row>
              </>
            )}
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.keywordsMatchType.title')}</Text>
              <ProgressBar percentageNumber={report.grader_long_tail} />
            </div>
          }
          key="6"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            isBlur={report.search_spend < 1}
            onCtaClick={onCtaClick}
            title={t('report.tabs.keywordsMatchType.title')}
            description=""
            score={report.grader_long_tail}
            ctaButtonText={t('report.tabs.keywordsMatchType.ctaButtonText')}
            ctaTitle={t('report.tabs.keywordsMatchType.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_long_tail,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.positive')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.neutral.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.keywordsMatchType.meansDescription.negative.part3')}
                </Text>
              </>
            )}
          >
            <Text size={20}>{t('report.tabs.keywordsMatchType.subtitle')}</Text>
            <div style={{ height: 400 }}>
              <ResponsivePie
                data={[
                  {
                    id: t('report.tabs.keywordsMatchType.exact'),
                    label: t('report.tabs.keywordsMatchType.exact'),
                    value: report.keywords_match_type_exact_percentage
                      ? Number(report.keywords_match_type_exact_percentage.toFixed(2))
                      : 1,
                    color: '#FE8485',
                  },
                  {
                    id: t('report.tabs.keywordsMatchType.phrase'),
                    label: t('report.tabs.keywordsMatchType.phrase'),
                    value: report.keywords_match_type_phrase_percentage
                      ? Number(report.keywords_match_type_phrase_percentage.toFixed(2))
                      : 1,
                    color: '#00bcd4',
                  },
                  {
                    id: t('report.tabs.keywordsMatchType.broad'),
                    label: t('report.tabs.keywordsMatchType.broad'),
                    value: report.keywords_match_type_broad_percentage
                      ? Number(report.keywords_match_type_broad_percentage.toFixed(2))
                      : 1,
                    color: '#85ECC1',
                  },
                ]}
                valueFormat={value => `${value}%`}
                colors={{ datum: 'data.color' }}
                sortByValue
                padAngle={1}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
                arcLabel={item => `${item.value}%`}
                enableArcLinkLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 48,
                    itemWidth: 70,
                    itemHeight: 18,
                    itemTextColor: '#666',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                  },
                ]}
              />
            </div>
          </PanelSection>
        </Panel>

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.impShare.title')}</Text>
              <ProgressBar percentageNumber={report.grader_impression_share} />
            </div>
          }
          key="7"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.impShare.title')}
            score={report.grader_impression_share}
            description={
              <>
                {t('report.tabs.impShare.description.part1')}
                <strong style={{ color: '#F26234' }}>
                  {doubleDecimals(100 - report.grader_impression_share)}%
                </strong>
                {t('report.tabs.impShare.description.part2')}
              </>
            }
            ctaButtonText={t('report.tabs.impShare.ctaButtonText')}
            ctaTitle={t('report.tabs.impShare.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_impression_share,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.positive.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.neutral.part3')}</Text>
              </>,
              <>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part1')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part2')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part3')}</Text>
                <Text size={16}>{t('report.tabs.impShare.meansDescription.negative.part4')}</Text>
              </>
            )}
          >
            <Row gutter={24} justify="center">
              <Col xs={24} sm={24} lg={12}>
                <div className={styles.wastedCollapseContainer}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text size={20}>{t('report.tabs.impShare.seachImpression')}</Text>
                    <Tooltip
                      className={styles.tooltipText}
                      placement="top"
                      title={
                        <Text color="white" size={14}>
                          {t('report.tabs.impShare.help')}
                        </Text>
                      }
                    >
                      <FaQuestionCircle />
                    </Tooltip>
                  </div>
                  <div className={styles.comparisonGraphDiv}>
                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareAcquired')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          backgroundColor: '#00ecc0',
                          width: `${doubleDecimals(report.search_impression_share)}%`,
                          marginRight: 8,
                        }}
                      />
                      <Text size={16}>{`${doubleDecimals(report.search_impression_share)}%`}</Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLostAdRank')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.search_rank_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ff8586',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.search_rank_lost_impression_share)}%`}
                      </Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLost')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.search_budget_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ffffb0',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.search_budget_lost_impression_share)}%`}
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <div className={styles.wastedCollapseContainer}>
                  <Text size={20}>{t('report.tabs.impShare.contentImpression')}</Text>
                  <div className={styles.comparisonGraphDiv}>
                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareAcquired')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          backgroundColor: '#00ecc0',
                          width: `${doubleDecimals(report.content_impression_share)}%`,
                          marginRight: 8,
                        }}
                      />
                      <Text size={16}>{`${doubleDecimals(report.content_impression_share)}%`}</Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLostAdRank')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.content_rank_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ff8586',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.content_rank_lost_impression_share)}%`}
                      </Text>
                    </div>

                    <Text isBold isTitle size={18}>
                      {t('report.tabs.impShare.shareLost')}
                    </Text>
                    <div className={styles.wastedCollapseGraphsContainer}>
                      <div
                        className={styles.graphBar}
                        style={{
                          width: `${doubleDecimals(report.content_budget_lost_impression_share)}%`,
                          marginRight: 8,
                          backgroundColor: '#ffffb0',
                        }}
                      />
                      <Text size={16}>
                        {`${doubleDecimals(report.content_budget_lost_impression_share)}%`}
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <ResponsivePie
                data={[
                  {
                    id: t('report.tabs.impShare.shareAcquired'),
                    label: t('report.tabs.impShare.shareAcquired'),
                    value: doubleDecimals(report.search_absolute_top_impression_share),
                    color: '#85ECC1',
                  },
                  {
                    id: t('report.tabs.impShare.shareLost'),
                    label: t('report.tabs.impShare.shareLost'),
                    value: doubleDecimals(report.search_budget_lost_absolute_top_impression_share),
                    color: '#ddd',
                  },
                  {
                    id: t('report.tabs.impShare.shareLostAdRank'),
                    label: t('report.tabs.impShare.shareLostAdRank'),
                    value: doubleDecimals(report.search_rank_lost_absolute_top_impression_share),
                    color: '#FE8485',
                  },
                ]}
                colors={{ datum: 'data.color' }}
                valueFormat={value => `${value}%`}
                sortByValue
                padAngle={1}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
                arcLabel={item => `${item.value}%`}
                enableArcLinkLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 140,
                    itemHeight: 18,
                    itemTextColor: '#666',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                  },
                ]}
              /> */}
          </PanelSection>
        </Panel>

        {/* <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>Mobile Advertising</Text>
              <ProgressBar percentageNumber={report.grader_mobile_ad} />
            </div>
          }
          key="9"
        >
          <PanelSection
            onCtaClick={onCtaClick}
            title="Mobile Advertising"
            score={report.grader_mobile_ad}
            description={
              <>
                <strong style={{ color: '#F26234' }}>
                  {formatCurrency(report.mobile_ad_projected_mobile_budget, {
                    currency,
                    language,
                  })}
                </strong>{' '}
                is the projected budget on mobile devices. It represent{' '}
                {doubleDecimals(report.mobile_ad_projected_mobile_budget_percentage)}% of your
                overall online advertising spend
              </>
            }
            ctaButtonText={t('report.tabs.mobile.ctaButtonText')}
            ctaTitle={t('report.tabs.mobile.ctaTitle')}
            meansDescription={
              <>
                Your campaigns are still missing out on opportunities to optimize for your mobile ad
                performance:
                <ul>
                  <li>
                    <strong>
                      {report.mobile_ad_sitelink_extension} of your {report.campaigns_total}{' '}
                      campaigns have mobile sitelink extension set up
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {report.mobile_ad_call_extension} of your {report.campaigns_total} campaigns
                      have mobile call extension set up
                    </strong>
                  </li>
                </ul>
              </>
            }
          >
            <div style={{ marginBottom: 32 }}>
              <Text size={20}>Mobile % of Total Budget</Text>
              <div className={styles.comparisonGraphDiv}>
                <Text isBold isTitle size={18}>
                  You
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div
                    className={styles.youGraph}
                    style={{
                      width:
                        getBenchmarkProp('mobile_%_of_total_budget', benchmarks) !== '-'
                          ? (report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2) /
                              getBenchmarkProp('mobile_%_of_total_budget', benchmarks)) *
                            200
                          : (report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2) / 20) *
                            200,
                    }}
                  />
                  <Text size={16}>
                    {report.mobile_ad_projected_mobile_budget_percentage?.toFixed(2)}%
                  </Text>
                </div>
                <Text isBold isTitle size={18}>
                  {t('industryBenchmark')}
                </Text>
                <div className={styles.wastedCollapseGraphsContainer}>
                  <div className={styles.benchmarkGraph} style={{ width: 200 }} />
                  <Text size={16}>
                    {getBenchmarkProp('mobile_%_of_total_budget', benchmarks) !== '-'
                      ? getBenchmarkProp('mobile_%_of_total_budget', benchmarks)
                      : 20}
                    %
                  </Text>
                </div>
              </div>
            </div>

            <Text size={20}>Device Comparison Analysis</Text>
            <Table
              pagination={false}
              dataSource={[
                {
                  title: 'Mobile',
                  cost: report.mobile_ad_mobile_cost,
                  ctr: report.mobile_ad_mobile_ctr,
                  topImpRate: report.mobile_ad_mobile_top_imp_rate,
                  avgCpc: report.mobile_ad_mobile_top_avg_cpc,
                },
                {
                  title: 'Desktop',
                  cost: report.mobile_ad_desktop_cost,
                  ctr: report.mobile_ad_desktop_ctr,
                  topImpRate: report.mobile_ad_desktop_top_imp_rate,
                  avgCpc: report.mobile_ad_desktop_top_avg_cpc,
                },
              ]}
              size="small"
            >
              <Column
                title="Device"
                dataIndex="title"
                key="title"
                render={value => <Text isBold>{value}</Text>}
              />
              <Column
                title="Cost"
                dataIndex="cost"
                key="cost"
                render={value =>
                  formatCurrency(value, {
                    currency,
                    language,
                  }) || 0
                }
              />
              <Column
                title="CTR"
                dataIndex="ctr"
                key="ctr"
                render={value => getPercentage(value)}
              />
              <Column
                title="Top Imp. Rate"
                dataIndex="topImpRate"
                key="topImpRate"
                render={value => getPercentage(value)}
              />
              <Column
                title="Avg CPC"
                dataIndex="avgCpc"
                key="avgCpc"
                render={value => formatCurrency(value, { currency, language })}
              />
            </Table>
          </PanelSection>
        </Panel> */}

        <Panel
          header={
            <div className={styles.collapseHeader}>
              <Text size={16}>{t('report.tabs.googleAdsBest.title')}</Text>
              <ProgressBar percentageNumber={report.grader_best_practices} />
            </div>
          }
          key="8"
        >
          <PanelSection
            isCTA={!lowSpendValue}
            onCtaClick={onCtaClick}
            title={t('report.tabs.googleAdsBest.title')}
            score={report.grader_best_practices}
            description={
              <>
                {t('report.tabs.googleAdsBest.description.part1')}
                <strong style={{ color: '#F26234' }}>
                  {t('report.tabs.googleAdsBest.description.part2Jp')}
                  {
                    [
                      report.language_targeting,
                      report.mobile_bid_adjustments,
                      /* report.network_targeting, */
                      report.geo_targeting,
                      report.conversion_tracking,
                      report.multiple_text_ads_running,
                      /*  report.modified_broad_match_type, */
                      report.negative_keywords,
                      report.use_google_extensions,
                      /* report.use_expanded_text_ads, */
                    ].filter(item => item === false).length
                  }{' '}
                  {t('report.tabs.googleAdsBest.description.part2')}
                </strong>{' '}
                {t('report.tabs.googleAdsBest.description.part3')}
              </>
            }
            ctaButtonText={t('report.tabs.googleAdsBest.ctaButtonText')}
            ctaTitle={t('report.tabs.googleAdsBest.ctaTitle')}
            meansDescription={checkingFeedback(
              report.grader_best_practices,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.positive.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.positive.part2')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.neutral.part3')}
                </Text>
              </>,
              <>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part1')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part2')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part3')}
                </Text>
                <Text size={16}>
                  {t('report.tabs.googleAdsBest.meansDescription.negative.part4')}
                </Text>
              </>
            )}
          >
            <Alert
              message={t('report.tabs.googleAdsBest.failedText')}
              type="error"
              description={
                <ul>
                  {report.language_targeting === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.languageTargeting.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.languageTargeting.textFailed')}
                    </li>
                  )}

                  {report.mobile_bid_adjustments === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.mobileBid.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.mobileBid.textFailed')}
                    </li>
                  )}

                  {report.geo_targeting === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.geoTargeting.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.geoTargeting.textFailed')}
                    </li>
                  )}

                  {report.conversion_tracking === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.conversionTracking.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.conversionTracking.textFailed')}
                    </li>
                  )}

                  {report.multiple_text_ads_running === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.multipleTextAds.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.multipleTextAds.textFailed')}
                    </li>
                  )}

                  {report.negative_keywords === false && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.negativeKeywords.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.negativeKeywords.textFailed')}
                    </li>
                  )}

                  {report.use_google_extensions === false && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.gaaExtensions.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.gaaExtensions.textFailed')}
                    </li>
                  )}
                </ul>
              }
              showIcon
            />
            <br />
            <br />
            <Alert
              message={t('report.tabs.googleAdsBest.passedText')}
              type="success"
              description={
                <ul>
                  {report.language_targeting === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.languageTargeting.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.languageTargeting.textPessed')}
                    </li>
                  )}

                  {report.mobile_bid_adjustments === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.mobileBid.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.mobileBid.textPessed')}
                    </li>
                  )}

                  {report.geo_targeting === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.geoTargeting.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.geoTargeting.textPessed')}
                    </li>
                  )}

                  {report.conversion_tracking === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.conversionTracking.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.conversionTracking.textPessed')}
                    </li>
                  )}

                  {report.multiple_text_ads_running === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.multipleTextAds.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.multipleTextAds.textPessed')}
                    </li>
                  )}

                  {report.negative_keywords === true && (
                    <li>
                      <strong>
                        {t('report.tabs.googleAdsBest.message.negativeKeywords.title')}
                      </strong>
                      {t('report.tabs.googleAdsBest.message.negativeKeywords.textPessed')}
                    </li>
                  )}

                  {report.use_google_extensions === true && (
                    <li>
                      <strong>{t('report.tabs.googleAdsBest.message.gaaExtensions.title')}</strong>
                      {t('report.tabs.googleAdsBest.message.gaaExtensions.textPessed')}
                    </li>
                  )}
                </ul>
              }
              showIcon
            />
          </PanelSection>
        </Panel>
      </Collapse>
    </section>
  )
}
OverallSection.propTypes = {
  report: PropTypes.instanceOf(Object).isRequired,
  benchmarks: PropTypes.instanceOf(Object).isRequired,
}

OverallSection.defaultProps = {}
