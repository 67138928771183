import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { FaQuestionCircle } from 'react-icons/fa'
import styles from './smallerPercentageCard.module.scss'
import Text from '../Text/text'

const SmallerPerformanceCard = ({ titleText, percentageNumber }) => {
  const percentageColor = percentage => {
    if (percentage <= 40) {
      return 'percentage1'
    }
    if (percentage < 65) {
      return 'percentage2'
    }
    return 'percentage3'
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleDiv}>
        <Text size={18} color="black" isBold isUppercase>
          {titleText}
        </Text>
        {/* <Tooltip
          className={styles.tooltipText}
          placement="top"
          color="black"
          getPopupContainer={triggerNode => triggerNode.parentNode}
          title={
            <Text color="white" size={14}>
              Tooltip text to be provided later
            </Text>
          }
        >
          <FaQuestionCircle />
        </Tooltip> */}
      </div>

      <div className={styles.percentageDiv}>
        <Text color={percentageColor(percentageNumber)} isBold isTitle size={100}>
          {Number(percentageNumber) < 0 ? 0 : percentageNumber?.toFixed(0)}
          <span>%</span>
        </Text>
        <div className={styles.progressBar}>
          <div
            className={classNames([[styles[`progress-${percentageColor(percentageNumber)}`]]])}
            style={{
              height: 10,
              width: Number(percentageNumber) <= 0 ? 0 : percentageNumber * 2,
              borderRadius: 16,
            }}
          />
        </div>
      </div>
    </div>
  )
}

SmallerPerformanceCard.propTypes = {
  titleText: PropTypes.string,
  percentageNumber: PropTypes.number,
}

SmallerPerformanceCard.defaultProps = {
  titleText: 'Ads Performance Grade',
  percentageNumber: 50,
}

export default SmallerPerformanceCard
