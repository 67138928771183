import { UPDATE_USER } from '@utils/constants'

const initialState = {
  customerId: '',
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
