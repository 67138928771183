import globalIcon from '../images/DiscoverAds/globalIcon.png'

const googleCountries = [
  { value: '', label: 'Anywhere', flag: globalIcon },
  { value: '2008', label: 'Albania', flag: 'https://flagcdn.com/al.svg' },
  { value: '2010', label: 'Antarctica', flag: 'https://flagcdn.com/aq.svg' },
  { value: '2012', label: 'Algeria', flag: 'https://flagcdn.com/dz.svg' },
  { value: '2016', label: 'American Samoa', flag: 'https://flagcdn.com/as.svg' },
  { value: '2020', label: 'Andorra', flag: 'https://flagcdn.com/ad.svg' },
  { value: '2024', label: 'Angola', flag: 'https://flagcdn.com/ao.svg' },
  { value: '2028', label: 'Antigua and Barbuda', flag: 'https://flagcdn.com/ag.svg' },
  { value: '2031', label: 'Azerbaijan', flag: 'https://flagcdn.com/az.svg' },
  { value: '2032', label: 'Argentina', flag: 'https://flagcdn.com/ar.svg' },
  { value: '2036', label: 'Australia', flag: 'https://flagcdn.com/au.svg' },
  { value: '2040', label: 'Austria', flag: 'https://flagcdn.com/at.svg' },
  { value: '2044', label: 'The Bahamas', flag: 'https://flagcdn.com/bs.svg' },
  { value: '2048', label: 'Bahrain', flag: 'https://flagcdn.com/bh.svg' },
  { value: '2050', label: 'Bangladesh', flag: 'https://flagcdn.com/bd.svg' },
  { value: '2051', label: 'Armenia', flag: 'https://flagcdn.com/am.svg' },
  { value: '2052', label: 'Barbados', flag: 'https://flagcdn.com/bb.svg' },
  { value: '2056', label: 'Belgium', flag: 'https://flagcdn.com/be.svg' },
  { value: '2060', label: 'Bermuda', flag: 'https://flagcdn.com/bm.svg' },
  { value: '2064', label: 'Bhutan', flag: 'https://flagcdn.com/bt.svg' },
  { value: '2068', label: 'Bolivia', flag: 'https://flagcdn.com/bo.svg' },
  { value: '2070', label: 'Bosnia and Herzegovina', flag: 'https://flagcdn.com/ba.svg' },
  { value: '2072', label: 'Botswana', flag: 'https://flagcdn.com/bw.svg' },
  { value: '2074', label: 'Bouvet Island', flag: 'https://flagcdn.com/bv.svg' },
  { value: '2076', label: 'Brazil', flag: 'https://flagcdn.com/br.svg' },
  { value: '2084', label: 'Belize', flag: 'https://flagcdn.com/bz.svg' },
  { value: '2086', label: 'British Indian Ocean Territory', flag: 'https://flagcdn.com/io.svg' },
  { value: '2090', label: 'Solomon Islands', flag: 'https://flagcdn.com/sb.svg' },
  { value: '2092', label: 'British Virgin Islands', flag: 'https://flagcdn.com/vg.svg' },
  { value: '2096', label: 'Brunei', flag: 'https://flagcdn.com/bn.svg' },
  { value: '2100', label: 'Bulgaria', flag: 'https://flagcdn.com/bg.svg' },
  { value: '2104', label: 'Myanmar (Burma)', flag: 'https://flagcdn.com/mm.svg' },
  { value: '2108', label: 'Burundi', flag: 'https://flagcdn.com/bi.svg' },
  { value: '2112', label: 'Belarus', flag: 'https://flagcdn.com/by.svg' },
  { value: '2116', label: 'Cambodia', flag: 'https://flagcdn.com/kh.svg' },
  { value: '2120', label: 'Cameroon', flag: 'https://flagcdn.com/cm.svg' },
  { value: '2124', label: 'Canada', flag: 'https://flagcdn.com/ca.svg' },
  { value: '2132', label: 'Cabo Verde', flag: 'https://flagcdn.com/cv.svg' },
  { value: '2136', label: 'Cayman Islands', flag: 'https://flagcdn.com/ky.svg' },
  { value: '2140', label: 'Central African Republic', flag: 'https://flagcdn.com/cf.svg' },
  { value: '2144', label: 'Sri Lanka', flag: 'https://flagcdn.com/lk.svg' },
  { value: '2148', label: 'Chad', flag: 'https://flagcdn.com/td.svg' },
  { value: '2152', label: 'Chile', flag: 'https://flagcdn.com/cl.svg' },
  { value: '2156', label: 'China', flag: 'https://flagcdn.com/cn.svg' },
  { value: '2158', label: 'Taiwan', flag: 'https://flagcdn.com/tw.svg' },
  { value: '2162', label: 'Christmas Island', flag: 'https://flagcdn.com/cx.svg' },
  { value: '2166', label: 'Cocos (Keeling) Islands', flag: 'https://flagcdn.com/cc.svg' },
  { value: '2170', label: 'Colombia', flag: 'https://flagcdn.com/co.svg' },
  { value: '2174', label: 'Comoros', flag: 'https://flagcdn.com/km.svg' },
  { value: '2175', label: 'Mayotte', flag: 'https://flagcdn.com/yt.svg' },
  { value: '2178', label: 'Republic of the Congo', flag: 'https://flagcdn.com/cg.svg' },
  { value: '2180', label: 'Democratic Republic of the Congo', flag: 'https://flagcdn.com/cd.svg' },
  { value: '2184', label: 'Cook Islands', flag: 'https://flagcdn.com/ck.svg' },
  { value: '2188', label: 'Costa Rica', flag: 'https://flagcdn.com/cr.svg' },
  { value: '2191', label: 'Croatia', flag: 'https://flagcdn.com/hr.svg' },
  { value: '2196', label: 'Cyprus', flag: 'https://flagcdn.com/cy.svg' },
  { value: '2203', label: 'Czechia', flag: 'https://flagcdn.com/cz.svg' },
  { value: '2204', label: 'Benin', flag: 'https://flagcdn.com/bj.svg' },
  { value: '2208', label: 'Denmark', flag: 'https://flagcdn.com/dk.svg' },
  { value: '2212', label: 'Dominica', flag: 'https://flagcdn.com/dm.svg' },
  { value: '2214', label: 'Dominican Republic', flag: 'https://flagcdn.com/do.svg' },
  { value: '2218', label: 'Ecuador', flag: 'https://flagcdn.com/ec.svg' },
  { value: '2222', label: 'El Salvador', flag: 'https://flagcdn.com/sv.svg' },
  { value: '2226', label: 'Equatorial Guinea', flag: 'https://flagcdn.com/gq.svg' },
  { value: '2231', label: 'Ethiopia', flag: 'https://flagcdn.com/et.svg' },
  { value: '2232', label: 'Eritrea', flag: 'https://flagcdn.com/er.svg' },
  { value: '2233', label: 'Estonia', flag: 'https://flagcdn.com/ee.svg' },
  { value: '2234', label: 'Faroe Islands', flag: 'https://flagcdn.com/fo.svg' },
  { value: '2238', label: 'Falkland Islands (Islas Malvinas)', flag: 'https://flagcdn.com/fk.svg' },
  {
    value: '2239',
    label: 'South Georgia and the South Sandwich Islands',
    flag: 'https://flagcdn.com/gs.svg',
  },
  { value: '2242', label: 'Fiji', flag: 'https://flagcdn.com/fj.svg' },
  { value: '2246', label: 'Finland', flag: 'https://flagcdn.com/fi.svg' },
  { value: '2250', label: 'France', flag: 'https://flagcdn.com/fr.svg' },
  { value: '2254', label: 'French Guiana', flag: 'https://flagcdn.com/gf.svg' },
  { value: '2258', label: 'French Polynesia', flag: 'https://flagcdn.com/pf.svg' },
  {
    value: '2260',
    label: 'French Southern and Antarctic Lands',
    flag: 'https://flagcdn.com/tf.svg',
  },
  { value: '2262', label: 'Djibouti', flag: 'https://flagcdn.com/dj.svg' },
  { value: '2266', label: 'Gabon', flag: 'https://flagcdn.com/ga.svg' },
  { value: '2268', label: 'Georgia', flag: 'https://flagcdn.com/ge.svg' },
  { value: '2270', label: 'The Gambia', flag: 'https://flagcdn.com/gm.svg' },
  { value: '2275', label: 'Palestine', flag: 'https://flagcdn.com/ps.svg' },
  { value: '2276', label: 'Germany', flag: 'https://flagcdn.com/de.svg' },
  { value: '2288', label: 'Ghana', flag: 'https://flagcdn.com/gh.svg' },
  { value: '2292', label: 'Gibraltar', flag: 'https://flagcdn.com/gi.svg' },
  { value: '2296', label: 'Kiribati', flag: 'https://flagcdn.com/ki.svg' },
  { value: '2300', label: 'Greece', flag: 'https://flagcdn.com/gr.svg' },
  { value: '2304', label: 'Greenland', flag: 'https://flagcdn.com/gl.svg' },
  { value: '2308', label: 'Grenada', flag: 'https://flagcdn.com/gd.svg' },
  { value: '2312', label: 'Guadeloupe', flag: 'https://flagcdn.com/gp.svg' },
  { value: '2316', label: 'Guam', flag: 'https://flagcdn.com/gu.svg' },
  { value: '2320', label: 'Guatemala', flag: 'https://flagcdn.com/gt.svg' },
  { value: '2324', label: 'Guinea', flag: 'https://flagcdn.com/gn.svg' },
  { value: '2328', label: 'Guyana', flag: 'https://flagcdn.com/gy.svg' },
  { value: '2332', label: 'Haiti', flag: 'https://flagcdn.com/ht.svg' },
  { value: '2334', label: 'Heard Island and McDonald Islands', flag: 'https://flagcdn.com/hm.svg' },
  { value: '2336', label: 'Vatican City', flag: 'https://flagcdn.com/va.svg' },
  { value: '2340', label: 'Honduras', flag: 'https://flagcdn.com/hn.svg' },
  { value: '2344', label: 'Hong Kong', flag: 'https://flagcdn.com/hk.svg' },
  { value: '2348', label: 'Hungary', flag: 'https://flagcdn.com/hu.svg' },
  { value: '2352', label: 'Iceland', flag: 'https://flagcdn.com/is.svg' },
  { value: '2356', label: 'India', flag: 'https://flagcdn.com/in.svg' },
  { value: '2360', label: 'Indonesia', flag: 'https://flagcdn.com/id.svg' },
  { value: '2368', label: 'Iraq', flag: 'https://flagcdn.com/iq.svg' },
  { value: '2372', label: 'Ireland', flag: 'https://flagcdn.com/ie.svg' },
  { value: '2376', label: 'Israel', flag: 'https://flagcdn.com/il.svg' },
  { value: '2380', label: 'Italy', flag: 'https://flagcdn.com/it.svg' },
  { value: '2384', label: "Cote d'Ivoire", flag: 'https://flagcdn.com/ci.svg' },
  { value: '2388', label: 'Jamaica', flag: 'https://flagcdn.com/jm.svg' },
  { value: '2392', label: 'Japan', flag: 'https://flagcdn.com/jp.svg' },
  { value: '2398', label: 'Kazakhstan', flag: 'https://flagcdn.com/kz.svg' },
  { value: '2400', label: 'Jordan', flag: 'https://flagcdn.com/jo.svg' },
  { value: '2404', label: 'Kenya', flag: 'https://flagcdn.com/ke.svg' },
  { value: '2410', label: 'South Korea', flag: 'https://flagcdn.com/kr.svg' },
  { value: '2414', label: 'Kuwait', flag: 'https://flagcdn.com/kw.svg' },
  { value: '2417', label: 'Kyrgyzstan', flag: 'https://flagcdn.com/kg.svg' },
  { value: '2418', label: 'Laos', flag: 'https://flagcdn.com/la.svg' },
  { value: '2422', label: 'Lebanon', flag: 'https://flagcdn.com/lb.svg' },
  { value: '2426', label: 'Lesotho', flag: 'https://flagcdn.com/ls.svg' },
  { value: '2428', label: 'Latvia', flag: 'https://flagcdn.com/lv.svg' },
  { value: '2430', label: 'Liberia', flag: 'https://flagcdn.com/lr.svg' },
  { value: '2434', label: 'Libya', flag: 'https://flagcdn.com/ly.svg' },
  { value: '2438', label: 'Liechtenstein', flag: 'https://flagcdn.com/li.svg' },
  { value: '2440', label: 'Lithuania', flag: 'https://flagcdn.com/lt.svg' },
  { value: '2442', label: 'Luxembourg', flag: 'https://flagcdn.com/lu.svg' },
  { value: '2446', label: 'Macao', flag: 'https://flagcdn.com/mo.svg' },
  { value: '2450', label: 'Madagascar', flag: 'https://flagcdn.com/mg.svg' },
  { value: '2454', label: 'Malawi', flag: 'https://flagcdn.com/mw.svg' },
  { value: '2458', label: 'Malaysia', flag: 'https://flagcdn.com/my.svg' },
  { value: '2462', label: 'Maldives', flag: 'https://flagcdn.com/mv.svg' },
  { value: '2466', label: 'Mali', flag: 'https://flagcdn.com/ml.svg' },
  { value: '2470', label: 'Malta', flag: 'https://flagcdn.com/mt.svg' },
  { value: '2474', label: 'Martinique', flag: 'https://flagcdn.com/mq.svg' },
  { value: '2478', label: 'Mauritania', flag: 'https://flagcdn.com/mr.svg' },
  { value: '2480', label: 'Mauritius', flag: 'https://flagcdn.com/mu.svg' },
  { value: '2484', label: 'Mexico', flag: 'https://flagcdn.com/mx.svg' },
  { value: '2492', label: 'Monaco', flag: 'https://flagcdn.com/mc.svg' },
  { value: '2496', label: 'Mongolia', flag: 'https://flagcdn.com/mn.svg' },
  { value: '2498', label: 'Moldova', flag: 'https://flagcdn.com/md.svg' },
  { value: '2499', label: 'Montenegro', flag: 'https://flagcdn.com/me.svg' },
  { value: '2500', label: 'Montserrat', flag: 'https://flagcdn.com/ms.svg' },
  { value: '2504', label: 'Morocco', flag: 'https://flagcdn.com/ma.svg' },
  { value: '2508', label: 'Mozambique', flag: 'https://flagcdn.com/mz.svg' },
  { value: '2512', label: 'Oman', flag: 'https://flagcdn.com/om.svg' },
  { value: '2516', label: 'Namibia', flag: 'https://flagcdn.com/na.svg' },
  { value: '2520', label: 'Nauru', flag: 'https://flagcdn.com/nr.svg' },
  { value: '2524', label: 'Nepal', flag: 'https://flagcdn.com/np.svg' },
  { value: '2528', label: 'Netherlands', flag: 'https://flagcdn.com/nl.svg' },
  { value: '2531', label: 'Curacao', flag: 'https://flagcdn.com/cw.svg' },
  { value: '2533', label: 'Aruba', flag: 'https://flagcdn.com/aw.svg' },
  { value: '2534', label: 'Sint Maarten', flag: 'https://flagcdn.com/sx.svg' },
  { value: '2535', label: 'Caribbean Netherlands', flag: 'https://flagcdn.com/bq.svg' },
  { value: '2540', label: 'New Caledonia', flag: 'https://flagcdn.com/nc.svg' },
  { value: '2548', label: 'Vanuatu', flag: 'https://flagcdn.com/vu.svg' },
  { value: '2554', label: 'New Zealand', flag: 'https://flagcdn.com/nz.svg' },
  { value: '2558', label: 'Nicaragua', flag: 'https://flagcdn.com/ni.svg' },
  { value: '2562', label: 'Niger', flag: 'https://flagcdn.com/ne.svg' },
  { value: '2566', label: 'Nigeria', flag: 'https://flagcdn.com/ng.svg' },
  { value: '2570', label: 'Niue', flag: 'https://flagcdn.com/nu.svg' },
  { value: '2574', label: 'Norfolk Island', flag: 'https://flagcdn.com/nf.svg' },
  { value: '2578', label: 'Norway', flag: 'https://flagcdn.com/no.svg' },
  { value: '2580', label: 'Northern Mariana Islands', flag: 'https://flagcdn.com/mp.svg' },
  {
    value: '2581',
    label: 'United States Minor Outlying Islands',
    flag: 'https://flagcdn.com/um.svg',
  },
  { value: '2583', label: 'Micronesia', flag: 'https://flagcdn.com/fm.svg' },
  { value: '2584', label: 'Marshall Islands', flag: 'https://flagcdn.com/mh.svg' },
  { value: '2585', label: 'Palau', flag: 'https://flagcdn.com/pw.svg' },
  { value: '2586', label: 'Pakistan', flag: 'https://flagcdn.com/pk.svg' },
  { value: '2591', label: 'Panama', flag: 'https://flagcdn.com/pa.svg' },
  { value: '2598', label: 'Papua New Guinea', flag: 'https://flagcdn.com/pg.svg' },
  { value: '2600', label: 'Paraguay', flag: 'https://flagcdn.com/py.svg' },
  { value: '2604', label: 'Peru', flag: 'https://flagcdn.com/pe.svg' },
  { value: '2608', label: 'Philippines', flag: 'https://flagcdn.com/ph.svg' },
  { value: '2612', label: 'Pitcairn Islands', flag: 'https://flagcdn.com/pn.svg' },
  { value: '2616', label: 'Poland', flag: 'https://flagcdn.com/pl.svg' },
  { value: '2620', label: 'Portugal', flag: 'https://flagcdn.com/pt.svg' },
  { value: '2624', label: 'Guinea-Bissau', flag: 'https://flagcdn.com/gw.svg' },
  { value: '2626', label: 'Timor-Leste', flag: 'https://flagcdn.com/tl.svg' },
  { value: '2630', label: 'Puerto Rico', flag: 'https://flagcdn.com/pr.svg' },
  { value: '2634', label: 'Qatar', flag: 'https://flagcdn.com/qa.svg' },
  { value: '2638', label: 'Reunion', flag: 'https://flagcdn.com/re.svg' },
  { value: '2642', label: 'Romania', flag: 'https://flagcdn.com/ro.svg' },
  { value: '2646', label: 'Rwanda', flag: 'https://flagcdn.com/rw.svg' },
  {
    value: '2654',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: 'https://flagcdn.com/sh.svg',
  },
  { value: '2659', label: 'Saint Kitts and Nevis', flag: 'https://flagcdn.com/kn.svg' },
  { value: '2660', label: 'Anguilla', flag: 'https://flagcdn.com/ai.svg' },
  { value: '2662', label: 'Saint Lucia', flag: 'https://flagcdn.com/lc.svg' },
  { value: '2663', label: 'Saint Martin', flag: 'https://flagcdn.com/mf.svg' },
  { value: '2666', label: 'Saint Pierre and Miquelon', flag: 'https://flagcdn.com/pm.svg' },
  { value: '2670', label: 'Saint Vincent and the Grenadines', flag: 'https://flagcdn.com/vc.svg' },
  { value: '2674', label: 'San Marino', flag: 'https://flagcdn.com/sm.svg' },
  { value: '2678', label: 'Sao Tome and Principe', flag: 'https://flagcdn.com/st.svg' },
  { value: '2682', label: 'Saudi Arabia', flag: 'https://flagcdn.com/sa.svg' },
  { value: '2686', label: 'Senegal', flag: 'https://flagcdn.com/sn.svg' },
  { value: '2690', label: 'Seychelles', flag: 'https://flagcdn.com/sc.svg' },
  { value: '2694', label: 'Sierra Leone', flag: 'https://flagcdn.com/sl.svg' },
  { value: '2702', label: 'Singapore', flag: 'https://flagcdn.com/sg.svg' },
  { value: '2703', label: 'Slovakia', flag: 'https://flagcdn.com/sk.svg' },
  { value: '2704', label: 'Vietnam', flag: 'https://flagcdn.com/vn.svg' },
  { value: '2705', label: 'Slovenia', flag: 'https://flagcdn.com/si.svg' },
  { value: '2706', label: 'Somalia', flag: 'https://flagcdn.com/so.svg' },
  { value: '2710', label: 'South Africa', flag: 'https://flagcdn.com/za.svg' },
  { value: '2716', label: 'Zimbabwe', flag: 'https://flagcdn.com/zw.svg' },
  { value: '2724', label: 'Spain', flag: 'https://flagcdn.com/es.svg' },
  { value: '2732', label: 'Western Sahara', flag: 'https://flagcdn.com/eh.svg' },
  { value: '2740', label: 'Suriname', flag: 'https://flagcdn.com/sr.svg' },
  { value: '2744', label: 'Svalbard and Jan Mayen', flag: 'https://flagcdn.com/sj.svg' },
  { value: '2748', label: 'Eswatini', flag: 'https://flagcdn.com/sz.svg' },
  { value: '2752', label: 'Sweden', flag: 'https://flagcdn.com/se.svg' },
  { value: '2756', label: 'Switzerland', flag: 'https://flagcdn.com/ch.svg' },
  { value: '2762', label: 'Tajikistan', flag: 'https://flagcdn.com/tj.svg' },
  { value: '2764', label: 'Thailand', flag: 'https://flagcdn.com/th.svg' },
  { value: '2768', label: 'Togo', flag: 'https://flagcdn.com/tg.svg' },
  { value: '2772', label: 'Tokelau', flag: 'https://flagcdn.com/tk.svg' },
  { value: '2776', label: 'Tonga', flag: 'https://flagcdn.com/to.svg' },
  { value: '2780', label: 'Trinidad and Tobago', flag: 'https://flagcdn.com/tt.svg' },
  { value: '2784', label: 'United Arab Emirates', flag: 'https://flagcdn.com/ae.svg' },
  { value: '2788', label: 'Tunisia', flag: 'https://flagcdn.com/tn.svg' },
  { value: '2792', label: 'Turkiye', flag: 'https://flagcdn.com/tr.svg' },
  { value: '2795', label: 'Turkmenistan', flag: 'https://flagcdn.com/tm.svg' },
  { value: '2796', label: 'Turks and Caicos Islands', flag: 'https://flagcdn.com/tc.svg' },
  { value: '2798', label: 'Tuvalu', flag: 'https://flagcdn.com/tv.svg' },
  { value: '2800', label: 'Uganda', flag: 'https://flagcdn.com/ug.svg' },
  { value: '2804', label: 'Ukraine', flag: 'https://flagcdn.com/ua.svg' },
  { value: '2807', label: 'North Macedonia', flag: 'https://flagcdn.com/mk.svg' },
  { value: '2818', label: 'Egypt', flag: 'https://flagcdn.com/eg.svg' },
  { value: '2826', label: 'United Kingdom', flag: 'https://flagcdn.com/gb.svg' },

  { value: '2831', label: 'Guernsey', flag: 'https://flagcdn.com/gg.svg' },
  { value: '2832', label: 'Jersey', flag: 'https://flagcdn.com/je.svg' },
  { value: '2833', label: 'Isle of Man', flag: 'https://flagcdn.com/im.svg' },
  { value: '2834', label: 'Tanzania', flag: 'https://flagcdn.com/tz.svg' },
  { value: '2840', label: 'United States', flag: 'https://flagcdn.com/us.svg' },
  { value: '2850', label: 'U.S. Virgin Islands', flag: 'https://flagcdn.com/vi.svg' },
  { value: '2854', label: 'Burkina Faso', flag: 'https://flagcdn.com/bf.svg' },
  { value: '2858', label: 'Uruguay', flag: 'https://flagcdn.com/uy.svg' },
  { value: '2860', label: 'Uzbekistan', flag: 'https://flagcdn.com/uz.svg' },
  { value: '2862', label: 'Venezuela', flag: 'https://flagcdn.com/ve.svg' },
  { value: '2876', label: 'Wallis and Futuna', flag: 'https://flagcdn.com/wf.svg' },
  { value: '2887', label: 'Yemen', flag: 'https://flagcdn.com/ye.svg' },
  { value: '2894', label: 'Zambia', flag: 'https://flagcdn.com/zm.svg' },
  { value: '20277', label: 'Canary Islands', flag: 'https://flagcdn.com/es.svg' },
]

// const metaCountries = [
//   { value: 'ALL', label: 'Anywhere' },
//   { value: 'BR', label: 'Brazil', flag: 'https://flagcdn.com/w320/br.png' },
//   { value: 'IN', label: 'India', flag: 'https://flagcdn.com/w320/in.png' },
//   { value: 'GB', label: 'United Kingdom', flag: 'https://flagcdn.com/w320/gb.png' },
//   { value: 'US', label: 'United States', flag: 'https://flagcdn.com/w320/us.png' },
//   { value: 'CA', label: 'Canada', flag: 'https://flagcdn.com/w320/ca.png' },
//   { value: 'AR', label: 'Argentina', flag: 'https://flagcdn.com/w320/ar.png' },
//   { value: 'AU', label: 'Australia', flag: 'https://flagcdn.com/w320/au.png' },
//   { value: 'AT', label: 'Austria', flag: 'https://flagcdn.com/w320/at.png' },
//   { value: 'BE', label: 'Belgium', flag: 'https://flagcdn.com/w320/be.png' },
//   { value: 'CL', label: 'Chile', flag: 'https://flagcdn.com/w320/cl.png' },
//   { value: 'CN', label: 'China', flag: 'https://flagcdn.com/w320/cn.png' },
//   { value: 'CO', label: 'Colombia', flag: 'https://flagcdn.com/w320/co.png' },
//   { value: 'HR', label: 'Croatia', flag: 'https://flagcdn.com/w320/hr.png' },
//   { value: 'DK', label: 'Denmark', flag: 'https://flagcdn.com/w320/dk.png' },
//   { value: 'DO', label: 'Dominican Republic', flag: 'https://flagcdn.com/w320/do.png' },
//   { value: 'EG', label: 'Egypt', flag: 'https://flagcdn.com/w320/eg.png' },
//   { value: 'FI', label: 'Finland', flag: 'https://flagcdn.com/w320/fi.png' },
//   { value: 'FR', label: 'France', flag: 'https://flagcdn.com/w320/fr.png' },
//   { value: 'DE', label: 'Germany', flag: 'https://flagcdn.com/w320/de.png' },
//   { value: 'GR', label: 'Greece', flag: 'https://flagcdn.com/w320/gr.png' },
//   { value: 'HK', label: 'Hong Kong', flag: 'https://flagcdn.com/w320/hk.png' },
//   { value: 'ID', label: 'Indonesia', flag: 'https://flagcdn.com/w320/id.png' },
//   { value: 'IE', label: 'Ireland', flag: 'https://flagcdn.com/w320/ie.png' },
//   { value: 'IL', label: 'Israel', flag: 'https://flagcdn.com/w320/il.png' },
//   { value: 'IT', label: 'Italy', flag: 'https://flagcdn.com/w320/it.png' },
//   { value: 'JP', label: 'Japan', flag: 'https://flagcdn.com/w320/jp.png' },
//   { value: 'PK', label: 'Pakistan', flag: 'https://flagcdn.com/w320/pk.png' },
//   { value: 'KR', label: 'South Korea', flag: 'https://flagcdn.com/w320/kr.png' },
//   { value: 'MX', label: 'Mexico', flag: 'https://flagcdn.com/w320/mx.png' },
//   { value: 'MY', label: 'Malaysia', flag: 'https://flagcdn.com/w320/my.png' },
//   { value: 'NG', label: 'Nigeria', flag: 'https://flagcdn.com/w320/ng.png' },
//   { value: 'NL', label: 'Netherlands', flag: 'https://flagcdn.com/w320/nl.png' },
//   { value: 'NZ', label: 'New Zealand', flag: 'https://flagcdn.com/w320/nz.png' },
//   { value: 'NO', label: 'Norway', flag: 'https://flagcdn.com/w320/no.png' },
//   { value: 'PH', label: 'Philippines', flag: 'https://flagcdn.com/w320/ph.png' },
//   { value: 'PL', label: 'Poland', flag: 'https://flagcdn.com/w320/pl.png' },
//   { value: 'PT', label: 'Portugal', flag: 'https://flagcdn.com/w320/pt.png' },
//   { value: 'RU', label: 'Russia', flag: 'https://flagcdn.com/w320/ru.png' },
//   { value: 'SA', label: 'Saudi Arabia', flag: 'https://flagcdn.com/w320/sa.png' },
//   { value: 'SG', label: 'Singapore', flag: 'https://flagcdn.com/w320/sg.png' },
//   { value: 'ZA', label: 'South Africa', flag: 'https://flagcdn.com/w320/za.png' },
//   { value: 'ES', label: 'Spain', flag: 'https://flagcdn.com/w320/es.png' },
//   { value: 'SE', label: 'Sweden', flag: 'https://flagcdn.com/w320/se.png' },
//   { value: 'CH', label: 'Switzerland', flag: 'https://flagcdn.com/w320/ch.png' },
//   { value: 'TH', label: 'Thailand', flag: 'https://flagcdn.com/w320/th.png' },
//   { value: 'TR', label: 'Turkey', flag: 'https://flagcdn.com/w320/tr.png' },
//   { value: 'UA', label: 'Ukraine', flag: 'https://flagcdn.com/w320/ua.png' },
//   { value: 'AE', label: 'United Arab Emirates', flag: 'https://flagcdn.com/w320/ae.png' },
//   { value: 'VN', label: 'Vietnam', flag: 'https://flagcdn.com/w320/vn.png' },
//   { value: 'ZW', label: 'Zimbabwe', flag: 'https://flagcdn.com/w320/zw.png' },
//   { value: 'CI', label: "Côte d'Ivoire", flag: 'https://flagcdn.com/w320/ci.png' },
//   { value: 'MM', label: 'Myanmar', flag: 'https://flagcdn.com/w320/mm.png' },
//   { value: 'AQ', label: 'Antarctica', flag: 'https://flagcdn.com/w320/aq.png' },
//   { value: 'BQ', label: 'Bonaire', flag: 'https://flagcdn.com/w320/bq.png' },
//   { value: 'BV', label: 'Bouvet Island', flag: 'https://flagcdn.com/w320/bv.png' },
//   { value: 'IO', label: 'British Indian Ocean Territory', flag: 'https://flagcdn.com/w320/io.png' },
//   { value: 'CX', label: 'Christmas Island', flag: 'https://flagcdn.com/w320/cx.png' },
//   { value: 'CC', label: 'Cocos (Keeling) Islands', flag: 'https://flagcdn.com/w320/cc.png' },
//   { value: 'CK', label: 'Cook Islands', flag: 'https://flagcdn.com/w320/ck.png' },
//   { value: 'CW', label: 'Curaçao', flag: 'https://flagcdn.com/w320/cw.png' },
//   {
//     value: 'TF',
//     label: 'French Southern and Antarctic Lands',
//     flag: 'https://flagcdn.com/w320/tf.png',
//   },
//   { value: 'GW', label: 'Guinea-Bissau', flag: 'https://flagcdn.com/w320/gw.png' },
//   {
//     value: 'HM',
//     label: 'Heard Island and McDonald Islands',
//     flag: 'https://flagcdn.com/w320/hm.png',
//   },
//   { value: 'XK', label: 'Kosovo', flag: 'https://flagcdn.com/w320/xk.png' },
//   { value: 'MS', label: 'Montserrat', flag: 'https://flagcdn.com/w320/ms.png' },
//   { value: 'NU', label: 'Niue', flag: 'https://flagcdn.com/w320/nu.png' },
//   { value: 'NF', label: 'Norfolk Island', flag: 'https://flagcdn.com/w320/nf.png' },
//   { value: 'PN', label: 'Pitcairn Islands', flag: 'https://flagcdn.com/w320/pn.png' },
//   { value: 'BL', label: 'Saint Barthélemy', flag: 'https://flagcdn.com/w320/bl.png' },
//   {
//     value: 'SH',
//     label: 'Saint Helena, Ascension and Tristan da Cunha',
//     flag: 'https://flagcdn.com/w320/sh.png',
//   },
//   { value: 'MF', label: 'Saint Martin', flag: 'https://flagcdn.com/w320/mf.png' },
//   { value: 'PM', label: 'Saint Pierre and Miquelon', flag: 'https://flagcdn.com/w320/pm.png' },
//   { value: 'SX', label: 'Sint Maarten', flag: 'https://flagcdn.com/w320/sx.png' },
//   {
//     value: 'GS',
//     label: 'South Georgia and the South Sandwich Islands',
//     flag: 'https://flagcdn.com/w320/gs.png',
//   },
//   { value: 'SS', label: 'South Sudan', flag: 'https://flagcdn.com/w320/ss.png' },
//   { value: 'SJ', label: 'Svalbard and Jan Mayen', flag: 'https://flagcdn.com/w320/sj.png' },
//   { value: 'TL', label: 'East Timor', flag: 'https://flagcdn.com/w320/tl.png' },
//   { value: 'TK', label: 'Tokelau', flag: 'https://flagcdn.com/w320/tk.png' },
//   {
//     value: 'UM',
//     label: 'United States Minor Outlying Islands',
//     flag: 'https://flagcdn.com/w320/um.png',
//   },
//   { value: 'WF', label: 'Wallis and Futuna', flag: 'https://flagcdn.com/w320/wf.png' },
//   { value: 'EH', label: 'Western Sahara', flag: 'https://flagcdn.com/w320/eh.png' },
// ]
// const metaCountries = [
//   { value: 'AF', label: 'Afghanistan', flag: 'https://flagcdn.com/w320/af.png' },
//   { value: 'AL', label: 'Albania', flag: 'https://flagcdn.com/w320/al.png' },
//   { value: 'DZ', label: 'Algeria', flag: 'https://flagcdn.com/w320/dz.png' },
//   { value: 'AS', label: 'American Samoa', flag: 'https://flagcdn.com/w320/as.png' },
//   { value: 'AD', label: 'Andorra', flag: 'https://flagcdn.com/w320/ad.png' },
//   { value: 'AO', label: 'Angola', flag: 'https://flagcdn.com/w320/ao.png' },
//   { value: 'AI', label: 'Anguilla', flag: 'https://flagcdn.com/w320/ai.png' },
//   { value: 'AQ', label: 'Antarctica', flag: 'https://flagcdn.com/w320/aq.png' },
//   { value: 'AG', label: 'Antigua and Barbuda', flag: 'https://flagcdn.com/w320/ag.png' },
//   { value: 'AR', label: 'Argentina', flag: 'https://flagcdn.com/w320/ar.png' },
//   { value: 'AM', label: 'Armenia', flag: 'https://flagcdn.com/w320/am.png' },
//   { value: 'AW', label: 'Aruba', flag: 'https://flagcdn.com/w320/aw.png' },
//   { value: 'AU', label: 'Australia', flag: 'https://flagcdn.com/w320/au.png' },
//   { value: 'AT', label: 'Austria', flag: 'https://flagcdn.com/w320/at.png' },
//   { value: 'AZ', label: 'Azerbaijan', flag: 'https://flagcdn.com/w320/az.png' },
//   { value: 'BS', label: 'Bahamas', flag: 'https://flagcdn.com/w320/bs.png' },
//   { value: 'BH', label: 'Bahrain', flag: 'https://flagcdn.com/w320/bh.png' },
//   { value: 'BD', label: 'Bangladesh', flag: 'https://flagcdn.com/w320/bd.png' },
//   { value: 'BB', label: 'Barbados', flag: 'https://flagcdn.com/w320/bb.png' },
//   { value: 'BY', label: 'Belarus', flag: 'https://flagcdn.com/w320/by.png' },
//   { value: 'BE', label: 'Belgium', flag: 'https://flagcdn.com/w320/be.png' },
//   { value: 'BZ', label: 'Belize', flag: 'https://flagcdn.com/w320/bz.png' },
//   { value: 'BJ', label: 'Benin', flag: 'https://flagcdn.com/w320/bj.png' },
//   { value: 'BM', label: 'Bermuda', flag: 'https://flagcdn.com/w320/bm.png' },
//   { value: 'BT', label: 'Bhutan', flag: 'https://flagcdn.com/w320/bt.png' },
//   {
//     value: 'BO',
//     label: 'Bolivia (Plurinational State of)',
//     flag: 'https://flagcdn.com/w320/bo.png',
//   },
//   {
//     value: 'BQ',
//     label: 'Bonaire, Sint Eustatius and Saba',
//     flag: 'https://flagcdn.com/w320/bq.png',
//   },
//   { value: 'BA', label: 'Bosnia and Herzegovina', flag: 'https://flagcdn.com/w320/ba.png' },
//   { value: 'BW', label: 'Botswana', flag: 'https://flagcdn.com/w320/bw.png' },
//   { value: 'BV', label: 'Bouvet Island', flag: 'https://flagcdn.com/w320/bv.png' },
//   { value: 'BR', label: 'Brazil', flag: 'https://flagcdn.com/w320/br.png' },
//   { value: 'IO', label: 'British Indian Ocean Territory', flag: 'https://flagcdn.com/w320/io.png' },
//   { value: 'BN', label: 'Brunei Darussalam', flag: 'https://flagcdn.com/w320/bn.png' },
//   { value: 'BG', label: 'Bulgaria', flag: 'https://flagcdn.com/w320/bg.png' },
//   { value: 'BF', label: 'Burkina Faso', flag: 'https://flagcdn.com/w320/bf.png' },
//   { value: 'BI', label: 'Burundi', flag: 'https://flagcdn.com/w320/bi.png' },
//   { value: 'CV', label: 'Cabo Verde', flag: 'https://flagcdn.com/w320/cv.png' },
//   { value: 'KH', label: 'Cambodia', flag: 'https://flagcdn.com/w320/kh.png' },
//   { value: 'CM', label: 'Cameroon', flag: 'https://flagcdn.com/w320/cm.png' },
//   { value: 'CA', label: 'Canada', flag: 'https://flagcdn.com/w320/ca.png' },
//   { value: 'KY', label: 'Cayman Islands', flag: 'https://flagcdn.com/w320/ky.png' },
//   { value: 'CF', label: 'Central African Republic', flag: 'https://flagcdn.com/w320/cf.png' },
//   { value: 'TD', label: 'Chad', flag: 'https://flagcdn.com/w320/td.png' },
//   { value: 'CL', label: 'Chile', flag: 'https://flagcdn.com/w320/cl.png' },
//   { value: 'CN', label: 'China', flag: 'https://flagcdn.com/w320/cn.png' },
//   { value: 'CX', label: 'Christmas Island', flag: 'https://flagcdn.com/w320/cx.png' },
//   { value: 'CC', label: 'Cocos (Keeling) Islands', flag: 'https://flagcdn.com/w320/cc.png' },
//   { value: 'CO', label: 'Colombia', flag: 'https://flagcdn.com/w320/co.png' },
//   { value: 'KM', label: 'Comoros', flag: 'https://flagcdn.com/w320/km.png' },
//   { value: 'CG', label: 'Congo', flag: 'https://flagcdn.com/w320/cg.png' },
//   {
//     value: 'CD',
//     label: 'Congo, Democratic Republic of the',
//     flag: 'https://flagcdn.com/w320/cd.png',
//   },
//   { value: 'CK', label: 'Cook Islands', flag: 'https://flagcdn.com/w320/ck.png' },
//   { value: 'CR', label: 'Costa Rica', flag: 'https://flagcdn.com/w320/cr.png' },
//   { value: 'CI', label: "Côte d'Ivoire", flag: 'https://flagcdn.com/w320/ci.png' },
//   { value: 'HR', label: 'Croatia', flag: 'https://flagcdn.com/w320/hr.png' },
//   { value: 'CU', label: 'Cuba', flag: 'https://flagcdn.com/w320/cu.png' },
//   { value: 'CW', label: 'Curaçao', flag: 'https://flagcdn.com/w320/cw.png' },
//   { value: 'CY', label: 'Cyprus', flag: 'https://flagcdn.com/w320/cy.png' },
//   { value: 'CZ', label: 'Czechia', flag: 'https://flagcdn.com/w320/cz.png' },
//   { value: 'DK', label: 'Denmark', flag: 'https://flagcdn.com/w320/dk.png' },
//   { value: 'DJ', label: 'Djibouti', flag: 'https://flagcdn.com/w320/dj.png' },
//   { value: 'DM', label: 'Dominica', flag: 'https://flagcdn.com/w320/dm.png' },
//   { value: 'DO', label: 'Dominican Republic', flag: 'https://flagcdn.com/w320/do.png' },
//   { value: 'EC', label: 'Ecuador', flag: 'https://flagcdn.com/w320/ec.png' },
//   { value: 'EG', label: 'Egypt', flag: 'https://flagcdn.com/w320/eg.png' },
//   { value: 'SV', label: 'El Salvador', flag: 'https://flagcdn.com/w320/sv.png' },
//   { value: 'GQ', label: 'Equatorial Guinea', flag: 'https://flagcdn.com/w320/gq.png' },
//   { value: 'ER', label: 'Eritrea', flag: 'https://flagcdn.com/w320/er.png' },
//   { value: 'EE', label: 'Estonia', flag: 'https://flagcdn.com/w320/ee.png' },
//   { value: 'SZ', label: 'Eswatini', flag: 'https://flagcdn.com/w320/sz.png' },
//   { value: 'ET', label: 'Ethiopia', flag: 'https://flagcdn.com/w320/et.png' },
//   { value: 'FK', label: 'Falkland Islands (Malvinas)', flag: 'https://flagcdn.com/w320/fk.png' },
//   { value: 'FO', label: 'Faroe Islands', flag: 'https://flagcdn.com/w320/fo.png' },
//   { value: 'FJ', label: 'Fiji', flag: 'https://flagcdn.com/w320/fj.png' },
//   { value: 'FI', label: 'Finland', flag: 'https://flagcdn.com/w320/fi.png' },
//   { value: 'FR', label: 'France', flag: 'https://flagcdn.com/w320/fr.png' },
//   { value: 'GF', label: 'French Guiana', flag: 'https://flagcdn.com/w320/gf.png' },
//   { value: 'PF', label: 'French Polynesia', flag: 'https://flagcdn.com/w320/pf.png' },
//   { value: 'TF', label: 'French Southern Territories', flag: 'https://flagcdn.com/w320/tf.png' },
//   { value: 'GA', label: 'Gabon', flag: 'https://flagcdn.com/w320/ga.png' },
//   { value: 'GM', label: 'Gambia', flag: 'https://flagcdn.com/w320/gm.png' },
//   { value: 'GE', label: 'Georgia', flag: 'https://flagcdn.com/w320/ge.png' },
//   { value: 'DE', label: 'Germany', flag: 'https://flagcdn.com/w320/de.png' },
//   { value: 'GH', label: 'Ghana', flag: 'https://flagcdn.com/w320/gh.png' },
//   { value: 'GI', label: 'Gibraltar', flag: 'https://flagcdn.com/w320/gi.png' },
//   { value: 'GR', label: 'Greece', flag: 'https://flagcdn.com/w320/gr.png' },
//   { value: 'GL', label: 'Greenland', flag: 'https://flagcdn.com/w320/gl.png' },
//   { value: 'GD', label: 'Grenada', flag: 'https://flagcdn.com/w320/gd.png' },
//   { value: 'GP', label: 'Guadeloupe', flag: 'https://flagcdn.com/w320/gp.png' },
//   { value: 'GU', label: 'Guam', flag: 'https://flagcdn.com/w320/gu.png' },
//   { value: 'GT', label: 'Guatemala', flag: 'https://flagcdn.com/w320/gt.png' },
//   { value: 'GG', label: 'Guernsey', flag: 'https://flagcdn.com/w320/gg.png' },
//   { value: 'GN', label: 'Guinea', flag: 'https://flagcdn.com/w320/gn.png' },
//   { value: 'GW', label: 'Guinea-Bissau', flag: 'https://flagcdn.com/w320/gw.png' },
//   { value: 'GY', label: 'Guyana', flag: 'https://flagcdn.com/w320/gy.png' },
//   { value: 'HT', label: 'Haiti', flag: 'https://flagcdn.com/w320/ht.png' },
//   {
//     value: 'HM',
//     label: 'Heard Island and McDonald Islands',
//     flag: 'https://flagcdn.com/w320/hm.png',
//   },
//   { value: 'VA', label: 'Holy See', flag: 'https://flagcdn.com/w320/va.png' },
//   { value: 'HN', label: 'Honduras', flag: 'https://flagcdn.com/w320/hn.png' },
//   { value: 'HK', label: 'Hong Kong', flag: 'https://flagcdn.com/w320/hk.png' },
//   { value: 'HU', label: 'Hungary', flag: 'https://flagcdn.com/w320/hu.png' },
//   { value: 'IS', label: 'Iceland', flag: 'https://flagcdn.com/w320/is.png' },
//   { value: 'IN', label: 'India', flag: 'https://flagcdn.com/w320/in.png' },
//   { value: 'ID', label: 'Indonesia', flag: 'https://flagcdn.com/w320/id.png' },
//   { value: 'IR', label: 'Iran (Islamic Republic of)', flag: 'https://flagcdn.com/w320/ir.png' },
//   { value: 'IQ', label: 'Iraq', flag: 'https://flagcdn.com/w320/iq.png' },
//   { value: 'IE', label: 'Ireland', flag: 'https://flagcdn.com/w320/ie.png' },
//   { value: 'IM', label: 'Isle of Man', flag: 'https://flagcdn.com/w320/im.png' },
//   { value: 'IL', label: 'Israel', flag: 'https://flagcdn.com/w320/il.png' },
//   { value: 'IT', label: 'Italy', flag: 'https://flagcdn.com/w320/it.png' },
//   { value: 'JM', label: 'Jamaica', flag: 'https://flagcdn.com/w320/jm.png' },
//   { value: 'JP', label: 'Japan', flag: 'https://flagcdn.com/w320/jp.png' },
//   { value: 'JE', label: 'Jersey', flag: 'https://flagcdn.com/w320/je.png' },
//   { value: 'JO', label: 'Jordan', flag: 'https://flagcdn.com/w320/jo.png' },
//   { value: 'KZ', label: 'Kazakhstan', flag: 'https://flagcdn.com/w320/kz.png' },
//   { value: 'KE', label: 'Kenya', flag: 'https://flagcdn.com/w320/ke.png' },
//   { value: 'KI', label: 'Kiribati', flag: 'https://flagcdn.com/w320/ki.png' },
//   {
//     value: 'KP',
//     label: "Korea (Democratic People's Republic of)",
//     flag: 'https://flagcdn.com/w320/kp.png',
//   },
//   { value: 'KR', label: 'Korea, Republic of', flag: 'https://flagcdn.com/w320/kr.png' },
//   { value: 'KW', label: 'Kuwait', flag: 'https://flagcdn.com/w320/kw.png' },
//   { value: 'KG', label: 'Kyrgyzstan', flag: 'https://flagcdn.com/w320/kg.png' },
//   {
//     value: 'LA',
//     label: "Lao People's Democratic Republic",
//     flag: 'https://flagcdn.com/w320/la.png',
//   },
//   { value: 'LV', label: 'Latvia', flag: 'https://flagcdn.com/w320/lv.png' },
//   { value: 'LB', label: 'Lebanon', flag: 'https://flagcdn.com/w320/lb.png' },
//   { value: 'LS', label: 'Lesotho', flag: 'https://flagcdn.com/w320/ls.png' },
//   { value: 'LR', label: 'Liberia', flag: 'https://flagcdn.com/w320/lr.png' },
//   { value: 'LY', label: 'Libya', flag: 'https://flagcdn.com/w320/ly.png' },
//   { value: 'LI', label: 'Liechtenstein', flag: 'https://flagcdn.com/w320/li.png' },
//   { value: 'LT', label: 'Lithuania', flag: 'https://flagcdn.com/w320/lt.png' },
//   { value: 'LU', label: 'Luxembourg', flag: 'https://flagcdn.com/w320/lu.png' },
//   { value: 'MO', label: 'Macao', flag: 'https://flagcdn.com/w320/mo.png' },
//   { value: 'MG', label: 'Madagascar', flag: 'https://flagcdn.com/w320/mg.png' },
//   { value: 'MW', label: 'Malawi', flag: 'https://flagcdn.com/w320/mw.png' },
//   { value: 'MY', label: 'Malaysia', flag: 'https://flagcdn.com/w320/my.png' },
//   { value: 'MV', label: 'Maldives', flag: 'https://flagcdn.com/w320/mv.png' },
//   { value: 'ML', label: 'Mali', flag: 'https://flagcdn.com/w320/ml.png' },
//   { value: 'MT', label: 'Malta', flag: 'https://flagcdn.com/w320/mt.png' },
//   { value: 'MH', label: 'Marshall Islands', flag: 'https://flagcdn.com/w320/mh.png' },
//   { value: 'MQ', label: 'Martinique', flag: 'https://flagcdn.com/w320/mq.png' },
//   { value: 'MR', label: 'Mauritania', flag: 'https://flagcdn.com/w320/mr.png' },
//   { value: 'MU', label: 'Mauritius', flag: 'https://flagcdn.com/w320/mu.png' },
//   { value: 'YT', label: 'Mayotte', flag: 'https://flagcdn.com/w320/yt.png' },
//   { value: 'MX', label: 'Mexico', flag: 'https://flagcdn.com/w320/mx.png' },
//   {
//     value: 'FM',
//     label: 'Micronesia (Federated States of)',
//     flag: 'https://flagcdn.com/w320/fm.png',
//   },
//   { value: 'MD', label: 'Moldova (Republic of)', flag: 'https://flagcdn.com/w320/md.png' },
//   { value: 'MC', label: 'Monaco', flag: 'https://flagcdn.com/w320/mc.png' },
//   { value: 'MN', label: 'Mongolia', flag: 'https://flagcdn.com/w320/mn.png' },
//   { value: 'ME', label: 'Montenegro', flag: 'https://flagcdn.com/w320/me.png' },
//   { value: 'MS', label: 'Montserrat', flag: 'https://flagcdn.com/w320/ms.png' },
//   { value: 'MA', label: 'Morocco', flag: 'https://flagcdn.com/w320/ma.png' },
//   { value: 'MZ', label: 'Mozambique', flag: 'https://flagcdn.com/w320/mz.png' },
//   { value: 'MM', label: 'Myanmar', flag: 'https://flagcdn.com/w320/mm.png' },
//   { value: 'NA', label: 'Namibia', flag: 'https://flagcdn.com/w320/na.png' },
//   { value: 'NR', label: 'Nauru', flag: 'https://flagcdn.com/w320/nr.png' },
//   { value: 'NP', label: 'Nepal', flag: 'https://flagcdn.com/w320/np.png' },
//   { value: 'NL', label: 'Netherlands', flag: 'https://flagcdn.com/w320/nl.png' },
//   { value: 'NC', label: 'New Caledonia', flag: 'https://flagcdn.com/w320/nc.png' },
//   { value: 'NZ', label: 'New Zealand', flag: 'https://flagcdn.com/w320/nz.png' },
//   { value: 'NI', label: 'Nicaragua', flag: 'https://flagcdn.com/w320/ni.png' },
//   { value: 'NE', label: 'Niger', flag: 'https://flagcdn.com/w320/ne.png' },
//   { value: 'NG', label: 'Nigeria', flag: 'https://flagcdn.com/w320/ng.png' },
//   { value: 'NU', label: 'Niue', flag: 'https://flagcdn.com/w320/nu.png' },
//   { value: 'NF', label: 'Norfolk Island', flag: 'https://flagcdn.com/w320/nf.png' },
//   { value: 'MP', label: 'Northern Mariana Islands', flag: 'https://flagcdn.com/w320/mp.png' },
//   { value: 'NO', label: 'Norway', flag: 'https://flagcdn.com/w320/no.png' },
//   { value: 'OM', label: 'Oman', flag: 'https://flagcdn.com/w320/om.png' },
//   { value: 'PK', label: 'Pakistan', flag: 'https://flagcdn.com/w320/pk.png' },
//   { value: 'PW', label: 'Palau', flag: 'https://flagcdn.com/w320/pw.png' },
//   { value: 'PS', label: 'Palestine, State of', flag: 'https://flagcdn.com/w320/ps.png' },
//   { value: 'PA', label: 'Panama', flag: 'https://flagcdn.com/w320/pa.png' },
//   { value: 'PG', label: 'Papua New Guinea', flag: 'https://flagcdn.com/w320/pg.png' },
//   { value: 'PY', label: 'Paraguay', flag: 'https://flagcdn.com/w320/py.png' },
//   { value: 'PE', label: 'Peru', flag: 'https://flagcdn.com/w320/pe.png' },
//   { value: 'PH', label: 'Philippines', flag: 'https://flagcdn.com/w320/ph.png' },
//   { value: 'PN', label: 'Pitcairn', flag: 'https://flagcdn.com/w320/pn.png' },
//   { value: 'PL', label: 'Poland', flag: 'https://flagcdn.com/w320/pl.png' },
//   { value: 'PT', label: 'Portugal', flag: 'https://flagcdn.com/w320/pt.png' },
//   { value: 'PR', label: 'Puerto Rico', flag: 'https://flagcdn.com/w320/pr.png' },
//   { value: 'QA', label: 'Qatar', flag: 'https://flagcdn.com/w320/qa.png' },
//   { value: 'MK', label: 'Republic of North Macedonia', flag: 'https://flagcdn.com/w320/mk.png' },
//   { value: 'RO', label: 'Romania', flag: 'https://flagcdn.com/w320/ro.png' },
//   { value: 'RU', label: 'Russian Federation', flag: 'https://flagcdn.com/w320/ru.png' },
//   { value: 'RW', label: 'Rwanda', flag: 'https://flagcdn.com/w320/rw.png' },
//   { value: 'RE', label: 'Réunion', flag: 'https://flagcdn.com/w320/re.png' },
//   { value: 'BL', label: 'Saint Barthélemy', flag: 'https://flagcdn.com/w320/bl.png' },
//   {
//     value: 'SH',
//     label: 'Saint Helena, Ascension and Tristan da Cunha',
//     flag: 'https://flagcdn.com/w320/sh.png',
//   },
//   { value: 'KN', label: 'Saint Kitts and Nevis', flag: 'https://flagcdn.com/w320/kn.png' },
//   { value: 'LC', label: 'Saint Lucia', flag: 'https://flagcdn.com/w320/lc.png' },
//   { value: 'MF', label: 'Saint Martin (French part)', flag: 'https://flagcdn.com/w320/mf.png' },
//   { value: 'PM', label: 'Saint Pierre and Miquelon', flag: 'https://flagcdn.com/w320/pm.png' },
//   {
//     value: 'VC',
//     label: 'Saint Vincent and the Grenadines',
//     flag: 'https://flagcdn.com/w320/vc.png',
//   },
//   { value: 'WS', label: 'Samoa', flag: 'https://flagcdn.com/w320/ws.png' },
//   { value: 'SM', label: 'San Marino', flag: 'https://flagcdn.com/w320/sm.png' },
//   { value: 'ST', label: 'Sao Tome and Principe', flag: 'https://flagcdn.com/w320/st.png' },
//   { value: 'SA', label: 'Saudi Arabia', flag: 'https://flagcdn.com/w320/sa.png' },
//   { value: 'SN', label: 'Senegal', flag: 'https://flagcdn.com/w320/sn.png' },
//   { value: 'RS', label: 'Serbia', flag: 'https://flagcdn.com/w320/rs.png' },
//   { value: 'SC', label: 'Seychelles', flag: 'https://flagcdn.com/w320/sc.png' },
//   { value: 'SL', label: 'Sierra Leone', flag: 'https://flagcdn.com/w320/sl.png' },
//   { value: 'SG', label: 'Singapore', flag: 'https://flagcdn.com/w320/sg.png' },
//   { value: 'SX', label: 'Sint Maarten (Dutch part)', flag: 'https://flagcdn.com/w320/sx.png' },
//   { value: 'SK', label: 'Slovakia', flag: 'https://flagcdn.com/w320/sk.png' },
//   { value: 'SI', label: 'Slovenia', flag: 'https://flagcdn.com/w320/si.png' },
//   { value: 'SB', label: 'Solomon Islands', flag: 'https://flagcdn.com/w320/sb.png' },
//   { value: 'SO', label: 'Somalia', flag: 'https://flagcdn.com/w320/so.png' },
//   { value: 'ZA', label: 'South Africa', flag: 'https://flagcdn.com/w320/za.png' },
//   {
//     value: 'GS',
//     label: 'South Georgia and the South Sandwich Islands',
//     flag: 'https://flagcdn.com/w320/gs.png',
//   },
//   { value: 'SS', label: 'South Sudan', flag: 'https://flagcdn.com/w320/ss.png' },
//   { value: 'ES', label: 'Spain', flag: 'https://flagcdn.com/w320/es.png' },
//   { value: 'LK', label: 'Sri Lanka', flag: 'https://flagcdn.com/w320/lk.png' },
//   { value: 'SD', label: 'Sudan', flag: 'https://flagcdn.com/w320/sd.png' },
//   { value: 'SR', label: 'Suriname', flag: 'https://flagcdn.com/w320/sr.png' },
//   { value: 'SJ', label: 'Svalbard and Jan Mayen', flag: 'https://flagcdn.com/w320/sj.png' },
//   { value: 'SE', label: 'Sweden', flag: 'https://flagcdn.com/w320/se.png' },
//   { value: 'CH', label: 'Switzerland', flag: 'https://flagcdn.com/w320/ch.png' },
//   { value: 'SY', label: 'Syrian Arab Republic', flag: 'https://flagcdn.com/w320/sy.png' },
//   { value: 'TW', label: 'Taiwan', flag: 'https://flagcdn.com/w320/tw.png' },
//   { value: 'TJ', label: 'Tajikistan', flag: 'https://flagcdn.com/w320/tj.png' },
//   { value: 'TZ', label: 'Tanzania, United Republic of', flag: 'https://flagcdn.com/w320/tz.png' },
//   { value: 'TH', label: 'Thailand', flag: 'https://flagcdn.com/w320/th.png' },
//   { value: 'TL', label: 'Timor-Leste', flag: 'https://flagcdn.com/w320/tl.png' },
//   { value: 'TG', label: 'Togo', flag: 'https://flagcdn.com/w320/tg.png' },
//   { value: 'TK', label: 'Tokelau', flag: 'https://flagcdn.com/w320/tk.png' },
//   { value: 'TO', label: 'Tonga', flag: 'https://flagcdn.com/w320/to.png' },
//   { value: 'TT', label: 'Trinidad and Tobago', flag: 'https://flagcdn.com/w320/tt.png' },
//   { value: 'TN', label: 'Tunisia', flag: 'https://flagcdn.com/w320/tn.png' },
//   { value: 'TR', label: 'Turkey', flag: 'https://flagcdn.com/w320/tr.png' },
//   { value: 'TM', label: 'Turkmenistan', flag: 'https://flagcdn.com/w320/tm.png' },
//   { value: 'TC', label: 'Turks and Caicos Islands', flag: 'https://flagcdn.com/w320/tc.png' },
//   { value: 'TV', label: 'Tuvalu', flag: 'https://flagcdn.com/w320/tv.png' },
//   { value: 'UG', label: 'Uganda', flag: 'https://flagcdn.com/w320/ug.png' },
//   { value: 'UA', label: 'Ukraine', flag: 'https://flagcdn.com/w320/ua.png' },
//   { value: 'AE', label: 'United Arab Emirates', flag: 'https://flagcdn.com/w320/ae.png' },
//   {
//     value: 'GB',
//     label: 'United Kingdom of Great Britain and Northern Ireland',
//     flag: 'https://flagcdn.com/w320/gb.png',
//   },
//   { value: 'US', label: 'United States of America', flag: 'https://flagcdn.com/w320/us.png' },
//   { value: 'UY', label: 'Uruguay', flag: 'https://flagcdn.com/w320/uy.png' },
//   { value: 'UZ', label: 'Uzbekistan', flag: 'https://flagcdn.com/w320/uz.png' },
//   { value: 'VU', label: 'Vanuatu', flag: 'https://flagcdn.com/w320/vu.png' },
//   {
//     value: 'VE',
//     label: 'Venezuela (Bolivarian Republic of)',
//     flag: 'https://flagcdn.com/w320/ve.png',
//   },
//   { value: 'VN', label: 'Viet Nam', flag: 'https://flagcdn.com/w320/vn.png' },
//   { value: 'WF', label: 'Wallis and Futuna', flag: 'https://flagcdn.com/w320/wf.png' },
//   { value: 'EH', label: 'Western Sahara', flag: 'https://flagcdn.com/w320/eh.png' },
//   { value: 'YE', label: 'Yemen', flag: 'https://flagcdn.com/w320/ye.png' },
//   { value: 'ZM', label: 'Zambia', flag: 'https://flagcdn.com/w320/zm.png' },
//   { value: 'ZW', label: 'Zimbabwe', flag: 'https://flagcdn.com/w320/zw.png' },
//   { value: 'AX', label: 'Åland Islands', flag: 'https://flagcdn.com/w320/ax.png' },
// ]

const metaCountriess = [
  { value: '', label: 'Anywhere' },
  { value: 'aa', label: 'Afar [AA]' },
  { value: 'ab', label: 'Abkhazian [AB]' },
  { value: 'ae', label: 'Avestan [AE]' },
  { value: 'af', label: 'Afrikaans [AF]' },
  { value: 'ak', label: 'Akan [AK]' },
  { value: 'am', label: 'Amharic [AM]' },
  { value: 'an', label: 'Aragonese [AN]' },
  { value: 'ar', label: 'Arabic [AR]' },
  { value: 'as', label: 'Assamese [AS]' },
  { value: 'av', label: 'Avaric [AV]' },
  { value: 'ay', label: 'Aymara [AY]' },
  { value: 'az', label: 'Azerbaijani [AZ]' },
  { value: 'ba', label: 'Bashkir [BA]' },
  { value: 'be', label: 'Belarusian [BE]' },
  { value: 'bg', label: 'Bulgarian [BG]' },
  { value: 'bh', label: 'Bihari languages [BH]' },
  { value: 'bi', label: 'Bislama [BI]' },
  { value: 'bm', label: 'Bambara [BM]' },
  { value: 'bn', label: 'Bengali [BN]' },
  { value: 'bo', label: 'Tibetan [BO]' },
  { value: 'br', label: 'Breton [BR]' },
  { value: 'bs', label: 'Bosnian [BS]' },
  { value: 'ca', label: 'Catalan [CA]' },
  { value: 'ce', label: 'Chechen [CE]' },
  { value: 'ch', label: 'Chamorro [CH]' },
  { value: 'co', label: 'Corsican [CO]' },
  { value: 'cr', label: 'Cree [CR]' },
  { value: 'cs', label: 'Czech [CS]' },
  { value: 'cu', label: 'Church Slavic [CU]' },
  { value: 'cv', label: 'Chuvash [CV]' },
  { value: 'cy', label: 'Welsh [CY]' },
  { value: 'da', label: 'Danish [DA]' },
  { value: 'de', label: 'German [DE]' },
  { value: 'dv', label: 'Divehi [DV]' },
  { value: 'dz', label: 'Dzongkha [DZ]' },
  { value: 'ee', label: 'Ewe [EE]' },
  { value: 'el', label: 'Greek [EL]' },
  { value: 'en', label: 'English [EN]' },
  { value: 'eo', label: 'Esperanto [EO]' },
  { value: 'es', label: 'Spanish [ES]' },
  { value: 'et', label: 'Estonian [ET]' },
  { value: 'eu', label: 'Basque [EU]' },
  { value: 'fa', label: 'Persian [FA]' },
  { value: 'ff', label: 'Fulah [FF]' },
  { value: 'fi', label: 'Finnish [FI]' },
  { value: 'fj', label: 'Fijian [FJ]' },
  { value: 'fo', label: 'Faroese [FO]' },
  { value: 'fr', label: 'French [FR]' },
  { value: 'fy', label: 'Western Frisian [FY]' },
  { value: 'ga', label: 'Irish [GA]' },
  { value: 'gd', label: 'Scottish Gaelic [GD]' },
  { value: 'gl', label: 'Galician [GL]' },
  { value: 'gn', label: 'Guaraní [GN]' },
  { value: 'gu', label: 'Gujarati [GU]' },
  { value: 'gv', label: 'Manx [GV]' },
  { value: 'ha', label: 'Hausa [HA]' },
  { value: 'he', label: 'Hebrew [HE]' },
  { value: 'hi', label: 'Hindi [HI]' },
  { value: 'ho', label: 'Hiri Motu [HO]' },
  { value: 'hr', label: 'Croatian [HR]' },
  { value: 'ht', label: 'Haitian Creole [HT]' },
  { value: 'hu', label: 'Hungarian [HU]' },
  { value: 'hy', label: 'Armenian [HY]' },
  { value: 'hz', label: 'Herero [HZ]' },
  { value: 'ia', label: 'Interlingua [IA]' },
  { value: 'id', label: 'Indonesian [ID]' },
  { value: 'ie', label: 'Interlingue [IE]' },
  { value: 'ig', label: 'Igbo [IG]' },
  { value: 'ii', label: 'Sichuan Yi [II]' },
  { value: 'ik', label: 'Inupiaq [IK]' },
  { value: 'io', label: 'Ido [IO]' },
  { value: 'is', label: 'Icelandic [IS]' },
  { value: 'it', label: 'Italian [IT]' },
  { value: 'iu', label: 'Inuktitut [IU]' },
  { value: 'ja', label: 'Japanese [JA]' },
  { value: 'jv', label: 'Javanese [JV]' },
  { value: 'ka', label: 'Georgian [KA]' },
  { value: 'kg', label: 'Kongo [KG]' },
  { value: 'ki', label: 'Kikuyu [KI]' },
  { value: 'kj', label: 'Kuanyama [KJ]' },
  { value: 'kk', label: 'Kazakh [KK]' },
  { value: 'kl', label: 'Kalaallisut [KL]' },
  { value: 'km', label: 'Central Khmer [KM]' },
  { value: 'kn', label: 'Kannada [KN]' },
  { value: 'ko', label: 'Korean [KO]' },
  { value: 'kr', label: 'Kanuri [KR]' },
  { value: 'ks', label: 'Kashmiri [KS]' },
  { value: 'ku', label: 'Kurdish [KU]' },
  { value: 'kv', label: 'Komi [KV]' },
  { value: 'kw', label: 'Cornish [KW]' },
  { value: 'ky', label: 'Kirghiz [KY]' },
  { value: 'la', label: 'Latin [LA]' },
  { value: 'lb', label: 'Luxembourgish [LB]' },
  { value: 'lg', label: 'Ganda [LG]' },
  { value: 'li', label: 'Limburgish [LI]' },
  { value: 'ln', label: 'Lingala [LN]' },
  { value: 'lo', label: 'Lao [LO]' },
  { value: 'lt', label: 'Lithuanian [LT]' },
  { value: 'lu', label: 'Luba-Katanga [LU]' },
  { value: 'lv', label: 'Latvian [LV]' },
  { value: 'mg', label: 'Malagasy [MG]' },
  { value: 'mh', label: 'Marshallese [MH]' },
  { value: 'mi', label: 'Maori [MI]' },
  { value: 'mk', label: 'Macedonian [MK]' },
  { value: 'ml', label: 'Malayalam [ML]' },
  { value: 'mn', label: 'Mongolian [MN]' },
  { value: 'mr', label: 'Marathi [MR]' },
  { value: 'ms', label: 'Malay [MS]' },
  { value: 'mt', label: 'Maltese [MT]' },
  { value: 'my', label: 'Burmese [MY]' },
  { value: 'na', label: 'Nauru [NA]' },
  { value: 'nb', label: 'Norwegian Bokmål [NB]' },
  { value: 'nd', label: 'North Ndebele [ND]' },
  { value: 'ne', label: 'Nepali [NE]' },
  { value: 'ng', label: 'Ndonga [NG]' },
  { value: 'nl', label: 'Dutch [NL]' },
  { value: 'nn', label: 'Norwegian Nynorsk [NN]' },
  { value: 'no', label: 'Norwegian [NO]' },
  { value: 'nr', label: 'South Ndebele [NR]' },
  { value: 'nv', label: 'Navajo [NV]' },
  { value: 'ny', label: 'Chichewa [NY]' },
  { value: 'oc', label: 'Occitan [OC]' },
  { value: 'oj', label: 'Ojibwe [OJ]' },
  { value: 'om', label: 'Oromo [OM]' },
  { value: 'or', label: 'Oriya [OR]' },
  { value: 'os', label: 'Ossetian [OS]' },
  { value: 'pa', label: 'Panjabi [PA]' },
  { value: 'pi', label: 'Pali [PI]' },
  { value: 'pl', label: 'Polish [PL]' },
  { value: 'ps', label: 'Pushto [PS]' },
  { value: 'pt', label: 'Portuguese [PT]' },
  { value: 'qu', label: 'Quechua [QU]' },
  { value: 'rm', label: 'Romansh [RM]' },
  { value: 'rn', label: 'Rundi [RN]' },
  { value: 'ro', label: 'Romanian [RO]' },
  { value: 'ru', label: 'Russian [RU]' },
  { value: 'rw', label: 'Kinyarwanda [RW]' },
  { value: 'sa', label: 'Sanskrit [SA]' },
  { value: 'sc', label: 'Sardinian [SC]' },
  { value: 'sd', label: 'Sindhi [SD]' },
  { value: 'se', label: 'Northern Sami [SE]' },
  { value: 'sg', label: 'Sango [SG]' },
  { value: 'si', label: 'Sinhalese [SI]' },
  { value: 'sk', label: 'Slovak [SK]' },
  { value: 'sl', label: 'Slovenian [SL]' },
  { value: 'sm', label: 'Samoan [SM]' },
  { value: 'sn', label: 'Shona [SN]' },
  { value: 'so', label: 'Somali [SO]' },
  { value: 'sq', label: 'Albanian [SQ]' },
  { value: 'sr', label: 'Serbian [SR]' },
  { value: 'ss', label: 'Swati [SS]' },
  { value: 'st', label: 'Sotho, Southern [ST]' },
  { value: 'su', label: 'Sundanese [SU]' },
  { value: 'sv', label: 'Swedish [SV]' },
  { value: 'sw', label: 'Swahili [SW]' },
  { value: 'ta', label: 'Tamil [TA]' },
  { value: 'te', label: 'Telugu [TE]' },
  { value: 'tg', label: 'Tajik [TG]' },
  { value: 'th', label: 'Thai [TH]' },
  { value: 'ti', label: 'Tigrinya [TI]' },
  { value: 'tk', label: 'Turkmen [TK]' },
  { value: 'tl', label: 'Tagalog [TL]' },
  { value: 'tn', label: 'Tswana [TN]' },
  { value: 'to', label: 'Tonga [TO]' },
  { value: 'tr', label: 'Turkish [TR]' },
  { value: 'ts', label: 'Tsonga [TS]' },
  { value: 'tt', label: 'Tatar [TT]' },
  { value: 'tw', label: 'Twi [TW]' },
  { value: 'ty', label: 'Tahitian [TY]' },
  { value: 'ug', label: 'Uighur [UG]' },
  { value: 'uk', label: 'Ukrainian [UK]' },
  { value: 'ur', label: 'Urdu [UR]' },
  { value: 'uz', label: 'Uzbek [UZ]' },
  { value: 've', label: 'Venda [VE]' },
  { value: 'vi', label: 'Vietnamese [VI]' },
  { value: 'vo', label: 'Volapük [VO]' },
  { value: 'wa', label: 'Walloon [WA]' },
  { value: 'wo', label: 'Wolof [WO]' },
  { value: 'xh', label: 'Xhosa [XH]' },
  { value: 'yi', label: 'Yiddish [YI]' },
  { value: 'yo', label: 'Yoruba [YO]' },
  { value: 'za', label: 'Zhuang [ZA]' },
  { value: 'zh', label: 'Chinese [ZH]' },
  { value: 'zu', label: 'Zulu [ZU]' },
]
const metaCountries = [
  { value: '', label: 'ALL' },
  { value: 'ar', label: 'Arabic [AR]' },
  { value: 'bn', label: 'Bengali [BN]' },
  { value: 'zh', label: 'Chinese [ZH]' },
  { value: 'en', label: 'English [EN]' },
  { value: 'fr', label: 'French [FR]' },
  { value: 'de', label: 'German [DE]' },
  { value: 'hi', label: 'Hindi [HI]' },
  { value: 'id', label: 'Indonesian [ID]' },
  { value: 'it', label: 'Italian [IT]' },
  { value: 'ja', label: 'Japanese [JA]' },
  { value: 'ko', label: 'Korean [KO]' },
  { value: 'pl', label: 'Polish [PL]' },
  { value: 'pt', label: 'Portuguese [PT]' },
  { value: 'ru', label: 'Russian [RU]' },
  { value: 'es', label: 'Spanish [ES]' },
  { value: 'sv', label: 'Swedish [SV]' },
  { value: 'th', label: 'Thai [TH]' },
  { value: 'tr', label: 'Turkish [TR]' },
  { value: 'vi', label: 'Vietnamese [VI]' },
]

export { googleCountries, metaCountries }
