import React, { useRef, useState } from 'react'
import { Col, Input, Modal, Row, Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { LS_KEY, LEARNING_CENTER_MESSAGE_FROM_MODAL } from '@utils/constants'
import { notify } from '@utils/notify'
import { useDispatch, useSelector } from 'react-redux'
import Text from '@components/Text/text'
import ModalPhoneValidate from '../ModalPhoneValidate'

const { Option } = Select
const LearningModal = ({ modalOpen, handleCancel }) => {
  const leadData = useSelector(state => state?.learningCenter?.leadData)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors2, ...formState },
  } = useForm()
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user.access_token
  const baseURL = process.env.REACT_APP_API_URL
  const dispatch = useDispatch()
  const modalMessage = useSelector(state => state?.learningCenterModalMessage?.message)
  const [selectBudgetColor, setSelectBudgetColor] = useState('#BEBFC0')
  const [selectTopicColor, setSelectTopicColor] = useState('#BEBFC0')
  const [selectIndustryColor, setSelectIndustryColor] = useState('#BEBFC0')
  const [isLearningDropdownActive, setIsLearningDropdownActive] = useState(false)
  const [isMarketingDropdownActive, setIsMarketingDropdownActive] = useState(false)
  const [isIndustryDropdownActive, setIsIndustryDropdownActive] = useState(false)

  const [formData, setFormData] = useState({
    company_email: leadData?.email,
    company_phone: leadData.phone,
    first_name: leadData.first_name,
    last_name: leadData.last_name,
    website_url: leadData.url,
    topic: '',
    industry: leadData.industry,
    monthly_budget: leadData.revenue,
    requested_resource: '',
  })
  const learningArrowIconRef = useRef(null)
  const marketingArrowIconRef = useRef(null)
  const industryArrowIconRef = useRef(null)
  const handleLearningDropdownVisible = open => {
    setIsLearningDropdownActive(open)
    if (learningArrowIconRef.current) {
      learningArrowIconRef.current.style.transform = open ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }
  const handleLearningArrowClick = () => {
    // Toggle the dropdown visibility when clicking the arrow icon
    handleLearningDropdownVisible(!isLearningDropdownActive)
  }
  const handleMarketingDropdownVisible = open => {
    setIsMarketingDropdownActive(open)
    if (marketingArrowIconRef.current) {
      marketingArrowIconRef.current.style.transform = open ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }
  const handleMarketingArrowClick = () => {
    // Toggle the dropdown visibility when clicking the arrow icon
    handleMarketingDropdownVisible(!isMarketingDropdownActive)
  }
  const handleIndustryDropdownVisible = open => {
    setIsIndustryDropdownActive(open)
    if (industryArrowIconRef.current) {
      industryArrowIconRef.current.style.transform = open ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }
  const handleIndustryArrowClick = () => {
    // Toggle the dropdown visibility when clicking the arrow icon
    handleIndustryDropdownVisible(!isIndustryDropdownActive)
  }
  const [formErrors, setFormErrors] = useState({
    company_email: '',
    company_phone: '',
    website_url: '',
    // ... other form fields
  })
  const [isModalOpen, setIsModalOpen] = useState(modalOpen)
  const showModal = () => {
    setIsModalOpen(modalOpen)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }

  const isSubmitButtonDisabled = Object.values(formData).some(value => value === '')
  const handleInputChange = (field, value) => {
    if (field === 'monthly_budget') {
      setFormData({
        ...formData,
        [field]: value,
      })
      setFormErrors({
        ...formErrors,
        [field]: '', // Clear the error when the user starts typing again
      })

      // Update text color based on selection
      setSelectBudgetColor(value ? '#26282C' : '#BEBFC0')
    } else if (field === 'topic') {
      setFormData({
        ...formData,
        [field]: value,
      })
      setFormErrors({
        ...formErrors,
        [field]: '', // Clear the error when the user starts typing again
      })

      // Update text color based on selection
      setSelectTopicColor(value ? '#26282C' : '#BEBFC0')
    } else if (field === 'industry') {
      setFormData({
        ...formData,
        [field]: value,
      })
      setFormErrors({
        ...formErrors,
        [field]: '', // Clear the error when the user starts typing again
      })

      // Update text color based on selection
      setSelectIndustryColor(value ? '#26282C' : '#BEBFC0')
    } else {
      setFormData({
        ...formData,
        [field]: value,
      })
      setFormErrors({
        ...formErrors,
        [field]: '', // Clear the error when the user starts typing again
      })
    }
  }
  const validateForm = () => {
    const errors = {}

    // Validate email
    if (!formData.company_email) {
      errors.company_email = 'Email is required'
    } else if (!/\S+@\S+/.test(formData.company_email)) {
      errors.company_email = 'Invalid email address'
    }

    // Validate phone number
    if (!formData.company_phone) {
      errors.company_phone = 'Phone number is required'
    } else if (ModalPhoneValidate.isValidPhoneNumber(formData.company_phone) === false) {
      errors.company_phone = 'Please enter a valid phone number'
    }

    // Validate URL
    if (!formData.website_url) {
      errors.website_url = 'Website URL is required'
    } else if (
      !/^(?![a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:https?:\/\/)?(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|localhost)(?:\/[^\s]*)?$/i.test(
        formData.website_url
      )
    ) {
      errors.website_url = 'Invalid URL.'
    }

    // Add more validation rules for other fields as needed

    return errors
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    const errors = validateForm()
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }
    await fetch(`${baseURL}/learning-center/search-term-missing/`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(res => {
        notify('Form submitted successfully.', 'success')
        dispatch({ type: LEARNING_CENTER_MESSAGE_FROM_MODAL, payload: formData.requested_resource })
      })
      .catch(error => {
        notify('Error while submitting form.')
      })
    handleCancel()
  }
  return (
    <div id="LearningCenterModal">
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width="100%"
        maskStyle={{ background: 'var(--Primary-Raisin-Black, #26282C)', opacity: '0.8' }}
        bodyStyle={{ borderRadius: '12px 12px 0 0 ' }}
        // id="learning-modal"
        footer={null}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ position: 'absolute', top: '0', right: '0' }}>
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
                margin: '11.37px',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
              onClick={handleCancel}
            >
              {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
              <CloseOutlined
                style={{
                  cursor: 'pointer',

                  color: 'black',
                  height: '20px',
                  width: '20px',
                }}
              />
            </button>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div
              style={{
                marginTop: '64px',
                marginLeft: '368px',
                marginRight: '368px',
                background: '#fff',
                borderRadius: '12px 12px 0px 0px',
              }}
            >
              <Text isBold size={32} style={{ color: '#26282C', fontStyle: 'normal' }}>
                Ask Neil anything or Request for new learning content
              </Text>
              <Text size={16} style={{ fontWeight: 400, color: '#939395' }}>
                Fill out the form below to speak with someone from our team.
              </Text>
              <div style={{ display: 'flex', marginTop: '40px' }}>
                <div style={{ flex: 1 }}>
                  <Input
                    value={formData.company_email}
                    placeholder="Company Email"
                    bordered={false}
                    style={{
                      borderBottom: `1px solid ${formErrors.company_email ? 'red' : '#D4D4D5'}`,
                      maxWidth: '416px',
                    }}
                    onChange={e => handleInputChange('company_email', e.target.value)}
                  />
                  {formErrors.company_email && (
                    <div style={{ color: 'red', marginLeft: '8px', marginTop: '8px' }}>
                      {formErrors.company_email}
                    </div>
                  )}
                </div>

                <div style={{ marginLeft: '32px', marginTop: 7 }}>
                  <Controller
                    name="company_phone"
                    control={control}
                    defaultValue="Pakistan"
                    render={({ field }) => (
                      <div id="modal-phone-border">
                        <div style={{ position: 'relative', width: '416px' }}>
                          <div style={{ position: 'relative', flex: 1 }}>
                            <div
                              style={{
                                borderLeft: '1px solid #D4D4D5',
                                height: '26px',
                                position: 'absolute',
                                left: '48px',
                              }}
                            />
                            <PhoneInput
                              {...field}
                              placeholder="Phone"
                              bordered={false}
                              style={{
                                border: 'none',
                                borderBottom: `1px solid ${
                                  formErrors.company_phone ? 'red' : '#D4D4D5'
                                }`,
                                paddingBottom: '12px',
                                width: '100%',
                              }}
                              international="true"
                              rules={ModalPhoneValidate}
                              value={formData.company_phone}
                              onChange={e => field.onChange(handleInputChange('company_phone', e))}
                            />

                            {!formData.company_phone && (
                              <span
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '66px',
                                  paddingBottom: '12px',
                                  transform: 'translateY(-50%)',
                                  color: '#BEBFC0',
                                  pointerEvents: 'none', // Make sure the span doesn't interfere with input events
                                }}
                              >
                                {/* Phone */}
                              </span>
                            )}
                          </div>
                        </div>
                        {formErrors.company_phone && (
                          <div style={{ color: 'red', marginLeft: '8px', marginTop: '8px' }}>
                            {formErrors.company_phone}
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', marginTop: '32px' }}>
                <Input
                  value={formData.first_name}
                  placeholder="First Name"
                  bordered={false}
                  style={{
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    maxWidth: '416px',
                  }}
                  onChange={e => handleInputChange('first_name', e.target.value)}
                />

                <Input
                  value={formData.last_name}
                  placeholder="Last Name"
                  bordered={false}
                  style={{
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    maxWidth: '416px',
                    marginLeft: '32px',
                  }}
                  onChange={e => handleInputChange('last_name', e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', marginTop: '32px' }}>
                <Input
                  value={formData.website_url}
                  placeholder="Website URL"
                  bordered={false}
                  style={{
                    borderBottom: `1px solid ${formErrors.website_url ? 'red' : '#D4D4D5'}`,
                    maxWidth: '416px',
                  }}
                  onChange={e => handleInputChange('website_url', e.target.value)}
                />

                <Select
                  defaultValue="Topic of Learning Content"
                  style={{
                    minWidth: '416px',
                    marginLeft: '32px',
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    textAlign: 'left',
                    // color: '#BEBFC0',
                    color: selectTopicColor,
                  }}
                  popupClassName="learning-dropdown"
                  bordered={false}
                  options={[
                    {
                      value: '1',
                      label: 'Common Google ads mistake and how to avoid them',
                    },
                    {
                      value: '2',
                      label: 'Comprehensive Guide on A/B Testing with Google Ads',
                    },
                    {
                      value: '3',
                      label: 'Budgeting your google ads campaign: Tips & Tiricks',
                    },
                    {
                      value: '4',
                      label: 'Leveraging ad extensions for enhanced performance ',
                    },
                    {
                      value: '5',
                      label: 'Keyword research template ',
                    },
                    {
                      value: '6 ',
                      label: 'Other ',
                    },
                  ]}
                  onChange={value => handleInputChange('topic', value)}
                  // onDropdownVisibleChange={handleLearningDropdownVisible}
                  // ref={selectRef}
                  // suffixIcon={
                  //   <DownOutlined
                  //     rotate={isLearningDropdownActive ? 180 : 360}
                  //     onClick={handleLearningSuffix}
                  //   />
                  // }
                  open={isLearningDropdownActive}
                  onClick={handleLearningArrowClick} // Handle the click event for the entire Select component
                  suffixIcon={
                    <DownOutlined ref={learningArrowIconRef} onClick={handleLearningArrowClick} />
                  }
                />
              </div>
              {formErrors.website_url && (
                <div style={{ color: 'red', marginLeft: '8px' }}>{formErrors.website_url}</div>
              )}
              <div style={{ display: 'flex', marginTop: '32px' }}>
                <Select
                  value={formData.industry}
                  defaultValue="Industry"
                  style={{
                    minWidth: '416px',
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    textAlign: 'left',
                    color: 'black',
                    maxWidth: '416px',
                  }}
                  popupClassName="learning-dropdown"
                  bordered={false}
                  options={[
                    {
                      value: '1',
                      label: 'Animal & Pet',
                    },
                    {
                      value: '2',
                      label: 'Apparel, Fashion & Jewelry',
                    },
                    {
                      value: '3',
                      label: 'Attorneys, Law & Legal',
                    },
                    {
                      value: '4',
                      label: 'Arts & Entertainment',
                    },
                    {
                      value: '5',
                      label: 'Automotive (Sales & Rental)',
                    },
                    {
                      value: '6',
                      label: 'Beauty, Cosmetics & Personal Care',
                    },
                    {
                      value: '7',
                      label: 'Career & Employment',
                    },
                    {
                      value: '8',
                      label: 'E-commerce',
                    },
                    {
                      value: '9',
                      label: 'Education, Coaching & Instruction',
                    },
                    {
                      value: '10',
                      label: 'Farming, Agriculture & Sustainability',
                    },
                    {
                      value: '11',
                      label: 'Finance, Banks, Investments & Accounting',
                    },
                    {
                      value: '12',
                      label: 'Fitness, Supplements & Vitamins',
                    },
                    {
                      value: '13',
                      label: 'Health-Related, Wellness & Medical',
                    },
                    {
                      value: '14',
                      label: 'Healthcare',
                    },
                    {
                      value: '15',
                      label: 'House Decor, Furniture, Home Improvement',
                    },
                    {
                      value: '16',
                      label: 'Heavy Industry, Energy & Manufacturing',
                    },
                    {
                      value: '17',
                      label: 'Insurance',
                    },
                    {
                      value: '18',
                      label: 'Logistics & Transportation',
                    },
                    {
                      value: '19',
                      label: 'Marketing, Advertising, PR & Digital Services',
                    },
                    {
                      value: '20',
                      label: 'Pharma',
                    },
                    {
                      value: '21',
                      label: 'Professional Services & Contractors (Cleaners, HVAC, Plumbers, etc.)',
                    },
                    {
                      value: '22',
                      label: 'Real Estate',
                    },
                    {
                      value: '23',
                      label: 'Restaurants, Foods & Beverages',
                    },
                    {
                      value: '24',
                      label: 'SaaS, IT & Software',
                    },
                    {
                      value: '25',
                      label: 'Travel, Hotel & Tourism',
                    },
                    {
                      value: '26',
                      label: 'Other',
                    },
                  ]}
                  onChange={value => handleInputChange('industry', value)}
                  open={isIndustryDropdownActive}
                  onClick={handleIndustryArrowClick} // Handle the click event for the entire Select component
                  suffixIcon={
                    <DownOutlined ref={industryArrowIconRef} onClick={handleIndustryArrowClick} />
                  }
                />

                <Select
                  value={formData.monthly_budget}
                  defaultValue="Monthly Marketing Budget"
                  style={{
                    minWidth: '416px',
                    marginLeft: '32px',
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    textAlign: 'left',
                    color: 'black',
                    // color: selectBudgetColor,
                  }}
                  bordered={false}
                  dropdownClassName="learning-dropdown"
                  options={[
                    {
                      value: '1',
                      label: 'Under $750',
                    },
                    {
                      value: '2',
                      label: '$750 to $1,500',
                    },
                    {
                      value: '3',
                      label: '$1,500 to $5,000',
                    },
                    {
                      value: '4',
                      label: '$5,000 to $10,000',
                    },
                    {
                      value: '5',
                      label: '$10,000 to $25,000',
                    },
                    {
                      value: '6',
                      label: '$25,000 to $50,000',
                    },

                    {
                      value: '7',
                      label: '$50,000 to $100,000',
                    },

                    {
                      value: '8',
                      label: 'Above $100,000',
                    },
                  ]}
                  onChange={value => handleInputChange('monthly_budget', value)}
                  open={isMarketingDropdownActive}
                  onClick={handleMarketingArrowClick} // Handle the click event for the entire Select component
                  suffixIcon={
                    <DownOutlined ref={marketingArrowIconRef} onClick={handleMarketingArrowClick} />
                  }
                />
              </div>
              <div style={{ marginTop: '32px' }}>
                <Input
                  placeholder="Your Message"
                  bordered={false}
                  style={{
                    borderBottom: '1px solid var(--primary-tint-raisin-black-20, #D4D4D5',
                    maxWidth: '864px',
                  }}
                  onChange={e => handleInputChange('requested_resource', e.target.value)}
                />
              </div>
              <div style={{ marginTop: 40, display: 'flex', marginBottom: '157px' }}>
                <Text
                  size={12}
                  style={{
                    fontStyle: 'normal',
                    maxWidth: 488,
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#939395',
                  }}
                >
                  Clicking below gives consent for automated contact by NP Digital and partners,
                  regardless of Do Not Call List status. Purchase doesn&apos;t require this consent.
                  See our{' '}
                  <a
                    href="https://npdigital.com/privacy-policy/"
                    style={{ textDecoration: 'underline', color: '#939395' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Text>
                <button
                  type="submit"
                  style={{
                    marginLeft: '220px',
                    height: '48px',
                    width: '160px',
                    padding: '0px 28px',
                    background: isSubmitButtonDisabled ? '#BEBFC0' : '#F16434',
                    border: isSubmitButtonDisabled ? '1px solid #BEBFC0' : '1px solid #F16434',
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 500,
                    cursor: isSubmitButtonDisabled ? '' : 'pointer',
                  }}
                  disabled={isSubmitButtonDisabled}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default LearningModal
