/* eslint-disable */

import axios from 'axios'
import tokenService from './tokenService'
import { apiRoutes } from 'config/apiRoutes'
import { notify } from 'utils/notify'
import { clearLocalStorage } from 'utils/helperFunctions'

const baseURL = process.env.REACT_APP_API_URL

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  config => {
    const accessToken = tokenService.getLocalAccessToken()
    const refreshToken = tokenService.getLocalRefreshToken()
    const isTokenValid = tokenService.checkRefreshTokenValidity(refreshToken)
    if (refreshToken && !isTokenValid) {
      notify(t('notification.tokenExpired'), 'error')
      clearLocalStorage()
      window.location.href = '/'
      return Promise.reject({ error: 'token expired' })
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config
    if (err.response) {
      //access token expired
      if (err?.response?.data?.code === 'token_not_valid' && !originalConfig._retry) {
        //handle infinite loop
        originalConfig._retry = true
        try {
          const { url, method } = apiRoutes.renewAccessToken
          const refresh = tokenService.getLocalRefreshToken()
          const res = await instance({
            url,
            method,
            data: { refresh },
          })
          const { access } = res.data
          if (access) {
            tokenService.updateAccessToken(access)
          }
          return instance(originalConfig)
        } catch (err) {
          return Promise.reject(err)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default instance
